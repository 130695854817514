import { useYbugApi } from "ybug-react";

export default function FeedbackButton() {
    const YbugContext = useYbugApi();

    const onClick = () => {
        if (YbugContext.Ybug) {
            YbugContext.Ybug.open("feedback");
        }
    };

    return (
        <button
            className="btn btn-danger btn-lg"
            onClick={onClick}
        >Open Feedback
        </button>
    );
}
