import React from "react"
import { Container, Row, Col } from "reactstrap"
import Head from "next/head";
import Link from 'next/link';

const Page403 = () => {
    return (
        <React.Fragment>
            <div className="account-pages my-5 pt-5">
                <Head>
                    <title>403 Error Page | Forbidden</title>
                </Head>
                <Container>
                    <Row>
                        <Col lg="12">
                            <div className="text-center mb-5">
                                <h1 className="display-2 font-weight-medium">
                                    4<i className="bx bx-buoy bx-spin text-primary display-3" />
                                    3
                                </h1>
                                <h4 className="text-uppercase">You are not authorized to access this page!</h4>
                                <div className="mt-5 text-center">
                                    <Link
                                        href={`/workspace`}
                                        passHref
                                        legacyBehavior
                                    >
                                        <a className="btn btn-primary btn-lg" rel="noopener noreferrer">
                                            Back to Workspace
                                        </a>
                                    </Link>{" "}
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )
}

export default Page403
