import { createSlice } from "@reduxjs/toolkit";

import { CognitoUserDto } from "models/DataModels/Account/AccountDto";

import { UserModel } from "../../gen/openapi";

interface initType {
  isAuth: boolean;
  cognitoUser: CognitoUserDto | null;
  user: UserModel | null;
}

const initialState: initType = {
  isAuth: false,
  cognitoUser: null,
  user: undefined
};

const userSlice = createSlice({
  name: "userSlice",
  initialState,
  reducers: {
    setIsAuth: (state, action) => ({ ...state, isAuth: action.payload }),
    setCognitoUser:(state,action)=>({ ...state, cognitoUser: action.payload }),
    setUser:(state,action)=>({...state,user:action.payload}),
  }
});

export const { setUser, setCognitoUser, setIsAuth } = userSlice.actions;
export default userSlice.reducer;
