const ISSERVER = typeof window === "undefined";

export const storage = ({
    GET: (key: string) => !ISSERVER? localStorage.getItem(key):null,
    SET: (key: string, data: any) => !ISSERVER? localStorage.setItem(key, data):null,
    REMOVE: (key: string) => localStorage.removeItem(key)
})

export const storageKeys = ({
    LanguageKey: "i18nextLng",
    Token: "AccessToken",
    RefreshToken: "RefreshToken",
    // User Information
    User:"User",
    CognitoUserName: "CognitoUserName",
    isAuth:"isAuth",
    // Company Information
    LanguageId: "LanguageId",
    userHistory:"userHistory",
    MyActiveProductFeatures:"MyActiveProductFeatures",
    CognitoGroups: "CognitoGroups",
    MyLocations: "MyLocations",
    MySelectedLocation: "MySelectedLocation",
})
