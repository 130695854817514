// import React from "react";
//
// class ErrorBoundary extends React.Component {
//     constructor(props) {
//         super(props)
//
//         // Define a state variable to track whether is an error or not
//         this.state = { hasError: false }
//     }
//     static getDerivedStateFromError(error) {
//         // Update state so the next render will show the fallback UI
//
//         return { hasError: true }
//     }
//     componentDidCatch(error, errorInfo) {
//         // You can use your own error logging service here
//         console.log({ error, errorInfo })
//     }
//     render() {
//         // Check if the error is thrown
//         if (this.state.hasError) {
//             // You can render any custom fallback UI
//             return (
//                 <div>
//                     <h2>Oops, there is an error!</h2>
//                     <button
//                         type="button"
//                         onClick={() => this.setState({ hasError: false })}
//                     >
//                         Try again?
//                     </button>
//                 </div>
//             )
//         }
//
//         // Return children components in case of no error
//
//         return this.props.children
//     }
// }
//
// export default ErrorBoundary
import React from "react";
import { Col, Container, Row } from "reactstrap";
import FeedbackButton from "./FeedbackButton";
import Head from "next/head";

class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props)

        // Define a state variable to track whether is an error or not
        this.state = { hasError: false }
    }
    static getDerivedStateFromError() {
        // Update state so the next render will show the fallback UI

        return { hasError: true }
    }
    componentDidCatch(error, errorInfo) {
        // You can use your own error logging service here
        console.log({ error, errorInfo })
    }
    render() {
        // Check if the error is thrown
        if (this.state.hasError) {
            // You can render any custom fallback UI
            return (
                <React.Fragment>
                    <div className="account-pages my-5 pt-5">
                        <Head>
                            <title>Error Page | Error</title>
                        </Head>
                        <Container>
                            <Row>
                                <Col lg="12">
                                    <div className="text-center mb-5">
                                        <h4 className="text-uppercase">Oops, there is an error!</h4>
                                        <div className="mt-5 text-center">
                                            <button
                                                onClick={() => this.setState({ hasError: false })}
                                                className="btn btn-primary btn-lg"
                                            >
                                                Try again
                                            </button>
                                        </div>
                                        <div className="mt-5 text-center">
                                            <FeedbackButton />
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                </React.Fragment>
            )
        }

        // Return children components in case of no error

        return this.props.children
    }
}

export default ErrorBoundary
