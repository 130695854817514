import { configureStore } from '@reduxjs/toolkit';
// import logger from 'redux-logger';
import { reduxBatch } from '@manaflair/redux-batch';

import commonSlice from './common/commonSlice';
import userSlice from './common/userSlice';

const reducer = { commonSlice,userSlice };

export const store = configureStore({
  reducer,
  // middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(logger),
  middleware: (getDefaultMiddleware) => getDefaultMiddleware(),
  devTools: process.env.NODE_ENV !== 'production',
  enhancers: [reduxBatch]
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
