import { createSlice } from "@reduxjs/toolkit";

import { NotificationType } from '../../models/Enums/NotificationType';

interface OpenNotificationDto {
  isShow: boolean;
  message: string;
  type: NotificationType;
}

interface initType {
  isLoading: boolean;
  isOverlay: boolean;
  isSplashScreen: boolean;
  openNotification: OpenNotificationDto;
  queryDate: string;
  headerTitle: string;
  headerSubtitle: string;
  headerProgramType: string;
  headerSup: string;

}

const initialState: initType = {
  isLoading: false,
  isOverlay: false,
  isSplashScreen: false,
  openNotification: {
    message: '',
    type: NotificationType.Error,
    isShow: false
  },
  queryDate: JSON.stringify(new Date()),
  headerTitle: '',
  headerSubtitle: '',
  headerProgramType: '',
  headerSup: ''
};

const commonSlice = createSlice({
  name: "commonSlice",
  initialState,
  reducers: {
    setLoading: (state, action) => ({ ...state, isLoading: action.payload }),
    setOverlay: (state, action) => ({ ...state, isOverlay: action.payload }),
    setSplashScreen: (state, action) => ({ ...state, isSplashScreen: action.payload }),
    setOpenNotification: (state, action) => ({ ...state, openNotifcation: action.payload }),
    setQueryDate: (state, action) => ({...state, queryDate: action.payload}),
    setHeaderTitle: (state, action) => ({...state, headerTitle: action.payload}),
    setHeaderSubtitle: (state, action) => ({...state, headerSubtitle: action.payload}),
    setHeaderProgramType: (state, action) => ({...state, headerProgramType: action.payload}),
    setHeaderSup: (state, action) => ({...state, headerSup: action.payload}),
  }
});

export const { setLoading, setOverlay, setSplashScreen, setOpenNotification, setQueryDate, setHeaderTitle, setHeaderSubtitle, setHeaderProgramType, setHeaderSup } = commonSlice.actions;
export default commonSlice.reducer;
