import React, { useEffect, useState } from 'react';
import ErrorBoundary from '../components/Error/Client/ErrorBoundary';
// import ComingSoon from "../components/Static/ComingSoon";
import dynamic from "next/dynamic";
import { useRouter } from 'next/router';
// import Script from 'next/script'

import { Provider } from 'react-redux';
import { Account } from '../store/account';
// import { setIsAuth } from 'store/common/userSlice';
import { store } from '../store/store';

import { YbugProvider } from "ybug-react";

import { NextSeo } from "next-seo";

import 'react-phone-number-input/style.css'

import '/public/assets/scss/theme.scss';
import '/public/assets/global.css';
import '../styles/hc/main.scss';

import '../components/VerticalLayout/index.css';

// import Amplify, { withSSRContext } from 'aws-amplify';
import Amplify from 'aws-amplify';
import { Hub } from '@aws-amplify/core';

import config from './api/static/aws-exports';

import { RoleType } from "../gen/openapi";

const ComingSoon = dynamic(
    () => import('components/Static/ComingSoon').then(d => d.default),
    { ssr: false }
);

const VerticalLayout = dynamic(
    () => import('components/VerticalLayout'),
    { ssr: false }
);

Amplify.configure({ ...config, ssr: true });

// const { Auth } = withSSRContext();
// Auth.configure({
//   region: process.env.NEXT_PUBLIC_COGNITO_REGION,
//   userPoolId:  process.env.NEXT_PUBLIC_COGNITO_USER_POOL_ID,
//   userPoolWebClientId: process.env.NEXT_PUBLIC_COGNITO_USER_POOL_CLIENT_ID,
//   authenticationFlowType: 'USER_SRP_AUTH',
// });

const YBUG_ID = process.env.NEXT_PUBLIC_YBUG_ID || "19j0dg8bgr5fa3kxyws3";

const App = ({ Component, pageProps }) => {
  const router = useRouter();
  const withoutLayoutArray = [
    '/',
    '/sign-in',
    '/sign-up',
    '/forgot-password',
    '/confirm-password',
    '/change-password',
    '/reset-password',
    '/404',
    '/500',
    '/verify',
    '/contact-us',
    '/terms-of-use',
    '/who-we-are',
    '/what-we-do',
    '/why-us',
    '/about-us',
    '/privacy-policy',
    '/end-user-license-agreement',
  ];

  if (JSON.parse(Account.User.get()) == null) {
    withoutLayoutArray.push('/pricing')
  }

  if (pageProps.fromHomepage) {
    withoutLayoutArray.push('/tools/ncci-lite/medicaid-demo')
    withoutLayoutArray.push('/tools/ncci-pro/medicaid-demo')
    withoutLayoutArray.push('/tools/ncci-lite/medicare-demo')
    withoutLayoutArray.push('/tools/ncci-pro/medicare-demo')
  }

  const isWithoutLayout = withoutLayoutArray.includes(router.pathname);
  const [isAuth, setAuth] = useState(false);

  const [userInfo, setUserInfo] = useState({});

  const [userCognitoGroups, setUserCognitoGroups] = useState(null);

  const [userActiveProductFeatures, setUserActiveProductFeatures] = useState(null);

  const getUserInfo = () => {
    try {
      let _user = JSON.parse(Account.User.get());

      if (_user)
        setUserInfo(_user);
      else
        setUserInfo({});
    } catch (error) {
      console.log(error);

      setUserInfo({});
    }
  };

  const getUserGroups = () => {
    try {
      let _cognitoGroups = Account.CognitoGroups.get();

      if (_cognitoGroups)
        setUserCognitoGroups(_cognitoGroups.split(',').map(g => g.trim()))
      else
        setUserCognitoGroups(null);
    } catch (error) {
      console.log(error);

      setUserCognitoGroups(null);
    }
  };

  const getUserActiveProductFeatures = () => {
    try {
      let _userActiveProductFeatures = JSON.parse(Account.MyActiveProductFeatures.get());

      if (_userActiveProductFeatures)
        setUserActiveProductFeatures(_userActiveProductFeatures);
      else
        setUserActiveProductFeatures(null);
    } catch (error) {
      console.log(error);

      setUserActiveProductFeatures(null);
    }
  };

  useEffect(() => {
    getUserInfo();
    getUserGroups();
    getUserActiveProductFeatures();
  }, [router.asPath]);

  useEffect(() => {
    Hub.listen('auth', ({ payload: { event } }) => {
      //console.log('hub- is Auth değişti', event, data)
      switch (event) {
        case 'signIn':
          setAuth(true);
          break;
        case 'signOut':
          setAuth(false);
          break;
        case 'signIn_failure':
          setAuth(false);
          break;
      }
    });
  }, [isAuth]);

  // Auth.currentSession()
  //   .then(async (currentSession) => {
  //     const idTokenExpireTime = currentSession.getIdToken().getExpiration();
  //     const currentTime = Math.round(+new Date() / 1000);
  //     // const refreshToken = currentSession.getRefreshToken();
  //
  //     if (idTokenExpireTime < currentTime) {
  //       Auth.currentAuthenticatedUser().then((res) => {
  //         // const { refreshToken } = res.getSignInUserSession();
  //         const userSession = res.getSignInUserSession();
  //
  //         // res.refreshSession(refreshToken, (err, session) => {
  //         res.refreshSession(userSession.refreshToken, (err, session) => {
  //           if (err) {
  //             //error logic
  //           } else {
  //             Account.token.set(session.idToken.jwtToken);
  //             Account.refreshToken.set(session.refreshToken.token);
  //           }
  //         });
  //       });
  //     }
  //
  //     store.dispatch(setIsAuth(true));
  //     setAuth(true);
  //   })
  //   .catch((error) => {
  //     console.log(error);
  //
  //     store.dispatch(setIsAuth(false));
  //     setAuth(false);
  //   });

  if (pageProps.protected && !Object.keys(userInfo).length)
    return (
      <div id={'globalLoader'}>
        <div className="loader"/>
      </div>
    );

  if (pageProps.authorized && !userActiveProductFeatures)
    return (
        <div id={'globalLoader'}>
          <div className="loader"/>
        </div>
    );

  // if (
  //   pageProps.protected &&
  //   Object.keys(userInfo).length &&
  //   pageProps.userTypes &&
  //   pageProps.userTypes.indexOf(userInfo.role) === -1
  // ) {
  if (
      pageProps.protected &&
      (
          (
              !pageProps.userTypes || (
                  pageProps.userTypes &&
                  pageProps.userTypes.length &&
                  pageProps.userTypes.indexOf(userInfo.role) === -1
              )
          )
          &&
          (
              !pageProps.groupTypes || (
                  pageProps.groupTypes &&
                  pageProps.groupTypes.length &&
                  pageProps.groupTypes.every(g => !(userCognitoGroups && userCognitoGroups.includes(g)))
              )
          )
      )
  ) {
    const Page403 = require('../components/Error/Server/403').default;

    return <Page403/>;
  }

  if (userInfo.role !== RoleType.SuperAdmin && userInfo.role !== RoleType.GlobalAdmin && userInfo.role !== RoleType.SupportAdmin) {
    if (pageProps.authorized &&
        userActiveProductFeatures &&
        pageProps.auth &&
        !userActiveProductFeatures.features.includes(pageProps.auth)
    ) {
      const Page403 = require('../components/Error/Server/403').default;

      return <Page403/>;
    }
  }

  return isWithoutLayout ? (
      <YbugProvider ybugId={YBUG_ID}>
        <Provider store={store}>
          <ErrorBoundary>
            <NextSeo
                defaultTitle="All Medical Codes"
                title={pageProps && pageProps.title ? pageProps.title : ""}
            />
            {pageProps.underConstruction ? <ComingSoon /> : <Component {...pageProps} />}
          </ErrorBoundary>
        </Provider>
      </YbugProvider>
  ) : (
      // <>
      //   <Script nonce={pageProps.nonce} type="text/javascript">
      //     {`
      //       (function() {
      //           window.ybug_settings = {"id":"19j0dg8bgr5fa3kxyws3", nonce: "${pageProps.nonce}"};
      //           var ybug = document.createElement('script'); ybug.nonce = "${pageProps.nonce}"; ybug.type = 'text/javascript'; ybug.async = true;
      //           ybug.src = 'https://widget.ybug.io/button/'+window.ybug_settings.id+'.js';
      //           var s = document.getElementsByTagName('script')[0]; s.parentNode.insertBefore(ybug, s);
      //       })();
      //     `}
      //   </Script>
        <YbugProvider ybugId={YBUG_ID}>
          <Provider store={store}>
            <ErrorBoundary>
              <VerticalLayout isAuth={isAuth}>
                <div className="page-content">
                  <NextSeo
                      defaultTitle="All Medical Codes"
                      title={pageProps && pageProps.title ? pageProps.title : ""}
                  />
                  {pageProps.underConstruction ? <ComingSoon /> : <Component {...pageProps} />}
                </div>
              </VerticalLayout>
            </ErrorBoundary>
          </Provider>
        </YbugProvider>
      // </>
  );
};

export default App;
