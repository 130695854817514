import { UserHistoryDto } from "models/DataModels/Account/UserHistoryDto";

import { UserLocationModel } from "gen/openapi";

import { storage, storageKeys } from "./storage";

export const Account = {
  token: {
    get: () => `Bearer ${storage.GET(storageKeys.Token)}`,
    set: (token: string) => storage.SET(storageKeys.Token, token),
    remove: () => storage.REMOVE(storageKeys.Token),
  },
  CognitoUserName: {
    get: () => storage.GET(storageKeys.CognitoUserName),
    set: (UserName: string) =>
      storage.SET(storageKeys.CognitoUserName, UserName),
    remove: () => storage.REMOVE(storageKeys.CognitoUserName),
  },
  User: {
    get: () => storage.GET(storageKeys.User),
    set: (User: any) =>
      storage.SET(storageKeys.User,User ),
    remove: () => storage.REMOVE(storageKeys.User),
  },
  languageId: {
    get: () => storage.GET(storageKeys.LanguageId),
    set: (languageId: number) =>
      storage.SET(storageKeys.LanguageId, languageId),
    remove: () => storage.REMOVE(storageKeys.LanguageId),
  },
  refreshToken: {
    get: () => storage.GET(storageKeys.RefreshToken),
    set: (refreshToken: string) =>
      storage.SET(storageKeys.RefreshToken, refreshToken),
    remove: () => storage.REMOVE(storageKeys.RefreshToken),
  },
  userHistory:{
    get: () => storage.GET(storageKeys.userHistory),
    set: (data: UserHistoryDto) =>
      storage.SET(storageKeys.userHistory,JSON.stringify(data)),
    remove: () => storage.REMOVE(storageKeys.userHistory),
  },
  MyActiveProductFeatures: {
    get: () => storage.GET(storageKeys.MyActiveProductFeatures),
    set: (MyActiveProductFeatures: any) =>
      storage.SET(storageKeys.MyActiveProductFeatures,MyActiveProductFeatures ),
    remove: () => storage.REMOVE(storageKeys.MyActiveProductFeatures),
  },
  CognitoGroups: {
    get: () => storage.GET(storageKeys.CognitoGroups),
    set: (Groups: string[]) =>
      storage.SET(storageKeys.CognitoGroups, Groups),
    remove: () => storage.REMOVE(storageKeys.CognitoGroups),
  },
  MyLocations: {
    get: () => storage.GET(storageKeys.MyLocations),
    set: (data: UserLocationModel[]) =>
      storage.SET(storageKeys.MyLocations,JSON.stringify(data) ),
    remove: () => storage.REMOVE(storageKeys.MyLocations),
  },
  MySelectedLocation: {
    get: () => storage.GET(storageKeys.MySelectedLocation),
    set: (data: UserLocationModel) =>
      storage.SET(storageKeys.MySelectedLocation,JSON.stringify(data) ),
    remove: () => storage.REMOVE(storageKeys.MySelectedLocation),
  },
};
