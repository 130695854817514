/* tslint:disable */
/* eslint-disable */
/**
 * MedicalCodes API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface AOCExceptionDetailModel
 */
export interface AOCExceptionDetailModel {
    /**
     * 
     * @type {string}
     * @memberof AOCExceptionDetailModel
     */
    'hcpcsCptCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AOCExceptionDetailModel
     */
    'exceptionType'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AOCExceptionDetailModel
     */
    'exceptionMessage'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AOCExceptionDetailModel
     */
    'primaryCodes'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AOCExceptionDetailModel
     */
    'additionalMessage'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AOCExceptionDetailModel
     */
    'aocEditEffectiveDate'?: string | null;
    /**
     * 
     * @type {AddOnCodeEditType}
     * @memberof AOCExceptionDetailModel
     */
    'addOnCodeEditType'?: AddOnCodeEditType;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const AddOnCodeEditType = {
    NotAvailable: 'NotAvailable',
    TypeI: 'TypeI',
    TypeIi: 'TypeII',
    TypeIii: 'TypeIII'
} as const;

export type AddOnCodeEditType = typeof AddOnCodeEditType[keyof typeof AddOnCodeEditType];


/**
 * 
 * @export
 * @interface AddressModel
 */
export interface AddressModel {
    /**
     * 
     * @type {string}
     * @memberof AddressModel
     */
    'city'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AddressModel
     */
    'country'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AddressModel
     */
    'line1'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AddressModel
     */
    'line2'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AddressModel
     */
    'zipCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AddressModel
     */
    'state'?: string | null;
}
/**
 * 
 * @export
 * @interface AdvancedSearchRequestModel
 */
export interface AdvancedSearchRequestModel {
    /**
     * 
     * @type {string}
     * @memberof AdvancedSearchRequestModel
     */
    'searchText'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof AdvancedSearchRequestModel
     */
    'isPrefix'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof AdvancedSearchRequestModel
     */
    'skipItemCount'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof AdvancedSearchRequestModel
     */
    'maxItemCount'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof AdvancedSearchRequestModel
     */
    'highlightPreTag'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AdvancedSearchRequestModel
     */
    'highlightPostTag'?: string | null;
    /**
     * 
     * @type {Array<CodeSetType>}
     * @memberof AdvancedSearchRequestModel
     */
    'codeSetTypes'?: Array<CodeSetType> | null;
    /**
     * 
     * @type {string}
     * @memberof AdvancedSearchRequestModel
     */
    'userId'?: string | null;
}
/**
 * 
 * @export
 * @interface ApiAccessLogModel
 */
export interface ApiAccessLogModel {
    /**
     * 
     * @type {string}
     * @memberof ApiAccessLogModel
     */
    'requestId'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiAccessLogModel
     */
    'httpMethod'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApiAccessLogModel
     */
    'sourceIp'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApiAccessLogModel
     */
    'protocol'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApiAccessLogModel
     */
    'requestAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiAccessLogModel
     */
    'resourcePath'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ApiAccessLogModel
     */
    'responseStatus'?: number;
    /**
     * 
     * @type {string}
     * @memberof ApiAccessLogModel
     */
    'userAgent'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ApiAccessLogModel
     */
    'responseLength'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ApiAccessLogModel
     */
    'createdOn'?: string;
}
/**
 * 
 * @export
 * @interface ApiAccessLogModelIEnumerableIDataResult
 */
export interface ApiAccessLogModelIEnumerableIDataResult {
    /**
     * 
     * @type {Array<ApiAccessLogModel>}
     * @memberof ApiAccessLogModelIEnumerableIDataResult
     */
    'data'?: Array<ApiAccessLogModel> | null;
    /**
     * 
     * @type {boolean}
     * @memberof ApiAccessLogModelIEnumerableIDataResult
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ApiAccessLogModelIEnumerableIDataResult
     */
    'message'?: string | null;
}
/**
 * 
 * @export
 * @interface ApiUsagePlanModel
 */
export interface ApiUsagePlanModel {
    /**
     * 
     * @type {string}
     * @memberof ApiUsagePlanModel
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiUsagePlanModel
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApiUsagePlanModel
     */
    'description'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ApiUsagePlanModel
     */
    'quota'?: number;
    /**
     * 
     * @type {number}
     * @memberof ApiUsagePlanModel
     */
    'rate'?: number;
    /**
     * 
     * @type {number}
     * @memberof ApiUsagePlanModel
     */
    'burst'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof ApiUsagePlanModel
     */
    'planProperties'?: Array<string> | null;
}
/**
 * 
 * @export
 * @interface ApiUsagePlanModelIEnumerableIDataResult
 */
export interface ApiUsagePlanModelIEnumerableIDataResult {
    /**
     * 
     * @type {Array<ApiUsagePlanModel>}
     * @memberof ApiUsagePlanModelIEnumerableIDataResult
     */
    'data'?: Array<ApiUsagePlanModel> | null;
    /**
     * 
     * @type {boolean}
     * @memberof ApiUsagePlanModelIEnumerableIDataResult
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ApiUsagePlanModelIEnumerableIDataResult
     */
    'message'?: string | null;
}
/**
 * 
 * @export
 * @interface Betos
 */
export interface Betos {
    /**
     * 
     * @type {string}
     * @memberof Betos
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof Betos
     */
    'createdBy'?: string;
    /**
     * 
     * @type {string}
     * @memberof Betos
     */
    'createdOn'?: string;
    /**
     * 
     * @type {string}
     * @memberof Betos
     */
    'lastModifiedBy'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Betos
     */
    'lastModifiedOn'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof Betos
     */
    'isDeleted'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Betos
     */
    'parentId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Betos
     */
    'group'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Betos
     */
    'description'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof Betos
     */
    'child'?: boolean;
}
/**
 * 
 * @export
 * @interface BetosIEnumerableIDataResult
 */
export interface BetosIEnumerableIDataResult {
    /**
     * 
     * @type {Array<Betos>}
     * @memberof BetosIEnumerableIDataResult
     */
    'data'?: Array<Betos> | null;
    /**
     * 
     * @type {boolean}
     * @memberof BetosIEnumerableIDataResult
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof BetosIEnumerableIDataResult
     */
    'message'?: string | null;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const BillingPeriodType = {
    OneType: 'OneType',
    Monthly: 'Monthly',
    Annually: 'Annually'
} as const;

export type BillingPeriodType = typeof BillingPeriodType[keyof typeof BillingPeriodType];


/**
 * 
 * @export
 * @interface BooleanIDataResult
 */
export interface BooleanIDataResult {
    /**
     * 
     * @type {boolean}
     * @memberof BooleanIDataResult
     */
    'data'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof BooleanIDataResult
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof BooleanIDataResult
     */
    'message'?: string | null;
}
/**
 * 
 * @export
 * @interface ByteArrayIDataResult
 */
export interface ByteArrayIDataResult {
    /**
     * 
     * @type {string}
     * @memberof ByteArrayIDataResult
     */
    'data'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ByteArrayIDataResult
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ByteArrayIDataResult
     */
    'message'?: string | null;
}
/**
 * 
 * @export
 * @interface ChangePasswordModel
 */
export interface ChangePasswordModel {
    /**
     * 
     * @type {string}
     * @memberof ChangePasswordModel
     */
    'accessToken'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ChangePasswordModel
     */
    'previousPassword'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ChangePasswordModel
     */
    'proposedPassword'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ChangePasswordModel
     */
    'proposedPasswordConfirmation'?: string | null;
}
/**
 * 
 * @export
 * @interface CodeItem
 */
export interface CodeItem {
    /**
     * 
     * @type {string}
     * @memberof CodeItem
     */
    'code'?: string;
    /**
     * 
     * @type {string}
     * @memberof CodeItem
     */
    'poa'?: CodeItemPoaEnum;
}

export const CodeItemPoaEnum = {
    Y: 'Y',
    N: 'N',
    U: 'U',
    W: 'W',
    One: 'ONE',
    E: 'E',
    Blank: 'BLANK',
    Invalid: 'INVALID'
} as const;

export type CodeItemPoaEnum = typeof CodeItemPoaEnum[keyof typeof CodeItemPoaEnum];

/**
 * 
 * @export
 * @interface CodeModel
 */
export interface CodeModel {
    /**
     * 
     * @type {CodeSetType}
     * @memberof CodeModel
     */
    'codeSetType'?: CodeSetType;
    /**
     * 
     * @type {string}
     * @memberof CodeModel
     */
    'code'?: string | null;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const CodeSetType = {
    Unknown: 'Unknown',
    Icd9Code: 'ICD9Code',
    Icd10CmCode: 'ICD10CMCode',
    Icd10PcsCode: 'ICD10PCSCode',
    Icd11Code: 'ICD11Code',
    HcpcsCode: 'HCPCSCode',
    HcpcsModifier: 'HCPCSModifier',
    CptCode: 'CPTCode',
    CptModifier: 'CPTModifier'
} as const;

export type CodeSetType = typeof CodeSetType[keyof typeof CodeSetType];


/**
 * 
 * @export
 * @enum {string}
 */

export const CodeType = {
    Unknown: 'Unknown',
    DiagnosisCode: 'DiagnosisCode',
    ProcedureCode: 'ProcedureCode'
} as const;

export type CodeType = typeof CodeType[keyof typeof CodeType];


/**
 * 
 * @export
 * @interface CodeVersionModel
 */
export interface CodeVersionModel {
    /**
     * 
     * @type {CodeSetType}
     * @memberof CodeVersionModel
     */
    'codeSetType'?: CodeSetType;
    /**
     * 
     * @type {string}
     * @memberof CodeVersionModel
     */
    'versionName'?: string | null;
    /**
     * 
     * @type {DateOnly}
     * @memberof CodeVersionModel
     */
    'quarterBeginDate'?: DateOnly;
}
/**
 * 
 * @export
 * @interface CodeVersionModelIEnumerableIDataResult
 */
export interface CodeVersionModelIEnumerableIDataResult {
    /**
     * 
     * @type {Array<CodeVersionModel>}
     * @memberof CodeVersionModelIEnumerableIDataResult
     */
    'data'?: Array<CodeVersionModel> | null;
    /**
     * 
     * @type {boolean}
     * @memberof CodeVersionModelIEnumerableIDataResult
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CodeVersionModelIEnumerableIDataResult
     */
    'message'?: string | null;
}
/**
 * 
 * @export
 * @interface CognitoUsersByCompanyRequestModel
 */
export interface CognitoUsersByCompanyRequestModel {
    /**
     * 
     * @type {string}
     * @memberof CognitoUsersByCompanyRequestModel
     */
    'companyId'?: string;
    /**
     * 
     * @type {string}
     * @memberof CognitoUsersByCompanyRequestModel
     */
    'email'?: string | null;
}
/**
 * 
 * @export
 * @interface CognitoUsersByCompanyResponseModel
 */
export interface CognitoUsersByCompanyResponseModel {
    /**
     * 
     * @type {string}
     * @memberof CognitoUsersByCompanyResponseModel
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof CognitoUsersByCompanyResponseModel
     */
    'email'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CognitoUsersByCompanyResponseModel
     */
    'userStatus'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CognitoUsersByCompanyResponseModel
     */
    'cretaedOnUtc'?: string;
    /**
     * 
     * @type {string}
     * @memberof CognitoUsersByCompanyResponseModel
     */
    'signupDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof CognitoUsersByCompanyResponseModel
     */
    'lastModifiedDate'?: string | null;
}
/**
 * 
 * @export
 * @interface CognitoUsersByCompanyResponseModelIEnumerableIDataResult
 */
export interface CognitoUsersByCompanyResponseModelIEnumerableIDataResult {
    /**
     * 
     * @type {Array<CognitoUsersByCompanyResponseModel>}
     * @memberof CognitoUsersByCompanyResponseModelIEnumerableIDataResult
     */
    'data'?: Array<CognitoUsersByCompanyResponseModel> | null;
    /**
     * 
     * @type {boolean}
     * @memberof CognitoUsersByCompanyResponseModelIEnumerableIDataResult
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CognitoUsersByCompanyResponseModelIEnumerableIDataResult
     */
    'message'?: string | null;
}
/**
 * 
 * @export
 * @interface Company
 */
export interface Company {
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    'createdBy'?: string;
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    'createdOn'?: string;
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    'lastModifiedBy'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    'lastModifiedOn'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof Company
     */
    'isDeleted'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    'companyName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    'address'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    'city'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    'state'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    'zipCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    'workFlowType'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    'logoFileName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    'billingFirstName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    'billingLastName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    'billingCompanyName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    'billingAddress'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    'billingState'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    'billingZipCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    'billingEmail'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    'billingPhone'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    'invoiceCcEmail'?: string | null;
}
/**
 * 
 * @export
 * @interface CompanyApiKeyResponseModel
 */
export interface CompanyApiKeyResponseModel {
    /**
     * 
     * @type {string}
     * @memberof CompanyApiKeyResponseModel
     */
    'companyApiKeyId'?: string;
    /**
     * 
     * @type {string}
     * @memberof CompanyApiKeyResponseModel
     */
    'companyId'?: string;
    /**
     * 
     * @type {string}
     * @memberof CompanyApiKeyResponseModel
     */
    'companyName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CompanyApiKeyResponseModel
     */
    'apiKeyId'?: string;
    /**
     * 
     * @type {string}
     * @memberof CompanyApiKeyResponseModel
     */
    'apiUsagePlanId'?: string;
    /**
     * 
     * @type {string}
     * @memberof CompanyApiKeyResponseModel
     */
    'apiUsagePlanName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CompanyApiKeyResponseModel
     */
    'description'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof CompanyApiKeyResponseModel
     */
    'quota'?: number;
    /**
     * 
     * @type {number}
     * @memberof CompanyApiKeyResponseModel
     */
    'rate'?: number;
    /**
     * 
     * @type {number}
     * @memberof CompanyApiKeyResponseModel
     */
    'burst'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof CompanyApiKeyResponseModel
     */
    'planProperties'?: Array<string> | null;
    /**
     * 
     * @type {boolean}
     * @memberof CompanyApiKeyResponseModel
     */
    'isActive'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CompanyApiKeyResponseModel
     */
    'awsApiKeyId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CompanyApiKeyResponseModel
     */
    'awsUsagePlanId'?: string | null;
}
/**
 * 
 * @export
 * @interface CompanyApiKeyResponseModelIDataResult
 */
export interface CompanyApiKeyResponseModelIDataResult {
    /**
     * 
     * @type {CompanyApiKeyResponseModel}
     * @memberof CompanyApiKeyResponseModelIDataResult
     */
    'data'?: CompanyApiKeyResponseModel;
    /**
     * 
     * @type {boolean}
     * @memberof CompanyApiKeyResponseModelIDataResult
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CompanyApiKeyResponseModelIDataResult
     */
    'message'?: string | null;
}
/**
 * 
 * @export
 * @interface CompanyModel
 */
export interface CompanyModel {
    /**
     * 
     * @type {string}
     * @memberof CompanyModel
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof CompanyModel
     */
    'companyName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CompanyModel
     */
    'address'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CompanyModel
     */
    'country'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CompanyModel
     */
    'city'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CompanyModel
     */
    'state'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CompanyModel
     */
    'zipCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CompanyModel
     */
    'workFlowType'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CompanyModel
     */
    'logoFileName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CompanyModel
     */
    'billingFirstName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CompanyModel
     */
    'billingLastName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CompanyModel
     */
    'billingCompanyName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CompanyModel
     */
    'billingAddress'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CompanyModel
     */
    'billingState'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CompanyModel
     */
    'billingCountry'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CompanyModel
     */
    'billingCity'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CompanyModel
     */
    'billingZipCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CompanyModel
     */
    'billingEmail'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CompanyModel
     */
    'billingPhone'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CompanyModel
     */
    'invoiceCcEmail'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CompanyModel
     */
    'modifiedBy'?: string | null;
}
/**
 * 
 * @export
 * @interface CompanyModelIDataResult
 */
export interface CompanyModelIDataResult {
    /**
     * 
     * @type {CompanyModel}
     * @memberof CompanyModelIDataResult
     */
    'data'?: CompanyModel;
    /**
     * 
     * @type {boolean}
     * @memberof CompanyModelIDataResult
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CompanyModelIDataResult
     */
    'message'?: string | null;
}
/**
 * 
 * @export
 * @interface CompanyModelIEnumerableIDataResult
 */
export interface CompanyModelIEnumerableIDataResult {
    /**
     * 
     * @type {Array<CompanyModel>}
     * @memberof CompanyModelIEnumerableIDataResult
     */
    'data'?: Array<CompanyModel> | null;
    /**
     * 
     * @type {boolean}
     * @memberof CompanyModelIEnumerableIDataResult
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CompanyModelIEnumerableIDataResult
     */
    'message'?: string | null;
}
/**
 * 
 * @export
 * @interface CompanyUserModel
 */
export interface CompanyUserModel {
    /**
     * 
     * @type {string}
     * @memberof CompanyUserModel
     */
    'id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CompanyUserModel
     */
    'cognitoUserId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CompanyUserModel
     */
    'firstName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CompanyUserModel
     */
    'lastName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CompanyUserModel
     */
    'phone'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CompanyUserModel
     */
    'email'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CompanyUserModel
     */
    'country'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CompanyUserModel
     */
    'zipCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CompanyUserModel
     */
    'title'?: string | null;
    /**
     * 
     * @type {RoleType}
     * @memberof CompanyUserModel
     */
    'role'?: RoleType;
    /**
     * 
     * @type {Array<string>}
     * @memberof CompanyUserModel
     */
    'groupNames'?: Array<string> | null;
}
/**
 * 
 * @export
 * @interface CompletionField
 */
export interface CompletionField {
    /**
     * 
     * @type {{ [key: string]: Array<string>; }}
     * @memberof CompletionField
     */
    'contexts'?: { [key: string]: Array<string>; } | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof CompletionField
     */
    'input'?: Array<string> | null;
    /**
     * 
     * @type {number}
     * @memberof CompletionField
     */
    'weight'?: number | null;
}
/**
 * 
 * @export
 * @interface ConfirmForgotPasswordModel
 */
export interface ConfirmForgotPasswordModel {
    /**
     * 
     * @type {string}
     * @memberof ConfirmForgotPasswordModel
     */
    'confirmationCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ConfirmForgotPasswordModel
     */
    'password'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ConfirmForgotPasswordModel
     */
    'passwordConfirmation'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ConfirmForgotPasswordModel
     */
    'username'?: string | null;
}
/**
 * 
 * @export
 * @interface ConflictsLiteRequestModel
 */
export interface ConflictsLiteRequestModel {
    /**
     * 
     * @type {NcciType}
     * @memberof ConflictsLiteRequestModel
     */
    'location'?: NcciType;
    /**
     * 
     * @type {Array<string>}
     * @memberof ConflictsLiteRequestModel
     */
    'proceduralCodeList'?: Array<string> | null;
    /**
     * 
     * @type {VersionModel}
     * @memberof ConflictsLiteRequestModel
     */
    'version'?: VersionModel;
    /**
     * 
     * @type {ProgramType}
     * @memberof ConflictsLiteRequestModel
     */
    'programType'?: ProgramType;
    /**
     * 
     * @type {string}
     * @memberof ConflictsLiteRequestModel
     */
    'gpciId'?: string | null;
}
/**
 * 
 * @export
 * @interface ConflictsRequestDetailModel
 */
export interface ConflictsRequestDetailModel {
    /**
     * 
     * @type {string}
     * @memberof ConflictsRequestDetailModel
     */
    'proceduralCode'?: string | null;
    /**
     * 
     * @type {DateOnlyRequestModel}
     * @memberof ConflictsRequestDetailModel
     */
    'serviceDate'?: DateOnlyRequestModel;
    /**
     * 
     * @type {number}
     * @memberof ConflictsRequestDetailModel
     */
    'quantity'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof ConflictsRequestDetailModel
     */
    'modifiers'?: Array<string> | null;
}
/**
 * 
 * @export
 * @interface ConflictsRequestModel
 */
export interface ConflictsRequestModel {
    /**
     * 
     * @type {NcciType}
     * @memberof ConflictsRequestModel
     */
    'location'?: NcciType;
    /**
     * 
     * @type {Array<ConflictsRequestDetailModel>}
     * @memberof ConflictsRequestModel
     */
    'proceduralCodeList'?: Array<ConflictsRequestDetailModel> | null;
    /**
     * 
     * @type {ProgramType}
     * @memberof ConflictsRequestModel
     */
    'programType'?: ProgramType;
    /**
     * 
     * @type {string}
     * @memberof ConflictsRequestModel
     */
    'gpciId'?: string | null;
}
/**
 * 
 * @export
 * @interface ConflictsResponseModel
 */
export interface ConflictsResponseModel {
    /**
     * 
     * @type {string}
     * @memberof ConflictsResponseModel
     */
    'hcpcsCptCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ConflictsResponseModel
     */
    'hcpcsCptDescription'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ConflictsResponseModel
     */
    'hcpcsCptLongDescription'?: string | null;
    /**
     * 
     * @type {Array<RVUModel>}
     * @memberof ConflictsResponseModel
     */
    'rvuValues'?: Array<RVUModel> | null;
    /**
     * 
     * @type {number}
     * @memberof ConflictsResponseModel
     */
    'quantity'?: number;
    /**
     * 
     * @type {string}
     * @memberof ConflictsResponseModel
     */
    'modifiers'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ConflictsResponseModel
     */
    'hasPtpConflict'?: boolean;
    /**
     * 
     * @type {Array<PtpExceptionDetailModel>}
     * @memberof ConflictsResponseModel
     */
    'ptpExceptions'?: Array<PtpExceptionDetailModel> | null;
    /**
     * 
     * @type {boolean}
     * @memberof ConflictsResponseModel
     */
    'hasMueConflict'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ConflictsResponseModel
     */
    'mue'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ConflictsResponseModel
     */
    'mueRationale'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ConflictsResponseModel
     */
    'mueRationaleExtended'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ConflictsResponseModel
     */
    'allowedMueQuantity'?: number;
    /**
     * 
     * @type {string}
     * @memberof ConflictsResponseModel
     */
    'serviceDate'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ConflictsResponseModel
     */
    'hasAoCException'?: boolean;
    /**
     * 
     * @type {AOCExceptionDetailModel}
     * @memberof ConflictsResponseModel
     */
    'aocException'?: AOCExceptionDetailModel;
    /**
     * 
     * @type {boolean}
     * @memberof ConflictsResponseModel
     */
    'hasModifierException'?: boolean;
    /**
     * 
     * @type {Array<ProceduralCodeDetailModel>}
     * @memberof ConflictsResponseModel
     */
    'modifierValidationException'?: Array<ProceduralCodeDetailModel> | null;
    /**
     * 
     * @type {string}
     * @memberof ConflictsResponseModel
     */
    'anatomicModifiers'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ConflictsResponseModel
     */
    'otherModifiers'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ConflictsResponseModel
     */
    'isValidProceduralCode'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ConflictsResponseModel
     */
    'proceduralCodeValidationErrorMessage'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ConflictsResponseModel
     */
    'proceduralCodeValidationErrorTitle'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ConflictsResponseModel
     */
    'multipleEffectiveNcciEditsExists'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ConflictsResponseModel
     */
    'hasNoVersionDate'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ConflictsResponseModel
     */
    'quarterBeginDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ConflictsResponseModel
     */
    'gpciId'?: string | null;
}
/**
 * 
 * @export
 * @interface ConflictsResponseModelIEnumerableIDataResult
 */
export interface ConflictsResponseModelIEnumerableIDataResult {
    /**
     * 
     * @type {Array<ConflictsResponseModel>}
     * @memberof ConflictsResponseModelIEnumerableIDataResult
     */
    'data'?: Array<ConflictsResponseModel> | null;
    /**
     * 
     * @type {boolean}
     * @memberof ConflictsResponseModelIEnumerableIDataResult
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ConflictsResponseModelIEnumerableIDataResult
     */
    'message'?: string | null;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const ContentType = {
    Manual: 'Manual',
    Chapter: 'Chapter'
} as const;

export type ContentType = typeof ContentType[keyof typeof ContentType];


/**
 * 
 * @export
 * @interface CreateCompanyModel
 */
export interface CreateCompanyModel {
    /**
     * 
     * @type {string}
     * @memberof CreateCompanyModel
     */
    'companyName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateCompanyModel
     */
    'address'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateCompanyModel
     */
    'country'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateCompanyModel
     */
    'city'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateCompanyModel
     */
    'state'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateCompanyModel
     */
    'zipCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateCompanyModel
     */
    'workFlowType'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateCompanyModel
     */
    'logoFileName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateCompanyModel
     */
    'billingFirstName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateCompanyModel
     */
    'billingLastName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateCompanyModel
     */
    'billingCompanyName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateCompanyModel
     */
    'billingAddress'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateCompanyModel
     */
    'billingState'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateCompanyModel
     */
    'billingCountry'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateCompanyModel
     */
    'billingCity'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateCompanyModel
     */
    'billingZipCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateCompanyModel
     */
    'billingEmail'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateCompanyModel
     */
    'billingPhone'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateCompanyModel
     */
    'invoiceCcEmail'?: string | null;
}
/**
 * 
 * @export
 * @interface CreateSubscriptionApiRequestModel
 */
export interface CreateSubscriptionApiRequestModel {
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateSubscriptionApiRequestModel
     */
    'stripePriceIds'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateSubscriptionApiRequestModel
     */
    'removalStripePriceIds'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof CreateSubscriptionApiRequestModel
     */
    'userId'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateSubscriptionApiRequestModel
     */
    'type'?: string | null;
}
/**
 * 
 * @export
 * @interface CreateUpdateSubscriptionResponseModel
 */
export interface CreateUpdateSubscriptionResponseModel {
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateSubscriptionResponseModel
     */
    'clientSecret'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof CreateUpdateSubscriptionResponseModel
     */
    'isSubscriptionUpdated'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateSubscriptionResponseModel
     */
    'latestInvoiceId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateSubscriptionResponseModel
     */
    'status'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateSubscriptionResponseModel
     */
    'subscriptionId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateSubscriptionResponseModel
     */
    'trialStart'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateSubscriptionResponseModel
     */
    'trialEnd'?: string | null;
    /**
     * 
     * @type {StripeUpcomingInvoiceResponseModel1}
     * @memberof CreateUpdateSubscriptionResponseModel
     */
    'upcomingInvoice'?: StripeUpcomingInvoiceResponseModel1;
}
/**
 * 
 * @export
 * @interface CreateUpdateSubscriptionResponseModelIDataResult
 */
export interface CreateUpdateSubscriptionResponseModelIDataResult {
    /**
     * 
     * @type {CreateUpdateSubscriptionResponseModel}
     * @memberof CreateUpdateSubscriptionResponseModelIDataResult
     */
    'data'?: CreateUpdateSubscriptionResponseModel;
    /**
     * 
     * @type {boolean}
     * @memberof CreateUpdateSubscriptionResponseModelIDataResult
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CreateUpdateSubscriptionResponseModelIDataResult
     */
    'message'?: string | null;
}
/**
 * 
 * @export
 * @interface CreateUserModel
 */
export interface CreateUserModel {
    /**
     * 
     * @type {string}
     * @memberof CreateUserModel
     */
    'cognitoUserId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateUserModel
     */
    'firstName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateUserModel
     */
    'lastName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateUserModel
     */
    'phone'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateUserModel
     */
    'email'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateUserModel
     */
    'country'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateUserModel
     */
    'zipCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateUserModel
     */
    'title'?: string | null;
    /**
     * 
     * @type {RoleType}
     * @memberof CreateUserModel
     */
    'role'?: RoleType;
    /**
     * 
     * @type {CreateCompanyModel}
     * @memberof CreateUserModel
     */
    'company'?: CreateCompanyModel;
}
/**
 * 
 * @export
 * @interface CustomerModel
 */
export interface CustomerModel {
    /**
     * 
     * @type {string}
     * @memberof CustomerModel
     */
    'userId'?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerModel
     */
    'stripeCustomerId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CustomerModel
     */
    'email'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CustomerModel
     */
    'firstName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CustomerModel
     */
    'lastName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CustomerModel
     */
    'phone'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CustomerModel
     */
    'companyName'?: string | null;
    /**
     * 
     * @type {AddressModel}
     * @memberof CustomerModel
     */
    'address'?: AddressModel;
}
/**
 * 
 * @export
 * @interface CustomerModelIDataResult
 */
export interface CustomerModelIDataResult {
    /**
     * 
     * @type {CustomerModel}
     * @memberof CustomerModelIDataResult
     */
    'data'?: CustomerModel;
    /**
     * 
     * @type {boolean}
     * @memberof CustomerModelIDataResult
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CustomerModelIDataResult
     */
    'message'?: string | null;
}
/**
 * 
 * @export
 * @interface DateOnly
 */
export interface DateOnly {
    /**
     * 
     * @type {number}
     * @memberof DateOnly
     */
    'year'?: number;
    /**
     * 
     * @type {number}
     * @memberof DateOnly
     */
    'month'?: number;
    /**
     * 
     * @type {number}
     * @memberof DateOnly
     */
    'day'?: number;
    /**
     * 
     * @type {DayOfWeek}
     * @memberof DateOnly
     */
    'dayOfWeek'?: DayOfWeek;
    /**
     * 
     * @type {number}
     * @memberof DateOnly
     */
    'dayOfYear'?: number;
    /**
     * 
     * @type {number}
     * @memberof DateOnly
     */
    'dayNumber'?: number;
}
/**
 * 
 * @export
 * @interface DateOnlyModel
 */
export interface DateOnlyModel {
    /**
     * 
     * @type {number}
     * @memberof DateOnlyModel
     */
    'day'?: number;
    /**
     * 
     * @type {number}
     * @memberof DateOnlyModel
     */
    'month'?: number;
    /**
     * 
     * @type {number}
     * @memberof DateOnlyModel
     */
    'year'?: number;
}
/**
 * 
 * @export
 * @interface DateOnlyRequestModel
 */
export interface DateOnlyRequestModel {
    /**
     * 
     * @type {number}
     * @memberof DateOnlyRequestModel
     */
    'day'?: number;
    /**
     * 
     * @type {number}
     * @memberof DateOnlyRequestModel
     */
    'month'?: number;
    /**
     * 
     * @type {number}
     * @memberof DateOnlyRequestModel
     */
    'year'?: number;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const DayOfWeek = {
    Sunday: 'Sunday',
    Monday: 'Monday',
    Tuesday: 'Tuesday',
    Wednesday: 'Wednesday',
    Thursday: 'Thursday',
    Friday: 'Friday',
    Saturday: 'Saturday'
} as const;

export type DayOfWeek = typeof DayOfWeek[keyof typeof DayOfWeek];


/**
 * 
 * @export
 * @interface FavoriteUserCodeNoteModel
 */
export interface FavoriteUserCodeNoteModel {
    /**
     * 
     * @type {CodeSetType}
     * @memberof FavoriteUserCodeNoteModel
     */
    'codeSetType'?: CodeSetType;
    /**
     * 
     * @type {string}
     * @memberof FavoriteUserCodeNoteModel
     */
    'code'?: string | null;
}
/**
 * 
 * @export
 * @interface FavoriteUserCodeNoteModelIEnumerableIDataResult
 */
export interface FavoriteUserCodeNoteModelIEnumerableIDataResult {
    /**
     * 
     * @type {Array<FavoriteUserCodeNoteModel>}
     * @memberof FavoriteUserCodeNoteModelIEnumerableIDataResult
     */
    'data'?: Array<FavoriteUserCodeNoteModel> | null;
    /**
     * 
     * @type {boolean}
     * @memberof FavoriteUserCodeNoteModelIEnumerableIDataResult
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof FavoriteUserCodeNoteModelIEnumerableIDataResult
     */
    'message'?: string | null;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const FileResultType = {
    File: 'File',
    Base64String: 'Base64String'
} as const;

export type FileResultType = typeof FileResultType[keyof typeof FileResultType];


/**
 * 
 * @export
 * @enum {string}
 */

export const FileType = {
    Undefined: 'Undefined',
    Pdf: 'Pdf',
    Doc: 'Doc',
    Word: 'Word'
} as const;

export type FileType = typeof FileType[keyof typeof FileType];


/**
 * 
 * @export
 * @interface ForgotPasswordCodeDeliveryDetailsModel
 */
export interface ForgotPasswordCodeDeliveryDetailsModel {
    /**
     * 
     * @type {string}
     * @memberof ForgotPasswordCodeDeliveryDetailsModel
     */
    'attributeName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ForgotPasswordCodeDeliveryDetailsModel
     */
    'deliveryMedium'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ForgotPasswordCodeDeliveryDetailsModel
     */
    'destination'?: string | null;
}
/**
 * 
 * @export
 * @interface ForgotPasswordCodeDeliveryDetailsModelIDataResult
 */
export interface ForgotPasswordCodeDeliveryDetailsModelIDataResult {
    /**
     * 
     * @type {ForgotPasswordCodeDeliveryDetailsModel}
     * @memberof ForgotPasswordCodeDeliveryDetailsModelIDataResult
     */
    'data'?: ForgotPasswordCodeDeliveryDetailsModel;
    /**
     * 
     * @type {boolean}
     * @memberof ForgotPasswordCodeDeliveryDetailsModelIDataResult
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ForgotPasswordCodeDeliveryDetailsModelIDataResult
     */
    'message'?: string | null;
}
/**
 * 
 * @export
 * @interface ForgotPasswordModel
 */
export interface ForgotPasswordModel {
    /**
     * 
     * @type {string}
     * @memberof ForgotPasswordModel
     */
    'userName'?: string | null;
}
/**
 * 
 * @export
 * @interface GetCognitoUserGroupsResponseModel
 */
export interface GetCognitoUserGroupsResponseModel {
    /**
     * 
     * @type {string}
     * @memberof GetCognitoUserGroupsResponseModel
     */
    'creationDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetCognitoUserGroupsResponseModel
     */
    'description'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetCognitoUserGroupsResponseModel
     */
    'groupName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetCognitoUserGroupsResponseModel
     */
    'lastModifiedDate'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetCognitoUserGroupsResponseModel
     */
    'precedence'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetCognitoUserGroupsResponseModel
     */
    'roleArn'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetCognitoUserGroupsResponseModel
     */
    'userPoolId'?: string | null;
}
/**
 * 
 * @export
 * @interface GetCognitoUserGroupsResponseModelIEnumerableIDataResult
 */
export interface GetCognitoUserGroupsResponseModelIEnumerableIDataResult {
    /**
     * 
     * @type {Array<GetCognitoUserGroupsResponseModel>}
     * @memberof GetCognitoUserGroupsResponseModelIEnumerableIDataResult
     */
    'data'?: Array<GetCognitoUserGroupsResponseModel> | null;
    /**
     * 
     * @type {boolean}
     * @memberof GetCognitoUserGroupsResponseModelIEnumerableIDataResult
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof GetCognitoUserGroupsResponseModelIEnumerableIDataResult
     */
    'message'?: string | null;
}
/**
 * 
 * @export
 * @interface GetMyInvoicesRequestModel
 */
export interface GetMyInvoicesRequestModel {
    /**
     * 
     * @type {string}
     * @memberof GetMyInvoicesRequestModel
     */
    'startDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetMyInvoicesRequestModel
     */
    'endDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetMyInvoicesRequestModel
     */
    'number'?: string | null;
    /**
     * 
     * @type {StripeInvoiceStatus}
     * @memberof GetMyInvoicesRequestModel
     */
    'status'?: StripeInvoiceStatus;
}
/**
 * 
 * @export
 * @interface Gpci
 */
export interface Gpci {
    /**
     * 
     * @type {string}
     * @memberof Gpci
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof Gpci
     */
    'createdBy'?: string;
    /**
     * 
     * @type {string}
     * @memberof Gpci
     */
    'createdOn'?: string;
    /**
     * 
     * @type {string}
     * @memberof Gpci
     */
    'lastModifiedBy'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Gpci
     */
    'lastModifiedOn'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof Gpci
     */
    'isDeleted'?: boolean;
    /**
     * 
     * @type {DateOnly}
     * @memberof Gpci
     */
    'quarterBeginDate'?: DateOnly;
    /**
     * 
     * @type {string}
     * @memberof Gpci
     */
    'versionName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Gpci
     */
    'medicareAdministrativeContractor'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Gpci
     */
    'state'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Gpci
     */
    'localityNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Gpci
     */
    'localityName'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof Gpci
     */
    'pwGpci'?: number;
    /**
     * 
     * @type {number}
     * @memberof Gpci
     */
    'peGpci'?: number;
    /**
     * 
     * @type {number}
     * @memberof Gpci
     */
    'mpGpci'?: number;
}
/**
 * 
 * @export
 * @interface GpciIEnumerableIDataResult
 */
export interface GpciIEnumerableIDataResult {
    /**
     * 
     * @type {Array<Gpci>}
     * @memberof GpciIEnumerableIDataResult
     */
    'data'?: Array<Gpci> | null;
    /**
     * 
     * @type {boolean}
     * @memberof GpciIEnumerableIDataResult
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof GpciIEnumerableIDataResult
     */
    'message'?: string | null;
}
/**
 * 
 * @export
 * @interface GuidelineModel
 */
export interface GuidelineModel {
    /**
     * 
     * @type {string}
     * @memberof GuidelineModel
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof GuidelineModel
     */
    'parentId'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof GuidelineModel
     */
    'order'?: number;
    /**
     * 
     * @type {ContentType}
     * @memberof GuidelineModel
     */
    'contentType'?: ContentType;
    /**
     * 
     * @type {FileType}
     * @memberof GuidelineModel
     */
    'fileType'?: FileType;
    /**
     * 
     * @type {string}
     * @memberof GuidelineModel
     */
    'fileName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GuidelineModel
     */
    'date'?: string | null;
    /**
     * 
     * @type {GuidelineType}
     * @memberof GuidelineModel
     */
    'guidelineType'?: GuidelineType;
    /**
     * 
     * @type {string}
     * @memberof GuidelineModel
     */
    'description'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GuidelineModel
     */
    'filePath'?: string | null;
}
/**
 * 
 * @export
 * @interface GuidelineModelIDataResult
 */
export interface GuidelineModelIDataResult {
    /**
     * 
     * @type {GuidelineModel}
     * @memberof GuidelineModelIDataResult
     */
    'data'?: GuidelineModel;
    /**
     * 
     * @type {boolean}
     * @memberof GuidelineModelIDataResult
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof GuidelineModelIDataResult
     */
    'message'?: string | null;
}
/**
 * 
 * @export
 * @interface GuidelineModelIEnumerableIDataResult
 */
export interface GuidelineModelIEnumerableIDataResult {
    /**
     * 
     * @type {Array<GuidelineModel>}
     * @memberof GuidelineModelIEnumerableIDataResult
     */
    'data'?: Array<GuidelineModel> | null;
    /**
     * 
     * @type {boolean}
     * @memberof GuidelineModelIEnumerableIDataResult
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof GuidelineModelIEnumerableIDataResult
     */
    'message'?: string | null;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const GuidelineType = {
    Medicare: 'Medicare',
    Medicaid: 'Medicaid',
    Icd10Cm: 'Icd10CM',
    Icd10Pcs: 'Icd10PCS'
} as const;

export type GuidelineType = typeof GuidelineType[keyof typeof GuidelineType];


/**
 * 
 * @export
 * @enum {string}
 */

export const HCPCSChangedStatusType = {
    NotAvailable: 'NotAvailable',
    Add: 'Add',
    Revise: 'Revise',
    Delete: 'Delete'
} as const;

export type HCPCSChangedStatusType = typeof HCPCSChangedStatusType[keyof typeof HCPCSChangedStatusType];


/**
 * 
 * @export
 * @interface HCPCSCodeChangedModel
 */
export interface HCPCSCodeChangedModel {
    /**
     * 
     * @type {string}
     * @memberof HCPCSCodeChangedModel
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof HCPCSCodeChangedModel
     */
    'code'?: string | null;
    /**
     * 
     * @type {DateOnly}
     * @memberof HCPCSCodeChangedModel
     */
    'quarterBeginDate'?: DateOnly;
    /**
     * 
     * @type {DateOnly}
     * @memberof HCPCSCodeChangedModel
     */
    'actionEffectiveDate'?: DateOnly;
    /**
     * 
     * @type {string}
     * @memberof HCPCSCodeChangedModel
     */
    'description'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof HCPCSCodeChangedModel
     */
    'longDescription'?: string | null;
}
/**
 * 
 * @export
 * @interface HCPCSCodeChangedModelIEnumerableIDataResult
 */
export interface HCPCSCodeChangedModelIEnumerableIDataResult {
    /**
     * 
     * @type {Array<HCPCSCodeChangedModel>}
     * @memberof HCPCSCodeChangedModelIEnumerableIDataResult
     */
    'data'?: Array<HCPCSCodeChangedModel> | null;
    /**
     * 
     * @type {boolean}
     * @memberof HCPCSCodeChangedModelIEnumerableIDataResult
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof HCPCSCodeChangedModelIEnumerableIDataResult
     */
    'message'?: string | null;
}
/**
 * 
 * @export
 * @interface HCPCSCodeChangedModelPagedResults
 */
export interface HCPCSCodeChangedModelPagedResults {
    /**
     * 
     * @type {Array<HCPCSCodeChangedModel>}
     * @memberof HCPCSCodeChangedModelPagedResults
     */
    'items'?: Array<HCPCSCodeChangedModel> | null;
    /**
     * 
     * @type {number}
     * @memberof HCPCSCodeChangedModelPagedResults
     */
    'totalSize'?: number;
}
/**
 * 
 * @export
 * @interface HCPCSCodeChangedModelPagedResultsIDataResult
 */
export interface HCPCSCodeChangedModelPagedResultsIDataResult {
    /**
     * 
     * @type {HCPCSCodeChangedModelPagedResults}
     * @memberof HCPCSCodeChangedModelPagedResultsIDataResult
     */
    'data'?: HCPCSCodeChangedModelPagedResults;
    /**
     * 
     * @type {boolean}
     * @memberof HCPCSCodeChangedModelPagedResultsIDataResult
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof HCPCSCodeChangedModelPagedResultsIDataResult
     */
    'message'?: string | null;
}
/**
 * 
 * @export
 * @interface HCPCSModifierCodeChangedModel
 */
export interface HCPCSModifierCodeChangedModel {
    /**
     * 
     * @type {string}
     * @memberof HCPCSModifierCodeChangedModel
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof HCPCSModifierCodeChangedModel
     */
    'code'?: string | null;
    /**
     * 
     * @type {DateOnly}
     * @memberof HCPCSModifierCodeChangedModel
     */
    'quarterBeginDate'?: DateOnly;
    /**
     * 
     * @type {DateOnly}
     * @memberof HCPCSModifierCodeChangedModel
     */
    'deletedDate'?: DateOnly;
    /**
     * 
     * @type {DateOnly}
     * @memberof HCPCSModifierCodeChangedModel
     */
    'changedDate'?: DateOnly;
    /**
     * 
     * @type {string}
     * @memberof HCPCSModifierCodeChangedModel
     */
    'description'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof HCPCSModifierCodeChangedModel
     */
    'hcpcsActionCodeTypeNote'?: string | null;
}
/**
 * 
 * @export
 * @interface HCPCSModifierCodeChangedModelPagedResults
 */
export interface HCPCSModifierCodeChangedModelPagedResults {
    /**
     * 
     * @type {Array<HCPCSModifierCodeChangedModel>}
     * @memberof HCPCSModifierCodeChangedModelPagedResults
     */
    'items'?: Array<HCPCSModifierCodeChangedModel> | null;
    /**
     * 
     * @type {number}
     * @memberof HCPCSModifierCodeChangedModelPagedResults
     */
    'totalSize'?: number;
}
/**
 * 
 * @export
 * @interface HCPCSModifierCodeChangedModelPagedResultsIDataResult
 */
export interface HCPCSModifierCodeChangedModelPagedResultsIDataResult {
    /**
     * 
     * @type {HCPCSModifierCodeChangedModelPagedResults}
     * @memberof HCPCSModifierCodeChangedModelPagedResultsIDataResult
     */
    'data'?: HCPCSModifierCodeChangedModelPagedResults;
    /**
     * 
     * @type {boolean}
     * @memberof HCPCSModifierCodeChangedModelPagedResultsIDataResult
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof HCPCSModifierCodeChangedModelPagedResultsIDataResult
     */
    'message'?: string | null;
}
/**
 * 
 * @export
 * @interface HcpcsCodeModel
 */
export interface HcpcsCodeModel {
    /**
     * 
     * @type {string}
     * @memberof HcpcsCodeModel
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof HcpcsCodeModel
     */
    'versionName'?: string | null;
    /**
     * 
     * @type {DateOnly}
     * @memberof HcpcsCodeModel
     */
    'quarterBeginDate'?: DateOnly;
    /**
     * 
     * @type {CodeType}
     * @memberof HcpcsCodeModel
     */
    'codeType'?: CodeType;
    /**
     * 
     * @type {string}
     * @memberof HcpcsCodeModel
     */
    'code'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof HcpcsCodeModel
     */
    'sequenceNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof HcpcsCodeModel
     */
    'recordIdentificationCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof HcpcsCodeModel
     */
    'shortDescription'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof HcpcsCodeModel
     */
    'longDescription'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof HcpcsCodeModel
     */
    'pricingIndicatorCode1'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof HcpcsCodeModel
     */
    'pricingIndicatorCode2'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof HcpcsCodeModel
     */
    'pricingIndicatorCode3'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof HcpcsCodeModel
     */
    'pricingIndicatorCode4'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof HcpcsCodeModel
     */
    'multiplePricingIndicator'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof HcpcsCodeModel
     */
    'coverageIssuesManuel1'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof HcpcsCodeModel
     */
    'coverageIssuesManuel2'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof HcpcsCodeModel
     */
    'coverageIssuesManuel3'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof HcpcsCodeModel
     */
    'medicareCarriersManuel1'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof HcpcsCodeModel
     */
    'medicareCarriersManuel2'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof HcpcsCodeModel
     */
    'medicareCarriersManuel3'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof HcpcsCodeModel
     */
    'statuteNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof HcpcsCodeModel
     */
    'labCertificationCode1'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof HcpcsCodeModel
     */
    'labCertificationCode2'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof HcpcsCodeModel
     */
    'labCertificationCode3'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof HcpcsCodeModel
     */
    'labCertificationCode4'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof HcpcsCodeModel
     */
    'labCertificationCode5'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof HcpcsCodeModel
     */
    'labCertificationCode6'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof HcpcsCodeModel
     */
    'labCertificationCode7'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof HcpcsCodeModel
     */
    'labCertificationCode8'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof HcpcsCodeModel
     */
    'crossReferenceCode1'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof HcpcsCodeModel
     */
    'crossReferenceCode2'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof HcpcsCodeModel
     */
    'crossReferenceCode3'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof HcpcsCodeModel
     */
    'crossReferenceCode4'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof HcpcsCodeModel
     */
    'crossReferenceCode5'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof HcpcsCodeModel
     */
    'coverageCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof HcpcsCodeModel
     */
    'ascPaymentGroupCode'?: string | null;
    /**
     * 
     * @type {DateOnly}
     * @memberof HcpcsCodeModel
     */
    'ascPaymentGroupEffectiveDate'?: DateOnly;
    /**
     * 
     * @type {string}
     * @memberof HcpcsCodeModel
     */
    'mogPaymentGroupCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof HcpcsCodeModel
     */
    'mogPaymentPolicyIndicator'?: string | null;
    /**
     * 
     * @type {DateOnly}
     * @memberof HcpcsCodeModel
     */
    'mogEffectiveDate'?: DateOnly;
    /**
     * 
     * @type {string}
     * @memberof HcpcsCodeModel
     */
    'processingNoteNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof HcpcsCodeModel
     */
    'berensonEggersTypeOfServiceCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof HcpcsCodeModel
     */
    'typeOfServiceCode1'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof HcpcsCodeModel
     */
    'typeOfServiceCode2'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof HcpcsCodeModel
     */
    'typeOfServiceCode3'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof HcpcsCodeModel
     */
    'typeOfServiceCode4'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof HcpcsCodeModel
     */
    'typeOfServiceCode5'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof HcpcsCodeModel
     */
    'anesthesiaBaseUnitQuantity'?: string | null;
    /**
     * 
     * @type {DateOnly}
     * @memberof HcpcsCodeModel
     */
    'codeAddedDate'?: DateOnly;
    /**
     * 
     * @type {DateOnly}
     * @memberof HcpcsCodeModel
     */
    'actionEffectiveDate'?: DateOnly;
    /**
     * 
     * @type {DateOnly}
     * @memberof HcpcsCodeModel
     */
    'terminationDate'?: DateOnly;
    /**
     * 
     * @type {string}
     * @memberof HcpcsCodeModel
     */
    'actionCode'?: string | null;
}
/**
 * 
 * @export
 * @interface HcpcsCodeModelIEnumerableIDataResult
 */
export interface HcpcsCodeModelIEnumerableIDataResult {
    /**
     * 
     * @type {Array<HcpcsCodeModel>}
     * @memberof HcpcsCodeModelIEnumerableIDataResult
     */
    'data'?: Array<HcpcsCodeModel> | null;
    /**
     * 
     * @type {boolean}
     * @memberof HcpcsCodeModelIEnumerableIDataResult
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof HcpcsCodeModelIEnumerableIDataResult
     */
    'message'?: string | null;
}
/**
 * 
 * @export
 * @interface HcpcsModifierModel
 */
export interface HcpcsModifierModel {
    /**
     * 
     * @type {string}
     * @memberof HcpcsModifierModel
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof HcpcsModifierModel
     */
    'versionName'?: string | null;
    /**
     * 
     * @type {DateOnly}
     * @memberof HcpcsModifierModel
     */
    'quarterBeginDate'?: DateOnly;
    /**
     * 
     * @type {CodeType}
     * @memberof HcpcsModifierModel
     */
    'codeType'?: CodeType;
    /**
     * 
     * @type {string}
     * @memberof HcpcsModifierModel
     */
    'code'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof HcpcsModifierModel
     */
    'sequenceNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof HcpcsModifierModel
     */
    'recordIdentificationCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof HcpcsModifierModel
     */
    'shortDescription'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof HcpcsModifierModel
     */
    'longDescription'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof HcpcsModifierModel
     */
    'pricingIndicatorCode1'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof HcpcsModifierModel
     */
    'pricingIndicatorCode2'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof HcpcsModifierModel
     */
    'pricingIndicatorCode3'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof HcpcsModifierModel
     */
    'pricingIndicatorCode4'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof HcpcsModifierModel
     */
    'multiplePricingIndicator'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof HcpcsModifierModel
     */
    'coverageIssuesManuel1'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof HcpcsModifierModel
     */
    'coverageIssuesManuel2'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof HcpcsModifierModel
     */
    'coverageIssuesManuel3'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof HcpcsModifierModel
     */
    'medicareCarriersManuel1'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof HcpcsModifierModel
     */
    'medicareCarriersManuel2'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof HcpcsModifierModel
     */
    'medicareCarriersManuel3'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof HcpcsModifierModel
     */
    'statuteNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof HcpcsModifierModel
     */
    'labCertificationCode1'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof HcpcsModifierModel
     */
    'labCertificationCode2'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof HcpcsModifierModel
     */
    'labCertificationCode3'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof HcpcsModifierModel
     */
    'labCertificationCode4'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof HcpcsModifierModel
     */
    'labCertificationCode5'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof HcpcsModifierModel
     */
    'labCertificationCode6'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof HcpcsModifierModel
     */
    'labCertificationCode7'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof HcpcsModifierModel
     */
    'labCertificationCode8'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof HcpcsModifierModel
     */
    'crossReferenceCode1'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof HcpcsModifierModel
     */
    'crossReferenceCode2'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof HcpcsModifierModel
     */
    'crossReferenceCode3'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof HcpcsModifierModel
     */
    'crossReferenceCode4'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof HcpcsModifierModel
     */
    'crossReferenceCode5'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof HcpcsModifierModel
     */
    'coverageCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof HcpcsModifierModel
     */
    'ascPaymentGroupCode'?: string | null;
    /**
     * 
     * @type {DateOnly}
     * @memberof HcpcsModifierModel
     */
    'ascPaymentGroupEffectiveDate'?: DateOnly;
    /**
     * 
     * @type {string}
     * @memberof HcpcsModifierModel
     */
    'mogPaymentGroupCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof HcpcsModifierModel
     */
    'mogPaymentPolicyIndicator'?: string | null;
    /**
     * 
     * @type {DateOnly}
     * @memberof HcpcsModifierModel
     */
    'mogEffectiveDate'?: DateOnly;
    /**
     * 
     * @type {string}
     * @memberof HcpcsModifierModel
     */
    'processingNoteNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof HcpcsModifierModel
     */
    'berensonEggersTypeOfServiceCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof HcpcsModifierModel
     */
    'typeOfServiceCode1'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof HcpcsModifierModel
     */
    'typeOfServiceCode2'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof HcpcsModifierModel
     */
    'typeOfServiceCode3'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof HcpcsModifierModel
     */
    'typeOfServiceCode4'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof HcpcsModifierModel
     */
    'typeOfServiceCode5'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof HcpcsModifierModel
     */
    'anesthesiaBaseUnitQuantity'?: string | null;
    /**
     * 
     * @type {DateOnly}
     * @memberof HcpcsModifierModel
     */
    'codeAddedDate'?: DateOnly;
    /**
     * 
     * @type {DateOnly}
     * @memberof HcpcsModifierModel
     */
    'actionEffectiveDate'?: DateOnly;
    /**
     * 
     * @type {DateOnly}
     * @memberof HcpcsModifierModel
     */
    'terminationDate'?: DateOnly;
    /**
     * 
     * @type {string}
     * @memberof HcpcsModifierModel
     */
    'actionCode'?: string | null;
}
/**
 * 
 * @export
 * @interface HcpcsModifierModelIEnumerableIDataResult
 */
export interface HcpcsModifierModelIEnumerableIDataResult {
    /**
     * 
     * @type {Array<HcpcsModifierModel>}
     * @memberof HcpcsModifierModelIEnumerableIDataResult
     */
    'data'?: Array<HcpcsModifierModel> | null;
    /**
     * 
     * @type {boolean}
     * @memberof HcpcsModifierModelIEnumerableIDataResult
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof HcpcsModifierModelIEnumerableIDataResult
     */
    'message'?: string | null;
}
/**
 * 
 * @export
 * @interface HcpcsModifierModelPagedResults
 */
export interface HcpcsModifierModelPagedResults {
    /**
     * 
     * @type {Array<HcpcsModifierModel>}
     * @memberof HcpcsModifierModelPagedResults
     */
    'items'?: Array<HcpcsModifierModel> | null;
    /**
     * 
     * @type {number}
     * @memberof HcpcsModifierModelPagedResults
     */
    'totalSize'?: number;
}
/**
 * 
 * @export
 * @interface HcpcsModifierModelPagedResultsIDataResult
 */
export interface HcpcsModifierModelPagedResultsIDataResult {
    /**
     * 
     * @type {HcpcsModifierModelPagedResults}
     * @memberof HcpcsModifierModelPagedResultsIDataResult
     */
    'data'?: HcpcsModifierModelPagedResults;
    /**
     * 
     * @type {boolean}
     * @memberof HcpcsModifierModelPagedResultsIDataResult
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof HcpcsModifierModelPagedResultsIDataResult
     */
    'message'?: string | null;
}
/**
 * 
 * @export
 * @interface HealthModelView
 */
export interface HealthModelView {
    /**
     * 
     * @type {boolean}
     * @memberof HealthModelView
     */
    'status'?: boolean;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const ICD10AddendaStatusType = {
    NotAvailable: 'NotAvailable',
    Add: 'Add',
    Revise: 'Revise',
    Delete: 'Delete'
} as const;

export type ICD10AddendaStatusType = typeof ICD10AddendaStatusType[keyof typeof ICD10AddendaStatusType];


/**
 * 
 * @export
 * @interface ICD10CMChapter
 */
export interface ICD10CMChapter {
    /**
     * 
     * @type {string}
     * @memberof ICD10CMChapter
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ICD10CMChapter
     */
    'createdBy'?: string;
    /**
     * 
     * @type {string}
     * @memberof ICD10CMChapter
     */
    'createdOn'?: string;
    /**
     * 
     * @type {string}
     * @memberof ICD10CMChapter
     */
    'lastModifiedBy'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ICD10CMChapter
     */
    'lastModifiedOn'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ICD10CMChapter
     */
    'isDeleted'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ICD10CMChapter
     */
    'versionName'?: string | null;
    /**
     * 
     * @type {DateOnly}
     * @memberof ICD10CMChapter
     */
    'quarterBeginDate'?: DateOnly;
    /**
     * 
     * @type {string}
     * @memberof ICD10CMChapter
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ICD10CMChapter
     */
    'description'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ICD10CMChapter
     */
    'includeNotes'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof ICD10CMChapter
     */
    'useAdditionalCodeNotes'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof ICD10CMChapter
     */
    'excludes1Notes'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof ICD10CMChapter
     */
    'excludes2Notes'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof ICD10CMChapter
     */
    'notes'?: Array<string> | null;
    /**
     * 
     * @type {any}
     * @memberof ICD10CMChapter
     */
    'section'?: any | null;
    /**
     * 
     * @type {Array<ICD10CMSection>}
     * @memberof ICD10CMChapter
     */
    'icD10CMSection'?: Array<ICD10CMSection> | null;
}
/**
 * 
 * @export
 * @interface ICD10CMChapterModel
 */
export interface ICD10CMChapterModel {
    /**
     * 
     * @type {string}
     * @memberof ICD10CMChapterModel
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ICD10CMChapterModel
     */
    'versionName'?: string | null;
    /**
     * 
     * @type {DateOnly}
     * @memberof ICD10CMChapterModel
     */
    'quarterBeginDate'?: DateOnly;
    /**
     * 
     * @type {string}
     * @memberof ICD10CMChapterModel
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ICD10CMChapterModel
     */
    'description'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ICD10CMChapterModel
     */
    'startCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ICD10CMChapterModel
     */
    'endCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ICD10CMChapterModel
     */
    'includeNotes'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof ICD10CMChapterModel
     */
    'useAdditionalCodeNotes'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof ICD10CMChapterModel
     */
    'excludes1Notes'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof ICD10CMChapterModel
     */
    'excludes2Notes'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof ICD10CMChapterModel
     */
    'notes'?: Array<string> | null;
    /**
     * 
     * @type {any}
     * @memberof ICD10CMChapterModel
     */
    'section'?: any | null;
    /**
     * 
     * @type {Array<ICD10CMSection>}
     * @memberof ICD10CMChapterModel
     */
    'icD10CMSection'?: Array<ICD10CMSection> | null;
}
/**
 * 
 * @export
 * @interface ICD10CMChapterModelIEnumerableIDataResult
 */
export interface ICD10CMChapterModelIEnumerableIDataResult {
    /**
     * 
     * @type {Array<ICD10CMChapterModel>}
     * @memberof ICD10CMChapterModelIEnumerableIDataResult
     */
    'data'?: Array<ICD10CMChapterModel> | null;
    /**
     * 
     * @type {boolean}
     * @memberof ICD10CMChapterModelIEnumerableIDataResult
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ICD10CMChapterModelIEnumerableIDataResult
     */
    'message'?: string | null;
}
/**
 * 
 * @export
 * @interface ICD10CMCode
 */
export interface ICD10CMCode {
    /**
     * 
     * @type {string}
     * @memberof ICD10CMCode
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ICD10CMCode
     */
    'createdBy'?: string;
    /**
     * 
     * @type {string}
     * @memberof ICD10CMCode
     */
    'createdOn'?: string;
    /**
     * 
     * @type {string}
     * @memberof ICD10CMCode
     */
    'lastModifiedBy'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ICD10CMCode
     */
    'lastModifiedOn'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ICD10CMCode
     */
    'isDeleted'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ICD10CMCode
     */
    'versionName'?: string | null;
    /**
     * 
     * @type {DateOnly}
     * @memberof ICD10CMCode
     */
    'quarterBeginDate'?: DateOnly;
    /**
     * 
     * @type {string}
     * @memberof ICD10CMCode
     */
    'code'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ICD10CMCode
     */
    'codeAlsoNotes'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof ICD10CMCode
     */
    'codeFirstNotes'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof ICD10CMCode
     */
    'description'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof ICD10CMCode
     */
    'exclude1Notes'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof ICD10CMCode
     */
    'exclude2Notes'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof ICD10CMCode
     */
    'inclusionTermNotes'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof ICD10CMCode
     */
    'useAdditionalCodeNotes'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof ICD10CMCode
     */
    'includeNotes'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof ICD10CMCode
     */
    'notes'?: Array<string> | null;
    /**
     * 
     * @type {any}
     * @memberof ICD10CMCode
     */
    'sevenChrDef'?: any | null;
    /**
     * 
     * @type {string}
     * @memberof ICD10CMCode
     */
    'sevenChrNotes'?: string | null;
    /**
     * 
     * @type {any}
     * @memberof ICD10CMCode
     */
    'visualImpairment'?: any | null;
    /**
     * 
     * @type {string}
     * @memberof ICD10CMCode
     */
    'parentId'?: string | null;
    /**
     * 
     * @type {ICD10CMCode}
     * @memberof ICD10CMCode
     */
    'icD10CMCodes'?: ICD10CMCode;
    /**
     * 
     * @type {string}
     * @memberof ICD10CMCode
     */
    'icD10CMSectionId'?: string | null;
    /**
     * 
     * @type {ICD10CMSection}
     * @memberof ICD10CMCode
     */
    'icD10CMSection'?: ICD10CMSection;
}
/**
 * 
 * @export
 * @interface ICD10CMCodeAddendaModel
 */
export interface ICD10CMCodeAddendaModel {
    /**
     * 
     * @type {string}
     * @memberof ICD10CMCodeAddendaModel
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ICD10CMCodeAddendaModel
     */
    'versionName'?: string | null;
    /**
     * 
     * @type {DateOnly}
     * @memberof ICD10CMCodeAddendaModel
     */
    'quarterBeginDate'?: DateOnly;
    /**
     * 
     * @type {string}
     * @memberof ICD10CMCodeAddendaModel
     */
    'code'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ICD10CMCodeAddendaModel
     */
    'description'?: string | null;
    /**
     * 
     * @type {ICD10AddendaStatusType}
     * @memberof ICD10CMCodeAddendaModel
     */
    'addendaStatusType'?: ICD10AddendaStatusType;
    /**
     * 
     * @type {string}
     * @memberof ICD10CMCodeAddendaModel
     */
    'addenda'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ICD10CMCodeAddendaModel
     */
    'note'?: string | null;
}
/**
 * 
 * @export
 * @interface ICD10CMCodeAddendaModelPagedResults
 */
export interface ICD10CMCodeAddendaModelPagedResults {
    /**
     * 
     * @type {Array<ICD10CMCodeAddendaModel>}
     * @memberof ICD10CMCodeAddendaModelPagedResults
     */
    'items'?: Array<ICD10CMCodeAddendaModel> | null;
    /**
     * 
     * @type {number}
     * @memberof ICD10CMCodeAddendaModelPagedResults
     */
    'totalSize'?: number;
}
/**
 * 
 * @export
 * @interface ICD10CMCodeAddendaModelPagedResultsIDataResult
 */
export interface ICD10CMCodeAddendaModelPagedResultsIDataResult {
    /**
     * 
     * @type {ICD10CMCodeAddendaModelPagedResults}
     * @memberof ICD10CMCodeAddendaModelPagedResultsIDataResult
     */
    'data'?: ICD10CMCodeAddendaModelPagedResults;
    /**
     * 
     * @type {boolean}
     * @memberof ICD10CMCodeAddendaModelPagedResultsIDataResult
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ICD10CMCodeAddendaModelPagedResultsIDataResult
     */
    'message'?: string | null;
}
/**
 * 
 * @export
 * @interface ICD10CMCodeIEnumerableIDataResult
 */
export interface ICD10CMCodeIEnumerableIDataResult {
    /**
     * 
     * @type {Array<ICD10CMCode>}
     * @memberof ICD10CMCodeIEnumerableIDataResult
     */
    'data'?: Array<ICD10CMCode> | null;
    /**
     * 
     * @type {boolean}
     * @memberof ICD10CMCodeIEnumerableIDataResult
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ICD10CMCodeIEnumerableIDataResult
     */
    'message'?: string | null;
}
/**
 * 
 * @export
 * @interface ICD10CMSection
 */
export interface ICD10CMSection {
    /**
     * 
     * @type {string}
     * @memberof ICD10CMSection
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ICD10CMSection
     */
    'createdBy'?: string;
    /**
     * 
     * @type {string}
     * @memberof ICD10CMSection
     */
    'createdOn'?: string;
    /**
     * 
     * @type {string}
     * @memberof ICD10CMSection
     */
    'lastModifiedBy'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ICD10CMSection
     */
    'lastModifiedOn'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ICD10CMSection
     */
    'isDeleted'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ICD10CMSection
     */
    'codeAlsoNotes'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof ICD10CMSection
     */
    'codeFirstNotes'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof ICD10CMSection
     */
    'description'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof ICD10CMSection
     */
    'includeNotes'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof ICD10CMSection
     */
    'inclusionTermNotes'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof ICD10CMSection
     */
    'exclude1Notes'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof ICD10CMSection
     */
    'exclude2Notes'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof ICD10CMSection
     */
    'notes'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof ICD10CMSection
     */
    'useAdditionalCodeNotes'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof ICD10CMSection
     */
    'icD10CMChapterId'?: string;
    /**
     * 
     * @type {ICD10CMChapter}
     * @memberof ICD10CMSection
     */
    'icD10CMChapter'?: ICD10CMChapter;
    /**
     * 
     * @type {Array<ICD10CMCode>}
     * @memberof ICD10CMSection
     */
    'icD10CMCode'?: Array<ICD10CMCode> | null;
}
/**
 * 
 * @export
 * @interface ICD10CMSectionModel
 */
export interface ICD10CMSectionModel {
    /**
     * 
     * @type {string}
     * @memberof ICD10CMSectionModel
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ICD10CMSectionModel
     */
    'codeAlsoNotes'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof ICD10CMSectionModel
     */
    'codeFirstNotes'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof ICD10CMSectionModel
     */
    'description'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ICD10CMSectionModel
     */
    'startCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ICD10CMSectionModel
     */
    'endCode'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof ICD10CMSectionModel
     */
    'includeNotes'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof ICD10CMSectionModel
     */
    'inclusionTermNotes'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof ICD10CMSectionModel
     */
    'exclude1Notes'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof ICD10CMSectionModel
     */
    'exclude2Notes'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof ICD10CMSectionModel
     */
    'notes'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof ICD10CMSectionModel
     */
    'useAdditionalCodeNotes'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof ICD10CMSectionModel
     */
    'icD10CMChapterId'?: string;
    /**
     * 
     * @type {ICD10CMChapter}
     * @memberof ICD10CMSectionModel
     */
    'icD10CMChapter'?: ICD10CMChapter;
    /**
     * 
     * @type {Array<ICD10CMCode>}
     * @memberof ICD10CMSectionModel
     */
    'icD10CMCode'?: Array<ICD10CMCode> | null;
}
/**
 * 
 * @export
 * @interface ICD10CMSectionModelIEnumerableIDataResult
 */
export interface ICD10CMSectionModelIEnumerableIDataResult {
    /**
     * 
     * @type {Array<ICD10CMSectionModel>}
     * @memberof ICD10CMSectionModelIEnumerableIDataResult
     */
    'data'?: Array<ICD10CMSectionModel> | null;
    /**
     * 
     * @type {boolean}
     * @memberof ICD10CMSectionModelIEnumerableIDataResult
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ICD10CMSectionModelIEnumerableIDataResult
     */
    'message'?: string | null;
}
/**
 * 
 * @export
 * @interface ICD10PCSCodeAddendaModel
 */
export interface ICD10PCSCodeAddendaModel {
    /**
     * 
     * @type {string}
     * @memberof ICD10PCSCodeAddendaModel
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ICD10PCSCodeAddendaModel
     */
    'versionName'?: string | null;
    /**
     * 
     * @type {DateOnly}
     * @memberof ICD10PCSCodeAddendaModel
     */
    'quarterBeginDate'?: DateOnly;
    /**
     * 
     * @type {string}
     * @memberof ICD10PCSCodeAddendaModel
     */
    'code'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ICD10PCSCodeAddendaModel
     */
    'description'?: string | null;
    /**
     * 
     * @type {ICD10AddendaStatusType}
     * @memberof ICD10PCSCodeAddendaModel
     */
    'addendaStatusType'?: ICD10AddendaStatusType;
    /**
     * 
     * @type {string}
     * @memberof ICD10PCSCodeAddendaModel
     */
    'addenda'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ICD10PCSCodeAddendaModel
     */
    'note'?: string | null;
}
/**
 * 
 * @export
 * @interface ICD10PCSCodeAddendaModelPagedResults
 */
export interface ICD10PCSCodeAddendaModelPagedResults {
    /**
     * 
     * @type {Array<ICD10PCSCodeAddendaModel>}
     * @memberof ICD10PCSCodeAddendaModelPagedResults
     */
    'items'?: Array<ICD10PCSCodeAddendaModel> | null;
    /**
     * 
     * @type {number}
     * @memberof ICD10PCSCodeAddendaModelPagedResults
     */
    'totalSize'?: number;
}
/**
 * 
 * @export
 * @interface ICD10PCSCodeAddendaModelPagedResultsIDataResult
 */
export interface ICD10PCSCodeAddendaModelPagedResultsIDataResult {
    /**
     * 
     * @type {ICD10PCSCodeAddendaModelPagedResults}
     * @memberof ICD10PCSCodeAddendaModelPagedResultsIDataResult
     */
    'data'?: ICD10PCSCodeAddendaModelPagedResults;
    /**
     * 
     * @type {boolean}
     * @memberof ICD10PCSCodeAddendaModelPagedResultsIDataResult
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ICD10PCSCodeAddendaModelPagedResultsIDataResult
     */
    'message'?: string | null;
}
/**
 * 
 * @export
 * @interface Icd10CmCodeModel
 */
export interface Icd10CmCodeModel {
    /**
     * 
     * @type {string}
     * @memberof Icd10CmCodeModel
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof Icd10CmCodeModel
     */
    'versionName'?: string | null;
    /**
     * 
     * @type {DateOnly}
     * @memberof Icd10CmCodeModel
     */
    'quarterBeginDate'?: DateOnly;
    /**
     * 
     * @type {string}
     * @memberof Icd10CmCodeModel
     */
    'code'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Icd10CmCodeModel
     */
    'codeAlsoNotes'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof Icd10CmCodeModel
     */
    'codeFirstNotes'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof Icd10CmCodeModel
     */
    'description'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof Icd10CmCodeModel
     */
    'exclude1Notes'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof Icd10CmCodeModel
     */
    'exclude2Notes'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof Icd10CmCodeModel
     */
    'inclusionTermNotes'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof Icd10CmCodeModel
     */
    'useAdditionalCodeNotes'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof Icd10CmCodeModel
     */
    'includeNotes'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof Icd10CmCodeModel
     */
    'notes'?: Array<string> | null;
    /**
     * 
     * @type {any}
     * @memberof Icd10CmCodeModel
     */
    'sevenChrDef'?: any | null;
    /**
     * 
     * @type {string}
     * @memberof Icd10CmCodeModel
     */
    'sevenChrNotes'?: string | null;
    /**
     * 
     * @type {any}
     * @memberof Icd10CmCodeModel
     */
    'visualImpairment'?: any | null;
    /**
     * 
     * @type {string}
     * @memberof Icd10CmCodeModel
     */
    'parentId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Icd10CmCodeModel
     */
    'icD10CMSectionId'?: string | null;
}
/**
 * 
 * @export
 * @interface Icd10CmCodeModelIEnumerableIDataResult
 */
export interface Icd10CmCodeModelIEnumerableIDataResult {
    /**
     * 
     * @type {Array<Icd10CmCodeModel>}
     * @memberof Icd10CmCodeModelIEnumerableIDataResult
     */
    'data'?: Array<Icd10CmCodeModel> | null;
    /**
     * 
     * @type {boolean}
     * @memberof Icd10CmCodeModelIEnumerableIDataResult
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Icd10CmCodeModelIEnumerableIDataResult
     */
    'message'?: string | null;
}
/**
 * 
 * @export
 * @interface Icd10PcsCodeModel
 */
export interface Icd10PcsCodeModel {
    /**
     * 
     * @type {string}
     * @memberof Icd10PcsCodeModel
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof Icd10PcsCodeModel
     */
    'versionName'?: string | null;
    /**
     * 
     * @type {DateOnly}
     * @memberof Icd10PcsCodeModel
     */
    'quarterBeginDate'?: DateOnly;
    /**
     * 
     * @type {string}
     * @memberof Icd10PcsCodeModel
     */
    'code'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof Icd10PcsCodeModel
     */
    'pos'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof Icd10PcsCodeModel
     */
    'title'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Icd10PcsCodeModel
     */
    'label'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Icd10PcsCodeModel
     */
    'description'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Icd10PcsCodeModel
     */
    'longDescription'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Icd10PcsCodeModel
     */
    'definition'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Icd10PcsCodeModel
     */
    'parentId'?: string | null;
}
/**
 * 
 * @export
 * @interface Icd10PcsCodeModelIEnumerableIDataResult
 */
export interface Icd10PcsCodeModelIEnumerableIDataResult {
    /**
     * 
     * @type {Array<Icd10PcsCodeModel>}
     * @memberof Icd10PcsCodeModelIEnumerableIDataResult
     */
    'data'?: Array<Icd10PcsCodeModel> | null;
    /**
     * 
     * @type {boolean}
     * @memberof Icd10PcsCodeModelIEnumerableIDataResult
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Icd10PcsCodeModelIEnumerableIDataResult
     */
    'message'?: string | null;
}
/**
 * 
 * @export
 * @interface Icd11CodeModel
 */
export interface Icd11CodeModel {
    /**
     * 
     * @type {string}
     * @memberof Icd11CodeModel
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof Icd11CodeModel
     */
    'versionName'?: string | null;
    /**
     * 
     * @type {DateOnly}
     * @memberof Icd11CodeModel
     */
    'quarterBeginDate'?: DateOnly;
    /**
     * 
     * @type {CodeType}
     * @memberof Icd11CodeModel
     */
    'codeType'?: CodeType;
    /**
     * 
     * @type {string}
     * @memberof Icd11CodeModel
     */
    'parentId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Icd11CodeModel
     */
    'code'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Icd11CodeModel
     */
    'title'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Icd11CodeModel
     */
    'codingNote'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Icd11CodeModel
     */
    'blockId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Icd11CodeModel
     */
    'codeRange'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Icd11CodeModel
     */
    'classKind'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Icd11CodeModel
     */
    'definition'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Icd11CodeModel
     */
    'longDefinition'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Icd11CodeModel
     */
    'fullySpecifiedName'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof Icd11CodeModel
     */
    'isResidual'?: boolean;
    /**
     * 
     * @type {any}
     * @memberof Icd11CodeModel
     */
    'synonym'?: any | null;
    /**
     * 
     * @type {any}
     * @memberof Icd11CodeModel
     */
    'narrowerTerm'?: any | null;
    /**
     * 
     * @type {any}
     * @memberof Icd11CodeModel
     */
    'inclusion'?: any | null;
    /**
     * 
     * @type {any}
     * @memberof Icd11CodeModel
     */
    'exclusion'?: any | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof Icd11CodeModel
     */
    'ancestors'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof Icd11CodeModel
     */
    'descendants'?: Array<string> | null;
    /**
     * 
     * @type {any}
     * @memberof Icd11CodeModel
     */
    'foundationChildElsewhere'?: any | null;
    /**
     * 
     * @type {any}
     * @memberof Icd11CodeModel
     */
    'indexTerm'?: any | null;
    /**
     * 
     * @type {any}
     * @memberof Icd11CodeModel
     */
    'postcoordinationScale'?: any | null;
    /**
     * 
     * @type {string}
     * @memberof Icd11CodeModel
     */
    'browserUrl'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Icd11CodeModel
     */
    'whoId'?: string | null;
}
/**
 * 
 * @export
 * @interface Icd11CodeModelIEnumerableIDataResult
 */
export interface Icd11CodeModelIEnumerableIDataResult {
    /**
     * 
     * @type {Array<Icd11CodeModel>}
     * @memberof Icd11CodeModelIEnumerableIDataResult
     */
    'data'?: Array<Icd11CodeModel> | null;
    /**
     * 
     * @type {boolean}
     * @memberof Icd11CodeModelIEnumerableIDataResult
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Icd11CodeModelIEnumerableIDataResult
     */
    'message'?: string | null;
}
/**
 * 
 * @export
 * @interface Icd9CodeModel
 */
export interface Icd9CodeModel {
    /**
     * 
     * @type {string}
     * @memberof Icd9CodeModel
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof Icd9CodeModel
     */
    'versionName'?: string | null;
    /**
     * 
     * @type {DateOnly}
     * @memberof Icd9CodeModel
     */
    'effectiveDate'?: DateOnly;
    /**
     * 
     * @type {CodeType}
     * @memberof Icd9CodeModel
     */
    'codeType'?: CodeType;
    /**
     * 
     * @type {string}
     * @memberof Icd9CodeModel
     */
    'code'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Icd9CodeModel
     */
    'shortDescription'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Icd9CodeModel
     */
    'longDescription'?: string | null;
}
/**
 * 
 * @export
 * @interface Icd9CodeModelIEnumerableIDataResult
 */
export interface Icd9CodeModelIEnumerableIDataResult {
    /**
     * 
     * @type {Array<Icd9CodeModel>}
     * @memberof Icd9CodeModelIEnumerableIDataResult
     */
    'data'?: Array<Icd9CodeModel> | null;
    /**
     * 
     * @type {boolean}
     * @memberof Icd9CodeModelIEnumerableIDataResult
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Icd9CodeModelIEnumerableIDataResult
     */
    'message'?: string | null;
}
/**
 * 
 * @export
 * @interface IndexCodesRequestModel
 */
export interface IndexCodesRequestModel {
    /**
     * 
     * @type {CodeSetType}
     * @memberof IndexCodesRequestModel
     */
    'codeSetType'?: CodeSetType;
    /**
     * 
     * @type {string}
     * @memberof IndexCodesRequestModel
     */
    'quarterBeginDate'?: string;
}
/**
 * 
 * @export
 * @interface InviteCognitoUserByEmailModel
 */
export interface InviteCognitoUserByEmailModel {
    /**
     * 
     * @type {string}
     * @memberof InviteCognitoUserByEmailModel
     */
    'email'?: string | null;
}
/**
 * 
 * @export
 * @interface InvoiceModel
 */
export interface InvoiceModel {
    /**
     * 
     * @type {string}
     * @memberof InvoiceModel
     */
    'userId'?: string;
    /**
     * 
     * @type {UserModel2}
     * @memberof InvoiceModel
     */
    'user'?: UserModel2;
    /**
     * 
     * @type {string}
     * @memberof InvoiceModel
     */
    'stripeInvoiceId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InvoiceModel
     */
    'stripeInvoiceNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InvoiceModel
     */
    'stripeSubscriptionId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InvoiceModel
     */
    'invoiceDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceModel
     */
    'currency'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof InvoiceModel
     */
    'amount'?: number;
    /**
     * 
     * @type {string}
     * @memberof InvoiceModel
     */
    'invoiceS3Path'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InvoiceModel
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceModel
     */
    'createdBy'?: string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceModel
     */
    'createdOn'?: string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceModel
     */
    'lastModifiedBy'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InvoiceModel
     */
    'lastModifiedOn'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof InvoiceModel
     */
    'isDeleted'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof InvoiceModel
     */
    'email'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InvoiceModel
     */
    'companyId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InvoiceModel
     */
    'companyName'?: string | null;
    /**
     * 
     * @type {StripeInvoiceStatus}
     * @memberof InvoiceModel
     */
    'status'?: StripeInvoiceStatus;
    /**
     * 
     * @type {Array<UserSubscriptionItemModel>}
     * @memberof InvoiceModel
     */
    'userSubscriptionItems'?: Array<UserSubscriptionItemModel> | null;
}
/**
 * 
 * @export
 * @interface InvoiceModelPagedResults
 */
export interface InvoiceModelPagedResults {
    /**
     * 
     * @type {Array<InvoiceModel>}
     * @memberof InvoiceModelPagedResults
     */
    'items'?: Array<InvoiceModel> | null;
    /**
     * 
     * @type {number}
     * @memberof InvoiceModelPagedResults
     */
    'totalSize'?: number;
}
/**
 * 
 * @export
 * @interface InvoiceModelPagedResultsIDataResult
 */
export interface InvoiceModelPagedResultsIDataResult {
    /**
     * 
     * @type {InvoiceModelPagedResults}
     * @memberof InvoiceModelPagedResultsIDataResult
     */
    'data'?: InvoiceModelPagedResults;
    /**
     * 
     * @type {boolean}
     * @memberof InvoiceModelPagedResultsIDataResult
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof InvoiceModelPagedResultsIDataResult
     */
    'message'?: string | null;
}
/**
 * 
 * @export
 * @interface InvoiceSendEmailRequestModel
 */
export interface InvoiceSendEmailRequestModel {
    /**
     * 
     * @type {string}
     * @memberof InvoiceSendEmailRequestModel
     */
    'id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InvoiceSendEmailRequestModel
     */
    'email'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof InvoiceSendEmailRequestModel
     */
    'isBillingUserChecked'?: boolean | null;
}
/**
 * 
 * @export
 * @interface InvoicesRequestModel
 */
export interface InvoicesRequestModel {
    /**
     * 
     * @type {string}
     * @memberof InvoicesRequestModel
     */
    'startDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InvoicesRequestModel
     */
    'endDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InvoicesRequestModel
     */
    'email'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InvoicesRequestModel
     */
    'invoiceId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InvoicesRequestModel
     */
    'subscriptionId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InvoicesRequestModel
     */
    'companyId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InvoicesRequestModel
     */
    'subscriptionItemId'?: string | null;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const LastPaymentStatus = {
    Paid: 'Paid',
    Failed: 'Failed'
} as const;

export type LastPaymentStatus = typeof LastPaymentStatus[keyof typeof LastPaymentStatus];


/**
 * 
 * @export
 * @interface LastSearchHistoryResponseModel
 */
export interface LastSearchHistoryResponseModel {
    /**
     * 
     * @type {string}
     * @memberof LastSearchHistoryResponseModel
     */
    'searchTerm'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof LastSearchHistoryResponseModel
     */
    'count'?: number;
}
/**
 * 
 * @export
 * @interface LastSearchHistoryResponseModelIEnumerableIDataResult
 */
export interface LastSearchHistoryResponseModelIEnumerableIDataResult {
    /**
     * 
     * @type {Array<LastSearchHistoryResponseModel>}
     * @memberof LastSearchHistoryResponseModelIEnumerableIDataResult
     */
    'data'?: Array<LastSearchHistoryResponseModel> | null;
    /**
     * 
     * @type {boolean}
     * @memberof LastSearchHistoryResponseModelIEnumerableIDataResult
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof LastSearchHistoryResponseModelIEnumerableIDataResult
     */
    'message'?: string | null;
}
/**
 * 
 * @export
 * @interface Line
 */
export interface Line {
    /**
     * 
     * @type {number}
     * @memberof Line
     */
    'amount'?: number;
    /**
     * 
     * @type {string}
     * @memberof Line
     */
    'currency'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Line
     */
    'productName'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof Line
     */
    'userFullNames'?: Array<string> | null;
    /**
     * 
     * @type {number}
     * @memberof Line
     */
    'quantity'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof Line
     */
    'unitPrice'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof Line
     */
    'actualAmount'?: number;
    /**
     * 
     * @type {number}
     * @memberof Line
     */
    'actualUnitPrice'?: number | null;
}
/**
 * 
 * @export
 * @interface MarkAsReadNotificationRequestModel
 */
export interface MarkAsReadNotificationRequestModel {
    /**
     * 
     * @type {Array<string>}
     * @memberof MarkAsReadNotificationRequestModel
     */
    'ids'?: Array<string> | null;
}
/**
 * 
 * @export
 * @interface MarkAsReadNotificationResponseModel
 */
export interface MarkAsReadNotificationResponseModel {
    /**
     * 
     * @type {string}
     * @memberof MarkAsReadNotificationResponseModel
     */
    'ids'?: string;
}
/**
 * 
 * @export
 * @interface MarkAsReadNotificationResponseModelIDataResult
 */
export interface MarkAsReadNotificationResponseModelIDataResult {
    /**
     * 
     * @type {MarkAsReadNotificationResponseModel}
     * @memberof MarkAsReadNotificationResponseModelIDataResult
     */
    'data'?: MarkAsReadNotificationResponseModel;
    /**
     * 
     * @type {boolean}
     * @memberof MarkAsReadNotificationResponseModelIDataResult
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof MarkAsReadNotificationResponseModelIDataResult
     */
    'message'?: string | null;
}
/**
 * 
 * @export
 * @interface MessageSendRequest
 */
export interface MessageSendRequest {
    /**
     * 
     * @type {string}
     * @memberof MessageSendRequest
     */
    'messageType'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MessageSendRequest
     */
    'from'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MessageSendRequest
     */
    'subject'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof MessageSendRequest
     */
    'to'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof MessageSendRequest
     */
    'content'?: string | null;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof MessageSendRequest
     */
    'metadata'?: { [key: string]: string; } | null;
}
/**
 * 
 * @export
 * @interface MsdrgCalculateData
 */
export interface MsdrgCalculateData {
    /**
     * 
     * @type {MsdrgCalculateDataDrg}
     * @memberof MsdrgCalculateData
     */
    'drg'?: MsdrgCalculateDataDrg;
    /**
     * 
     * @type {{ [key: string]: number; }}
     * @memberof MsdrgCalculateData
     */
    'mce'?: { [key: string]: number; };
}
/**
 * @type MsdrgCalculateDataDrg
 * @export
 */
export type MsdrgCalculateDataDrg = MsdrgOutputData;

/**
 * 
 * @export
 * @interface MsdrgCalculateQuery
 */
export interface MsdrgCalculateQuery {
    /**
     * 
     * @type {string}
     * @memberof MsdrgCalculateQuery
     */
    'dischargeDate': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof MsdrgCalculateQuery
     */
    'ptrCodes'?: Array<string>;
    /**
     * 
     * @type {Array<CodeItem>}
     * @memberof MsdrgCalculateQuery
     */
    'sdxCodes'?: Array<CodeItem>;
    /**
     * 
     * @type {CodeItem}
     * @memberof MsdrgCalculateQuery
     */
    'admissionDiagnosisCode'?: CodeItem;
    /**
     * 
     * @type {CodeItem}
     * @memberof MsdrgCalculateQuery
     */
    'principalDiagnosisCode': CodeItem;
    /**
     * 
     * @type {string}
     * @memberof MsdrgCalculateQuery
     */
    'sex'?: MsdrgCalculateQuerySexEnum;
    /**
     * 
     * @type {string}
     * @memberof MsdrgCalculateQuery
     */
    'dischargeStatus'?: MsdrgCalculateQueryDischargeStatusEnum;
    /**
     * 
     * @type {number}
     * @memberof MsdrgCalculateQuery
     */
    'ageDaysAdmit'?: number;
    /**
     * 
     * @type {number}
     * @memberof MsdrgCalculateQuery
     */
    'ageDaysDischarge'?: number;
    /**
     * 
     * @type {number}
     * @memberof MsdrgCalculateQuery
     */
    'ageInYears'?: number;
}

export const MsdrgCalculateQuerySexEnum = {
    Female: 'FEMALE',
    Male: 'MALE',
    Unknown: 'UNKNOWN'
} as const;

export type MsdrgCalculateQuerySexEnum = typeof MsdrgCalculateQuerySexEnum[keyof typeof MsdrgCalculateQuerySexEnum];
export const MsdrgCalculateQueryDischargeStatusEnum = {
    None: 'NONE',
    HomeSelfcareRoutine: 'HOME_SELFCARE_ROUTINE',
    ShortTermHospital: 'SHORT_TERM_HOSPITAL',
    Snf: 'SNF',
    CustSuppCare: 'CUST_SUPP_CARE',
    CancChildHosp: 'CANC_CHILD_HOSP',
    HomeHealthService: 'HOME_HEALTH_SERVICE',
    LeftAgainstMedicalAdvice: 'LEFT_AGAINST_MEDICAL_ADVICE',
    Died: 'DIED',
    CourtLawEnfrc: 'COURT_LAW_ENFRC',
    StillAPatient: 'STILL_A_PATIENT',
    FederalHospital: 'FEDERAL_HOSPITAL',
    HospiceHome: 'HOSPICE_HOME',
    HospiceMedicalFacility: 'HOSPICE_MEDICAL_FACILITY',
    SwingBed: 'SWING_BED',
    RehabFacilityRehabUnit: 'REHAB_FACILITY_REHAB_UNIT',
    LongTermCareHospital: 'LONG_TERM_CARE_HOSPITAL',
    NursingFacilityMedicaidCertified: 'NURSING_FACILITY_MEDICAID_CERTIFIED',
    PsychHospUnit: 'PSYCH_HOSP_UNIT',
    CritAccHosp: 'CRIT_ACC_HOSP',
    DesignatedDisasterAlternativeCareSite: 'DESIGNATED_DISASTER_ALTERNATIVE_CARE_SITE',
    OthInstitution: 'OTH_INSTITUTION',
    HomeSelfCareWPlannedReadmission: 'HOME_SELF_CARE_W_PLANNED_READMISSION',
    ShortTermHospitalWPlannedReadmission: 'SHORT_TERM_HOSPITAL_W_PLANNED_READMISSION',
    SnfWPlannedReadmission: 'SNF_W_PLANNED_READMISSION',
    CustSuppCareWPlannedReadmission: 'CUST_SUPP_CARE_W_PLANNED_READMISSION',
    CancChildHospWPlannedReadmission: 'CANC_CHILD_HOSP_W_PLANNED_READMISSION',
    HomeHealthServiceWPlannedReadmission: 'HOME_HEALTH_SERVICE_W_PLANNED_READMISSION',
    CourtLawEnfrcWPlannedReadmission: 'COURT_LAW_ENFRC_W_PLANNED_READMISSION',
    FederalHospitalWPlannedReadmission: 'FEDERAL_HOSPITAL_W_PLANNED_READMISSION',
    SwingBedWPlannedReadmission: 'SWING_BED_W_PLANNED_READMISSION',
    RehabFacilityUnitWPlannedReadmission: 'REHAB_FACILITY_UNIT_W_PLANNED_READMISSION',
    LtchWPlannedReadmission: 'LTCH_W_PLANNED_READMISSION',
    NursgFacMedicaidCertWPlannedReadmission: 'NURSG_FAC_MEDICAID_CERT_W_PLANNED_READMISSION',
    PsychHospUnitWPlannedReadmission: 'PSYCH_HOSP_UNIT_W_PLANNED_READMISSION',
    CritAccHospWPlannedReadmission: 'CRIT_ACC_HOSP_W_PLANNED_READMISSION',
    OthInstitutionWPlannedReadmission: 'OTH_INSTITUTION_W_PLANNED_READMISSION'
} as const;

export type MsdrgCalculateQueryDischargeStatusEnum = typeof MsdrgCalculateQueryDischargeStatusEnum[keyof typeof MsdrgCalculateQueryDischargeStatusEnum];

/**
 * 
 * @export
 * @interface MsdrgCalculateQueryPlainResult
 */
export interface MsdrgCalculateQueryPlainResult {
    /**
     * 
     * @type {string}
     * @memberof MsdrgCalculateQueryPlainResult
     */
    'data'?: string;
    /**
     * 
     * @type {string}
     * @memberof MsdrgCalculateQueryPlainResult
     */
    'contentType'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof MsdrgCalculateQueryPlainResult
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof MsdrgCalculateQueryPlainResult
     */
    'message'?: string;
}
/**
 * 
 * @export
 * @interface MsdrgCalculateQueryResult
 */
export interface MsdrgCalculateQueryResult {
    /**
     * 
     * @type {MsdrgCalculateData}
     * @memberof MsdrgCalculateQueryResult
     */
    'data'?: MsdrgCalculateData;
    /**
     * 
     * @type {boolean}
     * @memberof MsdrgCalculateQueryResult
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof MsdrgCalculateQueryResult
     */
    'message'?: string;
}
/**
 * 
 * @export
 * @interface MsdrgGrouperFlags
 */
export interface MsdrgGrouperFlags {
    /**
     * 
     * @type {string}
     * @memberof MsdrgGrouperFlags
     */
    'admitDxGrouperFlag'?: MsdrgGrouperFlagsAdmitDxGrouperFlagEnum;
    /**
     * 
     * @type {string}
     * @memberof MsdrgGrouperFlags
     */
    'finalDrgSecondaryDxCcMcc'?: MsdrgGrouperFlagsFinalDrgSecondaryDxCcMccEnum;
    /**
     * 
     * @type {string}
     * @memberof MsdrgGrouperFlags
     */
    'initialDrgSecondaryDxCcMcc'?: MsdrgGrouperFlagsInitialDrgSecondaryDxCcMccEnum;
    /**
     * 
     * @type {number}
     * @memberof MsdrgGrouperFlags
     */
    'numHacCategoriesSatisfied'?: number;
    /**
     * 
     * @type {string}
     * @memberof MsdrgGrouperFlags
     */
    'hacStatusValue'?: MsdrgGrouperFlagsHacStatusValueEnum;
}

export const MsdrgGrouperFlagsAdmitDxGrouperFlagEnum = {
    Invalid: 'DX_INVALID',
    Valid: 'DX_VALID',
    NotGiven: 'DX_NOT_GIVEN'
} as const;

export type MsdrgGrouperFlagsAdmitDxGrouperFlagEnum = typeof MsdrgGrouperFlagsAdmitDxGrouperFlagEnum[keyof typeof MsdrgGrouperFlagsAdmitDxGrouperFlagEnum];
export const MsdrgGrouperFlagsFinalDrgSecondaryDxCcMccEnum = {
    Mcc: 'MCC',
    Cc: 'CC',
    NonCc: 'NON_CC',
    None: 'NONE'
} as const;

export type MsdrgGrouperFlagsFinalDrgSecondaryDxCcMccEnum = typeof MsdrgGrouperFlagsFinalDrgSecondaryDxCcMccEnum[keyof typeof MsdrgGrouperFlagsFinalDrgSecondaryDxCcMccEnum];
export const MsdrgGrouperFlagsInitialDrgSecondaryDxCcMccEnum = {
    Mcc: 'MCC',
    Cc: 'CC',
    NonCc: 'NON_CC',
    None: 'NONE'
} as const;

export type MsdrgGrouperFlagsInitialDrgSecondaryDxCcMccEnum = typeof MsdrgGrouperFlagsInitialDrgSecondaryDxCcMccEnum[keyof typeof MsdrgGrouperFlagsInitialDrgSecondaryDxCcMccEnum];
export const MsdrgGrouperFlagsHacStatusValueEnum = {
    NotApplicable: 'NOT_APPLICABLE',
    FinalDrgNoChange: 'FINAL_DRG_NO_CHANGE',
    FinalDrgChanges: 'FINAL_DRG_CHANGES',
    FinalDrgUngroupable: 'FINAL_DRG_UNGROUPABLE'
} as const;

export type MsdrgGrouperFlagsHacStatusValueEnum = typeof MsdrgGrouperFlagsHacStatusValueEnum[keyof typeof MsdrgGrouperFlagsHacStatusValueEnum];

/**
 * 
 * @export
 * @interface MsdrgHac
 */
export interface MsdrgHac {
    /**
     * 
     * @type {string}
     * @memberof MsdrgHac
     */
    'hacStatus'?: MsdrgHacHacStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof MsdrgHac
     */
    'hacList'?: string;
    /**
     * 
     * @type {number}
     * @memberof MsdrgHac
     */
    'hacNumber'?: number;
    /**
     * 
     * @type {string}
     * @memberof MsdrgHac
     */
    'description'?: string;
}

export const MsdrgHacHacStatusEnum = {
    NotOnHacList: 'NOT_ON_HAC_LIST',
    HacCriteriaMet: 'HAC_CRITERIA_MET',
    HacCriteriaNotMet: 'HAC_CRITERIA_NOT_MET',
    HacNotApplicableExclusion: 'HAC_NOT_APPLICABLE_EXCLUSION',
    HacNotApplicableExempt: 'HAC_NOT_APPLICABLE_EXEMPT'
} as const;

export type MsdrgHacHacStatusEnum = typeof MsdrgHacHacStatusEnum[keyof typeof MsdrgHacHacStatusEnum];

/**
 * 
 * @export
 * @interface MsdrgInputDxCode
 */
export interface MsdrgInputDxCode {
    /**
     * 
     * @type {string}
     * @memberof MsdrgInputDxCode
     */
    'value'?: string;
    /**
     * 
     * @type {string}
     * @memberof MsdrgInputDxCode
     */
    'poa'?: MsdrgInputDxCodePoaEnum;
}

export const MsdrgInputDxCodePoaEnum = {
    Y: 'Y',
    N: 'N',
    U: 'U',
    W: 'W',
    One: 'ONE',
    E: 'E',
    Blank: 'BLANK',
    Invalid: 'INVALID'
} as const;

export type MsdrgInputDxCodePoaEnum = typeof MsdrgInputDxCodePoaEnum[keyof typeof MsdrgInputDxCodePoaEnum];

/**
 * 
 * @export
 * @interface MsdrgInputPrCode
 */
export interface MsdrgInputPrCode {
    /**
     * 
     * @type {string}
     * @memberof MsdrgInputPrCode
     */
    'value'?: string;
}
/**
 * 
 * @export
 * @interface MsdrgOutputData
 */
export interface MsdrgOutputData {
    /**
     * 
     * @type {string}
     * @memberof MsdrgOutputData
     */
    'initialDrgSdxSeverity'?: MsdrgOutputDataInitialDrgSdxSeverityEnum;
    /**
     * 
     * @type {number}
     * @memberof MsdrgOutputData
     */
    'numHacCategoriesSatisfied'?: number;
    /**
     * 
     * @type {Array<MsdrgOutputDxCode>}
     * @memberof MsdrgOutputData
     */
    'sdxOutput'?: Array<MsdrgOutputDxCode>;
    /**
     * 
     * @type {MsdrgGrouperFlags}
     * @memberof MsdrgOutputData
     */
    'grouperFlags'?: MsdrgGrouperFlags;
    /**
     * 
     * @type {MsdrgValueInteger}
     * @memberof MsdrgOutputData
     */
    'initialBaseDrg'?: MsdrgValueInteger;
    /**
     * 
     * @type {string}
     * @memberof MsdrgOutputData
     */
    'finalGrc'?: MsdrgOutputDataFinalGrcEnum;
    /**
     * 
     * @type {MsdrgValueInteger}
     * @memberof MsdrgOutputData
     */
    'finalBaseDrg'?: MsdrgValueInteger;
    /**
     * 
     * @type {string}
     * @memberof MsdrgOutputData
     */
    'finalDrgSdxSeverity'?: MsdrgOutputDataFinalDrgSdxSeverityEnum;
    /**
     * 
     * @type {MsdrgValueInteger}
     * @memberof MsdrgOutputData
     */
    'initialMdc'?: MsdrgValueInteger;
    /**
     * 
     * @type {string}
     * @memberof MsdrgOutputData
     */
    'hacStatus'?: MsdrgOutputDataHacStatusEnum;
    /**
     * 
     * @type {MsdrgValueInteger}
     * @memberof MsdrgOutputData
     */
    'initialDrg'?: MsdrgValueInteger;
    /**
     * 
     * @type {string}
     * @memberof MsdrgOutputData
     */
    'initialMedSugType'?: MsdrgOutputDataInitialMedSugTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof MsdrgOutputData
     */
    'initialSeverity'?: MsdrgOutputDataInitialSeverityEnum;
    /**
     * 
     * @type {MsdrgOutputDxCode}
     * @memberof MsdrgOutputData
     */
    'pdxOutput'?: MsdrgOutputDxCode;
    /**
     * 
     * @type {Array<MsdrgOutputPrCode>}
     * @memberof MsdrgOutputData
     */
    'procOutput'?: Array<MsdrgOutputPrCode>;
    /**
     * 
     * @type {string}
     * @memberof MsdrgOutputData
     */
    'finalMedSugType'?: MsdrgOutputDataFinalMedSugTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof MsdrgOutputData
     */
    'finalSeverity'?: MsdrgOutputDataFinalSeverityEnum;
    /**
     * 
     * @type {MsdrgValueInteger}
     * @memberof MsdrgOutputData
     */
    'finalMdc'?: MsdrgValueInteger;
    /**
     * 
     * @type {MsdrgValueInteger}
     * @memberof MsdrgOutputData
     */
    'finalDrg'?: MsdrgValueInteger;
    /**
     * 
     * @type {string}
     * @memberof MsdrgOutputData
     */
    'initialGrc'?: MsdrgOutputDataInitialGrcEnum;
}

export const MsdrgOutputDataInitialDrgSdxSeverityEnum = {
    Mcc: 'MCC',
    Cc: 'CC',
    NonCc: 'NON_CC',
    None: 'NONE'
} as const;

export type MsdrgOutputDataInitialDrgSdxSeverityEnum = typeof MsdrgOutputDataInitialDrgSdxSeverityEnum[keyof typeof MsdrgOutputDataInitialDrgSdxSeverityEnum];
export const MsdrgOutputDataFinalGrcEnum = {
    Ok: 'OK',
    DxCannotBePdx: 'DX_CANNOT_BE_PDX',
    InvalidSex: 'INVALID_SEX',
    InvalidDischargeStatus: 'INVALID_DISCHARGE_STATUS',
    InvalidPdx: 'INVALID_PDX',
    HacMissingOnePoa: 'HAC_MISSING_ONE_POA',
    HacStatusInvalidPoaNOrU: 'HAC_STATUS_INVALID_POA_N_OR_U',
    HacStatusInvalidPoaInvalidOr1: 'HAC_STATUS_INVALID_POA_INVALID_OR_1',
    HacStatusInvalidMultHacsPoaNotYW: 'HAC_STATUS_INVALID_MULT_HACS_POA_NOT_Y_W'
} as const;

export type MsdrgOutputDataFinalGrcEnum = typeof MsdrgOutputDataFinalGrcEnum[keyof typeof MsdrgOutputDataFinalGrcEnum];
export const MsdrgOutputDataFinalDrgSdxSeverityEnum = {
    Mcc: 'MCC',
    Cc: 'CC',
    NonCc: 'NON_CC',
    None: 'NONE'
} as const;

export type MsdrgOutputDataFinalDrgSdxSeverityEnum = typeof MsdrgOutputDataFinalDrgSdxSeverityEnum[keyof typeof MsdrgOutputDataFinalDrgSdxSeverityEnum];
export const MsdrgOutputDataHacStatusEnum = {
    NotApplicable: 'NOT_APPLICABLE',
    FinalDrgNoChange: 'FINAL_DRG_NO_CHANGE',
    FinalDrgChanges: 'FINAL_DRG_CHANGES',
    FinalDrgUngroupable: 'FINAL_DRG_UNGROUPABLE'
} as const;

export type MsdrgOutputDataHacStatusEnum = typeof MsdrgOutputDataHacStatusEnum[keyof typeof MsdrgOutputDataHacStatusEnum];
export const MsdrgOutputDataInitialMedSugTypeEnum = {
    Medical: 'MEDICAL',
    Surgical: 'SURGICAL',
    None: 'NONE'
} as const;

export type MsdrgOutputDataInitialMedSugTypeEnum = typeof MsdrgOutputDataInitialMedSugTypeEnum[keyof typeof MsdrgOutputDataInitialMedSugTypeEnum];
export const MsdrgOutputDataInitialSeverityEnum = {
    Mcc: 'MCC',
    Cc: 'CC',
    NonCc: 'NON_CC',
    None: 'NONE'
} as const;

export type MsdrgOutputDataInitialSeverityEnum = typeof MsdrgOutputDataInitialSeverityEnum[keyof typeof MsdrgOutputDataInitialSeverityEnum];
export const MsdrgOutputDataFinalMedSugTypeEnum = {
    Medical: 'MEDICAL',
    Surgical: 'SURGICAL',
    None: 'NONE'
} as const;

export type MsdrgOutputDataFinalMedSugTypeEnum = typeof MsdrgOutputDataFinalMedSugTypeEnum[keyof typeof MsdrgOutputDataFinalMedSugTypeEnum];
export const MsdrgOutputDataFinalSeverityEnum = {
    Mcc: 'MCC',
    Cc: 'CC',
    NonCc: 'NON_CC',
    None: 'NONE'
} as const;

export type MsdrgOutputDataFinalSeverityEnum = typeof MsdrgOutputDataFinalSeverityEnum[keyof typeof MsdrgOutputDataFinalSeverityEnum];
export const MsdrgOutputDataInitialGrcEnum = {
    Ok: 'OK',
    DxCannotBePdx: 'DX_CANNOT_BE_PDX',
    InvalidSex: 'INVALID_SEX',
    InvalidDischargeStatus: 'INVALID_DISCHARGE_STATUS',
    InvalidPdx: 'INVALID_PDX',
    HacMissingOnePoa: 'HAC_MISSING_ONE_POA',
    HacStatusInvalidPoaNOrU: 'HAC_STATUS_INVALID_POA_N_OR_U',
    HacStatusInvalidPoaInvalidOr1: 'HAC_STATUS_INVALID_POA_INVALID_OR_1',
    HacStatusInvalidMultHacsPoaNotYW: 'HAC_STATUS_INVALID_MULT_HACS_POA_NOT_Y_W'
} as const;

export type MsdrgOutputDataInitialGrcEnum = typeof MsdrgOutputDataInitialGrcEnum[keyof typeof MsdrgOutputDataInitialGrcEnum];

/**
 * 
 * @export
 * @interface MsdrgOutputDxCode
 */
export interface MsdrgOutputDxCode {
    /**
     * 
     * @type {MsdrgInputDxCode}
     * @memberof MsdrgOutputDxCode
     */
    'inputDxCode'?: MsdrgInputDxCode;
    /**
     * 
     * @type {string}
     * @memberof MsdrgOutputDxCode
     */
    'diagnosisAffectsDrg'?: MsdrgOutputDxCodeDiagnosisAffectsDrgEnum;
    /**
     * 
     * @type {boolean}
     * @memberof MsdrgOutputDxCode
     */
    'diagnosisRecognizedByGrouper'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof MsdrgOutputDxCode
     */
    'finalSeverityUsage'?: MsdrgOutputDxCodeFinalSeverityUsageEnum;
    /**
     * 
     * @type {string}
     * @memberof MsdrgOutputDxCode
     */
    'initialSeverityUsage'?: MsdrgOutputDxCodeInitialSeverityUsageEnum;
    /**
     * 
     * @type {string}
     * @memberof MsdrgOutputDxCode
     */
    'poaErrorCode'?: MsdrgOutputDxCodePoaErrorCodeEnum;
    /**
     * 
     * @type {Array<MsdrgHac>}
     * @memberof MsdrgOutputDxCode
     */
    'hacs'?: Array<MsdrgHac>;
    /**
     * 
     * @type {string}
     * @memberof MsdrgOutputDxCode
     */
    'legacyFlagString'?: string;
}

export const MsdrgOutputDxCodeDiagnosisAffectsDrgEnum = {
    Initial: 'INITIAL',
    Final: 'FINAL',
    Both: 'BOTH',
    None: 'NONE'
} as const;

export type MsdrgOutputDxCodeDiagnosisAffectsDrgEnum = typeof MsdrgOutputDxCodeDiagnosisAffectsDrgEnum[keyof typeof MsdrgOutputDxCodeDiagnosisAffectsDrgEnum];
export const MsdrgOutputDxCodeFinalSeverityUsageEnum = {
    Mcc: 'MCC',
    Cc: 'CC',
    MccExcluded: 'MCC_EXCLUDED',
    CcExcluded: 'CC_EXCLUDED',
    Neither: 'NEITHER'
} as const;

export type MsdrgOutputDxCodeFinalSeverityUsageEnum = typeof MsdrgOutputDxCodeFinalSeverityUsageEnum[keyof typeof MsdrgOutputDxCodeFinalSeverityUsageEnum];
export const MsdrgOutputDxCodeInitialSeverityUsageEnum = {
    Mcc: 'MCC',
    Cc: 'CC',
    MccExcluded: 'MCC_EXCLUDED',
    CcExcluded: 'CC_EXCLUDED',
    Neither: 'NEITHER'
} as const;

export type MsdrgOutputDxCodeInitialSeverityUsageEnum = typeof MsdrgOutputDxCodeInitialSeverityUsageEnum[keyof typeof MsdrgOutputDxCodeInitialSeverityUsageEnum];
export const MsdrgOutputDxCodePoaErrorCodeEnum = {
    PoaNotChecked: 'POA_NOT_CHECKED',
    PoaNotRecognized: 'POA_NOT_RECOGNIZED',
    PoaRecognizedNotPoa: 'POA_RECOGNIZED_NOT_POA',
    PoaRecognizedYesPoa: 'POA_RECOGNIZED_YES_POA',
    HospitalExempt: 'HOSPITAL_EXEMPT',
    BlankDxNotConsidered: 'BLANK_DX_NOT_CONSIDERED'
} as const;

export type MsdrgOutputDxCodePoaErrorCodeEnum = typeof MsdrgOutputDxCodePoaErrorCodeEnum[keyof typeof MsdrgOutputDxCodePoaErrorCodeEnum];

/**
 * 
 * @export
 * @interface MsdrgOutputPrCode
 */
export interface MsdrgOutputPrCode {
    /**
     * 
     * @type {MsdrgInputPrCode}
     * @memberof MsdrgOutputPrCode
     */
    'inputPrCode'?: MsdrgInputPrCode;
    /**
     * 
     * @type {string}
     * @memberof MsdrgOutputPrCode
     */
    'legacyFlagString'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof MsdrgOutputPrCode
     */
    'procedureRecognizedByGrouper'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MsdrgOutputPrCode
     */
    'procedureIsOperatingRoomProcedure'?: boolean;
    /**
     * 
     * @type {Set<string>}
     * @memberof MsdrgOutputPrCode
     */
    'hacUsage'?: Set<MsdrgOutputPrCodeHacUsageEnum>;
    /**
     * 
     * @type {string}
     * @memberof MsdrgOutputPrCode
     */
    'procedureAffectsDrg'?: MsdrgOutputPrCodeProcedureAffectsDrgEnum;
}

export const MsdrgOutputPrCodeHacUsageEnum = {
    HacNotUsed: 'HAC_NOT_USED',
    Hac08: 'HAC_08',
    Hac10: 'HAC_10',
    Hac11: 'HAC_11',
    Hac12: 'HAC_12',
    Hac13: 'HAC_13',
    Hac14: 'HAC_14',
    Blank: 'BLANK'
} as const;

export type MsdrgOutputPrCodeHacUsageEnum = typeof MsdrgOutputPrCodeHacUsageEnum[keyof typeof MsdrgOutputPrCodeHacUsageEnum];
export const MsdrgOutputPrCodeProcedureAffectsDrgEnum = {
    Initial: 'INITIAL',
    Final: 'FINAL',
    Both: 'BOTH',
    None: 'NONE'
} as const;

export type MsdrgOutputPrCodeProcedureAffectsDrgEnum = typeof MsdrgOutputPrCodeProcedureAffectsDrgEnum[keyof typeof MsdrgOutputPrCodeProcedureAffectsDrgEnum];

/**
 * 
 * @export
 * @interface MsdrgValueInteger
 */
export interface MsdrgValueInteger {
    /**
     * 
     * @type {number}
     * @memberof MsdrgValueInteger
     */
    'value'?: number;
    /**
     * 
     * @type {string}
     * @memberof MsdrgValueInteger
     */
    'description'?: string;
}
/**
 * 
 * @export
 * @interface NcciModifierCodeModel
 */
export interface NcciModifierCodeModel {
    /**
     * 
     * @type {NcciModifierType}
     * @memberof NcciModifierCodeModel
     */
    'type'?: NcciModifierType;
    /**
     * 
     * @type {string}
     * @memberof NcciModifierCodeModel
     */
    'code'?: string | null;
}
/**
 * 
 * @export
 * @interface NcciModifierCodeModelIEnumerableIDataResult
 */
export interface NcciModifierCodeModelIEnumerableIDataResult {
    /**
     * 
     * @type {Array<NcciModifierCodeModel>}
     * @memberof NcciModifierCodeModelIEnumerableIDataResult
     */
    'data'?: Array<NcciModifierCodeModel> | null;
    /**
     * 
     * @type {boolean}
     * @memberof NcciModifierCodeModelIEnumerableIDataResult
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof NcciModifierCodeModelIEnumerableIDataResult
     */
    'message'?: string | null;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const NcciModifierType = {
    Anatomic: 'Anatomic',
    GlobalSurgery: 'GlobalSurgery',
    Other: 'Other'
} as const;

export type NcciModifierType = typeof NcciModifierType[keyof typeof NcciModifierType];


/**
 * 
 * @export
 * @enum {string}
 */

export const NcciPtpColumnType = {
    Column1: 'Column1',
    Column2: 'Column2'
} as const;

export type NcciPtpColumnType = typeof NcciPtpColumnType[keyof typeof NcciPtpColumnType];


/**
 * 
 * @export
 * @interface NcciPtpEditRequestModel
 */
export interface NcciPtpEditRequestModel {
    /**
     * 
     * @type {string}
     * @memberof NcciPtpEditRequestModel
     */
    'code'?: string | null;
    /**
     * 
     * @type {DateOnlyModel}
     * @memberof NcciPtpEditRequestModel
     */
    'dateOnlyModel'?: DateOnlyModel;
    /**
     * 
     * @type {ProgramType}
     * @memberof NcciPtpEditRequestModel
     */
    'programType'?: ProgramType;
    /**
     * 
     * @type {NcciType}
     * @memberof NcciPtpEditRequestModel
     */
    'ncciType'?: NcciType;
    /**
     * 
     * @type {NcciPtpColumnType}
     * @memberof NcciPtpEditRequestModel
     */
    'ncciPtpColumnType'?: NcciPtpColumnType;
}
/**
 * 
 * @export
 * @interface NcciPtpEditResponseModel
 */
export interface NcciPtpEditResponseModel {
    /**
     * 
     * @type {string}
     * @memberof NcciPtpEditResponseModel
     */
    'code'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof NcciPtpEditResponseModel
     */
    'ptpEditRationale'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof NcciPtpEditResponseModel
     */
    'modifier'?: string | null;
}
/**
 * 
 * @export
 * @interface NcciPtpEditResponseModelIEnumerableIDataResult
 */
export interface NcciPtpEditResponseModelIEnumerableIDataResult {
    /**
     * 
     * @type {Array<NcciPtpEditResponseModel>}
     * @memberof NcciPtpEditResponseModelIEnumerableIDataResult
     */
    'data'?: Array<NcciPtpEditResponseModel> | null;
    /**
     * 
     * @type {boolean}
     * @memberof NcciPtpEditResponseModelIEnumerableIDataResult
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof NcciPtpEditResponseModelIEnumerableIDataResult
     */
    'message'?: string | null;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const NcciType = {
    NotAvailable: 'NotAvailable',
    OutpatientHospital: 'OutpatientHospital',
    Practitioner: 'Practitioner',
    Dme: 'Dme'
} as const;

export type NcciType = typeof NcciType[keyof typeof NcciType];


/**
 * 
 * @export
 * @interface NcciVersionModel
 */
export interface NcciVersionModel {
    /**
     * 
     * @type {DateOnly}
     * @memberof NcciVersionModel
     */
    'versionDate'?: DateOnly;
    /**
     * 
     * @type {ProgramType}
     * @memberof NcciVersionModel
     */
    'programType'?: ProgramType;
}
/**
 * 
 * @export
 * @interface NcciVersionModelIEnumerableIDataResult
 */
export interface NcciVersionModelIEnumerableIDataResult {
    /**
     * 
     * @type {Array<NcciVersionModel>}
     * @memberof NcciVersionModelIEnumerableIDataResult
     */
    'data'?: Array<NcciVersionModel> | null;
    /**
     * 
     * @type {boolean}
     * @memberof NcciVersionModelIEnumerableIDataResult
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof NcciVersionModelIEnumerableIDataResult
     */
    'message'?: string | null;
}
/**
 * 
 * @export
 * @interface NewsletterModel
 */
export interface NewsletterModel {
    /**
     * 
     * @type {string}
     * @memberof NewsletterModel
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof NewsletterModel
     */
    'email'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof NewsletterModel
     */
    'createdOn'?: string;
}
/**
 * 
 * @export
 * @interface NewsletterModelIDataResult
 */
export interface NewsletterModelIDataResult {
    /**
     * 
     * @type {NewsletterModel}
     * @memberof NewsletterModelIDataResult
     */
    'data'?: NewsletterModel;
    /**
     * 
     * @type {boolean}
     * @memberof NewsletterModelIDataResult
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof NewsletterModelIDataResult
     */
    'message'?: string | null;
}
/**
 * 
 * @export
 * @interface NewsletterRequestModel
 */
export interface NewsletterRequestModel {
    /**
     * 
     * @type {string}
     * @memberof NewsletterRequestModel
     */
    'email'?: string | null;
}
/**
 * 
 * @export
 * @interface NotificationModel
 */
export interface NotificationModel {
    /**
     * 
     * @type {string}
     * @memberof NotificationModel
     */
    'id'?: string;
    /**
     * 
     * @type {NotificationScope}
     * @memberof NotificationModel
     */
    'scope'?: NotificationScope;
    /**
     * 
     * @type {NotificationType}
     * @memberof NotificationModel
     */
    'notificationType'?: NotificationType;
    /**
     * 
     * @type {string}
     * @memberof NotificationModel
     */
    'createdAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof NotificationModel
     */
    'visibleUntil'?: string;
    /**
     * 
     * @type {string}
     * @memberof NotificationModel
     */
    'senderName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof NotificationModel
     */
    'receiptId'?: string;
    /**
     * 
     * @type {UserModel1}
     * @memberof NotificationModel
     */
    'receiver'?: UserModel1;
    /**
     * 
     * @type {string}
     * @memberof NotificationModel
     */
    'subject'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof NotificationModel
     */
    'content'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof NotificationModel
     */
    'read'?: boolean;
}
/**
 * 
 * @export
 * @interface NotificationModelPagedResults
 */
export interface NotificationModelPagedResults {
    /**
     * 
     * @type {Array<NotificationModel>}
     * @memberof NotificationModelPagedResults
     */
    'items'?: Array<NotificationModel> | null;
    /**
     * 
     * @type {number}
     * @memberof NotificationModelPagedResults
     */
    'totalSize'?: number;
}
/**
 * 
 * @export
 * @interface NotificationModelPagedResultsIDataResult
 */
export interface NotificationModelPagedResultsIDataResult {
    /**
     * 
     * @type {NotificationModelPagedResults}
     * @memberof NotificationModelPagedResultsIDataResult
     */
    'data'?: NotificationModelPagedResults;
    /**
     * 
     * @type {boolean}
     * @memberof NotificationModelPagedResultsIDataResult
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof NotificationModelPagedResultsIDataResult
     */
    'message'?: string | null;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const NotificationScope = {
    Announcement: 'Announcement',
    System: 'System',
    UserSpecified: 'UserSpecified'
} as const;

export type NotificationScope = typeof NotificationScope[keyof typeof NotificationScope];


/**
 * 
 * @export
 * @enum {string}
 */

export const NotificationType = {
    Info: 'Info',
    Warning: 'Warning',
    Error: 'Error'
} as const;

export type NotificationType = typeof NotificationType[keyof typeof NotificationType];


/**
 * 
 * @export
 * @interface NpiAddressEntity
 */
export interface NpiAddressEntity {
    /**
     * 
     * @type {NpiAddressPurpose}
     * @memberof NpiAddressEntity
     */
    'addressPurpose'?: NpiAddressPurpose;
    /**
     * 
     * @type {string}
     * @memberof NpiAddressEntity
     */
    'addressPurposeDescription'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof NpiAddressEntity
     */
    'city'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof NpiAddressEntity
     */
    'state'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof NpiAddressEntity
     */
    'postalCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof NpiAddressEntity
     */
    'countryCode'?: string | null;
}
/**
 * 
 * @export
 * @interface NpiAddressModel
 */
export interface NpiAddressModel {
    /**
     * 
     * @type {string}
     * @memberof NpiAddressModel
     */
    'id'?: string;
    /**
     * 
     * @type {NpiAddressPurpose}
     * @memberof NpiAddressModel
     */
    'addressPurpose'?: NpiAddressPurpose;
    /**
     * 
     * @type {string}
     * @memberof NpiAddressModel
     */
    'addressFirst'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof NpiAddressModel
     */
    'addressSecond'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof NpiAddressModel
     */
    'city'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof NpiAddressModel
     */
    'state'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof NpiAddressModel
     */
    'postalCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof NpiAddressModel
     */
    'countryCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof NpiAddressModel
     */
    'telephoneNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof NpiAddressModel
     */
    'faxNumber'?: string | null;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const NpiAddressPurpose = {
    Mailing: 'Mailing',
    Location: 'Location'
} as const;

export type NpiAddressPurpose = typeof NpiAddressPurpose[keyof typeof NpiAddressPurpose];


/**
 * 
 * @export
 * @interface NpiEndpointModel
 */
export interface NpiEndpointModel {
    /**
     * 
     * @type {string}
     * @memberof NpiEndpointModel
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof NpiEndpointModel
     */
    'endpointType'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof NpiEndpointModel
     */
    'endpointTypeDescription'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof NpiEndpointModel
     */
    'endpoint'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof NpiEndpointModel
     */
    'affiliation'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof NpiEndpointModel
     */
    'endpointDescription'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof NpiEndpointModel
     */
    'affiliationLegalBusinessName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof NpiEndpointModel
     */
    'useCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof NpiEndpointModel
     */
    'useDescription'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof NpiEndpointModel
     */
    'otherUseDescription'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof NpiEndpointModel
     */
    'contentType'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof NpiEndpointModel
     */
    'contentDescription'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof NpiEndpointModel
     */
    'otherContentDescription'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof NpiEndpointModel
     */
    'affiliationAddressLineOne'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof NpiEndpointModel
     */
    'affiliationAddressLineTwo'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof NpiEndpointModel
     */
    'affiliationAddressCity'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof NpiEndpointModel
     */
    'affiliationAddressState'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof NpiEndpointModel
     */
    'affiliationAddressCountry'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof NpiEndpointModel
     */
    'affiliationAddressLinePostalCode'?: string | null;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const NpiEntityTypeCode = {
    Individual: 'Individual',
    Organization: 'Organization'
} as const;

export type NpiEntityTypeCode = typeof NpiEntityTypeCode[keyof typeof NpiEntityTypeCode];


/**
 * 
 * @export
 * @interface NpiIdentifierModel
 */
export interface NpiIdentifierModel {
    /**
     * 
     * @type {string}
     * @memberof NpiIdentifierModel
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof NpiIdentifierModel
     */
    'identifier'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof NpiIdentifierModel
     */
    'code'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof NpiIdentifierModel
     */
    'state'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof NpiIdentifierModel
     */
    'issuer'?: string | null;
}
/**
 * 
 * @export
 * @interface NpiIndexEntity
 */
export interface NpiIndexEntity {
    /**
     * 
     * @type {string}
     * @memberof NpiIndexEntity
     */
    'id'?: string;
    /**
     * 
     * @type {CompletionField}
     * @memberof NpiIndexEntity
     */
    'suggest'?: CompletionField;
    /**
     * 
     * @type {Array<string>}
     * @memberof NpiIndexEntity
     */
    'keywordTerms'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof NpiIndexEntity
     */
    'searchingArea'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof NpiIndexEntity
     */
    'score'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof NpiIndexEntity
     */
    'npiNumber'?: number;
    /**
     * 
     * @type {NpiEntityTypeCode}
     * @memberof NpiIndexEntity
     */
    'npiEntityTypeCode'?: NpiEntityTypeCode;
    /**
     * 
     * @type {string}
     * @memberof NpiIndexEntity
     */
    'npiEntityTypeCodeDescription'?: string | null;
    /**
     * 
     * @type {Array<NpiTaxonomyEntity>}
     * @memberof NpiIndexEntity
     */
    'taxonomies'?: Array<NpiTaxonomyEntity> | null;
    /**
     * 
     * @type {string}
     * @memberof NpiIndexEntity
     */
    'providerOrganizationName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof NpiIndexEntity
     */
    'providerLastName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof NpiIndexEntity
     */
    'providerFirstName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof NpiIndexEntity
     */
    'providerMiddleName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof NpiIndexEntity
     */
    'providerNamePrefixText'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof NpiIndexEntity
     */
    'providerNameSuffixText'?: string | null;
    /**
     * 
     * @type {Array<NpiAddressEntity>}
     * @memberof NpiIndexEntity
     */
    'addresses'?: Array<NpiAddressEntity> | null;
}
/**
 * 
 * @export
 * @interface NpiModel
 */
export interface NpiModel {
    /**
     * 
     * @type {string}
     * @memberof NpiModel
     */
    'id'?: string;
    /**
     * 
     * @type {number}
     * @memberof NpiModel
     */
    'npiNumber'?: number;
    /**
     * 
     * @type {NpiEntityTypeCode}
     * @memberof NpiModel
     */
    'npiEntityTypeCode'?: NpiEntityTypeCode;
    /**
     * 
     * @type {number}
     * @memberof NpiModel
     */
    'replacementNpiNumber'?: number;
    /**
     * 
     * @type {string}
     * @memberof NpiModel
     */
    'employerIdentificationNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof NpiModel
     */
    'providerOrganizationName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof NpiModel
     */
    'providerLastName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof NpiModel
     */
    'providerFirstName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof NpiModel
     */
    'providerMiddleName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof NpiModel
     */
    'providerNamePrefixText'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof NpiModel
     */
    'providerNameSuffixText'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof NpiModel
     */
    'providerCredentialText'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof NpiModel
     */
    'providerOtherOrganizationName'?: string | null;
    /**
     * 
     * @type {NpiProviderNameTypeCode}
     * @memberof NpiModel
     */
    'providerOtherOrganizationNameTypeCode'?: NpiProviderNameTypeCode;
    /**
     * 
     * @type {string}
     * @memberof NpiModel
     */
    'providerOtherLastName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof NpiModel
     */
    'providerOtherFirstName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof NpiModel
     */
    'providerOtherMiddleName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof NpiModel
     */
    'providerOtherNamePrefixText'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof NpiModel
     */
    'providerOtherNameSuffixText'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof NpiModel
     */
    'providerOtherCredentialText'?: string | null;
    /**
     * 
     * @type {NpiProviderNameTypeCode}
     * @memberof NpiModel
     */
    'providerOtherLastNameTypeCode'?: NpiProviderNameTypeCode;
    /**
     * 
     * @type {DateOnly}
     * @memberof NpiModel
     */
    'providerEnumerationDate'?: DateOnly;
    /**
     * 
     * @type {DateOnly}
     * @memberof NpiModel
     */
    'lastUpdateDate'?: DateOnly;
    /**
     * 
     * @type {string}
     * @memberof NpiModel
     */
    'npiDeactivationReasonCode'?: string | null;
    /**
     * 
     * @type {DateOnly}
     * @memberof NpiModel
     */
    'npiDeactivationDate'?: DateOnly;
    /**
     * 
     * @type {DateOnly}
     * @memberof NpiModel
     */
    'npiReactivationDate'?: DateOnly;
    /**
     * 
     * @type {string}
     * @memberof NpiModel
     */
    'providerGenderCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof NpiModel
     */
    'authorizedOfficialLastName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof NpiModel
     */
    'authorizedOfficialFirstName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof NpiModel
     */
    'authorizedOfficialMiddleName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof NpiModel
     */
    'authorizedOfficialTitleOrPosition'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof NpiModel
     */
    'authorizedOfficialTelephoneNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof NpiModel
     */
    'isSoleProprietor'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof NpiModel
     */
    'isOrganizationSubpart'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof NpiModel
     */
    'parentOrganizationLBN'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof NpiModel
     */
    'parentOrganizationTIN'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof NpiModel
     */
    'authorizedOfficialNamePrefixText'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof NpiModel
     */
    'authorizedOfficialNameSuffixText'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof NpiModel
     */
    'authorizedOfficialCredentialText'?: string | null;
    /**
     * 
     * @type {DateOnly}
     * @memberof NpiModel
     */
    'certificationDate'?: DateOnly;
    /**
     * 
     * @type {Array<NpiTaxonomyModel>}
     * @memberof NpiModel
     */
    'taxonomies'?: Array<NpiTaxonomyModel> | null;
    /**
     * 
     * @type {Array<NpiAddressModel>}
     * @memberof NpiModel
     */
    'addresses'?: Array<NpiAddressModel> | null;
    /**
     * 
     * @type {Array<NpiEndpointModel>}
     * @memberof NpiModel
     */
    'endpoints'?: Array<NpiEndpointModel> | null;
    /**
     * 
     * @type {Array<NpiIdentifierModel>}
     * @memberof NpiModel
     */
    'identifiers'?: Array<NpiIdentifierModel> | null;
    /**
     * 
     * @type {Array<NpiOtherNameModel>}
     * @memberof NpiModel
     */
    'otherNames'?: Array<NpiOtherNameModel> | null;
    /**
     * 
     * @type {Array<NpiPracticeLocationModel>}
     * @memberof NpiModel
     */
    'practiceLocations'?: Array<NpiPracticeLocationModel> | null;
}
/**
 * 
 * @export
 * @interface NpiModelIDataResult
 */
export interface NpiModelIDataResult {
    /**
     * 
     * @type {NpiModel}
     * @memberof NpiModelIDataResult
     */
    'data'?: NpiModel;
    /**
     * 
     * @type {boolean}
     * @memberof NpiModelIDataResult
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof NpiModelIDataResult
     */
    'message'?: string | null;
}
/**
 * 
 * @export
 * @interface NpiOtherNameModel
 */
export interface NpiOtherNameModel {
    /**
     * 
     * @type {string}
     * @memberof NpiOtherNameModel
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof NpiOtherNameModel
     */
    'organizationName'?: string | null;
    /**
     * 
     * @type {NpiProviderNameTypeCode}
     * @memberof NpiOtherNameModel
     */
    'otherProviderNameTypeCode'?: NpiProviderNameTypeCode;
}
/**
 * 
 * @export
 * @interface NpiPracticeLocationModel
 */
export interface NpiPracticeLocationModel {
    /**
     * 
     * @type {string}
     * @memberof NpiPracticeLocationModel
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof NpiPracticeLocationModel
     */
    'addressLine1'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof NpiPracticeLocationModel
     */
    'addressLine2'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof NpiPracticeLocationModel
     */
    'city'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof NpiPracticeLocationModel
     */
    'state'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof NpiPracticeLocationModel
     */
    'postalCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof NpiPracticeLocationModel
     */
    'countryCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof NpiPracticeLocationModel
     */
    'telephone'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof NpiPracticeLocationModel
     */
    'telephoneExtension'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof NpiPracticeLocationModel
     */
    'faxNumber'?: string | null;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const NpiProviderNameTypeCode = {
    FormerNameI: 'FormerName_I',
    ProfessionalNameI: 'ProfessionalName_I',
    DoingBusinessAsO: 'DoingBusinessAs_O',
    FormerLegalBusinessNameO: 'FormerLegalBusinessName_O',
    OtherNameB: 'OtherName_B'
} as const;

export type NpiProviderNameTypeCode = typeof NpiProviderNameTypeCode[keyof typeof NpiProviderNameTypeCode];


/**
 * 
 * @export
 * @interface NpiSearchRequestModel
 */
export interface NpiSearchRequestModel {
    /**
     * 
     * @type {number}
     * @memberof NpiSearchRequestModel
     */
    'npiNumber'?: number | null;
    /**
     * 
     * @type {NpiEntityTypeCode}
     * @memberof NpiSearchRequestModel
     */
    'npiEntityTypeCode'?: NpiEntityTypeCode;
    /**
     * 
     * @type {string}
     * @memberof NpiSearchRequestModel
     */
    'taxonomyCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof NpiSearchRequestModel
     */
    'taxonomyDescription'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof NpiSearchRequestModel
     */
    'firstName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof NpiSearchRequestModel
     */
    'lastName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof NpiSearchRequestModel
     */
    'organizationName'?: string | null;
    /**
     * 
     * @type {NpiAddressPurpose}
     * @memberof NpiSearchRequestModel
     */
    'addressType'?: NpiAddressPurpose;
    /**
     * 
     * @type {string}
     * @memberof NpiSearchRequestModel
     */
    'city'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof NpiSearchRequestModel
     */
    'state'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof NpiSearchRequestModel
     */
    'countryCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof NpiSearchRequestModel
     */
    'postalCode'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof NpiSearchRequestModel
     */
    'skipItemCount'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof NpiSearchRequestModel
     */
    'maxItemCount'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof NpiSearchRequestModel
     */
    'searchForExactMatchesOnly'?: boolean;
}
/**
 * 
 * @export
 * @interface NpiSearchResponse
 */
export interface NpiSearchResponse {
    /**
     * 
     * @type {Array<NpiIndexEntity>}
     * @memberof NpiSearchResponse
     */
    'npis'?: Array<NpiIndexEntity> | null;
}
/**
 * 
 * @export
 * @interface NpiSearchResponseIDataResult
 */
export interface NpiSearchResponseIDataResult {
    /**
     * 
     * @type {NpiSearchResponse}
     * @memberof NpiSearchResponseIDataResult
     */
    'data'?: NpiSearchResponse;
    /**
     * 
     * @type {boolean}
     * @memberof NpiSearchResponseIDataResult
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof NpiSearchResponseIDataResult
     */
    'message'?: string | null;
}
/**
 * 
 * @export
 * @interface NpiTaxonomyEntity
 */
export interface NpiTaxonomyEntity {
    /**
     * 
     * @type {string}
     * @memberof NpiTaxonomyEntity
     */
    'code'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof NpiTaxonomyEntity
     */
    'name'?: string | null;
}
/**
 * 
 * @export
 * @interface NpiTaxonomyModel
 */
export interface NpiTaxonomyModel {
    /**
     * 
     * @type {string}
     * @memberof NpiTaxonomyModel
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof NpiTaxonomyModel
     */
    'group'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof NpiTaxonomyModel
     */
    'code'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof NpiTaxonomyModel
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof NpiTaxonomyModel
     */
    'license'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof NpiTaxonomyModel
     */
    'state'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof NpiTaxonomyModel
     */
    'primary'?: string | null;
}
/**
 * 
 * @export
 * @interface Package
 */
export interface Package {
    /**
     * 
     * @type {string}
     * @memberof Package
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof Package
     */
    'createdBy'?: string;
    /**
     * 
     * @type {string}
     * @memberof Package
     */
    'createdOn'?: string;
    /**
     * 
     * @type {string}
     * @memberof Package
     */
    'lastModifiedBy'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Package
     */
    'lastModifiedOn'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof Package
     */
    'isDeleted'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Package
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Package
     */
    'description'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof Package
     */
    'sortOrder'?: number;
    /**
     * 
     * @type {Array<ProductPackage>}
     * @memberof Package
     */
    'productPackages'?: Array<ProductPackage> | null;
}
/**
 * 
 * @export
 * @interface PackageModel
 */
export interface PackageModel {
    /**
     * 
     * @type {string}
     * @memberof PackageModel
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof PackageModel
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PackageModel
     */
    'description'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof PackageModel
     */
    'sortOrder'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof PackageModel
     */
    'stripeProductIds'?: Array<string> | null;
    /**
     * 
     * @type {number}
     * @memberof PackageModel
     */
    'price'?: number | null;
    /**
     * 
     * @type {Array<ProductPackageModel>}
     * @memberof PackageModel
     */
    'items'?: Array<ProductPackageModel> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof PackageModel
     */
    'productProperties'?: Array<string> | null;
}
/**
 * 
 * @export
 * @interface PackageModelIDataResult
 */
export interface PackageModelIDataResult {
    /**
     * 
     * @type {PackageModel}
     * @memberof PackageModelIDataResult
     */
    'data'?: PackageModel;
    /**
     * 
     * @type {boolean}
     * @memberof PackageModelIDataResult
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PackageModelIDataResult
     */
    'message'?: string | null;
}
/**
 * 
 * @export
 * @interface PackageModelIEnumerableIDataResult
 */
export interface PackageModelIEnumerableIDataResult {
    /**
     * 
     * @type {Array<PackageModel>}
     * @memberof PackageModelIEnumerableIDataResult
     */
    'data'?: Array<PackageModel> | null;
    /**
     * 
     * @type {boolean}
     * @memberof PackageModelIEnumerableIDataResult
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PackageModelIEnumerableIDataResult
     */
    'message'?: string | null;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const PackageOrAddOnName = {
    Free: 'Free',
    Light: 'Light',
    Basic: 'Basic',
    Pro: 'Pro',
    Addon: 'Addon'
} as const;

export type PackageOrAddOnName = typeof PackageOrAddOnName[keyof typeof PackageOrAddOnName];


/**
 * 
 * @export
 * @interface PaymentMethodBillingDetailsModel
 */
export interface PaymentMethodBillingDetailsModel {
    /**
     * 
     * @type {string}
     * @memberof PaymentMethodBillingDetailsModel
     */
    'email'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PaymentMethodBillingDetailsModel
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PaymentMethodBillingDetailsModel
     */
    'phone'?: string | null;
}
/**
 * 
 * @export
 * @interface PaymentMethodModel
 */
export interface PaymentMethodModel {
    /**
     * 
     * @type {string}
     * @memberof PaymentMethodModel
     */
    'id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PaymentMethodModel
     */
    'last4'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PaymentMethodModel
     */
    'brand'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof PaymentMethodModel
     */
    'expMonth'?: number;
    /**
     * 
     * @type {number}
     * @memberof PaymentMethodModel
     */
    'expYear'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof PaymentMethodModel
     */
    'isDefault'?: boolean;
    /**
     * 
     * @type {PaymentMethodUserModel}
     * @memberof PaymentMethodModel
     */
    'user'?: PaymentMethodUserModel;
    /**
     * 
     * @type {PaymentMethodBillingDetailsModel}
     * @memberof PaymentMethodModel
     */
    'billingDetails'?: PaymentMethodBillingDetailsModel;
    /**
     * 
     * @type {string}
     * @memberof PaymentMethodModel
     */
    'value'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PaymentMethodModel
     */
    'label'?: string | null;
}
/**
 * 
 * @export
 * @interface PaymentMethodModelIEnumerableIDataResult
 */
export interface PaymentMethodModelIEnumerableIDataResult {
    /**
     * 
     * @type {Array<PaymentMethodModel>}
     * @memberof PaymentMethodModelIEnumerableIDataResult
     */
    'data'?: Array<PaymentMethodModel> | null;
    /**
     * 
     * @type {boolean}
     * @memberof PaymentMethodModelIEnumerableIDataResult
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PaymentMethodModelIEnumerableIDataResult
     */
    'message'?: string | null;
}
/**
 * 
 * @export
 * @interface PaymentMethodUserModel
 */
export interface PaymentMethodUserModel {
    /**
     * 
     * @type {string}
     * @memberof PaymentMethodUserModel
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof PaymentMethodUserModel
     */
    'firstName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PaymentMethodUserModel
     */
    'lastName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PaymentMethodUserModel
     */
    'email'?: string | null;
}
/**
 * 
 * @export
 * @interface Period
 */
export interface Period {
    /**
     * 
     * @type {string}
     * @memberof Period
     */
    'periodName'?: string | null;
    /**
     * 
     * @type {Array<Line>}
     * @memberof Period
     */
    'lines'?: Array<Line> | null;
}
/**
 * 
 * @export
 * @interface PeriodRequestModel
 */
export interface PeriodRequestModel {
    /**
     * 
     * @type {string}
     * @memberof PeriodRequestModel
     */
    'startDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PeriodRequestModel
     */
    'endDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PeriodRequestModel
     */
    'email'?: string | null;
}
/**
 * 
 * @export
 * @interface PeriodRequestModel1
 */
export interface PeriodRequestModel1 {
    /**
     * 
     * @type {string}
     * @memberof PeriodRequestModel1
     */
    'startDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PeriodRequestModel1
     */
    'endDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PeriodRequestModel1
     */
    'userId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PeriodRequestModel1
     */
    'userEmail'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PeriodRequestModel1
     */
    'productId'?: string | null;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const PrivacyType = {
    Private: 'Private',
    Public: 'Public'
} as const;

export type PrivacyType = typeof PrivacyType[keyof typeof PrivacyType];


/**
 * 
 * @export
 * @interface ProblemDetails
 */
export interface ProblemDetails {
    [key: string]: any;

    /**
     * 
     * @type {string}
     * @memberof ProblemDetails
     */
    'type'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProblemDetails
     */
    'title'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ProblemDetails
     */
    'status'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ProblemDetails
     */
    'detail'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProblemDetails
     */
    'instance'?: string | null;
}
/**
 * 
 * @export
 * @interface ProceduralCodeDetailModel
 */
export interface ProceduralCodeDetailModel {
    /**
     * 
     * @type {string}
     * @memberof ProceduralCodeDetailModel
     */
    'code'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProceduralCodeDetailModel
     */
    'shortDescription'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProceduralCodeDetailModel
     */
    'longDescription'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProceduralCodeDetailModel
     */
    'codeAddedDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProceduralCodeDetailModel
     */
    'terminationDate'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ProceduralCodeDetailModel
     */
    'isValidCode'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ProceduralCodeDetailModel
     */
    'validationErrorMessage'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProceduralCodeDetailModel
     */
    'validationErrorTitle'?: string | null;
    /**
     * 
     * @type {Array<RVUModel>}
     * @memberof ProceduralCodeDetailModel
     */
    'rvUs'?: Array<RVUModel> | null;
}
/**
 * 
 * @export
 * @interface ProceduralCodeModel
 */
export interface ProceduralCodeModel {
    /**
     * 
     * @type {string}
     * @memberof ProceduralCodeModel
     */
    'id'?: string;
    /**
     * 
     * @type {ProceduralCodeType}
     * @memberof ProceduralCodeModel
     */
    'proceduralCodeType'?: ProceduralCodeType;
    /**
     * 
     * @type {string}
     * @memberof ProceduralCodeModel
     */
    'code'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProceduralCodeModel
     */
    'description'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProceduralCodeModel
     */
    'shortDescription'?: string | null;
}
/**
 * 
 * @export
 * @interface ProceduralCodeModelIDataResult
 */
export interface ProceduralCodeModelIDataResult {
    /**
     * 
     * @type {ProceduralCodeModel}
     * @memberof ProceduralCodeModelIDataResult
     */
    'data'?: ProceduralCodeModel;
    /**
     * 
     * @type {boolean}
     * @memberof ProceduralCodeModelIDataResult
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ProceduralCodeModelIDataResult
     */
    'message'?: string | null;
}
/**
 * 
 * @export
 * @interface ProceduralCodeModelIEnumerableIDataResult
 */
export interface ProceduralCodeModelIEnumerableIDataResult {
    /**
     * 
     * @type {Array<ProceduralCodeModel>}
     * @memberof ProceduralCodeModelIEnumerableIDataResult
     */
    'data'?: Array<ProceduralCodeModel> | null;
    /**
     * 
     * @type {boolean}
     * @memberof ProceduralCodeModelIEnumerableIDataResult
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ProceduralCodeModelIEnumerableIDataResult
     */
    'message'?: string | null;
}
/**
 * 
 * @export
 * @interface ProceduralCodeRequestModel
 */
export interface ProceduralCodeRequestModel {
    /**
     * 
     * @type {Array<string>}
     * @memberof ProceduralCodeRequestModel
     */
    'codeList'?: Array<string> | null;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const ProceduralCodeType = {
    Hcpcs: 'Hcpcs',
    Cpt: 'Cpt'
} as const;

export type ProceduralCodeType = typeof ProceduralCodeType[keyof typeof ProceduralCodeType];


/**
 * 
 * @export
 * @interface Product
 */
export interface Product {
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    'createdBy'?: string;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    'createdOn'?: string;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    'lastModifiedBy'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    'lastModifiedOn'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof Product
     */
    'isDeleted'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    'stripePriceId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    'description'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof Product
     */
    'price'?: number;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    'currency'?: string | null;
    /**
     * 
     * @type {BillingPeriodType}
     * @memberof Product
     */
    'billingPeriodType'?: BillingPeriodType;
    /**
     * 
     * @type {boolean}
     * @memberof Product
     */
    'isMetered'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    'freePeriod'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof Product
     */
    'features'?: Array<string> | null;
    /**
     * 
     * @type {number}
     * @memberof Product
     */
    'sortOrder'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof Product
     */
    'isActive'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Product
     */
    'addon'?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof Product
     */
    'productProperties'?: Array<string> | null;
    /**
     * 
     * @type {Array<ProductPackage>}
     * @memberof Product
     */
    'productPackages'?: Array<ProductPackage> | null;
    /**
     * 
     * @type {Array<UserSubscriptionItem>}
     * @memberof Product
     */
    'userSubscriptionItems'?: Array<UserSubscriptionItem> | null;
}
/**
 * 
 * @export
 * @interface ProductModel
 */
export interface ProductModel {
    /**
     * 
     * @type {string}
     * @memberof ProductModel
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductModel
     */
    'stripePriceId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProductModel
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProductModel
     */
    'description'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ProductModel
     */
    'price'?: number;
    /**
     * 
     * @type {string}
     * @memberof ProductModel
     */
    'currency'?: string | null;
    /**
     * 
     * @type {BillingPeriodType}
     * @memberof ProductModel
     */
    'billingPeriodType'?: BillingPeriodType;
    /**
     * 
     * @type {boolean}
     * @memberof ProductModel
     */
    'isMetered'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ProductModel
     */
    'freePeriod'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof ProductModel
     */
    'features'?: Array<string> | null;
    /**
     * 
     * @type {number}
     * @memberof ProductModel
     */
    'sortOrder'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof ProductModel
     */
    'isActive'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ProductModel
     */
    'addon'?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof ProductModel
     */
    'productProperties'?: Array<string> | null;
}
/**
 * 
 * @export
 * @interface ProductModelIDataResult
 */
export interface ProductModelIDataResult {
    /**
     * 
     * @type {ProductModel}
     * @memberof ProductModelIDataResult
     */
    'data'?: ProductModel;
    /**
     * 
     * @type {boolean}
     * @memberof ProductModelIDataResult
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ProductModelIDataResult
     */
    'message'?: string | null;
}
/**
 * 
 * @export
 * @interface ProductModelIEnumerableIDataResult
 */
export interface ProductModelIEnumerableIDataResult {
    /**
     * 
     * @type {Array<ProductModel>}
     * @memberof ProductModelIEnumerableIDataResult
     */
    'data'?: Array<ProductModel> | null;
    /**
     * 
     * @type {boolean}
     * @memberof ProductModelIEnumerableIDataResult
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ProductModelIEnumerableIDataResult
     */
    'message'?: string | null;
}
/**
 * 
 * @export
 * @interface ProductPackage
 */
export interface ProductPackage {
    /**
     * 
     * @type {string}
     * @memberof ProductPackage
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductPackage
     */
    'createdBy'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductPackage
     */
    'createdOn'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductPackage
     */
    'lastModifiedBy'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProductPackage
     */
    'lastModifiedOn'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ProductPackage
     */
    'isDeleted'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ProductPackage
     */
    'productId'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductPackage
     */
    'packageId'?: string;
    /**
     * 
     * @type {Product}
     * @memberof ProductPackage
     */
    'product'?: Product;
    /**
     * 
     * @type {Package}
     * @memberof ProductPackage
     */
    'package'?: Package;
}
/**
 * 
 * @export
 * @interface ProductPackageModel
 */
export interface ProductPackageModel {
    /**
     * 
     * @type {string}
     * @memberof ProductPackageModel
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductPackageModel
     */
    'productId'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductPackageModel
     */
    'packageId'?: string;
    /**
     * 
     * @type {ProductModel}
     * @memberof ProductPackageModel
     */
    'product'?: ProductModel;
    /**
     * 
     * @type {Package}
     * @memberof ProductPackageModel
     */
    'package'?: Package;
    /**
     * 
     * @type {number}
     * @memberof ProductPackageModel
     */
    'sortOrder'?: number | null;
}
/**
 * 
 * @export
 * @interface ProductPackageModelIDataResult
 */
export interface ProductPackageModelIDataResult {
    /**
     * 
     * @type {ProductPackageModel}
     * @memberof ProductPackageModelIDataResult
     */
    'data'?: ProductPackageModel;
    /**
     * 
     * @type {boolean}
     * @memberof ProductPackageModelIDataResult
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ProductPackageModelIDataResult
     */
    'message'?: string | null;
}
/**
 * 
 * @export
 * @interface ProductPackageModelIEnumerableIDataResult
 */
export interface ProductPackageModelIEnumerableIDataResult {
    /**
     * 
     * @type {Array<ProductPackageModel>}
     * @memberof ProductPackageModelIEnumerableIDataResult
     */
    'data'?: Array<ProductPackageModel> | null;
    /**
     * 
     * @type {boolean}
     * @memberof ProductPackageModelIEnumerableIDataResult
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ProductPackageModelIEnumerableIDataResult
     */
    'message'?: string | null;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const ProgramType = {
    Medicare: 'Medicare',
    Medicaid: 'Medicaid'
} as const;

export type ProgramType = typeof ProgramType[keyof typeof ProgramType];


/**
 * 
 * @export
 * @interface PtpExceptionDetailModel
 */
export interface PtpExceptionDetailModel {
    /**
     * 
     * @type {string}
     * @memberof PtpExceptionDetailModel
     */
    'description'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PtpExceptionDetailModel
     */
    'rationale'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PtpExceptionDetailModel
     */
    'rationaleExtended'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PtpExceptionDetailModel
     */
    'modifierRationale'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PtpExceptionDetailModel
     */
    'effectiveDate'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof PtpExceptionDetailModel
     */
    'hasModifierException'?: boolean;
    /**
     * 
     * @type {Array<ProceduralCodeDetailModel>}
     * @memberof PtpExceptionDetailModel
     */
    'modifierValidationException'?: Array<ProceduralCodeDetailModel> | null;
    /**
     * 
     * @type {string}
     * @memberof PtpExceptionDetailModel
     */
    'anatomicModifiers'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PtpExceptionDetailModel
     */
    'otherModifiers'?: string | null;
}
/**
 * 
 * @export
 * @interface RVUModel
 */
export interface RVUModel {
    /**
     * 
     * @type {string}
     * @memberof RVUModel
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof RVUModel
     */
    'hcpcsCptCode'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof RVUModel
     */
    'nonFacilityRVUValue'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof RVUModel
     */
    'facilityRVUValue'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof RVUModel
     */
    'modifier'?: string | null;
}
/**
 * 
 * @export
 * @interface RVUModel1
 */
export interface RVUModel1 {
    /**
     * 
     * @type {string}
     * @memberof RVUModel1
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof RVUModel1
     */
    'code'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof RVUModel1
     */
    'totalNonFacilityRvus'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof RVUModel1
     */
    'totalFacilityRvus'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof RVUModel1
     */
    'modifier'?: string | null;
    /**
     * 
     * @type {DateOnly}
     * @memberof RVUModel1
     */
    'quarterBeginDate'?: DateOnly;
}
/**
 * 
 * @export
 * @interface RVUModelIEnumerableIDataResult
 */
export interface RVUModelIEnumerableIDataResult {
    /**
     * 
     * @type {Array<RVUModel1>}
     * @memberof RVUModelIEnumerableIDataResult
     */
    'data'?: Array<RVUModel1> | null;
    /**
     * 
     * @type {boolean}
     * @memberof RVUModelIEnumerableIDataResult
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof RVUModelIEnumerableIDataResult
     */
    'message'?: string | null;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const RoleType = {
    SuperAdmin: 'SuperAdmin',
    CompanyAdmin: 'CompanyAdmin',
    User: 'User',
    GlobalAdmin: 'GlobalAdmin',
    BillingAdmin: 'BillingAdmin',
    SupportAdmin: 'SupportAdmin'
} as const;

export type RoleType = typeof RoleType[keyof typeof RoleType];


/**
 * 
 * @export
 * @interface RvuGpciCalculateResponseModel
 */
export interface RvuGpciCalculateResponseModel {
    /**
     * 
     * @type {string}
     * @memberof RvuGpciCalculateResponseModel
     */
    'rvuId'?: string;
    /**
     * 
     * @type {string}
     * @memberof RvuGpciCalculateResponseModel
     */
    'gpciId'?: string;
    /**
     * 
     * @type {number}
     * @memberof RvuGpciCalculateResponseModel
     */
    'workRVU'?: number;
    /**
     * 
     * @type {number}
     * @memberof RvuGpciCalculateResponseModel
     */
    'malpracticeRVU'?: number;
    /**
     * 
     * @type {number}
     * @memberof RvuGpciCalculateResponseModel
     */
    'facilityPracticeExpenseRVU'?: number;
    /**
     * 
     * @type {number}
     * @memberof RvuGpciCalculateResponseModel
     */
    'nonFacilityPracticeExpenseRVU'?: number;
    /**
     * 
     * @type {number}
     * @memberof RvuGpciCalculateResponseModel
     */
    'totalNonFacilityRvus'?: number;
    /**
     * 
     * @type {number}
     * @memberof RvuGpciCalculateResponseModel
     */
    'totalFacilityRvus'?: number;
}
/**
 * 
 * @export
 * @interface RvuGpciCalculateResponseModelIDataResult
 */
export interface RvuGpciCalculateResponseModelIDataResult {
    /**
     * 
     * @type {RvuGpciCalculateResponseModel}
     * @memberof RvuGpciCalculateResponseModelIDataResult
     */
    'data'?: RvuGpciCalculateResponseModel;
    /**
     * 
     * @type {boolean}
     * @memberof RvuGpciCalculateResponseModelIDataResult
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof RvuGpciCalculateResponseModelIDataResult
     */
    'message'?: string | null;
}
/**
 * 
 * @export
 * @interface SaleModel
 */
export interface SaleModel {
    /**
     * 
     * @type {string}
     * @memberof SaleModel
     */
    'id'?: string;
    /**
     * 
     * @type {SaleUserModel}
     * @memberof SaleModel
     */
    'user'?: SaleUserModel;
    /**
     * 
     * @type {string}
     * @memberof SaleModel
     */
    'packageOrAddOnName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SaleModel
     */
    'description'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof SaleModel
     */
    'price'?: number;
    /**
     * 
     * @type {string}
     * @memberof SaleModel
     */
    'currency'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SaleModel
     */
    'purchaseDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof SaleModel
     */
    'stripeInvoiceNumber'?: string | null;
    /**
     * 
     * @type {Array<SaleProductModel>}
     * @memberof SaleModel
     */
    'saleProducts'?: Array<SaleProductModel> | null;
}
/**
 * 
 * @export
 * @interface SaleModelPagedResults
 */
export interface SaleModelPagedResults {
    /**
     * 
     * @type {Array<SaleModel>}
     * @memberof SaleModelPagedResults
     */
    'items'?: Array<SaleModel> | null;
    /**
     * 
     * @type {number}
     * @memberof SaleModelPagedResults
     */
    'totalSize'?: number;
}
/**
 * 
 * @export
 * @interface SaleModelPagedResultsIDataResult
 */
export interface SaleModelPagedResultsIDataResult {
    /**
     * 
     * @type {SaleModelPagedResults}
     * @memberof SaleModelPagedResultsIDataResult
     */
    'data'?: SaleModelPagedResults;
    /**
     * 
     * @type {boolean}
     * @memberof SaleModelPagedResultsIDataResult
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof SaleModelPagedResultsIDataResult
     */
    'message'?: string | null;
}
/**
 * 
 * @export
 * @interface SaleProductModel
 */
export interface SaleProductModel {
    /**
     * 
     * @type {string}
     * @memberof SaleProductModel
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof SaleProductModel
     */
    'productId'?: string;
    /**
     * 
     * @type {string}
     * @memberof SaleProductModel
     */
    'productName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SaleProductModel
     */
    'description'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof SaleProductModel
     */
    'price'?: number;
    /**
     * 
     * @type {string}
     * @memberof SaleProductModel
     */
    'currency'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SaleProductModel
     */
    'startDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof SaleProductModel
     */
    'expireDate'?: string | null;
}
/**
 * 
 * @export
 * @interface SaleRequestModel
 */
export interface SaleRequestModel {
    /**
     * 
     * @type {string}
     * @memberof SaleRequestModel
     */
    'startDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SaleRequestModel
     */
    'endDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SaleRequestModel
     */
    'userId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SaleRequestModel
     */
    'userEmail'?: string | null;
    /**
     * 
     * @type {PackageOrAddOnName}
     * @memberof SaleRequestModel
     */
    'packageOrAddOnName'?: PackageOrAddOnName;
    /**
     * 
     * @type {Array<string>}
     * @memberof SaleRequestModel
     */
    'productIds'?: Array<string> | null;
}
/**
 * 
 * @export
 * @interface SaleUserModel
 */
export interface SaleUserModel {
    /**
     * 
     * @type {string}
     * @memberof SaleUserModel
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof SaleUserModel
     */
    'companyName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SaleUserModel
     */
    'firstName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SaleUserModel
     */
    'lastName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SaleUserModel
     */
    'phone'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SaleUserModel
     */
    'email'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SaleUserModel
     */
    'country'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SaleUserModel
     */
    'zipCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SaleUserModel
     */
    'title'?: string | null;
    /**
     * 
     * @type {RoleType}
     * @memberof SaleUserModel
     */
    'role'?: RoleType;
}
/**
 * 
 * @export
 * @interface SearchHistoryRequestModel
 */
export interface SearchHistoryRequestModel {
    /**
     * 
     * @type {string}
     * @memberof SearchHistoryRequestModel
     */
    'userId'?: string;
    /**
     * 
     * @type {number}
     * @memberof SearchHistoryRequestModel
     */
    'take'?: number | null;
}
/**
 * 
 * @export
 * @interface SearchResponse
 */
export interface SearchResponse {
    /**
     * 
     * @type {string}
     * @memberof SearchResponse
     */
    'codeId'?: string;
    /**
     * 
     * @type {string}
     * @memberof SearchResponse
     */
    'code'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SearchResponse
     */
    'codeSetType'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SearchResponse
     */
    'codeSet'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SearchResponse
     */
    'description'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SearchResponse
     */
    'quarterBeginDate'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof SearchResponse
     */
    'isResultFromUserCodeNotes'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof SearchResponse
     */
    'highlightValue'?: string | null;
}
/**
 * 
 * @export
 * @interface SearchResponseIDataResult
 */
export interface SearchResponseIDataResult {
    /**
     * 
     * @type {SearchResponse}
     * @memberof SearchResponseIDataResult
     */
    'data'?: SearchResponse;
    /**
     * 
     * @type {boolean}
     * @memberof SearchResponseIDataResult
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof SearchResponseIDataResult
     */
    'message'?: string | null;
}
/**
 * 
 * @export
 * @interface SearchResponseIEnumerableIDataResult
 */
export interface SearchResponseIEnumerableIDataResult {
    /**
     * 
     * @type {Array<SearchResponse>}
     * @memberof SearchResponseIEnumerableIDataResult
     */
    'data'?: Array<SearchResponse> | null;
    /**
     * 
     * @type {boolean}
     * @memberof SearchResponseIEnumerableIDataResult
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof SearchResponseIEnumerableIDataResult
     */
    'message'?: string | null;
}
/**
 * 
 * @export
 * @interface SignUpUserRequestModel
 */
export interface SignUpUserRequestModel {
    /**
     * 
     * @type {string}
     * @memberof SignUpUserRequestModel
     */
    'firstName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SignUpUserRequestModel
     */
    'lastName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SignUpUserRequestModel
     */
    'email'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SignUpUserRequestModel
     */
    'phone'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SignUpUserRequestModel
     */
    'companyName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SignUpUserRequestModel
     */
    'password'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SignUpUserRequestModel
     */
    'confirmPassword'?: string | null;
}
/**
 * 
 * @export
 * @interface SignUpUserResponseModel
 */
export interface SignUpUserResponseModel {
    /**
     * 
     * @type {string}
     * @memberof SignUpUserResponseModel
     */
    'firstName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SignUpUserResponseModel
     */
    'lastName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SignUpUserResponseModel
     */
    'email'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SignUpUserResponseModel
     */
    'phone'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SignUpUserResponseModel
     */
    'companyName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SignUpUserResponseModel
     */
    'userId'?: string;
    /**
     * 
     * @type {string}
     * @memberof SignUpUserResponseModel
     */
    'cognitoUserId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SignUpUserResponseModel
     */
    'idToken'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SignUpUserResponseModel
     */
    'accessToken'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SignUpUserResponseModel
     */
    'refreshToken'?: string | null;
    /**
     * 
     * @type {RoleType}
     * @memberof SignUpUserResponseModel
     */
    'role'?: RoleType;
}
/**
 * 
 * @export
 * @interface SignUpUserResponseModelIDataResult
 */
export interface SignUpUserResponseModelIDataResult {
    /**
     * 
     * @type {SignUpUserResponseModel}
     * @memberof SignUpUserResponseModelIDataResult
     */
    'data'?: SignUpUserResponseModel;
    /**
     * 
     * @type {boolean}
     * @memberof SignUpUserResponseModelIDataResult
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof SignUpUserResponseModelIDataResult
     */
    'message'?: string | null;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const Status = {
    New: 'New',
    Active: 'Active',
    Expired: 'Expired'
} as const;

export type Status = typeof Status[keyof typeof Status];


/**
 * 
 * @export
 * @interface StringIDataResult
 */
export interface StringIDataResult {
    /**
     * 
     * @type {string}
     * @memberof StringIDataResult
     */
    'data'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof StringIDataResult
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof StringIDataResult
     */
    'message'?: string | null;
}
/**
 * 
 * @export
 * @interface StringSuccessDataResult
 */
export interface StringSuccessDataResult {
    /**
     * 
     * @type {boolean}
     * @memberof StringSuccessDataResult
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof StringSuccessDataResult
     */
    'message'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof StringSuccessDataResult
     */
    'data'?: string | null;
}
/**
 * 
 * @export
 * @interface StripeInvoiceItemsModel
 */
export interface StripeInvoiceItemsModel {
    /**
     * 
     * @type {string}
     * @memberof StripeInvoiceItemsModel
     */
    'stripeInvoiceItemId'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof StripeInvoiceItemsModel
     */
    'amount'?: number;
    /**
     * 
     * @type {number}
     * @memberof StripeInvoiceItemsModel
     */
    'actualAmount'?: number;
    /**
     * 
     * @type {string}
     * @memberof StripeInvoiceItemsModel
     */
    'currency'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof StripeInvoiceItemsModel
     */
    'description'?: string | null;
    /**
     * 
     * @type {ProductModel}
     * @memberof StripeInvoiceItemsModel
     */
    'product'?: ProductModel;
    /**
     * 
     * @type {number}
     * @memberof StripeInvoiceItemsModel
     */
    'quantity'?: number | null;
}
/**
 * 
 * @export
 * @interface StripeInvoiceItemsModel1
 */
export interface StripeInvoiceItemsModel1 {
    /**
     * 
     * @type {string}
     * @memberof StripeInvoiceItemsModel1
     */
    'stripeInvoiceItemId'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof StripeInvoiceItemsModel1
     */
    'amount'?: number;
    /**
     * 
     * @type {string}
     * @memberof StripeInvoiceItemsModel1
     */
    'currency'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof StripeInvoiceItemsModel1
     */
    'description'?: string | null;
    /**
     * 
     * @type {ProductModel}
     * @memberof StripeInvoiceItemsModel1
     */
    'product'?: ProductModel;
    /**
     * 
     * @type {number}
     * @memberof StripeInvoiceItemsModel1
     */
    'quantity'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof StripeInvoiceItemsModel1
     */
    'unitPrice'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof StripeInvoiceItemsModel1
     */
    'periodStart'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof StripeInvoiceItemsModel1
     */
    'periodEnd'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof StripeInvoiceItemsModel1
     */
    'actualAmount'?: number;
    /**
     * 
     * @type {number}
     * @memberof StripeInvoiceItemsModel1
     */
    'actualUnitPrice'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof StripeInvoiceItemsModel1
     */
    'periodName'?: string | null;
}
/**
 * 
 * @export
 * @interface StripeInvoiceSearchResponseModel
 */
export interface StripeInvoiceSearchResponseModel {
    /**
     * 
     * @type {string}
     * @memberof StripeInvoiceSearchResponseModel
     */
    'stripeInvoiceId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof StripeInvoiceSearchResponseModel
     */
    'stripeCustomerId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof StripeInvoiceSearchResponseModel
     */
    'createdAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof StripeInvoiceSearchResponseModel
     */
    'stripeCustomerEmail'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof StripeInvoiceSearchResponseModel
     */
    'currency'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof StripeInvoiceSearchResponseModel
     */
    'paid'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof StripeInvoiceSearchResponseModel
     */
    'periodStart'?: string;
    /**
     * 
     * @type {string}
     * @memberof StripeInvoiceSearchResponseModel
     */
    'periodEnd'?: string;
    /**
     * 
     * @type {number}
     * @memberof StripeInvoiceSearchResponseModel
     */
    'subtotal'?: number;
    /**
     * 
     * @type {number}
     * @memberof StripeInvoiceSearchResponseModel
     */
    'actualSubTotal'?: number;
    /**
     * 
     * @type {number}
     * @memberof StripeInvoiceSearchResponseModel
     */
    'total'?: number;
    /**
     * 
     * @type {number}
     * @memberof StripeInvoiceSearchResponseModel
     */
    'actualTotal'?: number;
    /**
     * 
     * @type {string}
     * @memberof StripeInvoiceSearchResponseModel
     */
    'stripeSubscriptionId'?: string | null;
    /**
     * 
     * @type {StripeInvoiceStatus}
     * @memberof StripeInvoiceSearchResponseModel
     */
    'status'?: StripeInvoiceStatus;
    /**
     * 
     * @type {Array<StripeInvoiceItemsModel1>}
     * @memberof StripeInvoiceSearchResponseModel
     */
    'lines'?: Array<StripeInvoiceItemsModel1> | null;
}
/**
 * 
 * @export
 * @interface StripeInvoiceSearchResponseModelIDataResult
 */
export interface StripeInvoiceSearchResponseModelIDataResult {
    /**
     * 
     * @type {StripeInvoiceSearchResponseModel}
     * @memberof StripeInvoiceSearchResponseModelIDataResult
     */
    'data'?: StripeInvoiceSearchResponseModel;
    /**
     * 
     * @type {boolean}
     * @memberof StripeInvoiceSearchResponseModelIDataResult
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof StripeInvoiceSearchResponseModelIDataResult
     */
    'message'?: string | null;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const StripeInvoiceStatus = {
    Draft: 'draft',
    Open: 'open',
    Paid: 'paid',
    Uncollectible: 'uncollectible'
} as const;

export type StripeInvoiceStatus = typeof StripeInvoiceStatus[keyof typeof StripeInvoiceStatus];


/**
 * 
 * @export
 * @interface StripeSetupIntentResponseModel
 */
export interface StripeSetupIntentResponseModel {
    /**
     * 
     * @type {string}
     * @memberof StripeSetupIntentResponseModel
     */
    'clientSecret'?: string | null;
}
/**
 * 
 * @export
 * @interface StripeSetupIntentResponseModelIDataResult
 */
export interface StripeSetupIntentResponseModelIDataResult {
    /**
     * 
     * @type {StripeSetupIntentResponseModel}
     * @memberof StripeSetupIntentResponseModelIDataResult
     */
    'data'?: StripeSetupIntentResponseModel;
    /**
     * 
     * @type {boolean}
     * @memberof StripeSetupIntentResponseModelIDataResult
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof StripeSetupIntentResponseModelIDataResult
     */
    'message'?: string | null;
}
/**
 * 
 * @export
 * @interface StripeSubscriptionItemModel
 */
export interface StripeSubscriptionItemModel {
    /**
     * 
     * @type {string}
     * @memberof StripeSubscriptionItemModel
     */
    'subscriptionId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof StripeSubscriptionItemModel
     */
    'id'?: string | null;
    /**
     * 
     * @type {ProductModel}
     * @memberof StripeSubscriptionItemModel
     */
    'product'?: ProductModel;
}
/**
 * 
 * @export
 * @interface StripeSubscriptionItemModelIDataResult
 */
export interface StripeSubscriptionItemModelIDataResult {
    /**
     * 
     * @type {StripeSubscriptionItemModel}
     * @memberof StripeSubscriptionItemModelIDataResult
     */
    'data'?: StripeSubscriptionItemModel;
    /**
     * 
     * @type {boolean}
     * @memberof StripeSubscriptionItemModelIDataResult
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof StripeSubscriptionItemModelIDataResult
     */
    'message'?: string | null;
}
/**
 * 
 * @export
 * @interface StripeSubscriptionResponseModel
 */
export interface StripeSubscriptionResponseModel {
    /**
     * 
     * @type {string}
     * @memberof StripeSubscriptionResponseModel
     */
    'userId'?: string;
    /**
     * 
     * @type {string}
     * @memberof StripeSubscriptionResponseModel
     */
    'stripeCustomerId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof StripeSubscriptionResponseModel
     */
    'subscriptionId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof StripeSubscriptionResponseModel
     */
    'createdAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof StripeSubscriptionResponseModel
     */
    'currentPeriodEnd'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof StripeSubscriptionResponseModel
     */
    'currentPeriodStart'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof StripeSubscriptionResponseModel
     */
    'trialStart'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof StripeSubscriptionResponseModel
     */
    'trialEnd'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof StripeSubscriptionResponseModel
     */
    'defaultPaymentMethodId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof StripeSubscriptionResponseModel
     */
    'latestInvoiceId'?: string | null;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof StripeSubscriptionResponseModel
     */
    'metadata'?: { [key: string]: string; } | null;
    /**
     * 
     * @type {string}
     * @memberof StripeSubscriptionResponseModel
     */
    'cancelAt'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof StripeSubscriptionResponseModel
     */
    'canceledAt'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof StripeSubscriptionResponseModel
     */
    'cancelAtPeriodEnd'?: boolean;
    /**
     * 
     * @type {StripeSubscriptionStatus}
     * @memberof StripeSubscriptionResponseModel
     */
    'status'?: StripeSubscriptionStatus;
    /**
     * 
     * @type {string}
     * @memberof StripeSubscriptionResponseModel
     */
    'scheduleId'?: string | null;
    /**
     * 
     * @type {Array<StripeSubscriptionItemModel>}
     * @memberof StripeSubscriptionResponseModel
     */
    'subscriptionItems'?: Array<StripeSubscriptionItemModel> | null;
}
/**
 * 
 * @export
 * @interface StripeSubscriptionResponseModelIDataResult
 */
export interface StripeSubscriptionResponseModelIDataResult {
    /**
     * 
     * @type {StripeSubscriptionResponseModel}
     * @memberof StripeSubscriptionResponseModelIDataResult
     */
    'data'?: StripeSubscriptionResponseModel;
    /**
     * 
     * @type {boolean}
     * @memberof StripeSubscriptionResponseModelIDataResult
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof StripeSubscriptionResponseModelIDataResult
     */
    'message'?: string | null;
}
/**
 * 
 * @export
 * @interface StripeSubscriptionScheduleCurrentPhaseModel
 */
export interface StripeSubscriptionScheduleCurrentPhaseModel {
    /**
     * 
     * @type {string}
     * @memberof StripeSubscriptionScheduleCurrentPhaseModel
     */
    'endDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof StripeSubscriptionScheduleCurrentPhaseModel
     */
    'startDate'?: string | null;
}
/**
 * 
 * @export
 * @interface StripeSubscriptionSchedulePhaseItemModel
 */
export interface StripeSubscriptionSchedulePhaseItemModel {
    /**
     * 
     * @type {string}
     * @memberof StripeSubscriptionSchedulePhaseItemModel
     */
    'priceId'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof StripeSubscriptionSchedulePhaseItemModel
     */
    'quantity'?: number;
}
/**
 * 
 * @export
 * @interface StripeSubscriptionSchedulePhaseModel
 */
export interface StripeSubscriptionSchedulePhaseModel {
    /**
     * 
     * @type {string}
     * @memberof StripeSubscriptionSchedulePhaseModel
     */
    'endDate'?: string;
    /**
     * 
     * @type {Array<StripeSubscriptionSchedulePhaseItemModel>}
     * @memberof StripeSubscriptionSchedulePhaseModel
     */
    'items'?: Array<StripeSubscriptionSchedulePhaseItemModel> | null;
    /**
     * 
     * @type {string}
     * @memberof StripeSubscriptionSchedulePhaseModel
     */
    'prorationBehavior'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof StripeSubscriptionSchedulePhaseModel
     */
    'startDate'?: string;
}
/**
 * 
 * @export
 * @interface StripeSubscriptionScheduleResponseModel
 */
export interface StripeSubscriptionScheduleResponseModel {
    /**
     * 
     * @type {string}
     * @memberof StripeSubscriptionScheduleResponseModel
     */
    'canceledAt'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof StripeSubscriptionScheduleResponseModel
     */
    'completedAt'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof StripeSubscriptionScheduleResponseModel
     */
    'created'?: string;
    /**
     * 
     * @type {StripeSubscriptionScheduleCurrentPhaseModel}
     * @memberof StripeSubscriptionScheduleResponseModel
     */
    'currentPhase'?: StripeSubscriptionScheduleCurrentPhaseModel;
    /**
     * 
     * @type {Array<StripeSubscriptionSchedulePhaseModel>}
     * @memberof StripeSubscriptionScheduleResponseModel
     */
    'phases'?: Array<StripeSubscriptionSchedulePhaseModel> | null;
    /**
     * 
     * @type {string}
     * @memberof StripeSubscriptionScheduleResponseModel
     */
    'releasedAt'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof StripeSubscriptionScheduleResponseModel
     */
    'status'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof StripeSubscriptionScheduleResponseModel
     */
    'subscriptionId'?: string | null;
}
/**
 * 
 * @export
 * @interface StripeSubscriptionScheduleResponseModelIDataResult
 */
export interface StripeSubscriptionScheduleResponseModelIDataResult {
    /**
     * 
     * @type {StripeSubscriptionScheduleResponseModel}
     * @memberof StripeSubscriptionScheduleResponseModelIDataResult
     */
    'data'?: StripeSubscriptionScheduleResponseModel;
    /**
     * 
     * @type {boolean}
     * @memberof StripeSubscriptionScheduleResponseModelIDataResult
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof StripeSubscriptionScheduleResponseModelIDataResult
     */
    'message'?: string | null;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const StripeSubscriptionStatus = {
    Incomplete: 'incomplete',
    IncompleteExpired: 'incomplete_expired',
    Trialing: 'trialing',
    Active: 'active',
    PastDue: 'past_due',
    Canceled: 'canceled',
    Unpaid: 'unpaid'
} as const;

export type StripeSubscriptionStatus = typeof StripeSubscriptionStatus[keyof typeof StripeSubscriptionStatus];


/**
 * 
 * @export
 * @interface StripeSubscriptionSummaryModel
 */
export interface StripeSubscriptionSummaryModel {
    /**
     * 
     * @type {string}
     * @memberof StripeSubscriptionSummaryModel
     */
    'userId'?: string;
    /**
     * 
     * @type {string}
     * @memberof StripeSubscriptionSummaryModel
     */
    'stripeCustomerId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof StripeSubscriptionSummaryModel
     */
    'stripeSubscriptionId'?: string | null;
    /**
     * 
     * @type {StripeSubscriptionResponseModel}
     * @memberof StripeSubscriptionSummaryModel
     */
    'subscription'?: StripeSubscriptionResponseModel;
    /**
     * 
     * @type {Array<StripeInvoiceSearchResponseModel>}
     * @memberof StripeSubscriptionSummaryModel
     */
    'subscriptionInvoices'?: Array<StripeInvoiceSearchResponseModel> | null;
    /**
     * 
     * @type {StripeUpcomingInvoiceResponseModel1}
     * @memberof StripeSubscriptionSummaryModel
     */
    'upcomingInvoice'?: StripeUpcomingInvoiceResponseModel1;
    /**
     * 
     * @type {User}
     * @memberof StripeSubscriptionSummaryModel
     */
    'user'?: User;
}
/**
 * 
 * @export
 * @interface StripeSubscriptionSummaryModelIDataResult
 */
export interface StripeSubscriptionSummaryModelIDataResult {
    /**
     * 
     * @type {StripeSubscriptionSummaryModel}
     * @memberof StripeSubscriptionSummaryModelIDataResult
     */
    'data'?: StripeSubscriptionSummaryModel;
    /**
     * 
     * @type {boolean}
     * @memberof StripeSubscriptionSummaryModelIDataResult
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof StripeSubscriptionSummaryModelIDataResult
     */
    'message'?: string | null;
}
/**
 * 
 * @export
 * @interface StripeSubscriptionSummaryModelIEnumerableIDataResult
 */
export interface StripeSubscriptionSummaryModelIEnumerableIDataResult {
    /**
     * 
     * @type {Array<StripeSubscriptionSummaryModel>}
     * @memberof StripeSubscriptionSummaryModelIEnumerableIDataResult
     */
    'data'?: Array<StripeSubscriptionSummaryModel> | null;
    /**
     * 
     * @type {boolean}
     * @memberof StripeSubscriptionSummaryModelIEnumerableIDataResult
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof StripeSubscriptionSummaryModelIEnumerableIDataResult
     */
    'message'?: string | null;
}
/**
 * 
 * @export
 * @interface StripeUpcomingInvoiceResponseModel
 */
export interface StripeUpcomingInvoiceResponseModel {
    /**
     * 
     * @type {string}
     * @memberof StripeUpcomingInvoiceResponseModel
     */
    'id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof StripeUpcomingInvoiceResponseModel
     */
    'stripeCustomerId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof StripeUpcomingInvoiceResponseModel
     */
    'createdAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof StripeUpcomingInvoiceResponseModel
     */
    'stripeCustomerEmail'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof StripeUpcomingInvoiceResponseModel
     */
    'currency'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof StripeUpcomingInvoiceResponseModel
     */
    'paid'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof StripeUpcomingInvoiceResponseModel
     */
    'periodStart'?: string;
    /**
     * 
     * @type {string}
     * @memberof StripeUpcomingInvoiceResponseModel
     */
    'periodEnd'?: string;
    /**
     * 
     * @type {number}
     * @memberof StripeUpcomingInvoiceResponseModel
     */
    'subtotal'?: number;
    /**
     * 
     * @type {number}
     * @memberof StripeUpcomingInvoiceResponseModel
     */
    'total'?: number;
    /**
     * 
     * @type {number}
     * @memberof StripeUpcomingInvoiceResponseModel
     */
    'actualSubTotal'?: number;
    /**
     * 
     * @type {number}
     * @memberof StripeUpcomingInvoiceResponseModel
     */
    'actualTotal'?: number;
    /**
     * 
     * @type {string}
     * @memberof StripeUpcomingInvoiceResponseModel
     */
    'stripeSubscriptionId'?: string | null;
    /**
     * 
     * @type {StripeInvoiceStatus}
     * @memberof StripeUpcomingInvoiceResponseModel
     */
    'status'?: StripeInvoiceStatus;
    /**
     * 
     * @type {Array<StripeInvoiceItemsModel>}
     * @memberof StripeUpcomingInvoiceResponseModel
     */
    'lines'?: Array<StripeInvoiceItemsModel> | null;
}
/**
 * 
 * @export
 * @interface StripeUpcomingInvoiceResponseModel1
 */
export interface StripeUpcomingInvoiceResponseModel1 {
    /**
     * 
     * @type {string}
     * @memberof StripeUpcomingInvoiceResponseModel1
     */
    'id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof StripeUpcomingInvoiceResponseModel1
     */
    'stripeCustomerId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof StripeUpcomingInvoiceResponseModel1
     */
    'createdAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof StripeUpcomingInvoiceResponseModel1
     */
    'stripeCustomerEmail'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof StripeUpcomingInvoiceResponseModel1
     */
    'currency'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof StripeUpcomingInvoiceResponseModel1
     */
    'paid'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof StripeUpcomingInvoiceResponseModel1
     */
    'periodStart'?: string;
    /**
     * 
     * @type {string}
     * @memberof StripeUpcomingInvoiceResponseModel1
     */
    'periodEnd'?: string;
    /**
     * 
     * @type {number}
     * @memberof StripeUpcomingInvoiceResponseModel1
     */
    'subtotal'?: number;
    /**
     * 
     * @type {number}
     * @memberof StripeUpcomingInvoiceResponseModel1
     */
    'total'?: number;
    /**
     * 
     * @type {string}
     * @memberof StripeUpcomingInvoiceResponseModel1
     */
    'stripeSubscriptionId'?: string | null;
    /**
     * 
     * @type {StripeInvoiceStatus}
     * @memberof StripeUpcomingInvoiceResponseModel1
     */
    'status'?: StripeInvoiceStatus;
    /**
     * 
     * @type {Array<StripeInvoiceItemsModel1>}
     * @memberof StripeUpcomingInvoiceResponseModel1
     */
    'lines'?: Array<StripeInvoiceItemsModel1> | null;
    /**
     * 
     * @type {number}
     * @memberof StripeUpcomingInvoiceResponseModel1
     */
    'actualSubTotal'?: number;
    /**
     * 
     * @type {number}
     * @memberof StripeUpcomingInvoiceResponseModel1
     */
    'actualTotal'?: number;
}
/**
 * 
 * @export
 * @interface SuggestResponse
 */
export interface SuggestResponse {
    /**
     * 
     * @type {Array<SuggestTerm>}
     * @memberof SuggestResponse
     */
    'suggests'?: Array<SuggestTerm> | null;
}
/**
 * 
 * @export
 * @interface SuggestResponseIDataResult
 */
export interface SuggestResponseIDataResult {
    /**
     * 
     * @type {SuggestResponse}
     * @memberof SuggestResponseIDataResult
     */
    'data'?: SuggestResponse;
    /**
     * 
     * @type {boolean}
     * @memberof SuggestResponseIDataResult
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof SuggestResponseIDataResult
     */
    'message'?: string | null;
}
/**
 * 
 * @export
 * @interface SuggestTerm
 */
export interface SuggestTerm {
    /**
     * 
     * @type {string}
     * @memberof SuggestTerm
     */
    'codeSet'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SuggestTerm
     */
    'code'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SuggestTerm
     */
    'description'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof SuggestTerm
     */
    'score'?: number | null;
}
/**
 * 
 * @export
 * @interface UpcomingInvoiceBySubscriptionsRequestModel
 */
export interface UpcomingInvoiceBySubscriptionsRequestModel {
    /**
     * 
     * @type {Array<string>}
     * @memberof UpcomingInvoiceBySubscriptionsRequestModel
     */
    'stripeSubscriptionIds'?: Array<string> | null;
}
/**
 * 
 * @export
 * @interface UpcomingInvoiceSummaryResponseModel
 */
export interface UpcomingInvoiceSummaryResponseModel {
    /**
     * 
     * @type {number}
     * @memberof UpcomingInvoiceSummaryResponseModel
     */
    'subTotal'?: number;
    /**
     * 
     * @type {number}
     * @memberof UpcomingInvoiceSummaryResponseModel
     */
    'amountDue'?: number;
    /**
     * 
     * @type {number}
     * @memberof UpcomingInvoiceSummaryResponseModel
     */
    'total'?: number;
    /**
     * 
     * @type {Array<Period>}
     * @memberof UpcomingInvoiceSummaryResponseModel
     */
    'periods'?: Array<Period> | null;
    /**
     * 
     * @type {number}
     * @memberof UpcomingInvoiceSummaryResponseModel
     */
    'actualSubTotal'?: number;
    /**
     * 
     * @type {number}
     * @memberof UpcomingInvoiceSummaryResponseModel
     */
    'actualAmountDue'?: number;
    /**
     * 
     * @type {number}
     * @memberof UpcomingInvoiceSummaryResponseModel
     */
    'actualTotal'?: number;
}
/**
 * 
 * @export
 * @interface UpcomingInvoiceSummaryResponseModelIDataResult
 */
export interface UpcomingInvoiceSummaryResponseModelIDataResult {
    /**
     * 
     * @type {UpcomingInvoiceSummaryResponseModel}
     * @memberof UpcomingInvoiceSummaryResponseModelIDataResult
     */
    'data'?: UpcomingInvoiceSummaryResponseModel;
    /**
     * 
     * @type {boolean}
     * @memberof UpcomingInvoiceSummaryResponseModelIDataResult
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UpcomingInvoiceSummaryResponseModelIDataResult
     */
    'message'?: string | null;
}
/**
 * 
 * @export
 * @interface UpdateCognitoUserGroupsModel
 */
export interface UpdateCognitoUserGroupsModel {
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateCognitoUserGroupsModel
     */
    'groupNames'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateCognitoUserGroupsModel
     */
    'email'?: string | null;
}
/**
 * 
 * @export
 * @interface UpdateUserStripeCustomerIdModel
 */
export interface UpdateUserStripeCustomerIdModel {
    /**
     * 
     * @type {string}
     * @memberof UpdateUserStripeCustomerIdModel
     */
    'userId'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateUserStripeCustomerIdModel
     */
    'stripeCustomerId'?: string | null;
}
/**
 * 
 * @export
 * @interface User
 */
export interface User {
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'createdBy'?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'createdOn'?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'lastModifiedBy'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'lastModifiedOn'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof User
     */
    'isDeleted'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'cognitoUserId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'stripeCustomerId'?: string | null;
    /**
     * 
     * @type {Company}
     * @memberof User
     */
    'company'?: Company;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'companyId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'title'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'firstName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'lastName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'phone'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'email'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'country'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'zipCode'?: string | null;
    /**
     * 
     * @type {RoleType}
     * @memberof User
     */
    'role'?: RoleType;
    /**
     * 
     * @type {Array<UserSubscription>}
     * @memberof User
     */
    'userSubscriptions'?: Array<UserSubscription> | null;
}
/**
 * 
 * @export
 * @interface UserActiveProductFeaturesModel
 */
export interface UserActiveProductFeaturesModel {
    /**
     * 
     * @type {Array<string>}
     * @memberof UserActiveProductFeaturesModel
     */
    'features'?: Array<string> | null;
}
/**
 * 
 * @export
 * @interface UserActiveProductFeaturesModelIDataResult
 */
export interface UserActiveProductFeaturesModelIDataResult {
    /**
     * 
     * @type {UserActiveProductFeaturesModel}
     * @memberof UserActiveProductFeaturesModelIDataResult
     */
    'data'?: UserActiveProductFeaturesModel;
    /**
     * 
     * @type {boolean}
     * @memberof UserActiveProductFeaturesModelIDataResult
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UserActiveProductFeaturesModelIDataResult
     */
    'message'?: string | null;
}
/**
 * 
 * @export
 * @interface UserApiKeyResponseModel
 */
export interface UserApiKeyResponseModel {
    /**
     * 
     * @type {string}
     * @memberof UserApiKeyResponseModel
     */
    'companyId'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserApiKeyResponseModel
     */
    'awsApiKeyId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserApiKeyResponseModel
     */
    'awsApiKey'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserApiKeyResponseModel
     */
    'awsUsagePlanId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserApiKeyResponseModel
     */
    'usagePlanName'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof UserApiKeyResponseModel
     */
    'quota'?: number;
}
/**
 * 
 * @export
 * @interface UserApiKeyResponseModelIDataResult
 */
export interface UserApiKeyResponseModelIDataResult {
    /**
     * 
     * @type {UserApiKeyResponseModel}
     * @memberof UserApiKeyResponseModelIDataResult
     */
    'data'?: UserApiKeyResponseModel;
    /**
     * 
     * @type {boolean}
     * @memberof UserApiKeyResponseModelIDataResult
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UserApiKeyResponseModelIDataResult
     */
    'message'?: string | null;
}
/**
 * 
 * @export
 * @interface UserApiKeyUsageModel
 */
export interface UserApiKeyUsageModel {
    /**
     * 
     * @type {number}
     * @memberof UserApiKeyUsageModel
     */
    'quota'?: number;
    /**
     * 
     * @type {number}
     * @memberof UserApiKeyUsageModel
     */
    'usage'?: number;
    /**
     * 
     * @type {string}
     * @memberof UserApiKeyUsageModel
     */
    'usagePlanName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserApiKeyUsageModel
     */
    'endDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserApiKeyUsageModel
     */
    'startDate'?: string | null;
}
/**
 * 
 * @export
 * @interface UserApiKeyUsageModelIDataResult
 */
export interface UserApiKeyUsageModelIDataResult {
    /**
     * 
     * @type {UserApiKeyUsageModel}
     * @memberof UserApiKeyUsageModelIDataResult
     */
    'data'?: UserApiKeyUsageModel;
    /**
     * 
     * @type {boolean}
     * @memberof UserApiKeyUsageModelIDataResult
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UserApiKeyUsageModelIDataResult
     */
    'message'?: string | null;
}
/**
 * 
 * @export
 * @interface UserCodeModel
 */
export interface UserCodeModel {
    /**
     * 
     * @type {string}
     * @memberof UserCodeModel
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserCodeModel
     */
    'userId'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserCodeModel
     */
    'userName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserCodeModel
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserCodeModel
     */
    'description'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof UserCodeModel
     */
    'isDefault'?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof UserCodeModel
     */
    'codes'?: Array<string> | null;
    /**
     * 
     * @type {boolean}
     * @memberof UserCodeModel
     */
    'isSharable'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UserCodeModel
     */
    'lastModifiedOn'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserCodeModel
     */
    'createdOn'?: string;
}
/**
 * 
 * @export
 * @interface UserCodeModelIDataResult
 */
export interface UserCodeModelIDataResult {
    /**
     * 
     * @type {UserCodeModel}
     * @memberof UserCodeModelIDataResult
     */
    'data'?: UserCodeModel;
    /**
     * 
     * @type {boolean}
     * @memberof UserCodeModelIDataResult
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UserCodeModelIDataResult
     */
    'message'?: string | null;
}
/**
 * 
 * @export
 * @interface UserCodeModelIEnumerableIDataResult
 */
export interface UserCodeModelIEnumerableIDataResult {
    /**
     * 
     * @type {Array<UserCodeModel>}
     * @memberof UserCodeModelIEnumerableIDataResult
     */
    'data'?: Array<UserCodeModel> | null;
    /**
     * 
     * @type {boolean}
     * @memberof UserCodeModelIEnumerableIDataResult
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UserCodeModelIEnumerableIDataResult
     */
    'message'?: string | null;
}
/**
 * 
 * @export
 * @interface UserCodeModelPagedResults
 */
export interface UserCodeModelPagedResults {
    /**
     * 
     * @type {Array<UserCodeModel>}
     * @memberof UserCodeModelPagedResults
     */
    'items'?: Array<UserCodeModel> | null;
    /**
     * 
     * @type {number}
     * @memberof UserCodeModelPagedResults
     */
    'totalSize'?: number;
}
/**
 * 
 * @export
 * @interface UserCodeModelPagedResultsIDataResult
 */
export interface UserCodeModelPagedResultsIDataResult {
    /**
     * 
     * @type {UserCodeModelPagedResults}
     * @memberof UserCodeModelPagedResultsIDataResult
     */
    'data'?: UserCodeModelPagedResults;
    /**
     * 
     * @type {boolean}
     * @memberof UserCodeModelPagedResultsIDataResult
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UserCodeModelPagedResultsIDataResult
     */
    'message'?: string | null;
}
/**
 * 
 * @export
 * @interface UserCodeNoteBatchModel
 */
export interface UserCodeNoteBatchModel {
    /**
     * 
     * @type {string}
     * @memberof UserCodeNoteBatchModel
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserCodeNoteBatchModel
     */
    'userId'?: string;
    /**
     * 
     * @type {Array<CodeModel>}
     * @memberof UserCodeNoteBatchModel
     */
    'codes'?: Array<CodeModel> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof UserCodeNoteBatchModel
     */
    'keyWords'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof UserCodeNoteBatchModel
     */
    'note'?: string | null;
}
/**
 * 
 * @export
 * @interface UserCodeNoteBatchResponseModel
 */
export interface UserCodeNoteBatchResponseModel {
    /**
     * 
     * @type {UserCodeNoteBatchModel}
     * @memberof UserCodeNoteBatchResponseModel
     */
    'userCodeNoteBatchModel'?: UserCodeNoteBatchModel;
    /**
     * 
     * @type {Array<string>}
     * @memberof UserCodeNoteBatchResponseModel
     */
    'allreadyExistsCodes'?: Array<string> | null;
}
/**
 * 
 * @export
 * @interface UserCodeNoteBatchResponseModelIDataResult
 */
export interface UserCodeNoteBatchResponseModelIDataResult {
    /**
     * 
     * @type {UserCodeNoteBatchResponseModel}
     * @memberof UserCodeNoteBatchResponseModelIDataResult
     */
    'data'?: UserCodeNoteBatchResponseModel;
    /**
     * 
     * @type {boolean}
     * @memberof UserCodeNoteBatchResponseModelIDataResult
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UserCodeNoteBatchResponseModelIDataResult
     */
    'message'?: string | null;
}
/**
 * 
 * @export
 * @interface UserCodeNoteModel
 */
export interface UserCodeNoteModel {
    /**
     * 
     * @type {string}
     * @memberof UserCodeNoteModel
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserCodeNoteModel
     */
    'userId'?: string;
    /**
     * 
     * @type {CodeSetType}
     * @memberof UserCodeNoteModel
     */
    'codeSetType'?: CodeSetType;
    /**
     * 
     * @type {string}
     * @memberof UserCodeNoteModel
     */
    'code'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof UserCodeNoteModel
     */
    'keyWords'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof UserCodeNoteModel
     */
    'note'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserCodeNoteModel
     */
    'createdOn'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserCodeNoteModel
     */
    'lastModifiedOn'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserCodeNoteModel
     */
    'codeSetTypeDescription'?: string | null;
}
/**
 * 
 * @export
 * @interface UserCodeNoteModel1
 */
export interface UserCodeNoteModel1 {
    /**
     * 
     * @type {string}
     * @memberof UserCodeNoteModel1
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserCodeNoteModel1
     */
    'userId'?: string;
    /**
     * 
     * @type {CodeSetType}
     * @memberof UserCodeNoteModel1
     */
    'codeSetType'?: CodeSetType;
    /**
     * 
     * @type {string}
     * @memberof UserCodeNoteModel1
     */
    'code'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof UserCodeNoteModel1
     */
    'keyWords'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof UserCodeNoteModel1
     */
    'note'?: string | null;
}
/**
 * 
 * @export
 * @interface UserCodeNoteModelIDataResult
 */
export interface UserCodeNoteModelIDataResult {
    /**
     * 
     * @type {UserCodeNoteModel}
     * @memberof UserCodeNoteModelIDataResult
     */
    'data'?: UserCodeNoteModel;
    /**
     * 
     * @type {boolean}
     * @memberof UserCodeNoteModelIDataResult
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UserCodeNoteModelIDataResult
     */
    'message'?: string | null;
}
/**
 * 
 * @export
 * @interface UserCodeNoteModelPagedResults
 */
export interface UserCodeNoteModelPagedResults {
    /**
     * 
     * @type {Array<UserCodeNoteModel>}
     * @memberof UserCodeNoteModelPagedResults
     */
    'items'?: Array<UserCodeNoteModel> | null;
    /**
     * 
     * @type {number}
     * @memberof UserCodeNoteModelPagedResults
     */
    'totalSize'?: number;
}
/**
 * 
 * @export
 * @interface UserCodeNoteModelPagedResultsIDataResult
 */
export interface UserCodeNoteModelPagedResultsIDataResult {
    /**
     * 
     * @type {UserCodeNoteModelPagedResults}
     * @memberof UserCodeNoteModelPagedResultsIDataResult
     */
    'data'?: UserCodeNoteModelPagedResults;
    /**
     * 
     * @type {boolean}
     * @memberof UserCodeNoteModelPagedResultsIDataResult
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UserCodeNoteModelPagedResultsIDataResult
     */
    'message'?: string | null;
}
/**
 * 
 * @export
 * @interface UserCodeNoteSearchResponse
 */
export interface UserCodeNoteSearchResponse {
    /**
     * 
     * @type {string}
     * @memberof UserCodeNoteSearchResponse
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserCodeNoteSearchResponse
     */
    'codeSet'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserCodeNoteSearchResponse
     */
    'code'?: string | null;
}
/**
 * 
 * @export
 * @interface UserCodeNoteSearchResponseIEnumerableIDataResult
 */
export interface UserCodeNoteSearchResponseIEnumerableIDataResult {
    /**
     * 
     * @type {Array<UserCodeNoteSearchResponse>}
     * @memberof UserCodeNoteSearchResponseIEnumerableIDataResult
     */
    'data'?: Array<UserCodeNoteSearchResponse> | null;
    /**
     * 
     * @type {boolean}
     * @memberof UserCodeNoteSearchResponseIEnumerableIDataResult
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UserCodeNoteSearchResponseIEnumerableIDataResult
     */
    'message'?: string | null;
}
/**
 * 
 * @export
 * @interface UserDetailsModel
 */
export interface UserDetailsModel {
    /**
     * 
     * @type {string}
     * @memberof UserDetailsModel
     */
    'userId'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserDetailsModel
     */
    'fullName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserDetailsModel
     */
    'email'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserDetailsModel
     */
    'address'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserDetailsModel
     */
    'companyName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserDetailsModel
     */
    'registeredDate'?: string;
    /**
     * 
     * @type {RoleType}
     * @memberof UserDetailsModel
     */
    'userRoleType'?: RoleType;
    /**
     * 
     * @type {string}
     * @memberof UserDetailsModel
     */
    'lastLoginDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserDetailsModel
     */
    'registryStatus'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserDetailsModel
     */
    'cognitoUserId'?: string | null;
}
/**
 * 
 * @export
 * @interface UserDetailsModelIDataResult
 */
export interface UserDetailsModelIDataResult {
    /**
     * 
     * @type {UserDetailsModel}
     * @memberof UserDetailsModelIDataResult
     */
    'data'?: UserDetailsModel;
    /**
     * 
     * @type {boolean}
     * @memberof UserDetailsModelIDataResult
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UserDetailsModelIDataResult
     */
    'message'?: string | null;
}
/**
 * 
 * @export
 * @interface UserLocationModel
 */
export interface UserLocationModel {
    /**
     * 
     * @type {string}
     * @memberof UserLocationModel
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserLocationModel
     */
    'userId'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserLocationModel
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserLocationModel
     */
    'description'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof UserLocationModel
     */
    'isDefault'?: boolean;
    /**
     * 
     * @type {PrivacyType}
     * @memberof UserLocationModel
     */
    'privacyType'?: PrivacyType;
    /**
     * 
     * @type {string}
     * @memberof UserLocationModel
     */
    'medicareZipCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserLocationModel
     */
    'medicareState'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserLocationModel
     */
    'medicareContractor'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserLocationModel
     */
    'medicareLocalityNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserLocationModel
     */
    'medicareLocalityName'?: string | null;
    /**
     * 
     * @type {DateOnly}
     * @memberof UserLocationModel
     */
    'medicareQuarterBeginDate'?: DateOnly;
    /**
     * 
     * @type {string}
     * @memberof UserLocationModel
     */
    'medicareFeeComparisonFirstPercentage'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserLocationModel
     */
    'medicareFeeComparisonSecondPercentage'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserLocationModel
     */
    'hospitalProviderNo'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserLocationModel
     */
    'cbsa'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof UserLocationModel
     */
    'isHospitalQualifies'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UserLocationModel
     */
    'gpciId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserLocationModel
     */
    'createdOn'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserLocationModel
     */
    'lastModifiedOn'?: string | null;
}
/**
 * 
 * @export
 * @interface UserLocationModelIDataResult
 */
export interface UserLocationModelIDataResult {
    /**
     * 
     * @type {UserLocationModel}
     * @memberof UserLocationModelIDataResult
     */
    'data'?: UserLocationModel;
    /**
     * 
     * @type {boolean}
     * @memberof UserLocationModelIDataResult
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UserLocationModelIDataResult
     */
    'message'?: string | null;
}
/**
 * 
 * @export
 * @interface UserLocationModelPagedResults
 */
export interface UserLocationModelPagedResults {
    /**
     * 
     * @type {Array<UserLocationModel>}
     * @memberof UserLocationModelPagedResults
     */
    'items'?: Array<UserLocationModel> | null;
    /**
     * 
     * @type {number}
     * @memberof UserLocationModelPagedResults
     */
    'totalSize'?: number;
}
/**
 * 
 * @export
 * @interface UserLocationModelPagedResultsIDataResult
 */
export interface UserLocationModelPagedResultsIDataResult {
    /**
     * 
     * @type {UserLocationModelPagedResults}
     * @memberof UserLocationModelPagedResultsIDataResult
     */
    'data'?: UserLocationModelPagedResults;
    /**
     * 
     * @type {boolean}
     * @memberof UserLocationModelPagedResultsIDataResult
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UserLocationModelPagedResultsIDataResult
     */
    'message'?: string | null;
}
/**
 * 
 * @export
 * @interface UserLoginDayHistoryModel
 */
export interface UserLoginDayHistoryModel {
    /**
     * 
     * @type {string}
     * @memberof UserLoginDayHistoryModel
     */
    'cognitoUserId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserLoginDayHistoryModel
     */
    'email'?: string | null;
    /**
     * 
     * @type {DateOnly}
     * @memberof UserLoginDayHistoryModel
     */
    'date'?: DateOnly;
    /**
     * 
     * @type {number}
     * @memberof UserLoginDayHistoryModel
     */
    'count'?: number | null;
}
/**
 * 
 * @export
 * @interface UserLoginDayHistoryModelPagedResults
 */
export interface UserLoginDayHistoryModelPagedResults {
    /**
     * 
     * @type {Array<UserLoginDayHistoryModel>}
     * @memberof UserLoginDayHistoryModelPagedResults
     */
    'items'?: Array<UserLoginDayHistoryModel> | null;
    /**
     * 
     * @type {number}
     * @memberof UserLoginDayHistoryModelPagedResults
     */
    'totalSize'?: number;
}
/**
 * 
 * @export
 * @interface UserLoginDayHistoryModelPagedResultsIDataResult
 */
export interface UserLoginDayHistoryModelPagedResultsIDataResult {
    /**
     * 
     * @type {UserLoginDayHistoryModelPagedResults}
     * @memberof UserLoginDayHistoryModelPagedResultsIDataResult
     */
    'data'?: UserLoginDayHistoryModelPagedResults;
    /**
     * 
     * @type {boolean}
     * @memberof UserLoginDayHistoryModelPagedResultsIDataResult
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UserLoginDayHistoryModelPagedResultsIDataResult
     */
    'message'?: string | null;
}
/**
 * 
 * @export
 * @interface UserLoginDetailRequestModel
 */
export interface UserLoginDetailRequestModel {
    /**
     * 
     * @type {string}
     * @memberof UserLoginDetailRequestModel
     */
    'cognitoUserId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserLoginDetailRequestModel
     */
    'date'?: string;
}
/**
 * 
 * @export
 * @interface UserLoginHistoryModel
 */
export interface UserLoginHistoryModel {
    /**
     * 
     * @type {string}
     * @memberof UserLoginHistoryModel
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserLoginHistoryModel
     */
    'userPoolId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserLoginHistoryModel
     */
    'cognitoUserId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserLoginHistoryModel
     */
    'region'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserLoginHistoryModel
     */
    'email'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserLoginHistoryModel
     */
    'userAttributes'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserLoginHistoryModel
     */
    'cretaedOnUtc'?: string;
}
/**
 * 
 * @export
 * @interface UserLoginHistoryModelIEnumerableIDataResult
 */
export interface UserLoginHistoryModelIEnumerableIDataResult {
    /**
     * 
     * @type {Array<UserLoginHistoryModel>}
     * @memberof UserLoginHistoryModelIEnumerableIDataResult
     */
    'data'?: Array<UserLoginHistoryModel> | null;
    /**
     * 
     * @type {boolean}
     * @memberof UserLoginHistoryModelIEnumerableIDataResult
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UserLoginHistoryModelIEnumerableIDataResult
     */
    'message'?: string | null;
}
/**
 * 
 * @export
 * @interface UserLoginLogModel
 */
export interface UserLoginLogModel {
    /**
     * 
     * @type {string}
     * @memberof UserLoginLogModel
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserLoginLogModel
     */
    'userPoolId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserLoginLogModel
     */
    'cognitoUserId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserLoginLogModel
     */
    'region'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserLoginLogModel
     */
    'userAttributes'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserLoginLogModel
     */
    'userAgent'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserLoginLogModel
     */
    'sourceIp'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserLoginLogModel
     */
    'errorCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserLoginLogModel
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserLoginLogModel
     */
    'createdOn'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof UserLoginLogModel
     */
    'successed'?: boolean;
}
/**
 * 
 * @export
 * @interface UserLoginLogModelPagedResults
 */
export interface UserLoginLogModelPagedResults {
    /**
     * 
     * @type {Array<UserLoginLogModel>}
     * @memberof UserLoginLogModelPagedResults
     */
    'items'?: Array<UserLoginLogModel> | null;
    /**
     * 
     * @type {number}
     * @memberof UserLoginLogModelPagedResults
     */
    'totalSize'?: number;
}
/**
 * 
 * @export
 * @interface UserLoginLogModelPagedResultsIDataResult
 */
export interface UserLoginLogModelPagedResultsIDataResult {
    /**
     * 
     * @type {UserLoginLogModelPagedResults}
     * @memberof UserLoginLogModelPagedResultsIDataResult
     */
    'data'?: UserLoginLogModelPagedResults;
    /**
     * 
     * @type {boolean}
     * @memberof UserLoginLogModelPagedResultsIDataResult
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UserLoginLogModelPagedResultsIDataResult
     */
    'message'?: string | null;
}
/**
 * 
 * @export
 * @interface UserModel
 */
export interface UserModel {
    /**
     * 
     * @type {string}
     * @memberof UserModel
     */
    'cognitoUserId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserModel
     */
    'stripeCustomerId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserModel
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserModel
     */
    'companyId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserModel
     */
    'firstName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserModel
     */
    'lastName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserModel
     */
    'fullName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserModel
     */
    'phone'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserModel
     */
    'email'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserModel
     */
    'country'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserModel
     */
    'zipCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserModel
     */
    'title'?: string | null;
    /**
     * 
     * @type {RoleType}
     * @memberof UserModel
     */
    'role'?: RoleType;
    /**
     * 
     * @type {string}
     * @memberof UserModel
     */
    'createdOn'?: string | null;
    /**
     * 
     * @type {Array<GetCognitoUserGroupsResponseModel>}
     * @memberof UserModel
     */
    'cognitoUserGroups'?: Array<GetCognitoUserGroupsResponseModel> | null;
    /**
     * 
     * @type {CompanyModel}
     * @memberof UserModel
     */
    'company'?: CompanyModel;
    /**
     * 
     * @type {StripeSubscriptionResponseModel}
     * @memberof UserModel
     */
    'subscription'?: StripeSubscriptionResponseModel;
    /**
     * 
     * @type {StripeUpcomingInvoiceResponseModel}
     * @memberof UserModel
     */
    'upcomingInvoice'?: StripeUpcomingInvoiceResponseModel;
}
/**
 * 
 * @export
 * @interface UserModel1
 */
export interface UserModel1 {
    /**
     * 
     * @type {string}
     * @memberof UserModel1
     */
    'cognitoUserId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserModel1
     */
    'stripeCustomerId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserModel1
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserModel1
     */
    'companyId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserModel1
     */
    'firstName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserModel1
     */
    'lastName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserModel1
     */
    'phone'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserModel1
     */
    'email'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserModel1
     */
    'country'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserModel1
     */
    'zipCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserModel1
     */
    'title'?: string | null;
    /**
     * 
     * @type {RoleType}
     * @memberof UserModel1
     */
    'role'?: RoleType;
    /**
     * 
     * @type {string}
     * @memberof UserModel1
     */
    'temporaryPassword'?: string | null;
}
/**
 * 
 * @export
 * @interface UserModel2
 */
export interface UserModel2 {
    /**
     * 
     * @type {string}
     * @memberof UserModel2
     */
    'cognitoUserId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserModel2
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserModel2
     */
    'companyId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserModel2
     */
    'firstName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserModel2
     */
    'lastName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserModel2
     */
    'phone'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserModel2
     */
    'email'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserModel2
     */
    'country'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserModel2
     */
    'zipCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserModel2
     */
    'title'?: string | null;
    /**
     * 
     * @type {RoleType}
     * @memberof UserModel2
     */
    'role'?: RoleType;
    /**
     * 
     * @type {string}
     * @memberof UserModel2
     */
    'temporaryPassword'?: string | null;
    /**
     * 
     * @type {Company}
     * @memberof UserModel2
     */
    'company'?: Company;
}
/**
 * 
 * @export
 * @interface UserModelIDataResult
 */
export interface UserModelIDataResult {
    /**
     * 
     * @type {UserModel}
     * @memberof UserModelIDataResult
     */
    'data'?: UserModel;
    /**
     * 
     * @type {boolean}
     * @memberof UserModelIDataResult
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UserModelIDataResult
     */
    'message'?: string | null;
}
/**
 * 
 * @export
 * @interface UserModelIEnumerableIDataResult
 */
export interface UserModelIEnumerableIDataResult {
    /**
     * 
     * @type {Array<UserModel>}
     * @memberof UserModelIEnumerableIDataResult
     */
    'data'?: Array<UserModel> | null;
    /**
     * 
     * @type {boolean}
     * @memberof UserModelIEnumerableIDataResult
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UserModelIEnumerableIDataResult
     */
    'message'?: string | null;
}
/**
 * 
 * @export
 * @interface UserModelPagedResults
 */
export interface UserModelPagedResults {
    /**
     * 
     * @type {Array<UserModel>}
     * @memberof UserModelPagedResults
     */
    'items'?: Array<UserModel> | null;
    /**
     * 
     * @type {number}
     * @memberof UserModelPagedResults
     */
    'totalSize'?: number;
}
/**
 * 
 * @export
 * @interface UserModelPagedResultsIDataResult
 */
export interface UserModelPagedResultsIDataResult {
    /**
     * 
     * @type {UserModelPagedResults}
     * @memberof UserModelPagedResultsIDataResult
     */
    'data'?: UserModelPagedResults;
    /**
     * 
     * @type {boolean}
     * @memberof UserModelPagedResultsIDataResult
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UserModelPagedResultsIDataResult
     */
    'message'?: string | null;
}
/**
 * 
 * @export
 * @interface UserProductSaleReportsModel
 */
export interface UserProductSaleReportsModel {
    /**
     * 
     * @type {string}
     * @memberof UserProductSaleReportsModel
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserProductSaleReportsModel
     */
    'userId'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserProductSaleReportsModel
     */
    'productId'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserProductSaleReportsModel
     */
    'productName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserProductSaleReportsModel
     */
    'description'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof UserProductSaleReportsModel
     */
    'price'?: number;
    /**
     * 
     * @type {string}
     * @memberof UserProductSaleReportsModel
     */
    'currency'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserProductSaleReportsModel
     */
    'purchaseDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserProductSaleReportsModel
     */
    'expireDate'?: string;
    /**
     * 
     * @type {UserModel2}
     * @memberof UserProductSaleReportsModel
     */
    'user'?: UserModel2;
}
/**
 * 
 * @export
 * @interface UserProductSaleReportsModelPagedResults
 */
export interface UserProductSaleReportsModelPagedResults {
    /**
     * 
     * @type {Array<UserProductSaleReportsModel>}
     * @memberof UserProductSaleReportsModelPagedResults
     */
    'items'?: Array<UserProductSaleReportsModel> | null;
    /**
     * 
     * @type {number}
     * @memberof UserProductSaleReportsModelPagedResults
     */
    'totalSize'?: number;
}
/**
 * 
 * @export
 * @interface UserProductSaleReportsModelPagedResultsIDataResult
 */
export interface UserProductSaleReportsModelPagedResultsIDataResult {
    /**
     * 
     * @type {UserProductSaleReportsModelPagedResults}
     * @memberof UserProductSaleReportsModelPagedResultsIDataResult
     */
    'data'?: UserProductSaleReportsModelPagedResults;
    /**
     * 
     * @type {boolean}
     * @memberof UserProductSaleReportsModelPagedResultsIDataResult
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UserProductSaleReportsModelPagedResultsIDataResult
     */
    'message'?: string | null;
}
/**
 * 
 * @export
 * @interface UserProductsModel
 */
export interface UserProductsModel {
    /**
     * 
     * @type {string}
     * @memberof UserProductsModel
     */
    'userId'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserProductsModel
     */
    'firstName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserProductsModel
     */
    'lastName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserProductsModel
     */
    'phone'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserProductsModel
     */
    'email'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserProductsModel
     */
    'country'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserProductsModel
     */
    'zipCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserProductsModel
     */
    'title'?: string | null;
    /**
     * 
     * @type {RoleType}
     * @memberof UserProductsModel
     */
    'role'?: RoleType;
    /**
     * 
     * @type {Array<ProductModel>}
     * @memberof UserProductsModel
     */
    'products'?: Array<ProductModel> | null;
}
/**
 * 
 * @export
 * @interface UserProductsModelIEnumerableIDataResult
 */
export interface UserProductsModelIEnumerableIDataResult {
    /**
     * 
     * @type {Array<UserProductsModel>}
     * @memberof UserProductsModelIEnumerableIDataResult
     */
    'data'?: Array<UserProductsModel> | null;
    /**
     * 
     * @type {boolean}
     * @memberof UserProductsModelIEnumerableIDataResult
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UserProductsModelIEnumerableIDataResult
     */
    'message'?: string | null;
}
/**
 * 
 * @export
 * @interface UserRoleModel
 */
export interface UserRoleModel {
    /**
     * 
     * @type {string}
     * @memberof UserRoleModel
     */
    'id'?: string | null;
    /**
     * 
     * @type {RoleType}
     * @memberof UserRoleModel
     */
    'role'?: RoleType;
}
/**
 * 
 * @export
 * @interface UserSaleHistoryModel
 */
export interface UserSaleHistoryModel {
    /**
     * 
     * @type {string}
     * @memberof UserSaleHistoryModel
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserSaleHistoryModel
     */
    'packageOrAddOnName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserSaleHistoryModel
     */
    'description'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof UserSaleHistoryModel
     */
    'price'?: number;
    /**
     * 
     * @type {string}
     * @memberof UserSaleHistoryModel
     */
    'currency'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserSaleHistoryModel
     */
    'purchaseDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserSaleHistoryModel
     */
    'stripeInvoiceNumber'?: string | null;
    /**
     * 
     * @type {Array<SaleProductModel>}
     * @memberof UserSaleHistoryModel
     */
    'saleProducts'?: Array<SaleProductModel> | null;
}
/**
 * 
 * @export
 * @interface UserSaleHistoryModelIEnumerableIDataResult
 */
export interface UserSaleHistoryModelIEnumerableIDataResult {
    /**
     * 
     * @type {Array<UserSaleHistoryModel>}
     * @memberof UserSaleHistoryModelIEnumerableIDataResult
     */
    'data'?: Array<UserSaleHistoryModel> | null;
    /**
     * 
     * @type {boolean}
     * @memberof UserSaleHistoryModelIEnumerableIDataResult
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UserSaleHistoryModelIEnumerableIDataResult
     */
    'message'?: string | null;
}
/**
 * 
 * @export
 * @interface UserSession
 */
export interface UserSession {
    /**
     * 
     * @type {string}
     * @memberof UserSession
     */
    'userId'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserSession
     */
    'companyId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserSession
     */
    'cognitoUserId'?: string | null;
    /**
     * 
     * @type {RoleType}
     * @memberof UserSession
     */
    'role'?: RoleType;
    /**
     * 
     * @type {string}
     * @memberof UserSession
     */
    'email'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserSession
     */
    'accessToken'?: string | null;
}
/**
 * 
 * @export
 * @interface UserSessionIDataResult
 */
export interface UserSessionIDataResult {
    /**
     * 
     * @type {UserSession}
     * @memberof UserSessionIDataResult
     */
    'data'?: UserSession;
    /**
     * 
     * @type {boolean}
     * @memberof UserSessionIDataResult
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UserSessionIDataResult
     */
    'message'?: string | null;
}
/**
 * 
 * @export
 * @interface UserSubscription
 */
export interface UserSubscription {
    /**
     * 
     * @type {string}
     * @memberof UserSubscription
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserSubscription
     */
    'createdBy'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserSubscription
     */
    'createdOn'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserSubscription
     */
    'lastModifiedBy'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserSubscription
     */
    'lastModifiedOn'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof UserSubscription
     */
    'isDeleted'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UserSubscription
     */
    'userId'?: string;
    /**
     * 
     * @type {User}
     * @memberof UserSubscription
     */
    'user'?: User;
    /**
     * 
     * @type {string}
     * @memberof UserSubscription
     */
    'stripeSubscriptionId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserSubscription
     */
    'couponCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserSubscription
     */
    'expireAt'?: string;
    /**
     * 
     * @type {Status}
     * @memberof UserSubscription
     */
    'status'?: Status;
    /**
     * 
     * @type {LastPaymentStatus}
     * @memberof UserSubscription
     */
    'lastPaymentStatus'?: LastPaymentStatus;
    /**
     * 
     * @type {string}
     * @memberof UserSubscription
     */
    'lastPaymentDescription'?: string | null;
    /**
     * 
     * @type {Array<UserSubscriptionItem>}
     * @memberof UserSubscription
     */
    'userSubscriptionItems'?: Array<UserSubscriptionItem> | null;
}
/**
 * 
 * @export
 * @interface UserSubscriptionDetailsModel
 */
export interface UserSubscriptionDetailsModel {
    /**
     * 
     * @type {string}
     * @memberof UserSubscriptionDetailsModel
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserSubscriptionDetailsModel
     */
    'userId'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserSubscriptionDetailsModel
     */
    'stripeSubscriptionId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserSubscriptionDetailsModel
     */
    'couponCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserSubscriptionDetailsModel
     */
    'expireAt'?: string;
    /**
     * 
     * @type {Status}
     * @memberof UserSubscriptionDetailsModel
     */
    'status'?: Status;
    /**
     * 
     * @type {string}
     * @memberof UserSubscriptionDetailsModel
     */
    'createdBy'?: string;
    /**
     * 
     * @type {Array<UserSubscriptionItemDetailsModel>}
     * @memberof UserSubscriptionDetailsModel
     */
    'userSubscriptionItems'?: Array<UserSubscriptionItemDetailsModel> | null;
}
/**
 * 
 * @export
 * @interface UserSubscriptionDetailsModelIEnumerableIDataResult
 */
export interface UserSubscriptionDetailsModelIEnumerableIDataResult {
    /**
     * 
     * @type {Array<UserSubscriptionDetailsModel>}
     * @memberof UserSubscriptionDetailsModelIEnumerableIDataResult
     */
    'data'?: Array<UserSubscriptionDetailsModel> | null;
    /**
     * 
     * @type {boolean}
     * @memberof UserSubscriptionDetailsModelIEnumerableIDataResult
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UserSubscriptionDetailsModelIEnumerableIDataResult
     */
    'message'?: string | null;
}
/**
 * 
 * @export
 * @interface UserSubscriptionItem
 */
export interface UserSubscriptionItem {
    /**
     * 
     * @type {string}
     * @memberof UserSubscriptionItem
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserSubscriptionItem
     */
    'createdBy'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserSubscriptionItem
     */
    'createdOn'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserSubscriptionItem
     */
    'lastModifiedBy'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserSubscriptionItem
     */
    'lastModifiedOn'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof UserSubscriptionItem
     */
    'isDeleted'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UserSubscriptionItem
     */
    'productId'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserSubscriptionItem
     */
    'userSubscriptionId'?: string;
    /**
     * 
     * @type {Product}
     * @memberof UserSubscriptionItem
     */
    'product'?: Product;
    /**
     * 
     * @type {UserSubscription}
     * @memberof UserSubscriptionItem
     */
    'userSubscription'?: UserSubscription;
}
/**
 * 
 * @export
 * @interface UserSubscriptionItemDetailsModel
 */
export interface UserSubscriptionItemDetailsModel {
    /**
     * 
     * @type {string}
     * @memberof UserSubscriptionItemDetailsModel
     */
    'productId'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserSubscriptionItemDetailsModel
     */
    'userSubscriptionItemId'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserSubscriptionItemDetailsModel
     */
    'stripePriceId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserSubscriptionItemDetailsModel
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserSubscriptionItemDetailsModel
     */
    'description'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof UserSubscriptionItemDetailsModel
     */
    'price'?: number;
    /**
     * 
     * @type {string}
     * @memberof UserSubscriptionItemDetailsModel
     */
    'currency'?: string | null;
    /**
     * 
     * @type {BillingPeriodType}
     * @memberof UserSubscriptionItemDetailsModel
     */
    'billingPeriodType'?: BillingPeriodType;
    /**
     * 
     * @type {boolean}
     * @memberof UserSubscriptionItemDetailsModel
     */
    'isMetered'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UserSubscriptionItemDetailsModel
     */
    'freePeriod'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof UserSubscriptionItemDetailsModel
     */
    'features'?: Array<string> | null;
    /**
     * 
     * @type {number}
     * @memberof UserSubscriptionItemDetailsModel
     */
    'sortOrder'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof UserSubscriptionItemDetailsModel
     */
    'isActive'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserSubscriptionItemDetailsModel
     */
    'addon'?: boolean;
}
/**
 * 
 * @export
 * @interface UserSubscriptionItemModel
 */
export interface UserSubscriptionItemModel {
    /**
     * 
     * @type {string}
     * @memberof UserSubscriptionItemModel
     */
    'productId'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserSubscriptionItemModel
     */
    'userSubscriptionId'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserSubscriptionItemModel
     */
    'productName'?: string | null;
}
/**
 * 
 * @export
 * @interface UserSubscriptionLastPaymentInfoModel
 */
export interface UserSubscriptionLastPaymentInfoModel {
    /**
     * 
     * @type {string}
     * @memberof UserSubscriptionLastPaymentInfoModel
     */
    'userId'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserSubscriptionLastPaymentInfoModel
     */
    'stripeSubscriptionId'?: string | null;
    /**
     * 
     * @type {LastPaymentStatus}
     * @memberof UserSubscriptionLastPaymentInfoModel
     */
    'lastPaymentStatus'?: LastPaymentStatus;
    /**
     * 
     * @type {string}
     * @memberof UserSubscriptionLastPaymentInfoModel
     */
    'lastPaymentDescription'?: string | null;
}
/**
 * 
 * @export
 * @interface UserSubscriptionLastPaymentInfoModelIEnumerableIDataResult
 */
export interface UserSubscriptionLastPaymentInfoModelIEnumerableIDataResult {
    /**
     * 
     * @type {Array<UserSubscriptionLastPaymentInfoModel>}
     * @memberof UserSubscriptionLastPaymentInfoModelIEnumerableIDataResult
     */
    'data'?: Array<UserSubscriptionLastPaymentInfoModel> | null;
    /**
     * 
     * @type {boolean}
     * @memberof UserSubscriptionLastPaymentInfoModelIEnumerableIDataResult
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UserSubscriptionLastPaymentInfoModelIEnumerableIDataResult
     */
    'message'?: string | null;
}
/**
 * 
 * @export
 * @interface VerifyEmailRequestModel
 */
export interface VerifyEmailRequestModel {
    /**
     * 
     * @type {string}
     * @memberof VerifyEmailRequestModel
     */
    'm'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof VerifyEmailRequestModel
     */
    'c'?: string | null;
}
/**
 * 
 * @export
 * @interface VersionModel
 */
export interface VersionModel {
    /**
     * 
     * @type {string}
     * @memberof VersionModel
     */
    'tag'?: string | null;
    /**
     * 
     * @type {DateOnlyRequestModel}
     * @memberof VersionModel
     */
    'startDate'?: DateOnlyRequestModel;
}
/**
 * 
 * @export
 * @interface ZipCodeGpciModel
 */
export interface ZipCodeGpciModel {
    /**
     * 
     * @type {string}
     * @memberof ZipCodeGpciModel
     */
    'zipCodeId'?: string;
    /**
     * 
     * @type {string}
     * @memberof ZipCodeGpciModel
     */
    'gpciId'?: string;
    /**
     * 
     * @type {string}
     * @memberof ZipCodeGpciModel
     */
    'zipCode'?: string | null;
    /**
     * 
     * @type {DateOnly}
     * @memberof ZipCodeGpciModel
     */
    'gpciQuarterBeginDate'?: DateOnly;
    /**
     * 
     * @type {string}
     * @memberof ZipCodeGpciModel
     */
    'gpciVersionName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ZipCodeGpciModel
     */
    'zipCodeVersion'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ZipCodeGpciModel
     */
    'medicareAdministrativeContractor'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ZipCodeGpciModel
     */
    'state'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ZipCodeGpciModel
     */
    'localityNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ZipCodeGpciModel
     */
    'localityName'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ZipCodeGpciModel
     */
    'pwGpci'?: number;
    /**
     * 
     * @type {number}
     * @memberof ZipCodeGpciModel
     */
    'peGpci'?: number;
    /**
     * 
     * @type {number}
     * @memberof ZipCodeGpciModel
     */
    'mpGpci'?: number;
}
/**
 * 
 * @export
 * @interface ZipCodeGpciModelIEnumerableIDataResult
 */
export interface ZipCodeGpciModelIEnumerableIDataResult {
    /**
     * 
     * @type {Array<ZipCodeGpciModel>}
     * @memberof ZipCodeGpciModelIEnumerableIDataResult
     */
    'data'?: Array<ZipCodeGpciModel> | null;
    /**
     * 
     * @type {boolean}
     * @memberof ZipCodeGpciModelIEnumerableIDataResult
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ZipCodeGpciModelIEnumerableIDataResult
     */
    'message'?: string | null;
}

/**
 * AccountApi - axios parameter creator
 * @export
 */
export const AccountApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Change cognito password
         * @param {ChangePasswordModel} [changePasswordModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAccountCognitoChangePasswordPut: async (changePasswordModel?: ChangePasswordModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/account/cognito/change-password`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(changePasswordModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Cognito confirm forgot password
         * @param {ConfirmForgotPasswordModel} [confirmForgotPasswordModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAccountCognitoConfirmForgotPasswordPut: async (confirmForgotPasswordModel?: ConfirmForgotPasswordModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/account/cognito/confirm-forgot-password`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(confirmForgotPasswordModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Cognito forgot password
         * @param {ForgotPasswordModel} [forgotPasswordModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAccountCognitoForgotPasswordPut: async (forgotPasswordModel?: ForgotPasswordModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/account/cognito/forgot-password`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(forgotPasswordModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Cognito admin reset user password
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAccountCognitoUserIdAdminResetUserPasswordPut: async (userId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('apiAccountCognitoUserIdAdminResetUserPasswordPut', 'userId', userId)
            const localVarPath = `/api/account/cognito/{userId}/admin-reset-user-password`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get companies
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAccountCompaniesGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/account/companies`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get company by id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAccountCompaniesIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiAccountCompaniesIdGet', 'id', id)
            const localVarPath = `/api/account/companies/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Admin Add Company User
         * @param {string} id 
         * @param {CompanyUserModel} [companyUserModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAccountCompaniesIdUsersPost: async (id: string, companyUserModel?: CompanyUserModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiAccountCompaniesIdUsersPost', 'id', id)
            const localVarPath = `/api/account/companies/{id}/users`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(companyUserModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get company users
         * @param {string} id 
         * @param {boolean} showSubscriptionDetails 
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {string} [searchTerm] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAccountCompaniesIdUsersShowSubscriptionDetailsGet: async (id: string, showSubscriptionDetails: boolean, pageNumber?: number, pageSize?: number, searchTerm?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiAccountCompaniesIdUsersShowSubscriptionDetailsGet', 'id', id)
            // verify required parameter 'showSubscriptionDetails' is not null or undefined
            assertParamExists('apiAccountCompaniesIdUsersShowSubscriptionDetailsGet', 'showSubscriptionDetails', showSubscriptionDetails)
            const localVarPath = `/api/account/companies/{id}/users/{showSubscriptionDetails}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"showSubscriptionDetails"}}`, encodeURIComponent(String(showSubscriptionDetails)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (pageNumber !== undefined) {
                localVarQueryParameter['PageNumber'] = pageNumber;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }

            if (searchTerm !== undefined) {
                localVarQueryParameter['searchTerm'] = searchTerm;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Add company
         * @param {CreateCompanyModel} [createCompanyModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAccountCompaniesPost: async (createCompanyModel?: CreateCompanyModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/account/companies`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createCompanyModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update company
         * @param {CompanyModel} [companyModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAccountCompaniesPut: async (companyModel?: CompanyModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/account/companies`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(companyModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Invite company cognito user
         * @param {string} companyId 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAccountCompanyCompanyIdUserUserIdInvitePut: async (companyId: string, userId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('apiAccountCompanyCompanyIdUserUserIdInvitePut', 'companyId', companyId)
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('apiAccountCompanyCompanyIdUserUserIdInvitePut', 'userId', userId)
            const localVarPath = `/api/account/company/{companyId}/user/{userId}/invite`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)))
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Admin Update Company User
         * @param {string} id 
         * @param {CompanyUserModel} [companyUserModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAccountCompanyIdUserPut: async (id: string, companyUserModel?: CompanyUserModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiAccountCompanyIdUserPut', 'id', id)
            const localVarPath = `/api/account/company/{id}/user`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(companyUserModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Download company logo
         * @param {string} filename 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAccountCompanyLogoFilenameGet: async (filename: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'filename' is not null or undefined
            assertParamExists('apiAccountCompanyLogoFilenameGet', 'filename', filename)
            const localVarPath = `/api/account/company/logo/{filename}`
                .replace(`{${"filename"}}`, encodeURIComponent(String(filename)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Upload company logo
         * @param {File} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAccountCompanyLogoPost: async (file?: File, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/account/company/logo`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Add company user
         * @param {CompanyUserModel} [companyUserModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAccountCompanyUsersPost: async (companyUserModel?: CompanyUserModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/account/company-users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(companyUserModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get user session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAccountMeGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/account/me`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Sign up user and login
         * @param {SignUpUserRequestModel} [signUpUserRequestModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAccountSignUpPost: async (signUpUserRequestModel?: SignUpUserRequestModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/account/sign-up`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(signUpUserRequestModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Invite cognito user by email
         * @param {InviteCognitoUserByEmailModel} [inviteCognitoUserByEmailModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAccountUserInvitePut: async (inviteCognitoUserByEmailModel?: InviteCognitoUserByEmailModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/account/user/invite`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(inviteCognitoUserByEmailModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all users
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAccountUsersAllGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/account/users/all`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get user by cognito user id
         * @param {string} cognitoUserId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAccountUsersCognitoUserIdUserGet: async (cognitoUserId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'cognitoUserId' is not null or undefined
            assertParamExists('apiAccountUsersCognitoUserIdUserGet', 'cognitoUserId', cognitoUserId)
            const localVarPath = `/api/account/users/{cognitoUserId}/user`
                .replace(`{${"cognitoUserId"}}`, encodeURIComponent(String(cognitoUserId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update Cognito User Groups
         * @param {UpdateCognitoUserGroupsModel} [updateCognitoUserGroupsModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAccountUsersGroupsPut: async (updateCognitoUserGroupsModel?: UpdateCognitoUserGroupsModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/account/users/groups`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateCognitoUserGroupsModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Activate user
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAccountUsersIdActivatePut: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiAccountUsersIdActivatePut', 'id', id)
            const localVarPath = `/api/account/users/{id}/activate`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get User\'s company
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAccountUsersIdCompanyGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiAccountUsersIdCompanyGet', 'id', id)
            const localVarPath = `/api/account/users/{id}/company`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Customer\'s Details
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAccountUsersIdCustomerDetailsGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiAccountUsersIdCustomerDetailsGet', 'id', id)
            const localVarPath = `/api/account/users/{id}/customer-details`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Deactivate user
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAccountUsersIdDeactivatePut: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiAccountUsersIdDeactivatePut', 'id', id)
            const localVarPath = `/api/account/users/{id}/deactivate`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete user
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAccountUsersIdDelete: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiAccountUsersIdDelete', 'id', id)
            const localVarPath = `/api/account/users/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Cognito User Groups
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAccountUsersIdGroupsGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiAccountUsersIdGroupsGet', 'id', id)
            const localVarPath = `/api/account/users/{id}/groups`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get user locations by userid
         * @param {string} id 
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {Array<string>} [orderBy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAccountUsersIdLocationsGet: async (id: string, pageNumber?: number, pageSize?: number, orderBy?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiAccountUsersIdLocationsGet', 'id', id)
            const localVarPath = `/api/account/users/{id}/locations`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (pageNumber !== undefined) {
                localVarQueryParameter['PageNumber'] = pageNumber;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }

            if (orderBy) {
                localVarQueryParameter['OrderBy'] = orderBy;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get user\'s login logs by userid
         * @param {string} id 
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {Array<string>} [orderBy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAccountUsersIdLoginLogsGet: async (id: string, pageNumber?: number, pageSize?: number, orderBy?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiAccountUsersIdLoginLogsGet', 'id', id)
            const localVarPath = `/api/account/users/{id}/login-logs`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (pageNumber !== undefined) {
                localVarQueryParameter['PageNumber'] = pageNumber;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }

            if (orderBy) {
                localVarQueryParameter['OrderBy'] = orderBy;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get user\'s profile
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAccountUsersIdProfileGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiAccountUsersIdProfileGet', 'id', id)
            const localVarPath = `/api/account/users/{id}/profile`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get user\'s code notes by userid
         * @param {string} id 
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {Array<string>} [orderBy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAccountUsersIdUserCodeNotesGet: async (id: string, pageNumber?: number, pageSize?: number, orderBy?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiAccountUsersIdUserCodeNotesGet', 'id', id)
            const localVarPath = `/api/account/users/{id}/user-code-notes`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (pageNumber !== undefined) {
                localVarQueryParameter['PageNumber'] = pageNumber;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }

            if (orderBy) {
                localVarQueryParameter['OrderBy'] = orderBy;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get user\'s codes by userid
         * @param {string} id 
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {Array<string>} [orderBy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAccountUsersIdUserCodesGet: async (id: string, pageNumber?: number, pageSize?: number, orderBy?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiAccountUsersIdUserCodesGet', 'id', id)
            const localVarPath = `/api/account/users/{id}/user-codes`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (pageNumber !== undefined) {
                localVarQueryParameter['PageNumber'] = pageNumber;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }

            if (orderBy) {
                localVarQueryParameter['OrderBy'] = orderBy;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Add user
         * @param {CreateUserModel} [createUserModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAccountUsersPost: async (createUserModel?: CreateUserModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/account/users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createUserModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update User
         * @param {UserModel} [userModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAccountUsersPut: async (userModel?: UserModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/account/users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Change user\'s role
         * @param {UserRoleModel} [userRoleModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAccountUsersRolePut: async (userRoleModel?: UserRoleModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/account/users/role`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userRoleModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update User\'s stripe customer id
         * @param {UpdateUserStripeCustomerIdModel} [updateUserStripeCustomerIdModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAccountUsersStripePut: async (updateUserStripeCustomerIdModel?: UpdateUserStripeCustomerIdModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/account/users/stripe`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateUserStripeCustomerIdModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Verify user\'s email
         * @param {VerifyEmailRequestModel} [verifyEmailRequestModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAccountUsersVerifyEmailPut: async (verifyEmailRequestModel?: VerifyEmailRequestModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/account/users/verify-email`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(verifyEmailRequestModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AccountApi - functional programming interface
 * @export
 */
export const AccountApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AccountApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Change cognito password
         * @param {ChangePasswordModel} [changePasswordModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAccountCognitoChangePasswordPut(changePasswordModel?: ChangePasswordModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BooleanIDataResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAccountCognitoChangePasswordPut(changePasswordModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Cognito confirm forgot password
         * @param {ConfirmForgotPasswordModel} [confirmForgotPasswordModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAccountCognitoConfirmForgotPasswordPut(confirmForgotPasswordModel?: ConfirmForgotPasswordModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BooleanIDataResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAccountCognitoConfirmForgotPasswordPut(confirmForgotPasswordModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Cognito forgot password
         * @param {ForgotPasswordModel} [forgotPasswordModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAccountCognitoForgotPasswordPut(forgotPasswordModel?: ForgotPasswordModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ForgotPasswordCodeDeliveryDetailsModelIDataResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAccountCognitoForgotPasswordPut(forgotPasswordModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Cognito admin reset user password
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAccountCognitoUserIdAdminResetUserPasswordPut(userId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BooleanIDataResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAccountCognitoUserIdAdminResetUserPasswordPut(userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get companies
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAccountCompaniesGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompanyModelIEnumerableIDataResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAccountCompaniesGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get company by id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAccountCompaniesIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompanyModelIDataResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAccountCompaniesIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Admin Add Company User
         * @param {string} id 
         * @param {CompanyUserModel} [companyUserModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAccountCompaniesIdUsersPost(id: string, companyUserModel?: CompanyUserModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserModelIDataResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAccountCompaniesIdUsersPost(id, companyUserModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get company users
         * @param {string} id 
         * @param {boolean} showSubscriptionDetails 
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {string} [searchTerm] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAccountCompaniesIdUsersShowSubscriptionDetailsGet(id: string, showSubscriptionDetails: boolean, pageNumber?: number, pageSize?: number, searchTerm?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserModelPagedResultsIDataResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAccountCompaniesIdUsersShowSubscriptionDetailsGet(id, showSubscriptionDetails, pageNumber, pageSize, searchTerm, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Add company
         * @param {CreateCompanyModel} [createCompanyModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAccountCompaniesPost(createCompanyModel?: CreateCompanyModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompanyModelIDataResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAccountCompaniesPost(createCompanyModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update company
         * @param {CompanyModel} [companyModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAccountCompaniesPut(companyModel?: CompanyModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompanyModelIDataResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAccountCompaniesPut(companyModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Invite company cognito user
         * @param {string} companyId 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAccountCompanyCompanyIdUserUserIdInvitePut(companyId: string, userId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BooleanIDataResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAccountCompanyCompanyIdUserUserIdInvitePut(companyId, userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Admin Update Company User
         * @param {string} id 
         * @param {CompanyUserModel} [companyUserModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAccountCompanyIdUserPut(id: string, companyUserModel?: CompanyUserModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserModelIDataResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAccountCompanyIdUserPut(id, companyUserModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Download company logo
         * @param {string} filename 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAccountCompanyLogoFilenameGet(filename: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ByteArrayIDataResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAccountCompanyLogoFilenameGet(filename, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Upload company logo
         * @param {File} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAccountCompanyLogoPost(file?: File, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StringIDataResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAccountCompanyLogoPost(file, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Add company user
         * @param {CompanyUserModel} [companyUserModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAccountCompanyUsersPost(companyUserModel?: CompanyUserModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserModelIDataResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAccountCompanyUsersPost(companyUserModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get user session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAccountMeGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserSessionIDataResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAccountMeGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Sign up user and login
         * @param {SignUpUserRequestModel} [signUpUserRequestModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAccountSignUpPost(signUpUserRequestModel?: SignUpUserRequestModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SignUpUserResponseModelIDataResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAccountSignUpPost(signUpUserRequestModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Invite cognito user by email
         * @param {InviteCognitoUserByEmailModel} [inviteCognitoUserByEmailModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAccountUserInvitePut(inviteCognitoUserByEmailModel?: InviteCognitoUserByEmailModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BooleanIDataResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAccountUserInvitePut(inviteCognitoUserByEmailModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get all users
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAccountUsersAllGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserModelIEnumerableIDataResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAccountUsersAllGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get user by cognito user id
         * @param {string} cognitoUserId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAccountUsersCognitoUserIdUserGet(cognitoUserId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserModelIDataResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAccountUsersCognitoUserIdUserGet(cognitoUserId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update Cognito User Groups
         * @param {UpdateCognitoUserGroupsModel} [updateCognitoUserGroupsModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAccountUsersGroupsPut(updateCognitoUserGroupsModel?: UpdateCognitoUserGroupsModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetCognitoUserGroupsResponseModelIEnumerableIDataResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAccountUsersGroupsPut(updateCognitoUserGroupsModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Activate user
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAccountUsersIdActivatePut(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BooleanIDataResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAccountUsersIdActivatePut(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get User\'s company
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAccountUsersIdCompanyGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompanyModelIDataResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAccountUsersIdCompanyGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Customer\'s Details
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAccountUsersIdCustomerDetailsGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserDetailsModelIDataResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAccountUsersIdCustomerDetailsGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Deactivate user
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAccountUsersIdDeactivatePut(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BooleanIDataResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAccountUsersIdDeactivatePut(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete user
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAccountUsersIdDelete(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BooleanIDataResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAccountUsersIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Cognito User Groups
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAccountUsersIdGroupsGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetCognitoUserGroupsResponseModelIEnumerableIDataResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAccountUsersIdGroupsGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get user locations by userid
         * @param {string} id 
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {Array<string>} [orderBy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAccountUsersIdLocationsGet(id: string, pageNumber?: number, pageSize?: number, orderBy?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserLocationModelPagedResultsIDataResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAccountUsersIdLocationsGet(id, pageNumber, pageSize, orderBy, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get user\'s login logs by userid
         * @param {string} id 
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {Array<string>} [orderBy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAccountUsersIdLoginLogsGet(id: string, pageNumber?: number, pageSize?: number, orderBy?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserLoginLogModelPagedResultsIDataResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAccountUsersIdLoginLogsGet(id, pageNumber, pageSize, orderBy, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get user\'s profile
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAccountUsersIdProfileGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserModelIDataResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAccountUsersIdProfileGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get user\'s code notes by userid
         * @param {string} id 
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {Array<string>} [orderBy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAccountUsersIdUserCodeNotesGet(id: string, pageNumber?: number, pageSize?: number, orderBy?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserCodeNoteModelPagedResultsIDataResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAccountUsersIdUserCodeNotesGet(id, pageNumber, pageSize, orderBy, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get user\'s codes by userid
         * @param {string} id 
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {Array<string>} [orderBy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAccountUsersIdUserCodesGet(id: string, pageNumber?: number, pageSize?: number, orderBy?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserCodeModelPagedResultsIDataResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAccountUsersIdUserCodesGet(id, pageNumber, pageSize, orderBy, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Add user
         * @param {CreateUserModel} [createUserModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAccountUsersPost(createUserModel?: CreateUserModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserModelIDataResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAccountUsersPost(createUserModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update User
         * @param {UserModel} [userModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAccountUsersPut(userModel?: UserModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserModelIDataResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAccountUsersPut(userModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Change user\'s role
         * @param {UserRoleModel} [userRoleModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAccountUsersRolePut(userRoleModel?: UserRoleModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserModelIDataResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAccountUsersRolePut(userRoleModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update User\'s stripe customer id
         * @param {UpdateUserStripeCustomerIdModel} [updateUserStripeCustomerIdModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAccountUsersStripePut(updateUserStripeCustomerIdModel?: UpdateUserStripeCustomerIdModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BooleanIDataResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAccountUsersStripePut(updateUserStripeCustomerIdModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Verify user\'s email
         * @param {VerifyEmailRequestModel} [verifyEmailRequestModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAccountUsersVerifyEmailPut(verifyEmailRequestModel?: VerifyEmailRequestModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BooleanIDataResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAccountUsersVerifyEmailPut(verifyEmailRequestModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AccountApi - factory interface
 * @export
 */
export const AccountApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AccountApiFp(configuration)
    return {
        /**
         * 
         * @summary Change cognito password
         * @param {ChangePasswordModel} [changePasswordModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAccountCognitoChangePasswordPut(changePasswordModel?: ChangePasswordModel, options?: any): AxiosPromise<BooleanIDataResult> {
            return localVarFp.apiAccountCognitoChangePasswordPut(changePasswordModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Cognito confirm forgot password
         * @param {ConfirmForgotPasswordModel} [confirmForgotPasswordModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAccountCognitoConfirmForgotPasswordPut(confirmForgotPasswordModel?: ConfirmForgotPasswordModel, options?: any): AxiosPromise<BooleanIDataResult> {
            return localVarFp.apiAccountCognitoConfirmForgotPasswordPut(confirmForgotPasswordModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Cognito forgot password
         * @param {ForgotPasswordModel} [forgotPasswordModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAccountCognitoForgotPasswordPut(forgotPasswordModel?: ForgotPasswordModel, options?: any): AxiosPromise<ForgotPasswordCodeDeliveryDetailsModelIDataResult> {
            return localVarFp.apiAccountCognitoForgotPasswordPut(forgotPasswordModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Cognito admin reset user password
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAccountCognitoUserIdAdminResetUserPasswordPut(userId: string, options?: any): AxiosPromise<BooleanIDataResult> {
            return localVarFp.apiAccountCognitoUserIdAdminResetUserPasswordPut(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get companies
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAccountCompaniesGet(options?: any): AxiosPromise<CompanyModelIEnumerableIDataResult> {
            return localVarFp.apiAccountCompaniesGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get company by id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAccountCompaniesIdGet(id: string, options?: any): AxiosPromise<CompanyModelIDataResult> {
            return localVarFp.apiAccountCompaniesIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Admin Add Company User
         * @param {string} id 
         * @param {CompanyUserModel} [companyUserModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAccountCompaniesIdUsersPost(id: string, companyUserModel?: CompanyUserModel, options?: any): AxiosPromise<UserModelIDataResult> {
            return localVarFp.apiAccountCompaniesIdUsersPost(id, companyUserModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get company users
         * @param {string} id 
         * @param {boolean} showSubscriptionDetails 
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {string} [searchTerm] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAccountCompaniesIdUsersShowSubscriptionDetailsGet(id: string, showSubscriptionDetails: boolean, pageNumber?: number, pageSize?: number, searchTerm?: string, options?: any): AxiosPromise<UserModelPagedResultsIDataResult> {
            return localVarFp.apiAccountCompaniesIdUsersShowSubscriptionDetailsGet(id, showSubscriptionDetails, pageNumber, pageSize, searchTerm, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Add company
         * @param {CreateCompanyModel} [createCompanyModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAccountCompaniesPost(createCompanyModel?: CreateCompanyModel, options?: any): AxiosPromise<CompanyModelIDataResult> {
            return localVarFp.apiAccountCompaniesPost(createCompanyModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update company
         * @param {CompanyModel} [companyModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAccountCompaniesPut(companyModel?: CompanyModel, options?: any): AxiosPromise<CompanyModelIDataResult> {
            return localVarFp.apiAccountCompaniesPut(companyModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Invite company cognito user
         * @param {string} companyId 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAccountCompanyCompanyIdUserUserIdInvitePut(companyId: string, userId: string, options?: any): AxiosPromise<BooleanIDataResult> {
            return localVarFp.apiAccountCompanyCompanyIdUserUserIdInvitePut(companyId, userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Admin Update Company User
         * @param {string} id 
         * @param {CompanyUserModel} [companyUserModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAccountCompanyIdUserPut(id: string, companyUserModel?: CompanyUserModel, options?: any): AxiosPromise<UserModelIDataResult> {
            return localVarFp.apiAccountCompanyIdUserPut(id, companyUserModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Download company logo
         * @param {string} filename 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAccountCompanyLogoFilenameGet(filename: string, options?: any): AxiosPromise<ByteArrayIDataResult> {
            return localVarFp.apiAccountCompanyLogoFilenameGet(filename, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Upload company logo
         * @param {File} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAccountCompanyLogoPost(file?: File, options?: any): AxiosPromise<StringIDataResult> {
            return localVarFp.apiAccountCompanyLogoPost(file, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Add company user
         * @param {CompanyUserModel} [companyUserModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAccountCompanyUsersPost(companyUserModel?: CompanyUserModel, options?: any): AxiosPromise<UserModelIDataResult> {
            return localVarFp.apiAccountCompanyUsersPost(companyUserModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get user session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAccountMeGet(options?: any): AxiosPromise<UserSessionIDataResult> {
            return localVarFp.apiAccountMeGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Sign up user and login
         * @param {SignUpUserRequestModel} [signUpUserRequestModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAccountSignUpPost(signUpUserRequestModel?: SignUpUserRequestModel, options?: any): AxiosPromise<SignUpUserResponseModelIDataResult> {
            return localVarFp.apiAccountSignUpPost(signUpUserRequestModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Invite cognito user by email
         * @param {InviteCognitoUserByEmailModel} [inviteCognitoUserByEmailModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAccountUserInvitePut(inviteCognitoUserByEmailModel?: InviteCognitoUserByEmailModel, options?: any): AxiosPromise<BooleanIDataResult> {
            return localVarFp.apiAccountUserInvitePut(inviteCognitoUserByEmailModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get all users
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAccountUsersAllGet(options?: any): AxiosPromise<UserModelIEnumerableIDataResult> {
            return localVarFp.apiAccountUsersAllGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get user by cognito user id
         * @param {string} cognitoUserId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAccountUsersCognitoUserIdUserGet(cognitoUserId: string, options?: any): AxiosPromise<UserModelIDataResult> {
            return localVarFp.apiAccountUsersCognitoUserIdUserGet(cognitoUserId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update Cognito User Groups
         * @param {UpdateCognitoUserGroupsModel} [updateCognitoUserGroupsModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAccountUsersGroupsPut(updateCognitoUserGroupsModel?: UpdateCognitoUserGroupsModel, options?: any): AxiosPromise<GetCognitoUserGroupsResponseModelIEnumerableIDataResult> {
            return localVarFp.apiAccountUsersGroupsPut(updateCognitoUserGroupsModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Activate user
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAccountUsersIdActivatePut(id: string, options?: any): AxiosPromise<BooleanIDataResult> {
            return localVarFp.apiAccountUsersIdActivatePut(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get User\'s company
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAccountUsersIdCompanyGet(id: string, options?: any): AxiosPromise<CompanyModelIDataResult> {
            return localVarFp.apiAccountUsersIdCompanyGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Customer\'s Details
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAccountUsersIdCustomerDetailsGet(id: string, options?: any): AxiosPromise<UserDetailsModelIDataResult> {
            return localVarFp.apiAccountUsersIdCustomerDetailsGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Deactivate user
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAccountUsersIdDeactivatePut(id: string, options?: any): AxiosPromise<BooleanIDataResult> {
            return localVarFp.apiAccountUsersIdDeactivatePut(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete user
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAccountUsersIdDelete(id: string, options?: any): AxiosPromise<BooleanIDataResult> {
            return localVarFp.apiAccountUsersIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Cognito User Groups
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAccountUsersIdGroupsGet(id: string, options?: any): AxiosPromise<GetCognitoUserGroupsResponseModelIEnumerableIDataResult> {
            return localVarFp.apiAccountUsersIdGroupsGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get user locations by userid
         * @param {string} id 
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {Array<string>} [orderBy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAccountUsersIdLocationsGet(id: string, pageNumber?: number, pageSize?: number, orderBy?: Array<string>, options?: any): AxiosPromise<UserLocationModelPagedResultsIDataResult> {
            return localVarFp.apiAccountUsersIdLocationsGet(id, pageNumber, pageSize, orderBy, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get user\'s login logs by userid
         * @param {string} id 
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {Array<string>} [orderBy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAccountUsersIdLoginLogsGet(id: string, pageNumber?: number, pageSize?: number, orderBy?: Array<string>, options?: any): AxiosPromise<UserLoginLogModelPagedResultsIDataResult> {
            return localVarFp.apiAccountUsersIdLoginLogsGet(id, pageNumber, pageSize, orderBy, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get user\'s profile
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAccountUsersIdProfileGet(id: string, options?: any): AxiosPromise<UserModelIDataResult> {
            return localVarFp.apiAccountUsersIdProfileGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get user\'s code notes by userid
         * @param {string} id 
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {Array<string>} [orderBy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAccountUsersIdUserCodeNotesGet(id: string, pageNumber?: number, pageSize?: number, orderBy?: Array<string>, options?: any): AxiosPromise<UserCodeNoteModelPagedResultsIDataResult> {
            return localVarFp.apiAccountUsersIdUserCodeNotesGet(id, pageNumber, pageSize, orderBy, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get user\'s codes by userid
         * @param {string} id 
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {Array<string>} [orderBy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAccountUsersIdUserCodesGet(id: string, pageNumber?: number, pageSize?: number, orderBy?: Array<string>, options?: any): AxiosPromise<UserCodeModelPagedResultsIDataResult> {
            return localVarFp.apiAccountUsersIdUserCodesGet(id, pageNumber, pageSize, orderBy, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Add user
         * @param {CreateUserModel} [createUserModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAccountUsersPost(createUserModel?: CreateUserModel, options?: any): AxiosPromise<UserModelIDataResult> {
            return localVarFp.apiAccountUsersPost(createUserModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update User
         * @param {UserModel} [userModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAccountUsersPut(userModel?: UserModel, options?: any): AxiosPromise<UserModelIDataResult> {
            return localVarFp.apiAccountUsersPut(userModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Change user\'s role
         * @param {UserRoleModel} [userRoleModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAccountUsersRolePut(userRoleModel?: UserRoleModel, options?: any): AxiosPromise<UserModelIDataResult> {
            return localVarFp.apiAccountUsersRolePut(userRoleModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update User\'s stripe customer id
         * @param {UpdateUserStripeCustomerIdModel} [updateUserStripeCustomerIdModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAccountUsersStripePut(updateUserStripeCustomerIdModel?: UpdateUserStripeCustomerIdModel, options?: any): AxiosPromise<BooleanIDataResult> {
            return localVarFp.apiAccountUsersStripePut(updateUserStripeCustomerIdModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Verify user\'s email
         * @param {VerifyEmailRequestModel} [verifyEmailRequestModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAccountUsersVerifyEmailPut(verifyEmailRequestModel?: VerifyEmailRequestModel, options?: any): AxiosPromise<BooleanIDataResult> {
            return localVarFp.apiAccountUsersVerifyEmailPut(verifyEmailRequestModel, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AccountApi - object-oriented interface
 * @export
 * @class AccountApi
 * @extends {BaseAPI}
 */
export class AccountApi extends BaseAPI {
    /**
     * 
     * @summary Change cognito password
     * @param {ChangePasswordModel} [changePasswordModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public apiAccountCognitoChangePasswordPut(changePasswordModel?: ChangePasswordModel, options?: AxiosRequestConfig) {
        return AccountApiFp(this.configuration).apiAccountCognitoChangePasswordPut(changePasswordModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Cognito confirm forgot password
     * @param {ConfirmForgotPasswordModel} [confirmForgotPasswordModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public apiAccountCognitoConfirmForgotPasswordPut(confirmForgotPasswordModel?: ConfirmForgotPasswordModel, options?: AxiosRequestConfig) {
        return AccountApiFp(this.configuration).apiAccountCognitoConfirmForgotPasswordPut(confirmForgotPasswordModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Cognito forgot password
     * @param {ForgotPasswordModel} [forgotPasswordModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public apiAccountCognitoForgotPasswordPut(forgotPasswordModel?: ForgotPasswordModel, options?: AxiosRequestConfig) {
        return AccountApiFp(this.configuration).apiAccountCognitoForgotPasswordPut(forgotPasswordModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Cognito admin reset user password
     * @param {string} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public apiAccountCognitoUserIdAdminResetUserPasswordPut(userId: string, options?: AxiosRequestConfig) {
        return AccountApiFp(this.configuration).apiAccountCognitoUserIdAdminResetUserPasswordPut(userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get companies
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public apiAccountCompaniesGet(options?: AxiosRequestConfig) {
        return AccountApiFp(this.configuration).apiAccountCompaniesGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get company by id
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public apiAccountCompaniesIdGet(id: string, options?: AxiosRequestConfig) {
        return AccountApiFp(this.configuration).apiAccountCompaniesIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Admin Add Company User
     * @param {string} id 
     * @param {CompanyUserModel} [companyUserModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public apiAccountCompaniesIdUsersPost(id: string, companyUserModel?: CompanyUserModel, options?: AxiosRequestConfig) {
        return AccountApiFp(this.configuration).apiAccountCompaniesIdUsersPost(id, companyUserModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get company users
     * @param {string} id 
     * @param {boolean} showSubscriptionDetails 
     * @param {number} [pageNumber] 
     * @param {number} [pageSize] 
     * @param {string} [searchTerm] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public apiAccountCompaniesIdUsersShowSubscriptionDetailsGet(id: string, showSubscriptionDetails: boolean, pageNumber?: number, pageSize?: number, searchTerm?: string, options?: AxiosRequestConfig) {
        return AccountApiFp(this.configuration).apiAccountCompaniesIdUsersShowSubscriptionDetailsGet(id, showSubscriptionDetails, pageNumber, pageSize, searchTerm, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Add company
     * @param {CreateCompanyModel} [createCompanyModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public apiAccountCompaniesPost(createCompanyModel?: CreateCompanyModel, options?: AxiosRequestConfig) {
        return AccountApiFp(this.configuration).apiAccountCompaniesPost(createCompanyModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update company
     * @param {CompanyModel} [companyModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public apiAccountCompaniesPut(companyModel?: CompanyModel, options?: AxiosRequestConfig) {
        return AccountApiFp(this.configuration).apiAccountCompaniesPut(companyModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Invite company cognito user
     * @param {string} companyId 
     * @param {string} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public apiAccountCompanyCompanyIdUserUserIdInvitePut(companyId: string, userId: string, options?: AxiosRequestConfig) {
        return AccountApiFp(this.configuration).apiAccountCompanyCompanyIdUserUserIdInvitePut(companyId, userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Admin Update Company User
     * @param {string} id 
     * @param {CompanyUserModel} [companyUserModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public apiAccountCompanyIdUserPut(id: string, companyUserModel?: CompanyUserModel, options?: AxiosRequestConfig) {
        return AccountApiFp(this.configuration).apiAccountCompanyIdUserPut(id, companyUserModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Download company logo
     * @param {string} filename 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public apiAccountCompanyLogoFilenameGet(filename: string, options?: AxiosRequestConfig) {
        return AccountApiFp(this.configuration).apiAccountCompanyLogoFilenameGet(filename, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Upload company logo
     * @param {File} [file] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public apiAccountCompanyLogoPost(file?: File, options?: AxiosRequestConfig) {
        return AccountApiFp(this.configuration).apiAccountCompanyLogoPost(file, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Add company user
     * @param {CompanyUserModel} [companyUserModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public apiAccountCompanyUsersPost(companyUserModel?: CompanyUserModel, options?: AxiosRequestConfig) {
        return AccountApiFp(this.configuration).apiAccountCompanyUsersPost(companyUserModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get user session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public apiAccountMeGet(options?: AxiosRequestConfig) {
        return AccountApiFp(this.configuration).apiAccountMeGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Sign up user and login
     * @param {SignUpUserRequestModel} [signUpUserRequestModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public apiAccountSignUpPost(signUpUserRequestModel?: SignUpUserRequestModel, options?: AxiosRequestConfig) {
        return AccountApiFp(this.configuration).apiAccountSignUpPost(signUpUserRequestModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Invite cognito user by email
     * @param {InviteCognitoUserByEmailModel} [inviteCognitoUserByEmailModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public apiAccountUserInvitePut(inviteCognitoUserByEmailModel?: InviteCognitoUserByEmailModel, options?: AxiosRequestConfig) {
        return AccountApiFp(this.configuration).apiAccountUserInvitePut(inviteCognitoUserByEmailModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get all users
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public apiAccountUsersAllGet(options?: AxiosRequestConfig) {
        return AccountApiFp(this.configuration).apiAccountUsersAllGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get user by cognito user id
     * @param {string} cognitoUserId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public apiAccountUsersCognitoUserIdUserGet(cognitoUserId: string, options?: AxiosRequestConfig) {
        return AccountApiFp(this.configuration).apiAccountUsersCognitoUserIdUserGet(cognitoUserId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update Cognito User Groups
     * @param {UpdateCognitoUserGroupsModel} [updateCognitoUserGroupsModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public apiAccountUsersGroupsPut(updateCognitoUserGroupsModel?: UpdateCognitoUserGroupsModel, options?: AxiosRequestConfig) {
        return AccountApiFp(this.configuration).apiAccountUsersGroupsPut(updateCognitoUserGroupsModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Activate user
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public apiAccountUsersIdActivatePut(id: string, options?: AxiosRequestConfig) {
        return AccountApiFp(this.configuration).apiAccountUsersIdActivatePut(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get User\'s company
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public apiAccountUsersIdCompanyGet(id: string, options?: AxiosRequestConfig) {
        return AccountApiFp(this.configuration).apiAccountUsersIdCompanyGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Customer\'s Details
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public apiAccountUsersIdCustomerDetailsGet(id: string, options?: AxiosRequestConfig) {
        return AccountApiFp(this.configuration).apiAccountUsersIdCustomerDetailsGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Deactivate user
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public apiAccountUsersIdDeactivatePut(id: string, options?: AxiosRequestConfig) {
        return AccountApiFp(this.configuration).apiAccountUsersIdDeactivatePut(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete user
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public apiAccountUsersIdDelete(id: string, options?: AxiosRequestConfig) {
        return AccountApiFp(this.configuration).apiAccountUsersIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Cognito User Groups
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public apiAccountUsersIdGroupsGet(id: string, options?: AxiosRequestConfig) {
        return AccountApiFp(this.configuration).apiAccountUsersIdGroupsGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get user locations by userid
     * @param {string} id 
     * @param {number} [pageNumber] 
     * @param {number} [pageSize] 
     * @param {Array<string>} [orderBy] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public apiAccountUsersIdLocationsGet(id: string, pageNumber?: number, pageSize?: number, orderBy?: Array<string>, options?: AxiosRequestConfig) {
        return AccountApiFp(this.configuration).apiAccountUsersIdLocationsGet(id, pageNumber, pageSize, orderBy, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get user\'s login logs by userid
     * @param {string} id 
     * @param {number} [pageNumber] 
     * @param {number} [pageSize] 
     * @param {Array<string>} [orderBy] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public apiAccountUsersIdLoginLogsGet(id: string, pageNumber?: number, pageSize?: number, orderBy?: Array<string>, options?: AxiosRequestConfig) {
        return AccountApiFp(this.configuration).apiAccountUsersIdLoginLogsGet(id, pageNumber, pageSize, orderBy, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get user\'s profile
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public apiAccountUsersIdProfileGet(id: string, options?: AxiosRequestConfig) {
        return AccountApiFp(this.configuration).apiAccountUsersIdProfileGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get user\'s code notes by userid
     * @param {string} id 
     * @param {number} [pageNumber] 
     * @param {number} [pageSize] 
     * @param {Array<string>} [orderBy] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public apiAccountUsersIdUserCodeNotesGet(id: string, pageNumber?: number, pageSize?: number, orderBy?: Array<string>, options?: AxiosRequestConfig) {
        return AccountApiFp(this.configuration).apiAccountUsersIdUserCodeNotesGet(id, pageNumber, pageSize, orderBy, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get user\'s codes by userid
     * @param {string} id 
     * @param {number} [pageNumber] 
     * @param {number} [pageSize] 
     * @param {Array<string>} [orderBy] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public apiAccountUsersIdUserCodesGet(id: string, pageNumber?: number, pageSize?: number, orderBy?: Array<string>, options?: AxiosRequestConfig) {
        return AccountApiFp(this.configuration).apiAccountUsersIdUserCodesGet(id, pageNumber, pageSize, orderBy, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Add user
     * @param {CreateUserModel} [createUserModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public apiAccountUsersPost(createUserModel?: CreateUserModel, options?: AxiosRequestConfig) {
        return AccountApiFp(this.configuration).apiAccountUsersPost(createUserModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update User
     * @param {UserModel} [userModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public apiAccountUsersPut(userModel?: UserModel, options?: AxiosRequestConfig) {
        return AccountApiFp(this.configuration).apiAccountUsersPut(userModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Change user\'s role
     * @param {UserRoleModel} [userRoleModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public apiAccountUsersRolePut(userRoleModel?: UserRoleModel, options?: AxiosRequestConfig) {
        return AccountApiFp(this.configuration).apiAccountUsersRolePut(userRoleModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update User\'s stripe customer id
     * @param {UpdateUserStripeCustomerIdModel} [updateUserStripeCustomerIdModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public apiAccountUsersStripePut(updateUserStripeCustomerIdModel?: UpdateUserStripeCustomerIdModel, options?: AxiosRequestConfig) {
        return AccountApiFp(this.configuration).apiAccountUsersStripePut(updateUserStripeCustomerIdModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Verify user\'s email
     * @param {VerifyEmailRequestModel} [verifyEmailRequestModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public apiAccountUsersVerifyEmailPut(verifyEmailRequestModel?: VerifyEmailRequestModel, options?: AxiosRequestConfig) {
        return AccountApiFp(this.configuration).apiAccountUsersVerifyEmailPut(verifyEmailRequestModel, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * CalculatorApi - axios parameter creator
 * @export
 */
export const CalculatorApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {ConflictsRequestModel} [conflictsRequestModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiNcciCalculatorConflictsAsHtmlPost: async (conflictsRequestModel?: ConflictsRequestModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/ncci/calculator/conflicts/as-html`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(conflictsRequestModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ConflictsRequestModel} [conflictsRequestModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiNcciCalculatorConflictsAsPdfPost: async (conflictsRequestModel?: ConflictsRequestModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/ncci/calculator/conflicts/as-pdf`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(conflictsRequestModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ConflictsRequestModel} [conflictsRequestModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiNcciCalculatorConflictsPost: async (conflictsRequestModel?: ConflictsRequestModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/ncci/calculator/conflicts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(conflictsRequestModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CalculatorApi - functional programming interface
 * @export
 */
export const CalculatorApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CalculatorApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {ConflictsRequestModel} [conflictsRequestModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiNcciCalculatorConflictsAsHtmlPost(conflictsRequestModel?: ConflictsRequestModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ConflictsResponseModelIEnumerableIDataResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiNcciCalculatorConflictsAsHtmlPost(conflictsRequestModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ConflictsRequestModel} [conflictsRequestModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiNcciCalculatorConflictsAsPdfPost(conflictsRequestModel?: ConflictsRequestModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ConflictsResponseModelIEnumerableIDataResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiNcciCalculatorConflictsAsPdfPost(conflictsRequestModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ConflictsRequestModel} [conflictsRequestModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiNcciCalculatorConflictsPost(conflictsRequestModel?: ConflictsRequestModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ConflictsResponseModelIEnumerableIDataResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiNcciCalculatorConflictsPost(conflictsRequestModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CalculatorApi - factory interface
 * @export
 */
export const CalculatorApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CalculatorApiFp(configuration)
    return {
        /**
         * 
         * @param {ConflictsRequestModel} [conflictsRequestModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiNcciCalculatorConflictsAsHtmlPost(conflictsRequestModel?: ConflictsRequestModel, options?: any): AxiosPromise<ConflictsResponseModelIEnumerableIDataResult> {
            return localVarFp.apiNcciCalculatorConflictsAsHtmlPost(conflictsRequestModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ConflictsRequestModel} [conflictsRequestModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiNcciCalculatorConflictsAsPdfPost(conflictsRequestModel?: ConflictsRequestModel, options?: any): AxiosPromise<ConflictsResponseModelIEnumerableIDataResult> {
            return localVarFp.apiNcciCalculatorConflictsAsPdfPost(conflictsRequestModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ConflictsRequestModel} [conflictsRequestModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiNcciCalculatorConflictsPost(conflictsRequestModel?: ConflictsRequestModel, options?: any): AxiosPromise<ConflictsResponseModelIEnumerableIDataResult> {
            return localVarFp.apiNcciCalculatorConflictsPost(conflictsRequestModel, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CalculatorApi - object-oriented interface
 * @export
 * @class CalculatorApi
 * @extends {BaseAPI}
 */
export class CalculatorApi extends BaseAPI {
    /**
     * 
     * @param {ConflictsRequestModel} [conflictsRequestModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CalculatorApi
     */
    public apiNcciCalculatorConflictsAsHtmlPost(conflictsRequestModel?: ConflictsRequestModel, options?: AxiosRequestConfig) {
        return CalculatorApiFp(this.configuration).apiNcciCalculatorConflictsAsHtmlPost(conflictsRequestModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ConflictsRequestModel} [conflictsRequestModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CalculatorApi
     */
    public apiNcciCalculatorConflictsAsPdfPost(conflictsRequestModel?: ConflictsRequestModel, options?: AxiosRequestConfig) {
        return CalculatorApiFp(this.configuration).apiNcciCalculatorConflictsAsPdfPost(conflictsRequestModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ConflictsRequestModel} [conflictsRequestModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CalculatorApi
     */
    public apiNcciCalculatorConflictsPost(conflictsRequestModel?: ConflictsRequestModel, options?: AxiosRequestConfig) {
        return CalculatorApiFp(this.configuration).apiNcciCalculatorConflictsPost(conflictsRequestModel, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * CodesApi - axios parameter creator
 * @export
 */
export const CodesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCodesBetosHierarchicalListGet: async (id?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/codes/betos-hierarchical-list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [rvuId] 
         * @param {string} [gpciId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCodesCalculateRvuGet: async (rvuId?: string, gpciId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/codes/calculate-rvu`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (rvuId !== undefined) {
                localVarQueryParameter['RvuId'] = rvuId;
            }

            if (gpciId !== undefined) {
                localVarQueryParameter['GpciId'] = gpciId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} code 
         * @param {string} [quarterBeginDate] 
         * @param {string} [gpciId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCodesCodeCodeRvuValuesGet: async (code: string, quarterBeginDate?: string, gpciId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'code' is not null or undefined
            assertParamExists('apiCodesCodeCodeRvuValuesGet', 'code', code)
            const localVarPath = `/api/codes/code/{code}/rvu-values`
                .replace(`{${"code"}}`, encodeURIComponent(String(code)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (quarterBeginDate !== undefined) {
                localVarQueryParameter['quarterBeginDate'] = (quarterBeginDate as any instanceof Date) ?
                    (quarterBeginDate as any).toISOString() :
                    quarterBeginDate;
            }

            if (gpciId !== undefined) {
                localVarQueryParameter['gpciId'] = gpciId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} code 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCodesCptCodeGet: async (code: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'code' is not null or undefined
            assertParamExists('apiCodesCptCodeGet', 'code', code)
            const localVarPath = `/api/codes/cpt/{code}`
                .replace(`{${"code"}}`, encodeURIComponent(String(code)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [quarterBeginDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCodesGpcisGet: async (quarterBeginDate?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/codes/gpcis`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (quarterBeginDate !== undefined) {
                localVarQueryParameter['quarterBeginDate'] = (quarterBeginDate as any instanceof Date) ?
                    (quarterBeginDate as any).toISOString() :
                    quarterBeginDate;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [betosGroup] 
         * @param {string} [quarterBeginDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCodesHcpcsBetosGroupCodesGet: async (betosGroup?: string, quarterBeginDate?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/codes/hcpcs-betos-group-codes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (betosGroup !== undefined) {
                localVarQueryParameter['betosGroup'] = betosGroup;
            }

            if (quarterBeginDate !== undefined) {
                localVarQueryParameter['quarterBeginDate'] = (quarterBeginDate as any instanceof Date) ?
                    (quarterBeginDate as any).toISOString() :
                    quarterBeginDate;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {HCPCSChangedStatusType} [type] 
         * @param {string} [quarterBeginDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCodesHcpcsChangedCodesGet: async (pageNumber?: number, pageSize?: number, type?: HCPCSChangedStatusType, quarterBeginDate?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/codes/hcpcs-changed-codes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (pageNumber !== undefined) {
                localVarQueryParameter['PageNumber'] = pageNumber;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }

            if (quarterBeginDate !== undefined) {
                localVarQueryParameter['quarterBeginDate'] = (quarterBeginDate as any instanceof Date) ?
                    (quarterBeginDate as any).toISOString() :
                    quarterBeginDate;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} code 
         * @param {string} [quarterBeginDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCodesHcpcsCodeGet: async (code: string, quarterBeginDate?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'code' is not null or undefined
            assertParamExists('apiCodesHcpcsCodeGet', 'code', code)
            const localVarPath = `/api/codes/hcpcs/{code}`
                .replace(`{${"code"}}`, encodeURIComponent(String(code)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (quarterBeginDate !== undefined) {
                localVarQueryParameter['quarterBeginDate'] = (quarterBeginDate as any instanceof Date) ?
                    (quarterBeginDate as any).toISOString() :
                    quarterBeginDate;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} code 
         * @param {string} [quarterBeginDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCodesHcpcsModifiersCodeGet: async (code: string, quarterBeginDate?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'code' is not null or undefined
            assertParamExists('apiCodesHcpcsModifiersCodeGet', 'code', code)
            const localVarPath = `/api/codes/hcpcs-modifiers/{code}`
                .replace(`{${"code"}}`, encodeURIComponent(String(code)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (quarterBeginDate !== undefined) {
                localVarQueryParameter['quarterBeginDate'] = (quarterBeginDate as any instanceof Date) ?
                    (quarterBeginDate as any).toISOString() :
                    quarterBeginDate;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {string} [quarterBeginDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCodesHcpcsmodifierActiveListGet: async (pageNumber?: number, pageSize?: number, quarterBeginDate?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/codes/hcpcsmodifier-active-list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (pageNumber !== undefined) {
                localVarQueryParameter['PageNumber'] = pageNumber;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }

            if (quarterBeginDate !== undefined) {
                localVarQueryParameter['quarterBeginDate'] = (quarterBeginDate as any instanceof Date) ?
                    (quarterBeginDate as any).toISOString() :
                    quarterBeginDate;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {string} [quarterBeginDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCodesHcpcsmodifierChangedDeletedGet: async (pageNumber?: number, pageSize?: number, quarterBeginDate?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/codes/hcpcsmodifier-changed-deleted`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (pageNumber !== undefined) {
                localVarQueryParameter['PageNumber'] = pageNumber;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }

            if (quarterBeginDate !== undefined) {
                localVarQueryParameter['quarterBeginDate'] = (quarterBeginDate as any instanceof Date) ?
                    (quarterBeginDate as any).toISOString() :
                    quarterBeginDate;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {ICD10AddendaStatusType} [type] 
         * @param {string} [quarterBeginDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCodesIcd10CmAddendasGet: async (pageNumber?: number, pageSize?: number, type?: ICD10AddendaStatusType, quarterBeginDate?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/codes/icd-10-cm-addendas`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (pageNumber !== undefined) {
                localVarQueryParameter['PageNumber'] = pageNumber;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }

            if (quarterBeginDate !== undefined) {
                localVarQueryParameter['quarterBeginDate'] = (quarterBeginDate as any instanceof Date) ?
                    (quarterBeginDate as any).toISOString() :
                    quarterBeginDate;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [quarterBeginDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCodesIcd10CmChaptersGet: async (quarterBeginDate?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/codes/icd-10-cm-chapters`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (quarterBeginDate !== undefined) {
                localVarQueryParameter['quarterBeginDate'] = (quarterBeginDate as any instanceof Date) ?
                    (quarterBeginDate as any).toISOString() :
                    quarterBeginDate;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} code 
         * @param {string} [quarterBeginDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCodesIcd10CmCodeGet: async (code: string, quarterBeginDate?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'code' is not null or undefined
            assertParamExists('apiCodesIcd10CmCodeGet', 'code', code)
            const localVarPath = `/api/codes/icd-10-cm/{code}`
                .replace(`{${"code"}}`, encodeURIComponent(String(code)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (quarterBeginDate !== undefined) {
                localVarQueryParameter['quarterBeginDate'] = (quarterBeginDate as any instanceof Date) ?
                    (quarterBeginDate as any).toISOString() :
                    quarterBeginDate;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCodesIcd10CmHierarchicalListGet: async (id?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/codes/icd-10-cm-hierarchical-list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [sectionId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCodesIcd10CmSectionCodesGet: async (sectionId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/codes/icd-10-cm-section-codes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (sectionId !== undefined) {
                localVarQueryParameter['sectionId'] = sectionId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [chapterId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCodesIcd10CmSectionsGet: async (chapterId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/codes/icd-10-cm-sections`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (chapterId !== undefined) {
                localVarQueryParameter['chapterId'] = chapterId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {ICD10AddendaStatusType} [type] 
         * @param {string} [quarterBeginDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCodesIcd10PcsAddendasGet: async (pageNumber?: number, pageSize?: number, type?: ICD10AddendaStatusType, quarterBeginDate?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/codes/icd-10-pcs-addendas`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (pageNumber !== undefined) {
                localVarQueryParameter['PageNumber'] = pageNumber;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }

            if (quarterBeginDate !== undefined) {
                localVarQueryParameter['quarterBeginDate'] = (quarterBeginDate as any instanceof Date) ?
                    (quarterBeginDate as any).toISOString() :
                    quarterBeginDate;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} code 
         * @param {string} [quarterBeginDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCodesIcd10PcsCodeGet: async (code: string, quarterBeginDate?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'code' is not null or undefined
            assertParamExists('apiCodesIcd10PcsCodeGet', 'code', code)
            const localVarPath = `/api/codes/icd-10-pcs/{code}`
                .replace(`{${"code"}}`, encodeURIComponent(String(code)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (quarterBeginDate !== undefined) {
                localVarQueryParameter['quarterBeginDate'] = (quarterBeginDate as any instanceof Date) ?
                    (quarterBeginDate as any).toISOString() :
                    quarterBeginDate;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [id] 
         * @param {string} [quarterBeginDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCodesIcd10PcsHierarchicalListGet: async (id?: string, quarterBeginDate?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/codes/icd-10-pcs-hierarchical-list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            if (quarterBeginDate !== undefined) {
                localVarQueryParameter['quarterBeginDate'] = (quarterBeginDate as any instanceof Date) ?
                    (quarterBeginDate as any).toISOString() :
                    quarterBeginDate;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} code 
         * @param {string} [quarterBeginDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCodesIcd11CodeGet: async (code: string, quarterBeginDate?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'code' is not null or undefined
            assertParamExists('apiCodesIcd11CodeGet', 'code', code)
            const localVarPath = `/api/codes/icd-11/{code}`
                .replace(`{${"code"}}`, encodeURIComponent(String(code)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (quarterBeginDate !== undefined) {
                localVarQueryParameter['quarterBeginDate'] = (quarterBeginDate as any instanceof Date) ?
                    (quarterBeginDate as any).toISOString() :
                    quarterBeginDate;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} code 
         * @param {string} [quarterBeginDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCodesIcd9CodeGet: async (code: string, quarterBeginDate?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'code' is not null or undefined
            assertParamExists('apiCodesIcd9CodeGet', 'code', code)
            const localVarPath = `/api/codes/icd-9/{code}`
                .replace(`{${"code"}}`, encodeURIComponent(String(code)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (quarterBeginDate !== undefined) {
                localVarQueryParameter['quarterBeginDate'] = (quarterBeginDate as any instanceof Date) ?
                    (quarterBeginDate as any).toISOString() :
                    quarterBeginDate;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {IndexCodesRequestModel} [indexCodesRequestModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCodesIndexPost: async (indexCodesRequestModel?: IndexCodesRequestModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/codes/index`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(indexCodesRequestModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {NcciPtpEditRequestModel} [ncciPtpEditRequestModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCodesNcciPtpEditsPost: async (ncciPtpEditRequestModel?: NcciPtpEditRequestModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/codes/ncci-ptp-edits`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(ncciPtpEditRequestModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCodesNcciVersionsGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/codes/ncci-versions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCodesProceduralCodesGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/codes/procedural-codes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCodesVersionsGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/codes/versions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CodesApi - functional programming interface
 * @export
 */
export const CodesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CodesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCodesBetosHierarchicalListGet(id?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BetosIEnumerableIDataResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCodesBetosHierarchicalListGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [rvuId] 
         * @param {string} [gpciId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCodesCalculateRvuGet(rvuId?: string, gpciId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RvuGpciCalculateResponseModelIDataResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCodesCalculateRvuGet(rvuId, gpciId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} code 
         * @param {string} [quarterBeginDate] 
         * @param {string} [gpciId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCodesCodeCodeRvuValuesGet(code: string, quarterBeginDate?: string, gpciId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RVUModelIEnumerableIDataResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCodesCodeCodeRvuValuesGet(code, quarterBeginDate, gpciId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} code 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCodesCptCodeGet(code: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProceduralCodeModelIEnumerableIDataResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCodesCptCodeGet(code, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [quarterBeginDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCodesGpcisGet(quarterBeginDate?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GpciIEnumerableIDataResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCodesGpcisGet(quarterBeginDate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [betosGroup] 
         * @param {string} [quarterBeginDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCodesHcpcsBetosGroupCodesGet(betosGroup?: string, quarterBeginDate?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<HCPCSCodeChangedModelIEnumerableIDataResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCodesHcpcsBetosGroupCodesGet(betosGroup, quarterBeginDate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {HCPCSChangedStatusType} [type] 
         * @param {string} [quarterBeginDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCodesHcpcsChangedCodesGet(pageNumber?: number, pageSize?: number, type?: HCPCSChangedStatusType, quarterBeginDate?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<HCPCSCodeChangedModelPagedResultsIDataResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCodesHcpcsChangedCodesGet(pageNumber, pageSize, type, quarterBeginDate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} code 
         * @param {string} [quarterBeginDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCodesHcpcsCodeGet(code: string, quarterBeginDate?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<HcpcsCodeModelIEnumerableIDataResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCodesHcpcsCodeGet(code, quarterBeginDate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} code 
         * @param {string} [quarterBeginDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCodesHcpcsModifiersCodeGet(code: string, quarterBeginDate?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<HcpcsModifierModelIEnumerableIDataResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCodesHcpcsModifiersCodeGet(code, quarterBeginDate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {string} [quarterBeginDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCodesHcpcsmodifierActiveListGet(pageNumber?: number, pageSize?: number, quarterBeginDate?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<HcpcsModifierModelPagedResultsIDataResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCodesHcpcsmodifierActiveListGet(pageNumber, pageSize, quarterBeginDate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {string} [quarterBeginDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCodesHcpcsmodifierChangedDeletedGet(pageNumber?: number, pageSize?: number, quarterBeginDate?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<HCPCSModifierCodeChangedModelPagedResultsIDataResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCodesHcpcsmodifierChangedDeletedGet(pageNumber, pageSize, quarterBeginDate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {ICD10AddendaStatusType} [type] 
         * @param {string} [quarterBeginDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCodesIcd10CmAddendasGet(pageNumber?: number, pageSize?: number, type?: ICD10AddendaStatusType, quarterBeginDate?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ICD10CMCodeAddendaModelPagedResultsIDataResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCodesIcd10CmAddendasGet(pageNumber, pageSize, type, quarterBeginDate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [quarterBeginDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCodesIcd10CmChaptersGet(quarterBeginDate?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ICD10CMChapterModelIEnumerableIDataResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCodesIcd10CmChaptersGet(quarterBeginDate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} code 
         * @param {string} [quarterBeginDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCodesIcd10CmCodeGet(code: string, quarterBeginDate?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Icd10CmCodeModelIEnumerableIDataResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCodesIcd10CmCodeGet(code, quarterBeginDate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCodesIcd10CmHierarchicalListGet(id?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ICD10CMCodeIEnumerableIDataResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCodesIcd10CmHierarchicalListGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [sectionId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCodesIcd10CmSectionCodesGet(sectionId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Icd10CmCodeModelIEnumerableIDataResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCodesIcd10CmSectionCodesGet(sectionId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [chapterId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCodesIcd10CmSectionsGet(chapterId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ICD10CMSectionModelIEnumerableIDataResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCodesIcd10CmSectionsGet(chapterId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {ICD10AddendaStatusType} [type] 
         * @param {string} [quarterBeginDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCodesIcd10PcsAddendasGet(pageNumber?: number, pageSize?: number, type?: ICD10AddendaStatusType, quarterBeginDate?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ICD10PCSCodeAddendaModelPagedResultsIDataResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCodesIcd10PcsAddendasGet(pageNumber, pageSize, type, quarterBeginDate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} code 
         * @param {string} [quarterBeginDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCodesIcd10PcsCodeGet(code: string, quarterBeginDate?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Icd10PcsCodeModelIEnumerableIDataResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCodesIcd10PcsCodeGet(code, quarterBeginDate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [id] 
         * @param {string} [quarterBeginDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCodesIcd10PcsHierarchicalListGet(id?: string, quarterBeginDate?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Icd10PcsCodeModelIEnumerableIDataResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCodesIcd10PcsHierarchicalListGet(id, quarterBeginDate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} code 
         * @param {string} [quarterBeginDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCodesIcd11CodeGet(code: string, quarterBeginDate?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Icd11CodeModelIEnumerableIDataResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCodesIcd11CodeGet(code, quarterBeginDate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} code 
         * @param {string} [quarterBeginDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCodesIcd9CodeGet(code: string, quarterBeginDate?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Icd9CodeModelIEnumerableIDataResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCodesIcd9CodeGet(code, quarterBeginDate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {IndexCodesRequestModel} [indexCodesRequestModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCodesIndexPost(indexCodesRequestModel?: IndexCodesRequestModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCodesIndexPost(indexCodesRequestModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {NcciPtpEditRequestModel} [ncciPtpEditRequestModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCodesNcciPtpEditsPost(ncciPtpEditRequestModel?: NcciPtpEditRequestModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NcciPtpEditResponseModelIEnumerableIDataResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCodesNcciPtpEditsPost(ncciPtpEditRequestModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCodesNcciVersionsGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NcciVersionModelIEnumerableIDataResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCodesNcciVersionsGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCodesProceduralCodesGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProceduralCodeModelIEnumerableIDataResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCodesProceduralCodesGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCodesVersionsGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CodeVersionModelIEnumerableIDataResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCodesVersionsGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CodesApi - factory interface
 * @export
 */
export const CodesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CodesApiFp(configuration)
    return {
        /**
         * 
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCodesBetosHierarchicalListGet(id?: string, options?: any): AxiosPromise<BetosIEnumerableIDataResult> {
            return localVarFp.apiCodesBetosHierarchicalListGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [rvuId] 
         * @param {string} [gpciId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCodesCalculateRvuGet(rvuId?: string, gpciId?: string, options?: any): AxiosPromise<RvuGpciCalculateResponseModelIDataResult> {
            return localVarFp.apiCodesCalculateRvuGet(rvuId, gpciId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} code 
         * @param {string} [quarterBeginDate] 
         * @param {string} [gpciId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCodesCodeCodeRvuValuesGet(code: string, quarterBeginDate?: string, gpciId?: string, options?: any): AxiosPromise<RVUModelIEnumerableIDataResult> {
            return localVarFp.apiCodesCodeCodeRvuValuesGet(code, quarterBeginDate, gpciId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} code 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCodesCptCodeGet(code: string, options?: any): AxiosPromise<ProceduralCodeModelIEnumerableIDataResult> {
            return localVarFp.apiCodesCptCodeGet(code, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [quarterBeginDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCodesGpcisGet(quarterBeginDate?: string, options?: any): AxiosPromise<GpciIEnumerableIDataResult> {
            return localVarFp.apiCodesGpcisGet(quarterBeginDate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [betosGroup] 
         * @param {string} [quarterBeginDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCodesHcpcsBetosGroupCodesGet(betosGroup?: string, quarterBeginDate?: string, options?: any): AxiosPromise<HCPCSCodeChangedModelIEnumerableIDataResult> {
            return localVarFp.apiCodesHcpcsBetosGroupCodesGet(betosGroup, quarterBeginDate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {HCPCSChangedStatusType} [type] 
         * @param {string} [quarterBeginDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCodesHcpcsChangedCodesGet(pageNumber?: number, pageSize?: number, type?: HCPCSChangedStatusType, quarterBeginDate?: string, options?: any): AxiosPromise<HCPCSCodeChangedModelPagedResultsIDataResult> {
            return localVarFp.apiCodesHcpcsChangedCodesGet(pageNumber, pageSize, type, quarterBeginDate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} code 
         * @param {string} [quarterBeginDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCodesHcpcsCodeGet(code: string, quarterBeginDate?: string, options?: any): AxiosPromise<HcpcsCodeModelIEnumerableIDataResult> {
            return localVarFp.apiCodesHcpcsCodeGet(code, quarterBeginDate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} code 
         * @param {string} [quarterBeginDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCodesHcpcsModifiersCodeGet(code: string, quarterBeginDate?: string, options?: any): AxiosPromise<HcpcsModifierModelIEnumerableIDataResult> {
            return localVarFp.apiCodesHcpcsModifiersCodeGet(code, quarterBeginDate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {string} [quarterBeginDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCodesHcpcsmodifierActiveListGet(pageNumber?: number, pageSize?: number, quarterBeginDate?: string, options?: any): AxiosPromise<HcpcsModifierModelPagedResultsIDataResult> {
            return localVarFp.apiCodesHcpcsmodifierActiveListGet(pageNumber, pageSize, quarterBeginDate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {string} [quarterBeginDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCodesHcpcsmodifierChangedDeletedGet(pageNumber?: number, pageSize?: number, quarterBeginDate?: string, options?: any): AxiosPromise<HCPCSModifierCodeChangedModelPagedResultsIDataResult> {
            return localVarFp.apiCodesHcpcsmodifierChangedDeletedGet(pageNumber, pageSize, quarterBeginDate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {ICD10AddendaStatusType} [type] 
         * @param {string} [quarterBeginDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCodesIcd10CmAddendasGet(pageNumber?: number, pageSize?: number, type?: ICD10AddendaStatusType, quarterBeginDate?: string, options?: any): AxiosPromise<ICD10CMCodeAddendaModelPagedResultsIDataResult> {
            return localVarFp.apiCodesIcd10CmAddendasGet(pageNumber, pageSize, type, quarterBeginDate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [quarterBeginDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCodesIcd10CmChaptersGet(quarterBeginDate?: string, options?: any): AxiosPromise<ICD10CMChapterModelIEnumerableIDataResult> {
            return localVarFp.apiCodesIcd10CmChaptersGet(quarterBeginDate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} code 
         * @param {string} [quarterBeginDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCodesIcd10CmCodeGet(code: string, quarterBeginDate?: string, options?: any): AxiosPromise<Icd10CmCodeModelIEnumerableIDataResult> {
            return localVarFp.apiCodesIcd10CmCodeGet(code, quarterBeginDate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCodesIcd10CmHierarchicalListGet(id?: string, options?: any): AxiosPromise<ICD10CMCodeIEnumerableIDataResult> {
            return localVarFp.apiCodesIcd10CmHierarchicalListGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [sectionId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCodesIcd10CmSectionCodesGet(sectionId?: string, options?: any): AxiosPromise<Icd10CmCodeModelIEnumerableIDataResult> {
            return localVarFp.apiCodesIcd10CmSectionCodesGet(sectionId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [chapterId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCodesIcd10CmSectionsGet(chapterId?: string, options?: any): AxiosPromise<ICD10CMSectionModelIEnumerableIDataResult> {
            return localVarFp.apiCodesIcd10CmSectionsGet(chapterId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {ICD10AddendaStatusType} [type] 
         * @param {string} [quarterBeginDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCodesIcd10PcsAddendasGet(pageNumber?: number, pageSize?: number, type?: ICD10AddendaStatusType, quarterBeginDate?: string, options?: any): AxiosPromise<ICD10PCSCodeAddendaModelPagedResultsIDataResult> {
            return localVarFp.apiCodesIcd10PcsAddendasGet(pageNumber, pageSize, type, quarterBeginDate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} code 
         * @param {string} [quarterBeginDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCodesIcd10PcsCodeGet(code: string, quarterBeginDate?: string, options?: any): AxiosPromise<Icd10PcsCodeModelIEnumerableIDataResult> {
            return localVarFp.apiCodesIcd10PcsCodeGet(code, quarterBeginDate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [id] 
         * @param {string} [quarterBeginDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCodesIcd10PcsHierarchicalListGet(id?: string, quarterBeginDate?: string, options?: any): AxiosPromise<Icd10PcsCodeModelIEnumerableIDataResult> {
            return localVarFp.apiCodesIcd10PcsHierarchicalListGet(id, quarterBeginDate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} code 
         * @param {string} [quarterBeginDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCodesIcd11CodeGet(code: string, quarterBeginDate?: string, options?: any): AxiosPromise<Icd11CodeModelIEnumerableIDataResult> {
            return localVarFp.apiCodesIcd11CodeGet(code, quarterBeginDate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} code 
         * @param {string} [quarterBeginDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCodesIcd9CodeGet(code: string, quarterBeginDate?: string, options?: any): AxiosPromise<Icd9CodeModelIEnumerableIDataResult> {
            return localVarFp.apiCodesIcd9CodeGet(code, quarterBeginDate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {IndexCodesRequestModel} [indexCodesRequestModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCodesIndexPost(indexCodesRequestModel?: IndexCodesRequestModel, options?: any): AxiosPromise<void> {
            return localVarFp.apiCodesIndexPost(indexCodesRequestModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {NcciPtpEditRequestModel} [ncciPtpEditRequestModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCodesNcciPtpEditsPost(ncciPtpEditRequestModel?: NcciPtpEditRequestModel, options?: any): AxiosPromise<NcciPtpEditResponseModelIEnumerableIDataResult> {
            return localVarFp.apiCodesNcciPtpEditsPost(ncciPtpEditRequestModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCodesNcciVersionsGet(options?: any): AxiosPromise<NcciVersionModelIEnumerableIDataResult> {
            return localVarFp.apiCodesNcciVersionsGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCodesProceduralCodesGet(options?: any): AxiosPromise<ProceduralCodeModelIEnumerableIDataResult> {
            return localVarFp.apiCodesProceduralCodesGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCodesVersionsGet(options?: any): AxiosPromise<CodeVersionModelIEnumerableIDataResult> {
            return localVarFp.apiCodesVersionsGet(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CodesApi - object-oriented interface
 * @export
 * @class CodesApi
 * @extends {BaseAPI}
 */
export class CodesApi extends BaseAPI {
    /**
     * 
     * @param {string} [id] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CodesApi
     */
    public apiCodesBetosHierarchicalListGet(id?: string, options?: AxiosRequestConfig) {
        return CodesApiFp(this.configuration).apiCodesBetosHierarchicalListGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [rvuId] 
     * @param {string} [gpciId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CodesApi
     */
    public apiCodesCalculateRvuGet(rvuId?: string, gpciId?: string, options?: AxiosRequestConfig) {
        return CodesApiFp(this.configuration).apiCodesCalculateRvuGet(rvuId, gpciId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} code 
     * @param {string} [quarterBeginDate] 
     * @param {string} [gpciId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CodesApi
     */
    public apiCodesCodeCodeRvuValuesGet(code: string, quarterBeginDate?: string, gpciId?: string, options?: AxiosRequestConfig) {
        return CodesApiFp(this.configuration).apiCodesCodeCodeRvuValuesGet(code, quarterBeginDate, gpciId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} code 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CodesApi
     */
    public apiCodesCptCodeGet(code: string, options?: AxiosRequestConfig) {
        return CodesApiFp(this.configuration).apiCodesCptCodeGet(code, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [quarterBeginDate] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CodesApi
     */
    public apiCodesGpcisGet(quarterBeginDate?: string, options?: AxiosRequestConfig) {
        return CodesApiFp(this.configuration).apiCodesGpcisGet(quarterBeginDate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [betosGroup] 
     * @param {string} [quarterBeginDate] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CodesApi
     */
    public apiCodesHcpcsBetosGroupCodesGet(betosGroup?: string, quarterBeginDate?: string, options?: AxiosRequestConfig) {
        return CodesApiFp(this.configuration).apiCodesHcpcsBetosGroupCodesGet(betosGroup, quarterBeginDate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [pageNumber] 
     * @param {number} [pageSize] 
     * @param {HCPCSChangedStatusType} [type] 
     * @param {string} [quarterBeginDate] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CodesApi
     */
    public apiCodesHcpcsChangedCodesGet(pageNumber?: number, pageSize?: number, type?: HCPCSChangedStatusType, quarterBeginDate?: string, options?: AxiosRequestConfig) {
        return CodesApiFp(this.configuration).apiCodesHcpcsChangedCodesGet(pageNumber, pageSize, type, quarterBeginDate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} code 
     * @param {string} [quarterBeginDate] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CodesApi
     */
    public apiCodesHcpcsCodeGet(code: string, quarterBeginDate?: string, options?: AxiosRequestConfig) {
        return CodesApiFp(this.configuration).apiCodesHcpcsCodeGet(code, quarterBeginDate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} code 
     * @param {string} [quarterBeginDate] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CodesApi
     */
    public apiCodesHcpcsModifiersCodeGet(code: string, quarterBeginDate?: string, options?: AxiosRequestConfig) {
        return CodesApiFp(this.configuration).apiCodesHcpcsModifiersCodeGet(code, quarterBeginDate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [pageNumber] 
     * @param {number} [pageSize] 
     * @param {string} [quarterBeginDate] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CodesApi
     */
    public apiCodesHcpcsmodifierActiveListGet(pageNumber?: number, pageSize?: number, quarterBeginDate?: string, options?: AxiosRequestConfig) {
        return CodesApiFp(this.configuration).apiCodesHcpcsmodifierActiveListGet(pageNumber, pageSize, quarterBeginDate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [pageNumber] 
     * @param {number} [pageSize] 
     * @param {string} [quarterBeginDate] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CodesApi
     */
    public apiCodesHcpcsmodifierChangedDeletedGet(pageNumber?: number, pageSize?: number, quarterBeginDate?: string, options?: AxiosRequestConfig) {
        return CodesApiFp(this.configuration).apiCodesHcpcsmodifierChangedDeletedGet(pageNumber, pageSize, quarterBeginDate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [pageNumber] 
     * @param {number} [pageSize] 
     * @param {ICD10AddendaStatusType} [type] 
     * @param {string} [quarterBeginDate] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CodesApi
     */
    public apiCodesIcd10CmAddendasGet(pageNumber?: number, pageSize?: number, type?: ICD10AddendaStatusType, quarterBeginDate?: string, options?: AxiosRequestConfig) {
        return CodesApiFp(this.configuration).apiCodesIcd10CmAddendasGet(pageNumber, pageSize, type, quarterBeginDate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [quarterBeginDate] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CodesApi
     */
    public apiCodesIcd10CmChaptersGet(quarterBeginDate?: string, options?: AxiosRequestConfig) {
        return CodesApiFp(this.configuration).apiCodesIcd10CmChaptersGet(quarterBeginDate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} code 
     * @param {string} [quarterBeginDate] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CodesApi
     */
    public apiCodesIcd10CmCodeGet(code: string, quarterBeginDate?: string, options?: AxiosRequestConfig) {
        return CodesApiFp(this.configuration).apiCodesIcd10CmCodeGet(code, quarterBeginDate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [id] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CodesApi
     */
    public apiCodesIcd10CmHierarchicalListGet(id?: string, options?: AxiosRequestConfig) {
        return CodesApiFp(this.configuration).apiCodesIcd10CmHierarchicalListGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [sectionId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CodesApi
     */
    public apiCodesIcd10CmSectionCodesGet(sectionId?: string, options?: AxiosRequestConfig) {
        return CodesApiFp(this.configuration).apiCodesIcd10CmSectionCodesGet(sectionId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [chapterId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CodesApi
     */
    public apiCodesIcd10CmSectionsGet(chapterId?: string, options?: AxiosRequestConfig) {
        return CodesApiFp(this.configuration).apiCodesIcd10CmSectionsGet(chapterId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [pageNumber] 
     * @param {number} [pageSize] 
     * @param {ICD10AddendaStatusType} [type] 
     * @param {string} [quarterBeginDate] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CodesApi
     */
    public apiCodesIcd10PcsAddendasGet(pageNumber?: number, pageSize?: number, type?: ICD10AddendaStatusType, quarterBeginDate?: string, options?: AxiosRequestConfig) {
        return CodesApiFp(this.configuration).apiCodesIcd10PcsAddendasGet(pageNumber, pageSize, type, quarterBeginDate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} code 
     * @param {string} [quarterBeginDate] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CodesApi
     */
    public apiCodesIcd10PcsCodeGet(code: string, quarterBeginDate?: string, options?: AxiosRequestConfig) {
        return CodesApiFp(this.configuration).apiCodesIcd10PcsCodeGet(code, quarterBeginDate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [id] 
     * @param {string} [quarterBeginDate] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CodesApi
     */
    public apiCodesIcd10PcsHierarchicalListGet(id?: string, quarterBeginDate?: string, options?: AxiosRequestConfig) {
        return CodesApiFp(this.configuration).apiCodesIcd10PcsHierarchicalListGet(id, quarterBeginDate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} code 
     * @param {string} [quarterBeginDate] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CodesApi
     */
    public apiCodesIcd11CodeGet(code: string, quarterBeginDate?: string, options?: AxiosRequestConfig) {
        return CodesApiFp(this.configuration).apiCodesIcd11CodeGet(code, quarterBeginDate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} code 
     * @param {string} [quarterBeginDate] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CodesApi
     */
    public apiCodesIcd9CodeGet(code: string, quarterBeginDate?: string, options?: AxiosRequestConfig) {
        return CodesApiFp(this.configuration).apiCodesIcd9CodeGet(code, quarterBeginDate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {IndexCodesRequestModel} [indexCodesRequestModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CodesApi
     */
    public apiCodesIndexPost(indexCodesRequestModel?: IndexCodesRequestModel, options?: AxiosRequestConfig) {
        return CodesApiFp(this.configuration).apiCodesIndexPost(indexCodesRequestModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {NcciPtpEditRequestModel} [ncciPtpEditRequestModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CodesApi
     */
    public apiCodesNcciPtpEditsPost(ncciPtpEditRequestModel?: NcciPtpEditRequestModel, options?: AxiosRequestConfig) {
        return CodesApiFp(this.configuration).apiCodesNcciPtpEditsPost(ncciPtpEditRequestModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CodesApi
     */
    public apiCodesNcciVersionsGet(options?: AxiosRequestConfig) {
        return CodesApiFp(this.configuration).apiCodesNcciVersionsGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CodesApi
     */
    public apiCodesProceduralCodesGet(options?: AxiosRequestConfig) {
        return CodesApiFp(this.configuration).apiCodesProceduralCodesGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CodesApi
     */
    public apiCodesVersionsGet(options?: AxiosRequestConfig) {
        return CodesApiFp(this.configuration).apiCodesVersionsGet(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * CompanyApi - axios parameter creator
 * @export
 */
export const CompanyApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get company invoices
         * @param {string} id 
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {GetMyInvoicesRequestModel} [getMyInvoicesRequestModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPaymentCompaniesIdInvoicesPost: async (id: string, pageNumber?: number, pageSize?: number, getMyInvoicesRequestModel?: GetMyInvoicesRequestModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiPaymentCompaniesIdInvoicesPost', 'id', id)
            const localVarPath = `/api/payment/companies/{id}/invoices`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (pageNumber !== undefined) {
                localVarQueryParameter['PageNumber'] = pageNumber;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(getMyInvoicesRequestModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get company payment methods
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPaymentCompaniesIdPaymentMethodsGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiPaymentCompaniesIdPaymentMethodsGet', 'id', id)
            const localVarPath = `/api/payment/companies/{id}/payment-methods`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Set company default payment method
         * @param {string} id 
         * @param {string} paymentMethodId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPaymentCompaniesIdPaymentMethodsPaymentMethodIdDefaultPut: async (id: string, paymentMethodId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiPaymentCompaniesIdPaymentMethodsPaymentMethodIdDefaultPut', 'id', id)
            // verify required parameter 'paymentMethodId' is not null or undefined
            assertParamExists('apiPaymentCompaniesIdPaymentMethodsPaymentMethodIdDefaultPut', 'paymentMethodId', paymentMethodId)
            const localVarPath = `/api/payment/companies/{id}/payment-methods/{paymentMethodId}/default`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"paymentMethodId"}}`, encodeURIComponent(String(paymentMethodId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create a subscription request. If there is an active subscription, products will be added to the subscription.  Important! For the active subscription MetaData fields user_id and user_emails fields must have value.
         * @param {string} id 
         * @param {CreateSubscriptionApiRequestModel} [createSubscriptionApiRequestModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPaymentCompaniesIdSubscriptionsPost: async (id: string, createSubscriptionApiRequestModel?: CreateSubscriptionApiRequestModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiPaymentCompaniesIdSubscriptionsPost', 'id', id)
            const localVarPath = `/api/payment/companies/{id}/subscriptions`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createSubscriptionApiRequestModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CompanyApi - functional programming interface
 * @export
 */
export const CompanyApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CompanyApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get company invoices
         * @param {string} id 
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {GetMyInvoicesRequestModel} [getMyInvoicesRequestModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiPaymentCompaniesIdInvoicesPost(id: string, pageNumber?: number, pageSize?: number, getMyInvoicesRequestModel?: GetMyInvoicesRequestModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InvoiceModelPagedResultsIDataResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiPaymentCompaniesIdInvoicesPost(id, pageNumber, pageSize, getMyInvoicesRequestModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get company payment methods
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiPaymentCompaniesIdPaymentMethodsGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaymentMethodModelIEnumerableIDataResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiPaymentCompaniesIdPaymentMethodsGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Set company default payment method
         * @param {string} id 
         * @param {string} paymentMethodId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiPaymentCompaniesIdPaymentMethodsPaymentMethodIdDefaultPut(id: string, paymentMethodId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiPaymentCompaniesIdPaymentMethodsPaymentMethodIdDefaultPut(id, paymentMethodId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create a subscription request. If there is an active subscription, products will be added to the subscription.  Important! For the active subscription MetaData fields user_id and user_emails fields must have value.
         * @param {string} id 
         * @param {CreateSubscriptionApiRequestModel} [createSubscriptionApiRequestModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiPaymentCompaniesIdSubscriptionsPost(id: string, createSubscriptionApiRequestModel?: CreateSubscriptionApiRequestModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateUpdateSubscriptionResponseModelIDataResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiPaymentCompaniesIdSubscriptionsPost(id, createSubscriptionApiRequestModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CompanyApi - factory interface
 * @export
 */
export const CompanyApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CompanyApiFp(configuration)
    return {
        /**
         * 
         * @summary Get company invoices
         * @param {string} id 
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {GetMyInvoicesRequestModel} [getMyInvoicesRequestModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPaymentCompaniesIdInvoicesPost(id: string, pageNumber?: number, pageSize?: number, getMyInvoicesRequestModel?: GetMyInvoicesRequestModel, options?: any): AxiosPromise<InvoiceModelPagedResultsIDataResult> {
            return localVarFp.apiPaymentCompaniesIdInvoicesPost(id, pageNumber, pageSize, getMyInvoicesRequestModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get company payment methods
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPaymentCompaniesIdPaymentMethodsGet(id: string, options?: any): AxiosPromise<PaymentMethodModelIEnumerableIDataResult> {
            return localVarFp.apiPaymentCompaniesIdPaymentMethodsGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Set company default payment method
         * @param {string} id 
         * @param {string} paymentMethodId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPaymentCompaniesIdPaymentMethodsPaymentMethodIdDefaultPut(id: string, paymentMethodId: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiPaymentCompaniesIdPaymentMethodsPaymentMethodIdDefaultPut(id, paymentMethodId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create a subscription request. If there is an active subscription, products will be added to the subscription.  Important! For the active subscription MetaData fields user_id and user_emails fields must have value.
         * @param {string} id 
         * @param {CreateSubscriptionApiRequestModel} [createSubscriptionApiRequestModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPaymentCompaniesIdSubscriptionsPost(id: string, createSubscriptionApiRequestModel?: CreateSubscriptionApiRequestModel, options?: any): AxiosPromise<CreateUpdateSubscriptionResponseModelIDataResult> {
            return localVarFp.apiPaymentCompaniesIdSubscriptionsPost(id, createSubscriptionApiRequestModel, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CompanyApi - object-oriented interface
 * @export
 * @class CompanyApi
 * @extends {BaseAPI}
 */
export class CompanyApi extends BaseAPI {
    /**
     * 
     * @summary Get company invoices
     * @param {string} id 
     * @param {number} [pageNumber] 
     * @param {number} [pageSize] 
     * @param {GetMyInvoicesRequestModel} [getMyInvoicesRequestModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyApi
     */
    public apiPaymentCompaniesIdInvoicesPost(id: string, pageNumber?: number, pageSize?: number, getMyInvoicesRequestModel?: GetMyInvoicesRequestModel, options?: AxiosRequestConfig) {
        return CompanyApiFp(this.configuration).apiPaymentCompaniesIdInvoicesPost(id, pageNumber, pageSize, getMyInvoicesRequestModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get company payment methods
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyApi
     */
    public apiPaymentCompaniesIdPaymentMethodsGet(id: string, options?: AxiosRequestConfig) {
        return CompanyApiFp(this.configuration).apiPaymentCompaniesIdPaymentMethodsGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Set company default payment method
     * @param {string} id 
     * @param {string} paymentMethodId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyApi
     */
    public apiPaymentCompaniesIdPaymentMethodsPaymentMethodIdDefaultPut(id: string, paymentMethodId: string, options?: AxiosRequestConfig) {
        return CompanyApiFp(this.configuration).apiPaymentCompaniesIdPaymentMethodsPaymentMethodIdDefaultPut(id, paymentMethodId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create a subscription request. If there is an active subscription, products will be added to the subscription.  Important! For the active subscription MetaData fields user_id and user_emails fields must have value.
     * @param {string} id 
     * @param {CreateSubscriptionApiRequestModel} [createSubscriptionApiRequestModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyApi
     */
    public apiPaymentCompaniesIdSubscriptionsPost(id: string, createSubscriptionApiRequestModel?: CreateSubscriptionApiRequestModel, options?: AxiosRequestConfig) {
        return CompanyApiFp(this.configuration).apiPaymentCompaniesIdSubscriptionsPost(id, createSubscriptionApiRequestModel, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * CustomerApi - axios parameter creator
 * @export
 */
export const CustomerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Send Invoice Email
         * @param {InvoiceSendEmailRequestModel} [invoiceSendEmailRequestModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPaymentCustomerInvoiceSendEmailPost: async (invoiceSendEmailRequestModel?: InvoiceSendEmailRequestModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/payment/customer/invoice-send-email`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(invoiceSendEmailRequestModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get my invoices
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {GetMyInvoicesRequestModel} [getMyInvoicesRequestModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPaymentCustomerMyInvoicesPost: async (pageNumber?: number, pageSize?: number, getMyInvoicesRequestModel?: GetMyInvoicesRequestModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/payment/customer/my-invoices`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (pageNumber !== undefined) {
                localVarQueryParameter['PageNumber'] = pageNumber;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(getMyInvoicesRequestModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get my payment methods
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPaymentCustomerMyPaymentMethodsGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/payment/customer/my-payment-methods`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Set Default Payment Method
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPaymentCustomerPaymentMethodsIdDefaultPut: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiPaymentCustomerPaymentMethodsIdDefaultPut', 'id', id)
            const localVarPath = `/api/payment/customer/payment-methods/{id}/default`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete a payment method
         * @param {string} id 
         * @param {boolean} [deletePermanently] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPaymentCustomerPaymentMethodsIdDelete: async (id: string, deletePermanently?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiPaymentCustomerPaymentMethodsIdDelete', 'id', id)
            const localVarPath = `/api/payment/customer/payment-methods/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (deletePermanently !== undefined) {
                localVarQueryParameter['deletePermanently'] = deletePermanently;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create a new customer
         * @param {CustomerModel} [customerModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPaymentCustomerPost: async (customerModel?: CustomerModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/payment/customer`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(customerModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CustomerApi - functional programming interface
 * @export
 */
export const CustomerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CustomerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Send Invoice Email
         * @param {InvoiceSendEmailRequestModel} [invoiceSendEmailRequestModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiPaymentCustomerInvoiceSendEmailPost(invoiceSendEmailRequestModel?: InvoiceSendEmailRequestModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiPaymentCustomerInvoiceSendEmailPost(invoiceSendEmailRequestModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get my invoices
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {GetMyInvoicesRequestModel} [getMyInvoicesRequestModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiPaymentCustomerMyInvoicesPost(pageNumber?: number, pageSize?: number, getMyInvoicesRequestModel?: GetMyInvoicesRequestModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InvoiceModelPagedResultsIDataResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiPaymentCustomerMyInvoicesPost(pageNumber, pageSize, getMyInvoicesRequestModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get my payment methods
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiPaymentCustomerMyPaymentMethodsGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaymentMethodModelIEnumerableIDataResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiPaymentCustomerMyPaymentMethodsGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Set Default Payment Method
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiPaymentCustomerPaymentMethodsIdDefaultPut(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiPaymentCustomerPaymentMethodsIdDefaultPut(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete a payment method
         * @param {string} id 
         * @param {boolean} [deletePermanently] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiPaymentCustomerPaymentMethodsIdDelete(id: string, deletePermanently?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiPaymentCustomerPaymentMethodsIdDelete(id, deletePermanently, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create a new customer
         * @param {CustomerModel} [customerModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiPaymentCustomerPost(customerModel?: CustomerModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CustomerModelIDataResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiPaymentCustomerPost(customerModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CustomerApi - factory interface
 * @export
 */
export const CustomerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CustomerApiFp(configuration)
    return {
        /**
         * 
         * @summary Send Invoice Email
         * @param {InvoiceSendEmailRequestModel} [invoiceSendEmailRequestModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPaymentCustomerInvoiceSendEmailPost(invoiceSendEmailRequestModel?: InvoiceSendEmailRequestModel, options?: any): AxiosPromise<void> {
            return localVarFp.apiPaymentCustomerInvoiceSendEmailPost(invoiceSendEmailRequestModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get my invoices
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {GetMyInvoicesRequestModel} [getMyInvoicesRequestModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPaymentCustomerMyInvoicesPost(pageNumber?: number, pageSize?: number, getMyInvoicesRequestModel?: GetMyInvoicesRequestModel, options?: any): AxiosPromise<InvoiceModelPagedResultsIDataResult> {
            return localVarFp.apiPaymentCustomerMyInvoicesPost(pageNumber, pageSize, getMyInvoicesRequestModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get my payment methods
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPaymentCustomerMyPaymentMethodsGet(options?: any): AxiosPromise<PaymentMethodModelIEnumerableIDataResult> {
            return localVarFp.apiPaymentCustomerMyPaymentMethodsGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Set Default Payment Method
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPaymentCustomerPaymentMethodsIdDefaultPut(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiPaymentCustomerPaymentMethodsIdDefaultPut(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete a payment method
         * @param {string} id 
         * @param {boolean} [deletePermanently] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPaymentCustomerPaymentMethodsIdDelete(id: string, deletePermanently?: boolean, options?: any): AxiosPromise<void> {
            return localVarFp.apiPaymentCustomerPaymentMethodsIdDelete(id, deletePermanently, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create a new customer
         * @param {CustomerModel} [customerModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPaymentCustomerPost(customerModel?: CustomerModel, options?: any): AxiosPromise<CustomerModelIDataResult> {
            return localVarFp.apiPaymentCustomerPost(customerModel, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CustomerApi - object-oriented interface
 * @export
 * @class CustomerApi
 * @extends {BaseAPI}
 */
export class CustomerApi extends BaseAPI {
    /**
     * 
     * @summary Send Invoice Email
     * @param {InvoiceSendEmailRequestModel} [invoiceSendEmailRequestModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerApi
     */
    public apiPaymentCustomerInvoiceSendEmailPost(invoiceSendEmailRequestModel?: InvoiceSendEmailRequestModel, options?: AxiosRequestConfig) {
        return CustomerApiFp(this.configuration).apiPaymentCustomerInvoiceSendEmailPost(invoiceSendEmailRequestModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get my invoices
     * @param {number} [pageNumber] 
     * @param {number} [pageSize] 
     * @param {GetMyInvoicesRequestModel} [getMyInvoicesRequestModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerApi
     */
    public apiPaymentCustomerMyInvoicesPost(pageNumber?: number, pageSize?: number, getMyInvoicesRequestModel?: GetMyInvoicesRequestModel, options?: AxiosRequestConfig) {
        return CustomerApiFp(this.configuration).apiPaymentCustomerMyInvoicesPost(pageNumber, pageSize, getMyInvoicesRequestModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get my payment methods
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerApi
     */
    public apiPaymentCustomerMyPaymentMethodsGet(options?: AxiosRequestConfig) {
        return CustomerApiFp(this.configuration).apiPaymentCustomerMyPaymentMethodsGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Set Default Payment Method
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerApi
     */
    public apiPaymentCustomerPaymentMethodsIdDefaultPut(id: string, options?: AxiosRequestConfig) {
        return CustomerApiFp(this.configuration).apiPaymentCustomerPaymentMethodsIdDefaultPut(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete a payment method
     * @param {string} id 
     * @param {boolean} [deletePermanently] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerApi
     */
    public apiPaymentCustomerPaymentMethodsIdDelete(id: string, deletePermanently?: boolean, options?: AxiosRequestConfig) {
        return CustomerApiFp(this.configuration).apiPaymentCustomerPaymentMethodsIdDelete(id, deletePermanently, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create a new customer
     * @param {CustomerModel} [customerModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerApi
     */
    public apiPaymentCustomerPost(customerModel?: CustomerModel, options?: AxiosRequestConfig) {
        return CustomerApiFp(this.configuration).apiPaymentCustomerPost(customerModel, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DeveloperApi - axios parameter creator
 * @export
 */
export const DeveloperApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary DeleteApiKey
         * @param {string} apiKeyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAccountDeveloperApiKeyApiKeyIdDelete: async (apiKeyId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'apiKeyId' is not null or undefined
            assertParamExists('apiAccountDeveloperApiKeyApiKeyIdDelete', 'apiKeyId', apiKeyId)
            const localVarPath = `/api/account/developer/api-key/{apiKeyId}`
                .replace(`{${"apiKeyId"}}`, encodeURIComponent(String(apiKeyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary GetApiUsagePlans
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAccountDeveloperApiUsagePlansGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/account/developer/api-usage-plans`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary GetCompanyApiKey
         * @param {string} companyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAccountDeveloperCompaniesCompanyIdApiKeyGet: async (companyId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('apiAccountDeveloperCompaniesCompanyIdApiKeyGet', 'companyId', companyId)
            const localVarPath = `/api/account/developer/companies/{companyId}/api-key`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary AddCompanyApiKey
         * @param {string} companyId 
         * @param {string} apiUsagePlanId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAccountDeveloperCompaniesCompanyIdApiUsagePlansApiUsagePlanIdApiKeyPost: async (companyId: string, apiUsagePlanId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('apiAccountDeveloperCompaniesCompanyIdApiUsagePlansApiUsagePlanIdApiKeyPost', 'companyId', companyId)
            // verify required parameter 'apiUsagePlanId' is not null or undefined
            assertParamExists('apiAccountDeveloperCompaniesCompanyIdApiUsagePlansApiUsagePlanIdApiKeyPost', 'apiUsagePlanId', apiUsagePlanId)
            const localVarPath = `/api/account/developer/companies/{companyId}/api-usage-plans/{apiUsagePlanId}/api-key`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)))
                .replace(`{${"apiUsagePlanId"}}`, encodeURIComponent(String(apiUsagePlanId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary GetUserApiKey
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAccountDeveloperUsersMyApiKeyGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/account/developer/users/my-api-key`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get User Api Access Logs
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAccountDeveloperUsersUserIdApiAccessLogsGet: async (userId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('apiAccountDeveloperUsersUserIdApiAccessLogsGet', 'userId', userId)
            const localVarPath = `/api/account/developer/users/{userId}/api-access-logs`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get user session
         * @param {string} userId 
         * @param {number} [year] 
         * @param {number} [month] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAccountDeveloperUsersUserIdMonthlyApiUsageGet: async (userId: string, year?: number, month?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('apiAccountDeveloperUsersUserIdMonthlyApiUsageGet', 'userId', userId)
            const localVarPath = `/api/account/developer/users/{userId}/monthly-api-usage`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (year !== undefined) {
                localVarQueryParameter['year'] = year;
            }

            if (month !== undefined) {
                localVarQueryParameter['month'] = month;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DeveloperApi - functional programming interface
 * @export
 */
export const DeveloperApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DeveloperApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary DeleteApiKey
         * @param {string} apiKeyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAccountDeveloperApiKeyApiKeyIdDelete(apiKeyId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BooleanIDataResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAccountDeveloperApiKeyApiKeyIdDelete(apiKeyId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary GetApiUsagePlans
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAccountDeveloperApiUsagePlansGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiUsagePlanModelIEnumerableIDataResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAccountDeveloperApiUsagePlansGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary GetCompanyApiKey
         * @param {string} companyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAccountDeveloperCompaniesCompanyIdApiKeyGet(companyId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompanyApiKeyResponseModelIDataResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAccountDeveloperCompaniesCompanyIdApiKeyGet(companyId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary AddCompanyApiKey
         * @param {string} companyId 
         * @param {string} apiUsagePlanId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAccountDeveloperCompaniesCompanyIdApiUsagePlansApiUsagePlanIdApiKeyPost(companyId: string, apiUsagePlanId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompanyApiKeyResponseModelIDataResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAccountDeveloperCompaniesCompanyIdApiUsagePlansApiUsagePlanIdApiKeyPost(companyId, apiUsagePlanId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary GetUserApiKey
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAccountDeveloperUsersMyApiKeyGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserApiKeyResponseModelIDataResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAccountDeveloperUsersMyApiKeyGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get User Api Access Logs
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAccountDeveloperUsersUserIdApiAccessLogsGet(userId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiAccessLogModelIEnumerableIDataResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAccountDeveloperUsersUserIdApiAccessLogsGet(userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get user session
         * @param {string} userId 
         * @param {number} [year] 
         * @param {number} [month] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAccountDeveloperUsersUserIdMonthlyApiUsageGet(userId: string, year?: number, month?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserApiKeyUsageModelIDataResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAccountDeveloperUsersUserIdMonthlyApiUsageGet(userId, year, month, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DeveloperApi - factory interface
 * @export
 */
export const DeveloperApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DeveloperApiFp(configuration)
    return {
        /**
         * 
         * @summary DeleteApiKey
         * @param {string} apiKeyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAccountDeveloperApiKeyApiKeyIdDelete(apiKeyId: string, options?: any): AxiosPromise<BooleanIDataResult> {
            return localVarFp.apiAccountDeveloperApiKeyApiKeyIdDelete(apiKeyId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary GetApiUsagePlans
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAccountDeveloperApiUsagePlansGet(options?: any): AxiosPromise<ApiUsagePlanModelIEnumerableIDataResult> {
            return localVarFp.apiAccountDeveloperApiUsagePlansGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary GetCompanyApiKey
         * @param {string} companyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAccountDeveloperCompaniesCompanyIdApiKeyGet(companyId: string, options?: any): AxiosPromise<CompanyApiKeyResponseModelIDataResult> {
            return localVarFp.apiAccountDeveloperCompaniesCompanyIdApiKeyGet(companyId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary AddCompanyApiKey
         * @param {string} companyId 
         * @param {string} apiUsagePlanId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAccountDeveloperCompaniesCompanyIdApiUsagePlansApiUsagePlanIdApiKeyPost(companyId: string, apiUsagePlanId: string, options?: any): AxiosPromise<CompanyApiKeyResponseModelIDataResult> {
            return localVarFp.apiAccountDeveloperCompaniesCompanyIdApiUsagePlansApiUsagePlanIdApiKeyPost(companyId, apiUsagePlanId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary GetUserApiKey
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAccountDeveloperUsersMyApiKeyGet(options?: any): AxiosPromise<UserApiKeyResponseModelIDataResult> {
            return localVarFp.apiAccountDeveloperUsersMyApiKeyGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get User Api Access Logs
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAccountDeveloperUsersUserIdApiAccessLogsGet(userId: string, options?: any): AxiosPromise<ApiAccessLogModelIEnumerableIDataResult> {
            return localVarFp.apiAccountDeveloperUsersUserIdApiAccessLogsGet(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get user session
         * @param {string} userId 
         * @param {number} [year] 
         * @param {number} [month] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAccountDeveloperUsersUserIdMonthlyApiUsageGet(userId: string, year?: number, month?: number, options?: any): AxiosPromise<UserApiKeyUsageModelIDataResult> {
            return localVarFp.apiAccountDeveloperUsersUserIdMonthlyApiUsageGet(userId, year, month, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DeveloperApi - object-oriented interface
 * @export
 * @class DeveloperApi
 * @extends {BaseAPI}
 */
export class DeveloperApi extends BaseAPI {
    /**
     * 
     * @summary DeleteApiKey
     * @param {string} apiKeyId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeveloperApi
     */
    public apiAccountDeveloperApiKeyApiKeyIdDelete(apiKeyId: string, options?: AxiosRequestConfig) {
        return DeveloperApiFp(this.configuration).apiAccountDeveloperApiKeyApiKeyIdDelete(apiKeyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary GetApiUsagePlans
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeveloperApi
     */
    public apiAccountDeveloperApiUsagePlansGet(options?: AxiosRequestConfig) {
        return DeveloperApiFp(this.configuration).apiAccountDeveloperApiUsagePlansGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary GetCompanyApiKey
     * @param {string} companyId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeveloperApi
     */
    public apiAccountDeveloperCompaniesCompanyIdApiKeyGet(companyId: string, options?: AxiosRequestConfig) {
        return DeveloperApiFp(this.configuration).apiAccountDeveloperCompaniesCompanyIdApiKeyGet(companyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary AddCompanyApiKey
     * @param {string} companyId 
     * @param {string} apiUsagePlanId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeveloperApi
     */
    public apiAccountDeveloperCompaniesCompanyIdApiUsagePlansApiUsagePlanIdApiKeyPost(companyId: string, apiUsagePlanId: string, options?: AxiosRequestConfig) {
        return DeveloperApiFp(this.configuration).apiAccountDeveloperCompaniesCompanyIdApiUsagePlansApiUsagePlanIdApiKeyPost(companyId, apiUsagePlanId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary GetUserApiKey
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeveloperApi
     */
    public apiAccountDeveloperUsersMyApiKeyGet(options?: AxiosRequestConfig) {
        return DeveloperApiFp(this.configuration).apiAccountDeveloperUsersMyApiKeyGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get User Api Access Logs
     * @param {string} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeveloperApi
     */
    public apiAccountDeveloperUsersUserIdApiAccessLogsGet(userId: string, options?: AxiosRequestConfig) {
        return DeveloperApiFp(this.configuration).apiAccountDeveloperUsersUserIdApiAccessLogsGet(userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get user session
     * @param {string} userId 
     * @param {number} [year] 
     * @param {number} [month] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeveloperApi
     */
    public apiAccountDeveloperUsersUserIdMonthlyApiUsageGet(userId: string, year?: number, month?: number, options?: AxiosRequestConfig) {
        return DeveloperApiFp(this.configuration).apiAccountDeveloperUsersUserIdMonthlyApiUsageGet(userId, year, month, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * FileApi - axios parameter creator
 * @export
 */
export const FileApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Download Invoice file
         * @param {string} key 
         * @param {FileResultType} fileResultType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiContentFileDownloadFileResultTypeKeyGet: async (key: string, fileResultType: FileResultType, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'key' is not null or undefined
            assertParamExists('apiContentFileDownloadFileResultTypeKeyGet', 'key', key)
            // verify required parameter 'fileResultType' is not null or undefined
            assertParamExists('apiContentFileDownloadFileResultTypeKeyGet', 'fileResultType', fileResultType)
            const localVarPath = `/api/content/file/download/{fileResultType}/{key}`
                .replace(`{${"key"}}`, encodeURIComponent(String(key)))
                .replace(`{${"fileResultType"}}`, encodeURIComponent(String(fileResultType)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FileApi - functional programming interface
 * @export
 */
export const FileApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = FileApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Download Invoice file
         * @param {string} key 
         * @param {FileResultType} fileResultType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiContentFileDownloadFileResultTypeKeyGet(key: string, fileResultType: FileResultType, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ByteArrayIDataResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiContentFileDownloadFileResultTypeKeyGet(key, fileResultType, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * FileApi - factory interface
 * @export
 */
export const FileApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FileApiFp(configuration)
    return {
        /**
         * 
         * @summary Download Invoice file
         * @param {string} key 
         * @param {FileResultType} fileResultType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiContentFileDownloadFileResultTypeKeyGet(key: string, fileResultType: FileResultType, options?: any): AxiosPromise<ByteArrayIDataResult> {
            return localVarFp.apiContentFileDownloadFileResultTypeKeyGet(key, fileResultType, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * FileApi - object-oriented interface
 * @export
 * @class FileApi
 * @extends {BaseAPI}
 */
export class FileApi extends BaseAPI {
    /**
     * 
     * @summary Download Invoice file
     * @param {string} key 
     * @param {FileResultType} fileResultType 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileApi
     */
    public apiContentFileDownloadFileResultTypeKeyGet(key: string, fileResultType: FileResultType, options?: AxiosRequestConfig) {
        return FileApiFp(this.configuration).apiContentFileDownloadFileResultTypeKeyGet(key, fileResultType, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * GuidelineApi - axios parameter creator
 * @export
 */
export const GuidelineApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Download Guideline file
         * @param {string} guidelineId 
         * @param {FileResultType} fileResultType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiContentGuidelinesFileFileResultTypeGuidelineIdGet: async (guidelineId: string, fileResultType: FileResultType, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'guidelineId' is not null or undefined
            assertParamExists('apiContentGuidelinesFileFileResultTypeGuidelineIdGet', 'guidelineId', guidelineId)
            // verify required parameter 'fileResultType' is not null or undefined
            assertParamExists('apiContentGuidelinesFileFileResultTypeGuidelineIdGet', 'fileResultType', fileResultType)
            const localVarPath = `/api/content/guidelines/file/{fileResultType}/{guidelineId}`
                .replace(`{${"guidelineId"}}`, encodeURIComponent(String(guidelineId)))
                .replace(`{${"fileResultType"}}`, encodeURIComponent(String(fileResultType)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all guidelines
         * @param {GuidelineType} guidelineType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiContentGuidelinesGuidelineTypeGet: async (guidelineType: GuidelineType, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'guidelineType' is not null or undefined
            assertParamExists('apiContentGuidelinesGuidelineTypeGet', 'guidelineType', guidelineType)
            const localVarPath = `/api/content/guidelines/{guidelineType}`
                .replace(`{${"guidelineType"}}`, encodeURIComponent(String(guidelineType)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete guideline
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiContentGuidelinesIdDelete: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiContentGuidelinesIdDelete', 'id', id)
            const localVarPath = `/api/content/guidelines/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Add guideline
         * @param {GuidelineModel} [guidelineModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiContentGuidelinesPost: async (guidelineModel?: GuidelineModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/content/guidelines`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(guidelineModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update guideline
         * @param {GuidelineModel} [guidelineModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiContentGuidelinesPut: async (guidelineModel?: GuidelineModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/content/guidelines`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(guidelineModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * GuidelineApi - functional programming interface
 * @export
 */
export const GuidelineApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = GuidelineApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Download Guideline file
         * @param {string} guidelineId 
         * @param {FileResultType} fileResultType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiContentGuidelinesFileFileResultTypeGuidelineIdGet(guidelineId: string, fileResultType: FileResultType, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ByteArrayIDataResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiContentGuidelinesFileFileResultTypeGuidelineIdGet(guidelineId, fileResultType, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get all guidelines
         * @param {GuidelineType} guidelineType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiContentGuidelinesGuidelineTypeGet(guidelineType: GuidelineType, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GuidelineModelIEnumerableIDataResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiContentGuidelinesGuidelineTypeGet(guidelineType, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete guideline
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiContentGuidelinesIdDelete(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiContentGuidelinesIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Add guideline
         * @param {GuidelineModel} [guidelineModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiContentGuidelinesPost(guidelineModel?: GuidelineModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GuidelineModelIDataResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiContentGuidelinesPost(guidelineModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update guideline
         * @param {GuidelineModel} [guidelineModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiContentGuidelinesPut(guidelineModel?: GuidelineModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GuidelineModelIDataResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiContentGuidelinesPut(guidelineModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * GuidelineApi - factory interface
 * @export
 */
export const GuidelineApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = GuidelineApiFp(configuration)
    return {
        /**
         * 
         * @summary Download Guideline file
         * @param {string} guidelineId 
         * @param {FileResultType} fileResultType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiContentGuidelinesFileFileResultTypeGuidelineIdGet(guidelineId: string, fileResultType: FileResultType, options?: any): AxiosPromise<ByteArrayIDataResult> {
            return localVarFp.apiContentGuidelinesFileFileResultTypeGuidelineIdGet(guidelineId, fileResultType, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get all guidelines
         * @param {GuidelineType} guidelineType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiContentGuidelinesGuidelineTypeGet(guidelineType: GuidelineType, options?: any): AxiosPromise<GuidelineModelIEnumerableIDataResult> {
            return localVarFp.apiContentGuidelinesGuidelineTypeGet(guidelineType, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete guideline
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiContentGuidelinesIdDelete(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiContentGuidelinesIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Add guideline
         * @param {GuidelineModel} [guidelineModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiContentGuidelinesPost(guidelineModel?: GuidelineModel, options?: any): AxiosPromise<GuidelineModelIDataResult> {
            return localVarFp.apiContentGuidelinesPost(guidelineModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update guideline
         * @param {GuidelineModel} [guidelineModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiContentGuidelinesPut(guidelineModel?: GuidelineModel, options?: any): AxiosPromise<GuidelineModelIDataResult> {
            return localVarFp.apiContentGuidelinesPut(guidelineModel, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * GuidelineApi - object-oriented interface
 * @export
 * @class GuidelineApi
 * @extends {BaseAPI}
 */
export class GuidelineApi extends BaseAPI {
    /**
     * 
     * @summary Download Guideline file
     * @param {string} guidelineId 
     * @param {FileResultType} fileResultType 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GuidelineApi
     */
    public apiContentGuidelinesFileFileResultTypeGuidelineIdGet(guidelineId: string, fileResultType: FileResultType, options?: AxiosRequestConfig) {
        return GuidelineApiFp(this.configuration).apiContentGuidelinesFileFileResultTypeGuidelineIdGet(guidelineId, fileResultType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get all guidelines
     * @param {GuidelineType} guidelineType 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GuidelineApi
     */
    public apiContentGuidelinesGuidelineTypeGet(guidelineType: GuidelineType, options?: AxiosRequestConfig) {
        return GuidelineApiFp(this.configuration).apiContentGuidelinesGuidelineTypeGet(guidelineType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete guideline
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GuidelineApi
     */
    public apiContentGuidelinesIdDelete(id: string, options?: AxiosRequestConfig) {
        return GuidelineApiFp(this.configuration).apiContentGuidelinesIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Add guideline
     * @param {GuidelineModel} [guidelineModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GuidelineApi
     */
    public apiContentGuidelinesPost(guidelineModel?: GuidelineModel, options?: AxiosRequestConfig) {
        return GuidelineApiFp(this.configuration).apiContentGuidelinesPost(guidelineModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update guideline
     * @param {GuidelineModel} [guidelineModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GuidelineApi
     */
    public apiContentGuidelinesPut(guidelineModel?: GuidelineModel, options?: AxiosRequestConfig) {
        return GuidelineApiFp(this.configuration).apiContentGuidelinesPut(guidelineModel, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * InvoiceApi - axios parameter creator
 * @export
 */
export const InvoiceApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Download Invoice file
         * @param {string} key 
         * @param {FileResultType} fileResultType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPaymentInvoiceDownloadFileResultTypeKeyGet: async (key: string, fileResultType: FileResultType, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'key' is not null or undefined
            assertParamExists('apiPaymentInvoiceDownloadFileResultTypeKeyGet', 'key', key)
            // verify required parameter 'fileResultType' is not null or undefined
            assertParamExists('apiPaymentInvoiceDownloadFileResultTypeKeyGet', 'fileResultType', fileResultType)
            const localVarPath = `/api/payment/invoice/download/{fileResultType}/{key}`
                .replace(`{${"key"}}`, encodeURIComponent(String(key)))
                .replace(`{${"fileResultType"}}`, encodeURIComponent(String(fileResultType)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Download invoice
         * @param {string} [c] 
         * @param {string} [s] 
         * @param {number} [t] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPaymentInvoiceDownloadGet: async (c?: string, s?: string, t?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/payment/invoice/download`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (c !== undefined) {
                localVarQueryParameter['C'] = c;
            }

            if (s !== undefined) {
                localVarQueryParameter['S'] = s;
            }

            if (t !== undefined) {
                localVarQueryParameter['T'] = t;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Invoices
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {Array<string>} [orderBy] 
         * @param {InvoicesRequestModel} [invoicesRequestModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPaymentInvoiceInvoicesPost: async (pageNumber?: number, pageSize?: number, orderBy?: Array<string>, invoicesRequestModel?: InvoicesRequestModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/payment/invoice/invoices`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (pageNumber !== undefined) {
                localVarQueryParameter['PageNumber'] = pageNumber;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }

            if (orderBy) {
                localVarQueryParameter['OrderBy'] = orderBy;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(invoicesRequestModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Stripe Invoices
         * @param {string} stripeInvoiceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPaymentInvoiceStripeInvoiceDetailStripeInvoiceIdGet: async (stripeInvoiceId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'stripeInvoiceId' is not null or undefined
            assertParamExists('apiPaymentInvoiceStripeInvoiceDetailStripeInvoiceIdGet', 'stripeInvoiceId', stripeInvoiceId)
            const localVarPath = `/api/payment/invoice/stripe-invoice-detail/{stripeInvoiceId}`
                .replace(`{${"stripeInvoiceId"}}`, encodeURIComponent(String(stripeInvoiceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * InvoiceApi - functional programming interface
 * @export
 */
export const InvoiceApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = InvoiceApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Download Invoice file
         * @param {string} key 
         * @param {FileResultType} fileResultType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiPaymentInvoiceDownloadFileResultTypeKeyGet(key: string, fileResultType: FileResultType, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StringSuccessDataResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiPaymentInvoiceDownloadFileResultTypeKeyGet(key, fileResultType, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Download invoice
         * @param {string} [c] 
         * @param {string} [s] 
         * @param {number} [t] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiPaymentInvoiceDownloadGet(c?: string, s?: string, t?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiPaymentInvoiceDownloadGet(c, s, t, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Invoices
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {Array<string>} [orderBy] 
         * @param {InvoicesRequestModel} [invoicesRequestModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiPaymentInvoiceInvoicesPost(pageNumber?: number, pageSize?: number, orderBy?: Array<string>, invoicesRequestModel?: InvoicesRequestModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InvoiceModelPagedResultsIDataResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiPaymentInvoiceInvoicesPost(pageNumber, pageSize, orderBy, invoicesRequestModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Stripe Invoices
         * @param {string} stripeInvoiceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiPaymentInvoiceStripeInvoiceDetailStripeInvoiceIdGet(stripeInvoiceId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StripeInvoiceSearchResponseModelIDataResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiPaymentInvoiceStripeInvoiceDetailStripeInvoiceIdGet(stripeInvoiceId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * InvoiceApi - factory interface
 * @export
 */
export const InvoiceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = InvoiceApiFp(configuration)
    return {
        /**
         * 
         * @summary Download Invoice file
         * @param {string} key 
         * @param {FileResultType} fileResultType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPaymentInvoiceDownloadFileResultTypeKeyGet(key: string, fileResultType: FileResultType, options?: any): AxiosPromise<StringSuccessDataResult> {
            return localVarFp.apiPaymentInvoiceDownloadFileResultTypeKeyGet(key, fileResultType, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Download invoice
         * @param {string} [c] 
         * @param {string} [s] 
         * @param {number} [t] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPaymentInvoiceDownloadGet(c?: string, s?: string, t?: number, options?: any): AxiosPromise<object> {
            return localVarFp.apiPaymentInvoiceDownloadGet(c, s, t, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Invoices
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {Array<string>} [orderBy] 
         * @param {InvoicesRequestModel} [invoicesRequestModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPaymentInvoiceInvoicesPost(pageNumber?: number, pageSize?: number, orderBy?: Array<string>, invoicesRequestModel?: InvoicesRequestModel, options?: any): AxiosPromise<InvoiceModelPagedResultsIDataResult> {
            return localVarFp.apiPaymentInvoiceInvoicesPost(pageNumber, pageSize, orderBy, invoicesRequestModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Stripe Invoices
         * @param {string} stripeInvoiceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPaymentInvoiceStripeInvoiceDetailStripeInvoiceIdGet(stripeInvoiceId: string, options?: any): AxiosPromise<StripeInvoiceSearchResponseModelIDataResult> {
            return localVarFp.apiPaymentInvoiceStripeInvoiceDetailStripeInvoiceIdGet(stripeInvoiceId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * InvoiceApi - object-oriented interface
 * @export
 * @class InvoiceApi
 * @extends {BaseAPI}
 */
export class InvoiceApi extends BaseAPI {
    /**
     * 
     * @summary Download Invoice file
     * @param {string} key 
     * @param {FileResultType} fileResultType 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoiceApi
     */
    public apiPaymentInvoiceDownloadFileResultTypeKeyGet(key: string, fileResultType: FileResultType, options?: AxiosRequestConfig) {
        return InvoiceApiFp(this.configuration).apiPaymentInvoiceDownloadFileResultTypeKeyGet(key, fileResultType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Download invoice
     * @param {string} [c] 
     * @param {string} [s] 
     * @param {number} [t] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoiceApi
     */
    public apiPaymentInvoiceDownloadGet(c?: string, s?: string, t?: number, options?: AxiosRequestConfig) {
        return InvoiceApiFp(this.configuration).apiPaymentInvoiceDownloadGet(c, s, t, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Invoices
     * @param {number} [pageNumber] 
     * @param {number} [pageSize] 
     * @param {Array<string>} [orderBy] 
     * @param {InvoicesRequestModel} [invoicesRequestModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoiceApi
     */
    public apiPaymentInvoiceInvoicesPost(pageNumber?: number, pageSize?: number, orderBy?: Array<string>, invoicesRequestModel?: InvoicesRequestModel, options?: AxiosRequestConfig) {
        return InvoiceApiFp(this.configuration).apiPaymentInvoiceInvoicesPost(pageNumber, pageSize, orderBy, invoicesRequestModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Stripe Invoices
     * @param {string} stripeInvoiceId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvoiceApi
     */
    public apiPaymentInvoiceStripeInvoiceDetailStripeInvoiceIdGet(stripeInvoiceId: string, options?: AxiosRequestConfig) {
        return InvoiceApiFp(this.configuration).apiPaymentInvoiceStripeInvoiceDetailStripeInvoiceIdGet(stripeInvoiceId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * LiteCalculatorApi - axios parameter creator
 * @export
 */
export const LiteCalculatorApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {ConflictsLiteRequestModel} [conflictsLiteRequestModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiNcciCalculatorLiteConflictsAsHtmlPost: async (conflictsLiteRequestModel?: ConflictsLiteRequestModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/ncci/calculator/lite/conflicts/as-html`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(conflictsLiteRequestModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ConflictsLiteRequestModel} [conflictsLiteRequestModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiNcciCalculatorLiteConflictsAsPdfPost: async (conflictsLiteRequestModel?: ConflictsLiteRequestModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/ncci/calculator/lite/conflicts/as-pdf`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(conflictsLiteRequestModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ConflictsLiteRequestModel} [conflictsLiteRequestModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiNcciCalculatorLiteConflictsPost: async (conflictsLiteRequestModel?: ConflictsLiteRequestModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/ncci/calculator/lite/conflicts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(conflictsLiteRequestModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * LiteCalculatorApi - functional programming interface
 * @export
 */
export const LiteCalculatorApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = LiteCalculatorApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {ConflictsLiteRequestModel} [conflictsLiteRequestModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiNcciCalculatorLiteConflictsAsHtmlPost(conflictsLiteRequestModel?: ConflictsLiteRequestModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StringIDataResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiNcciCalculatorLiteConflictsAsHtmlPost(conflictsLiteRequestModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ConflictsLiteRequestModel} [conflictsLiteRequestModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiNcciCalculatorLiteConflictsAsPdfPost(conflictsLiteRequestModel?: ConflictsLiteRequestModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StringIDataResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiNcciCalculatorLiteConflictsAsPdfPost(conflictsLiteRequestModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ConflictsLiteRequestModel} [conflictsLiteRequestModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiNcciCalculatorLiteConflictsPost(conflictsLiteRequestModel?: ConflictsLiteRequestModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ConflictsResponseModelIEnumerableIDataResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiNcciCalculatorLiteConflictsPost(conflictsLiteRequestModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * LiteCalculatorApi - factory interface
 * @export
 */
export const LiteCalculatorApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = LiteCalculatorApiFp(configuration)
    return {
        /**
         * 
         * @param {ConflictsLiteRequestModel} [conflictsLiteRequestModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiNcciCalculatorLiteConflictsAsHtmlPost(conflictsLiteRequestModel?: ConflictsLiteRequestModel, options?: any): AxiosPromise<StringIDataResult> {
            return localVarFp.apiNcciCalculatorLiteConflictsAsHtmlPost(conflictsLiteRequestModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ConflictsLiteRequestModel} [conflictsLiteRequestModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiNcciCalculatorLiteConflictsAsPdfPost(conflictsLiteRequestModel?: ConflictsLiteRequestModel, options?: any): AxiosPromise<StringIDataResult> {
            return localVarFp.apiNcciCalculatorLiteConflictsAsPdfPost(conflictsLiteRequestModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ConflictsLiteRequestModel} [conflictsLiteRequestModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiNcciCalculatorLiteConflictsPost(conflictsLiteRequestModel?: ConflictsLiteRequestModel, options?: any): AxiosPromise<ConflictsResponseModelIEnumerableIDataResult> {
            return localVarFp.apiNcciCalculatorLiteConflictsPost(conflictsLiteRequestModel, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * LiteCalculatorApi - object-oriented interface
 * @export
 * @class LiteCalculatorApi
 * @extends {BaseAPI}
 */
export class LiteCalculatorApi extends BaseAPI {
    /**
     * 
     * @param {ConflictsLiteRequestModel} [conflictsLiteRequestModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LiteCalculatorApi
     */
    public apiNcciCalculatorLiteConflictsAsHtmlPost(conflictsLiteRequestModel?: ConflictsLiteRequestModel, options?: AxiosRequestConfig) {
        return LiteCalculatorApiFp(this.configuration).apiNcciCalculatorLiteConflictsAsHtmlPost(conflictsLiteRequestModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ConflictsLiteRequestModel} [conflictsLiteRequestModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LiteCalculatorApi
     */
    public apiNcciCalculatorLiteConflictsAsPdfPost(conflictsLiteRequestModel?: ConflictsLiteRequestModel, options?: AxiosRequestConfig) {
        return LiteCalculatorApiFp(this.configuration).apiNcciCalculatorLiteConflictsAsPdfPost(conflictsLiteRequestModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ConflictsLiteRequestModel} [conflictsLiteRequestModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LiteCalculatorApi
     */
    public apiNcciCalculatorLiteConflictsPost(conflictsLiteRequestModel?: ConflictsLiteRequestModel, options?: AxiosRequestConfig) {
        return LiteCalculatorApiFp(this.configuration).apiNcciCalculatorLiteConflictsPost(conflictsLiteRequestModel, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * MessageApi - axios parameter creator
 * @export
 */
export const MessageApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Send Message
         * @param {MessageSendRequest} [messageSendRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiContentMessagesPost: async (messageSendRequest?: MessageSendRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/content/messages`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(messageSendRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MessageApi - functional programming interface
 * @export
 */
export const MessageApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MessageApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Send Message
         * @param {MessageSendRequest} [messageSendRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiContentMessagesPost(messageSendRequest?: MessageSendRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiContentMessagesPost(messageSendRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * MessageApi - factory interface
 * @export
 */
export const MessageApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MessageApiFp(configuration)
    return {
        /**
         * 
         * @summary Send Message
         * @param {MessageSendRequest} [messageSendRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiContentMessagesPost(messageSendRequest?: MessageSendRequest, options?: any): AxiosPromise<void> {
            return localVarFp.apiContentMessagesPost(messageSendRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * MessageApi - object-oriented interface
 * @export
 * @class MessageApi
 * @extends {BaseAPI}
 */
export class MessageApi extends BaseAPI {
    /**
     * 
     * @summary Send Message
     * @param {MessageSendRequest} [messageSendRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MessageApi
     */
    public apiContentMessagesPost(messageSendRequest?: MessageSendRequest, options?: AxiosRequestConfig) {
        return MessageApiFp(this.configuration).apiContentMessagesPost(messageSendRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * MsdrgApi - axios parameter creator
 * @export
 */
export const MsdrgApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {MsdrgCalculateQuery} msdrgCalculateQuery 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        calculate: async (msdrgCalculateQuery: MsdrgCalculateQuery, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'msdrgCalculateQuery' is not null or undefined
            assertParamExists('calculate', 'msdrgCalculateQuery', msdrgCalculateQuery)
            const localVarPath = `/api/msdrg/calculate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(msdrgCalculateQuery, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {MsdrgCalculateQuery} msdrgCalculateQuery 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        calculateAsHtml: async (msdrgCalculateQuery: MsdrgCalculateQuery, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'msdrgCalculateQuery' is not null or undefined
            assertParamExists('calculateAsHtml', 'msdrgCalculateQuery', msdrgCalculateQuery)
            const localVarPath = `/api/msdrg/calculate/as-html`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(msdrgCalculateQuery, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {MsdrgCalculateQuery} msdrgCalculateQuery 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        calculateAsPdf: async (msdrgCalculateQuery: MsdrgCalculateQuery, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'msdrgCalculateQuery' is not null or undefined
            assertParamExists('calculateAsPdf', 'msdrgCalculateQuery', msdrgCalculateQuery)
            const localVarPath = `/api/msdrg/calculate/as-pdf`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(msdrgCalculateQuery, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MsdrgApi - functional programming interface
 * @export
 */
export const MsdrgApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MsdrgApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {MsdrgCalculateQuery} msdrgCalculateQuery 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async calculate(msdrgCalculateQuery: MsdrgCalculateQuery, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MsdrgCalculateQueryResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.calculate(msdrgCalculateQuery, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {MsdrgCalculateQuery} msdrgCalculateQuery 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async calculateAsHtml(msdrgCalculateQuery: MsdrgCalculateQuery, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MsdrgCalculateQueryPlainResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.calculateAsHtml(msdrgCalculateQuery, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {MsdrgCalculateQuery} msdrgCalculateQuery 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async calculateAsPdf(msdrgCalculateQuery: MsdrgCalculateQuery, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MsdrgCalculateQueryPlainResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.calculateAsPdf(msdrgCalculateQuery, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * MsdrgApi - factory interface
 * @export
 */
export const MsdrgApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MsdrgApiFp(configuration)
    return {
        /**
         * 
         * @param {MsdrgCalculateQuery} msdrgCalculateQuery 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        calculate(msdrgCalculateQuery: MsdrgCalculateQuery, options?: any): AxiosPromise<MsdrgCalculateQueryResult> {
            return localVarFp.calculate(msdrgCalculateQuery, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {MsdrgCalculateQuery} msdrgCalculateQuery 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        calculateAsHtml(msdrgCalculateQuery: MsdrgCalculateQuery, options?: any): AxiosPromise<MsdrgCalculateQueryPlainResult> {
            return localVarFp.calculateAsHtml(msdrgCalculateQuery, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {MsdrgCalculateQuery} msdrgCalculateQuery 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        calculateAsPdf(msdrgCalculateQuery: MsdrgCalculateQuery, options?: any): AxiosPromise<MsdrgCalculateQueryPlainResult> {
            return localVarFp.calculateAsPdf(msdrgCalculateQuery, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * MsdrgApi - object-oriented interface
 * @export
 * @class MsdrgApi
 * @extends {BaseAPI}
 */
export class MsdrgApi extends BaseAPI {
    /**
     * 
     * @param {MsdrgCalculateQuery} msdrgCalculateQuery 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MsdrgApi
     */
    public calculate(msdrgCalculateQuery: MsdrgCalculateQuery, options?: AxiosRequestConfig) {
        return MsdrgApiFp(this.configuration).calculate(msdrgCalculateQuery, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MsdrgCalculateQuery} msdrgCalculateQuery 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MsdrgApi
     */
    public calculateAsHtml(msdrgCalculateQuery: MsdrgCalculateQuery, options?: AxiosRequestConfig) {
        return MsdrgApiFp(this.configuration).calculateAsHtml(msdrgCalculateQuery, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MsdrgCalculateQuery} msdrgCalculateQuery 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MsdrgApi
     */
    public calculateAsPdf(msdrgCalculateQuery: MsdrgCalculateQuery, options?: AxiosRequestConfig) {
        return MsdrgApiFp(this.configuration).calculateAsPdf(msdrgCalculateQuery, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * NewsletterApi - axios parameter creator
 * @export
 */
export const NewsletterApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Add newsletter
         * @param {NewsletterRequestModel} [newsletterRequestModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAccountNewsletterPost: async (newsletterRequestModel?: NewsletterRequestModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/account/newsletter`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(newsletterRequestModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * NewsletterApi - functional programming interface
 * @export
 */
export const NewsletterApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = NewsletterApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Add newsletter
         * @param {NewsletterRequestModel} [newsletterRequestModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAccountNewsletterPost(newsletterRequestModel?: NewsletterRequestModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NewsletterModelIDataResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAccountNewsletterPost(newsletterRequestModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * NewsletterApi - factory interface
 * @export
 */
export const NewsletterApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = NewsletterApiFp(configuration)
    return {
        /**
         * 
         * @summary Add newsletter
         * @param {NewsletterRequestModel} [newsletterRequestModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAccountNewsletterPost(newsletterRequestModel?: NewsletterRequestModel, options?: any): AxiosPromise<NewsletterModelIDataResult> {
            return localVarFp.apiAccountNewsletterPost(newsletterRequestModel, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * NewsletterApi - object-oriented interface
 * @export
 * @class NewsletterApi
 * @extends {BaseAPI}
 */
export class NewsletterApi extends BaseAPI {
    /**
     * 
     * @summary Add newsletter
     * @param {NewsletterRequestModel} [newsletterRequestModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NewsletterApi
     */
    public apiAccountNewsletterPost(newsletterRequestModel?: NewsletterRequestModel, options?: AxiosRequestConfig) {
        return NewsletterApiFp(this.configuration).apiAccountNewsletterPost(newsletterRequestModel, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * NotificationApi - axios parameter creator
 * @export
 */
export const NotificationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get notifications
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiContentNotificationAllGet: async (pageNumber?: number, pageSize?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/content/notification/all`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (pageNumber !== undefined) {
                localVarQueryParameter['PageNumber'] = pageNumber;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get user notification
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {boolean} [onlyUnread] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiContentNotificationGet: async (pageNumber?: number, pageSize?: number, onlyUnread?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/content/notification`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (pageNumber !== undefined) {
                localVarQueryParameter['PageNumber'] = pageNumber;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }

            if (onlyUnread !== undefined) {
                localVarQueryParameter['onlyUnread'] = onlyUnread;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get user notification
         * @param {MarkAsReadNotificationRequestModel} [markAsReadNotificationRequestModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiContentNotificationMarkAsReadPut: async (markAsReadNotificationRequestModel?: MarkAsReadNotificationRequestModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/content/notification/mark-as-read`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(markAsReadNotificationRequestModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * NotificationApi - functional programming interface
 * @export
 */
export const NotificationApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = NotificationApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get notifications
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiContentNotificationAllGet(pageNumber?: number, pageSize?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NotificationModelPagedResultsIDataResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiContentNotificationAllGet(pageNumber, pageSize, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get user notification
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {boolean} [onlyUnread] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiContentNotificationGet(pageNumber?: number, pageSize?: number, onlyUnread?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NotificationModelPagedResultsIDataResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiContentNotificationGet(pageNumber, pageSize, onlyUnread, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get user notification
         * @param {MarkAsReadNotificationRequestModel} [markAsReadNotificationRequestModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiContentNotificationMarkAsReadPut(markAsReadNotificationRequestModel?: MarkAsReadNotificationRequestModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MarkAsReadNotificationResponseModelIDataResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiContentNotificationMarkAsReadPut(markAsReadNotificationRequestModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * NotificationApi - factory interface
 * @export
 */
export const NotificationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = NotificationApiFp(configuration)
    return {
        /**
         * 
         * @summary Get notifications
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiContentNotificationAllGet(pageNumber?: number, pageSize?: number, options?: any): AxiosPromise<NotificationModelPagedResultsIDataResult> {
            return localVarFp.apiContentNotificationAllGet(pageNumber, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get user notification
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {boolean} [onlyUnread] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiContentNotificationGet(pageNumber?: number, pageSize?: number, onlyUnread?: boolean, options?: any): AxiosPromise<NotificationModelPagedResultsIDataResult> {
            return localVarFp.apiContentNotificationGet(pageNumber, pageSize, onlyUnread, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get user notification
         * @param {MarkAsReadNotificationRequestModel} [markAsReadNotificationRequestModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiContentNotificationMarkAsReadPut(markAsReadNotificationRequestModel?: MarkAsReadNotificationRequestModel, options?: any): AxiosPromise<MarkAsReadNotificationResponseModelIDataResult> {
            return localVarFp.apiContentNotificationMarkAsReadPut(markAsReadNotificationRequestModel, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * NotificationApi - object-oriented interface
 * @export
 * @class NotificationApi
 * @extends {BaseAPI}
 */
export class NotificationApi extends BaseAPI {
    /**
     * 
     * @summary Get notifications
     * @param {number} [pageNumber] 
     * @param {number} [pageSize] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationApi
     */
    public apiContentNotificationAllGet(pageNumber?: number, pageSize?: number, options?: AxiosRequestConfig) {
        return NotificationApiFp(this.configuration).apiContentNotificationAllGet(pageNumber, pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get user notification
     * @param {number} [pageNumber] 
     * @param {number} [pageSize] 
     * @param {boolean} [onlyUnread] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationApi
     */
    public apiContentNotificationGet(pageNumber?: number, pageSize?: number, onlyUnread?: boolean, options?: AxiosRequestConfig) {
        return NotificationApiFp(this.configuration).apiContentNotificationGet(pageNumber, pageSize, onlyUnread, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get user notification
     * @param {MarkAsReadNotificationRequestModel} [markAsReadNotificationRequestModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationApi
     */
    public apiContentNotificationMarkAsReadPut(markAsReadNotificationRequestModel?: MarkAsReadNotificationRequestModel, options?: AxiosRequestConfig) {
        return NotificationApiFp(this.configuration).apiContentNotificationMarkAsReadPut(markAsReadNotificationRequestModel, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * NpiApi - axios parameter creator
 * @export
 */
export const NpiApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} [npiNumber] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCodesNpisGet: async (npiNumber?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/codes/npis`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (npiNumber !== undefined) {
                localVarQueryParameter['NpiNumber'] = npiNumber;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCodesNpisIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiCodesNpisIdGet', 'id', id)
            const localVarPath = `/api/codes/npis/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * NpiApi - functional programming interface
 * @export
 */
export const NpiApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = NpiApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} [npiNumber] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCodesNpisGet(npiNumber?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NpiModelIDataResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCodesNpisGet(npiNumber, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCodesNpisIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NpiModelIDataResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCodesNpisIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * NpiApi - factory interface
 * @export
 */
export const NpiApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = NpiApiFp(configuration)
    return {
        /**
         * 
         * @param {number} [npiNumber] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCodesNpisGet(npiNumber?: number, options?: any): AxiosPromise<NpiModelIDataResult> {
            return localVarFp.apiCodesNpisGet(npiNumber, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCodesNpisIdGet(id: string, options?: any): AxiosPromise<NpiModelIDataResult> {
            return localVarFp.apiCodesNpisIdGet(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * NpiApi - object-oriented interface
 * @export
 * @class NpiApi
 * @extends {BaseAPI}
 */
export class NpiApi extends BaseAPI {
    /**
     * 
     * @param {number} [npiNumber] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NpiApi
     */
    public apiCodesNpisGet(npiNumber?: number, options?: AxiosRequestConfig) {
        return NpiApiFp(this.configuration).apiCodesNpisGet(npiNumber, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NpiApi
     */
    public apiCodesNpisIdGet(id: string, options?: AxiosRequestConfig) {
        return NpiApiFp(this.configuration).apiCodesNpisIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PackageApi - axios parameter creator
 * @export
 */
export const PackageApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get all packages
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPaymentPackagesGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/payment/packages`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete a single package
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPaymentPackagesIdDelete: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiPaymentPackagesIdDelete', 'id', id)
            const localVarPath = `/api/payment/packages/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a single package
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPaymentPackagesIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiPaymentPackagesIdGet', 'id', id)
            const localVarPath = `/api/payment/packages/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Add a package
         * @param {PackageModel} [packageModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPaymentPackagesPost: async (packageModel?: PackageModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/payment/packages`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(packageModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update package
         * @param {PackageModel} [packageModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPaymentPackagesPut: async (packageModel?: PackageModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/payment/packages`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(packageModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PackageApi - functional programming interface
 * @export
 */
export const PackageApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PackageApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get all packages
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiPaymentPackagesGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PackageModelIEnumerableIDataResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiPaymentPackagesGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete a single package
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiPaymentPackagesIdDelete(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiPaymentPackagesIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get a single package
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiPaymentPackagesIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PackageModelIDataResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiPaymentPackagesIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Add a package
         * @param {PackageModel} [packageModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiPaymentPackagesPost(packageModel?: PackageModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PackageModelIDataResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiPaymentPackagesPost(packageModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update package
         * @param {PackageModel} [packageModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiPaymentPackagesPut(packageModel?: PackageModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PackageModelIDataResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiPaymentPackagesPut(packageModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PackageApi - factory interface
 * @export
 */
export const PackageApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PackageApiFp(configuration)
    return {
        /**
         * 
         * @summary Get all packages
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPaymentPackagesGet(options?: any): AxiosPromise<PackageModelIEnumerableIDataResult> {
            return localVarFp.apiPaymentPackagesGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete a single package
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPaymentPackagesIdDelete(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiPaymentPackagesIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get a single package
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPaymentPackagesIdGet(id: string, options?: any): AxiosPromise<PackageModelIDataResult> {
            return localVarFp.apiPaymentPackagesIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Add a package
         * @param {PackageModel} [packageModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPaymentPackagesPost(packageModel?: PackageModel, options?: any): AxiosPromise<PackageModelIDataResult> {
            return localVarFp.apiPaymentPackagesPost(packageModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update package
         * @param {PackageModel} [packageModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPaymentPackagesPut(packageModel?: PackageModel, options?: any): AxiosPromise<PackageModelIDataResult> {
            return localVarFp.apiPaymentPackagesPut(packageModel, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PackageApi - object-oriented interface
 * @export
 * @class PackageApi
 * @extends {BaseAPI}
 */
export class PackageApi extends BaseAPI {
    /**
     * 
     * @summary Get all packages
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PackageApi
     */
    public apiPaymentPackagesGet(options?: AxiosRequestConfig) {
        return PackageApiFp(this.configuration).apiPaymentPackagesGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete a single package
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PackageApi
     */
    public apiPaymentPackagesIdDelete(id: string, options?: AxiosRequestConfig) {
        return PackageApiFp(this.configuration).apiPaymentPackagesIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get a single package
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PackageApi
     */
    public apiPaymentPackagesIdGet(id: string, options?: AxiosRequestConfig) {
        return PackageApiFp(this.configuration).apiPaymentPackagesIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Add a package
     * @param {PackageModel} [packageModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PackageApi
     */
    public apiPaymentPackagesPost(packageModel?: PackageModel, options?: AxiosRequestConfig) {
        return PackageApiFp(this.configuration).apiPaymentPackagesPost(packageModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update package
     * @param {PackageModel} [packageModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PackageApi
     */
    public apiPaymentPackagesPut(packageModel?: PackageModel, options?: AxiosRequestConfig) {
        return PackageApiFp(this.configuration).apiPaymentPackagesPut(packageModel, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PaymentApi - axios parameter creator
 * @export
 */
export const PaymentApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Webhook for stripe
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPaymentWebhookPost: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/payment/webhook`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PaymentApi - functional programming interface
 * @export
 */
export const PaymentApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PaymentApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Webhook for stripe
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiPaymentWebhookPost(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiPaymentWebhookPost(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PaymentApi - factory interface
 * @export
 */
export const PaymentApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PaymentApiFp(configuration)
    return {
        /**
         * 
         * @summary Webhook for stripe
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPaymentWebhookPost(options?: any): AxiosPromise<void> {
            return localVarFp.apiPaymentWebhookPost(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PaymentApi - object-oriented interface
 * @export
 * @class PaymentApi
 * @extends {BaseAPI}
 */
export class PaymentApi extends BaseAPI {
    /**
     * 
     * @summary Webhook for stripe
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentApi
     */
    public apiPaymentWebhookPost(options?: AxiosRequestConfig) {
        return PaymentApiFp(this.configuration).apiPaymentWebhookPost(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ProCalculatorApi - axios parameter creator
 * @export
 */
export const ProCalculatorApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {ConflictsRequestModel} [conflictsRequestModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiNcciCalculatorProConflictsAsHtmlPost: async (conflictsRequestModel?: ConflictsRequestModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/ncci/calculator/pro/conflicts/as-html`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(conflictsRequestModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ConflictsRequestModel} [conflictsRequestModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiNcciCalculatorProConflictsAsPdfPost: async (conflictsRequestModel?: ConflictsRequestModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/ncci/calculator/pro/conflicts/as-pdf`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(conflictsRequestModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ConflictsRequestModel} [conflictsRequestModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiNcciCalculatorProConflictsPost: async (conflictsRequestModel?: ConflictsRequestModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/ncci/calculator/pro/conflicts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(conflictsRequestModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ProCalculatorApi - functional programming interface
 * @export
 */
export const ProCalculatorApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ProCalculatorApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {ConflictsRequestModel} [conflictsRequestModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiNcciCalculatorProConflictsAsHtmlPost(conflictsRequestModel?: ConflictsRequestModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StringIDataResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiNcciCalculatorProConflictsAsHtmlPost(conflictsRequestModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ConflictsRequestModel} [conflictsRequestModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiNcciCalculatorProConflictsAsPdfPost(conflictsRequestModel?: ConflictsRequestModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StringIDataResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiNcciCalculatorProConflictsAsPdfPost(conflictsRequestModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ConflictsRequestModel} [conflictsRequestModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiNcciCalculatorProConflictsPost(conflictsRequestModel?: ConflictsRequestModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ConflictsResponseModelIEnumerableIDataResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiNcciCalculatorProConflictsPost(conflictsRequestModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ProCalculatorApi - factory interface
 * @export
 */
export const ProCalculatorApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ProCalculatorApiFp(configuration)
    return {
        /**
         * 
         * @param {ConflictsRequestModel} [conflictsRequestModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiNcciCalculatorProConflictsAsHtmlPost(conflictsRequestModel?: ConflictsRequestModel, options?: any): AxiosPromise<StringIDataResult> {
            return localVarFp.apiNcciCalculatorProConflictsAsHtmlPost(conflictsRequestModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ConflictsRequestModel} [conflictsRequestModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiNcciCalculatorProConflictsAsPdfPost(conflictsRequestModel?: ConflictsRequestModel, options?: any): AxiosPromise<StringIDataResult> {
            return localVarFp.apiNcciCalculatorProConflictsAsPdfPost(conflictsRequestModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ConflictsRequestModel} [conflictsRequestModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiNcciCalculatorProConflictsPost(conflictsRequestModel?: ConflictsRequestModel, options?: any): AxiosPromise<ConflictsResponseModelIEnumerableIDataResult> {
            return localVarFp.apiNcciCalculatorProConflictsPost(conflictsRequestModel, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ProCalculatorApi - object-oriented interface
 * @export
 * @class ProCalculatorApi
 * @extends {BaseAPI}
 */
export class ProCalculatorApi extends BaseAPI {
    /**
     * 
     * @param {ConflictsRequestModel} [conflictsRequestModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProCalculatorApi
     */
    public apiNcciCalculatorProConflictsAsHtmlPost(conflictsRequestModel?: ConflictsRequestModel, options?: AxiosRequestConfig) {
        return ProCalculatorApiFp(this.configuration).apiNcciCalculatorProConflictsAsHtmlPost(conflictsRequestModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ConflictsRequestModel} [conflictsRequestModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProCalculatorApi
     */
    public apiNcciCalculatorProConflictsAsPdfPost(conflictsRequestModel?: ConflictsRequestModel, options?: AxiosRequestConfig) {
        return ProCalculatorApiFp(this.configuration).apiNcciCalculatorProConflictsAsPdfPost(conflictsRequestModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ConflictsRequestModel} [conflictsRequestModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProCalculatorApi
     */
    public apiNcciCalculatorProConflictsPost(conflictsRequestModel?: ConflictsRequestModel, options?: AxiosRequestConfig) {
        return ProCalculatorApiFp(this.configuration).apiNcciCalculatorProConflictsPost(conflictsRequestModel, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ProceduralCodesApi - axios parameter creator
 * @export
 */
export const ProceduralCodesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {ProceduralCodeRequestModel} [proceduralCodeRequestModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiNcciProceduralCodesCheckPost: async (proceduralCodeRequestModel?: ProceduralCodeRequestModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/ncci/procedural-codes/check`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(proceduralCodeRequestModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiNcciProceduralCodesGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/ncci/procedural-codes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiNcciProceduralCodesIdDelete: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiNcciProceduralCodesIdDelete', 'id', id)
            const localVarPath = `/api/ncci/procedural-codes/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiNcciProceduralCodesNcciModifiersGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/ncci/procedural-codes/ncci-modifiers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ProceduralCodeModel} [proceduralCodeModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiNcciProceduralCodesPost: async (proceduralCodeModel?: ProceduralCodeModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/ncci/procedural-codes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(proceduralCodeModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ProceduralCodeModel} [proceduralCodeModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiNcciProceduralCodesPut: async (proceduralCodeModel?: ProceduralCodeModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/ncci/procedural-codes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(proceduralCodeModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ProceduralCodesApi - functional programming interface
 * @export
 */
export const ProceduralCodesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ProceduralCodesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {ProceduralCodeRequestModel} [proceduralCodeRequestModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiNcciProceduralCodesCheckPost(proceduralCodeRequestModel?: ProceduralCodeRequestModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProceduralCodeModelIEnumerableIDataResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiNcciProceduralCodesCheckPost(proceduralCodeRequestModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiNcciProceduralCodesGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProceduralCodeModelIEnumerableIDataResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiNcciProceduralCodesGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiNcciProceduralCodesIdDelete(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiNcciProceduralCodesIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiNcciProceduralCodesNcciModifiersGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NcciModifierCodeModelIEnumerableIDataResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiNcciProceduralCodesNcciModifiersGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ProceduralCodeModel} [proceduralCodeModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiNcciProceduralCodesPost(proceduralCodeModel?: ProceduralCodeModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProceduralCodeModelIDataResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiNcciProceduralCodesPost(proceduralCodeModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ProceduralCodeModel} [proceduralCodeModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiNcciProceduralCodesPut(proceduralCodeModel?: ProceduralCodeModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiNcciProceduralCodesPut(proceduralCodeModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ProceduralCodesApi - factory interface
 * @export
 */
export const ProceduralCodesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ProceduralCodesApiFp(configuration)
    return {
        /**
         * 
         * @param {ProceduralCodeRequestModel} [proceduralCodeRequestModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiNcciProceduralCodesCheckPost(proceduralCodeRequestModel?: ProceduralCodeRequestModel, options?: any): AxiosPromise<ProceduralCodeModelIEnumerableIDataResult> {
            return localVarFp.apiNcciProceduralCodesCheckPost(proceduralCodeRequestModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiNcciProceduralCodesGet(options?: any): AxiosPromise<ProceduralCodeModelIEnumerableIDataResult> {
            return localVarFp.apiNcciProceduralCodesGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiNcciProceduralCodesIdDelete(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiNcciProceduralCodesIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiNcciProceduralCodesNcciModifiersGet(options?: any): AxiosPromise<NcciModifierCodeModelIEnumerableIDataResult> {
            return localVarFp.apiNcciProceduralCodesNcciModifiersGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ProceduralCodeModel} [proceduralCodeModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiNcciProceduralCodesPost(proceduralCodeModel?: ProceduralCodeModel, options?: any): AxiosPromise<ProceduralCodeModelIDataResult> {
            return localVarFp.apiNcciProceduralCodesPost(proceduralCodeModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ProceduralCodeModel} [proceduralCodeModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiNcciProceduralCodesPut(proceduralCodeModel?: ProceduralCodeModel, options?: any): AxiosPromise<void> {
            return localVarFp.apiNcciProceduralCodesPut(proceduralCodeModel, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ProceduralCodesApi - object-oriented interface
 * @export
 * @class ProceduralCodesApi
 * @extends {BaseAPI}
 */
export class ProceduralCodesApi extends BaseAPI {
    /**
     * 
     * @param {ProceduralCodeRequestModel} [proceduralCodeRequestModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProceduralCodesApi
     */
    public apiNcciProceduralCodesCheckPost(proceduralCodeRequestModel?: ProceduralCodeRequestModel, options?: AxiosRequestConfig) {
        return ProceduralCodesApiFp(this.configuration).apiNcciProceduralCodesCheckPost(proceduralCodeRequestModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProceduralCodesApi
     */
    public apiNcciProceduralCodesGet(options?: AxiosRequestConfig) {
        return ProceduralCodesApiFp(this.configuration).apiNcciProceduralCodesGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProceduralCodesApi
     */
    public apiNcciProceduralCodesIdDelete(id: string, options?: AxiosRequestConfig) {
        return ProceduralCodesApiFp(this.configuration).apiNcciProceduralCodesIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProceduralCodesApi
     */
    public apiNcciProceduralCodesNcciModifiersGet(options?: AxiosRequestConfig) {
        return ProceduralCodesApiFp(this.configuration).apiNcciProceduralCodesNcciModifiersGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ProceduralCodeModel} [proceduralCodeModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProceduralCodesApi
     */
    public apiNcciProceduralCodesPost(proceduralCodeModel?: ProceduralCodeModel, options?: AxiosRequestConfig) {
        return ProceduralCodesApiFp(this.configuration).apiNcciProceduralCodesPost(proceduralCodeModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ProceduralCodeModel} [proceduralCodeModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProceduralCodesApi
     */
    public apiNcciProceduralCodesPut(proceduralCodeModel?: ProceduralCodeModel, options?: AxiosRequestConfig) {
        return ProceduralCodesApiFp(this.configuration).apiNcciProceduralCodesPut(proceduralCodeModel, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ProductApi - axios parameter creator
 * @export
 */
export const ProductApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Delete a single product
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPaymentProductsIdDelete: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiPaymentProductsIdDelete', 'id', id)
            const localVarPath = `/api/payment/products/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get single product detail
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPaymentProductsIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiPaymentProductsIdGet', 'id', id)
            const localVarPath = `/api/payment/products/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Add product
         * @param {ProductModel} [productModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPaymentProductsPost: async (productModel?: ProductModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/payment/products`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(productModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update product.
         * @param {ProductModel} [productModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPaymentProductsPut: async (productModel?: ProductModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/payment/products`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(productModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get products
         * @param {boolean} showAll 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPaymentProductsShowAllGet: async (showAll: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'showAll' is not null or undefined
            assertParamExists('apiPaymentProductsShowAllGet', 'showAll', showAll)
            const localVarPath = `/api/payment/products/{showAll}`
                .replace(`{${"showAll"}}`, encodeURIComponent(String(showAll)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ProductApi - functional programming interface
 * @export
 */
export const ProductApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ProductApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Delete a single product
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiPaymentProductsIdDelete(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiPaymentProductsIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get single product detail
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiPaymentProductsIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductModelIDataResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiPaymentProductsIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Add product
         * @param {ProductModel} [productModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiPaymentProductsPost(productModel?: ProductModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductModelIDataResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiPaymentProductsPost(productModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update product.
         * @param {ProductModel} [productModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiPaymentProductsPut(productModel?: ProductModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductModelIDataResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiPaymentProductsPut(productModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get products
         * @param {boolean} showAll 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiPaymentProductsShowAllGet(showAll: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductModelIEnumerableIDataResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiPaymentProductsShowAllGet(showAll, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ProductApi - factory interface
 * @export
 */
export const ProductApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ProductApiFp(configuration)
    return {
        /**
         * 
         * @summary Delete a single product
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPaymentProductsIdDelete(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiPaymentProductsIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get single product detail
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPaymentProductsIdGet(id: string, options?: any): AxiosPromise<ProductModelIDataResult> {
            return localVarFp.apiPaymentProductsIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Add product
         * @param {ProductModel} [productModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPaymentProductsPost(productModel?: ProductModel, options?: any): AxiosPromise<ProductModelIDataResult> {
            return localVarFp.apiPaymentProductsPost(productModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update product.
         * @param {ProductModel} [productModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPaymentProductsPut(productModel?: ProductModel, options?: any): AxiosPromise<ProductModelIDataResult> {
            return localVarFp.apiPaymentProductsPut(productModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get products
         * @param {boolean} showAll 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPaymentProductsShowAllGet(showAll: boolean, options?: any): AxiosPromise<ProductModelIEnumerableIDataResult> {
            return localVarFp.apiPaymentProductsShowAllGet(showAll, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ProductApi - object-oriented interface
 * @export
 * @class ProductApi
 * @extends {BaseAPI}
 */
export class ProductApi extends BaseAPI {
    /**
     * 
     * @summary Delete a single product
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductApi
     */
    public apiPaymentProductsIdDelete(id: string, options?: AxiosRequestConfig) {
        return ProductApiFp(this.configuration).apiPaymentProductsIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get single product detail
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductApi
     */
    public apiPaymentProductsIdGet(id: string, options?: AxiosRequestConfig) {
        return ProductApiFp(this.configuration).apiPaymentProductsIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Add product
     * @param {ProductModel} [productModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductApi
     */
    public apiPaymentProductsPost(productModel?: ProductModel, options?: AxiosRequestConfig) {
        return ProductApiFp(this.configuration).apiPaymentProductsPost(productModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update product.
     * @param {ProductModel} [productModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductApi
     */
    public apiPaymentProductsPut(productModel?: ProductModel, options?: AxiosRequestConfig) {
        return ProductApiFp(this.configuration).apiPaymentProductsPut(productModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get products
     * @param {boolean} showAll 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductApi
     */
    public apiPaymentProductsShowAllGet(showAll: boolean, options?: AxiosRequestConfig) {
        return ProductApiFp(this.configuration).apiPaymentProductsShowAllGet(showAll, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ProductPackageApi - axios parameter creator
 * @export
 */
export const ProductPackageApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get product package
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPaymentProductPackagesGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/payment/product-packages`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete a product package
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPaymentProductPackagesIdDelete: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiPaymentProductPackagesIdDelete', 'id', id)
            const localVarPath = `/api/payment/product-packages/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get single product package detail.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPaymentProductPackagesIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiPaymentProductPackagesIdGet', 'id', id)
            const localVarPath = `/api/payment/product-packages/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Add a new product package
         * @param {ProductPackageModel} [productPackageModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPaymentProductPackagesPost: async (productPackageModel?: ProductPackageModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/payment/product-packages`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(productPackageModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update product packages
         * @param {ProductPackageModel} [productPackageModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPaymentProductPackagesPut: async (productPackageModel?: ProductPackageModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/payment/product-packages`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(productPackageModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ProductPackageApi - functional programming interface
 * @export
 */
export const ProductPackageApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ProductPackageApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get product package
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiPaymentProductPackagesGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductPackageModelIEnumerableIDataResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiPaymentProductPackagesGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete a product package
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiPaymentProductPackagesIdDelete(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiPaymentProductPackagesIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get single product package detail.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiPaymentProductPackagesIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductPackageModelIDataResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiPaymentProductPackagesIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Add a new product package
         * @param {ProductPackageModel} [productPackageModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiPaymentProductPackagesPost(productPackageModel?: ProductPackageModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductPackageModelIDataResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiPaymentProductPackagesPost(productPackageModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update product packages
         * @param {ProductPackageModel} [productPackageModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiPaymentProductPackagesPut(productPackageModel?: ProductPackageModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductPackageModelIDataResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiPaymentProductPackagesPut(productPackageModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ProductPackageApi - factory interface
 * @export
 */
export const ProductPackageApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ProductPackageApiFp(configuration)
    return {
        /**
         * 
         * @summary Get product package
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPaymentProductPackagesGet(options?: any): AxiosPromise<ProductPackageModelIEnumerableIDataResult> {
            return localVarFp.apiPaymentProductPackagesGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete a product package
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPaymentProductPackagesIdDelete(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiPaymentProductPackagesIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get single product package detail.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPaymentProductPackagesIdGet(id: string, options?: any): AxiosPromise<ProductPackageModelIDataResult> {
            return localVarFp.apiPaymentProductPackagesIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Add a new product package
         * @param {ProductPackageModel} [productPackageModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPaymentProductPackagesPost(productPackageModel?: ProductPackageModel, options?: any): AxiosPromise<ProductPackageModelIDataResult> {
            return localVarFp.apiPaymentProductPackagesPost(productPackageModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update product packages
         * @param {ProductPackageModel} [productPackageModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPaymentProductPackagesPut(productPackageModel?: ProductPackageModel, options?: any): AxiosPromise<ProductPackageModelIDataResult> {
            return localVarFp.apiPaymentProductPackagesPut(productPackageModel, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ProductPackageApi - object-oriented interface
 * @export
 * @class ProductPackageApi
 * @extends {BaseAPI}
 */
export class ProductPackageApi extends BaseAPI {
    /**
     * 
     * @summary Get product package
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductPackageApi
     */
    public apiPaymentProductPackagesGet(options?: AxiosRequestConfig) {
        return ProductPackageApiFp(this.configuration).apiPaymentProductPackagesGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete a product package
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductPackageApi
     */
    public apiPaymentProductPackagesIdDelete(id: string, options?: AxiosRequestConfig) {
        return ProductPackageApiFp(this.configuration).apiPaymentProductPackagesIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get single product package detail.
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductPackageApi
     */
    public apiPaymentProductPackagesIdGet(id: string, options?: AxiosRequestConfig) {
        return ProductPackageApiFp(this.configuration).apiPaymentProductPackagesIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Add a new product package
     * @param {ProductPackageModel} [productPackageModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductPackageApi
     */
    public apiPaymentProductPackagesPost(productPackageModel?: ProductPackageModel, options?: AxiosRequestConfig) {
        return ProductPackageApiFp(this.configuration).apiPaymentProductPackagesPost(productPackageModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update product packages
     * @param {ProductPackageModel} [productPackageModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductPackageApi
     */
    public apiPaymentProductPackagesPut(productPackageModel?: ProductPackageModel, options?: AxiosRequestConfig) {
        return ProductPackageApiFp(this.configuration).apiPaymentProductPackagesPut(productPackageModel, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ReportApi - axios parameter creator
 * @export
 */
export const ReportApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get cognito users by company
         * @param {CognitoUsersByCompanyRequestModel} [cognitoUsersByCompanyRequestModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAccountReportLoginsPost: async (cognitoUsersByCompanyRequestModel?: CognitoUsersByCompanyRequestModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/account/report/logins`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(cognitoUsersByCompanyRequestModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all subscriptions (implemented to bypass 100 limit of Stripe API)
         * @param {string} [endDate] 
         * @param {string} [startDate] 
         * @param {string} [status] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPaymentReportSubscriptionsAllGet: async (endDate?: string, startDate?: string, status?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/payment/report/subscriptions/all`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (endDate !== undefined) {
                localVarQueryParameter['EndDate'] = (endDate as any instanceof Date) ?
                    (endDate as any).toISOString() :
                    endDate;
            }

            if (startDate !== undefined) {
                localVarQueryParameter['StartDate'] = (startDate as any instanceof Date) ?
                    (startDate as any).toISOString() :
                    startDate;
            }

            if (status !== undefined) {
                localVarQueryParameter['Status'] = status;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get subscriptions
         * @param {number} [limit] 
         * @param {string} [startingAfter] 
         * @param {string} [status] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPaymentReportSubscriptionsGet: async (limit?: number, startingAfter?: string, status?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/payment/report/subscriptions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (limit !== undefined) {
                localVarQueryParameter['Limit'] = limit;
            }

            if (startingAfter !== undefined) {
                localVarQueryParameter['StartingAfter'] = startingAfter;
            }

            if (status !== undefined) {
                localVarQueryParameter['Status'] = status;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ReportApi - functional programming interface
 * @export
 */
export const ReportApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ReportApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get cognito users by company
         * @param {CognitoUsersByCompanyRequestModel} [cognitoUsersByCompanyRequestModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAccountReportLoginsPost(cognitoUsersByCompanyRequestModel?: CognitoUsersByCompanyRequestModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CognitoUsersByCompanyResponseModelIEnumerableIDataResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAccountReportLoginsPost(cognitoUsersByCompanyRequestModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get all subscriptions (implemented to bypass 100 limit of Stripe API)
         * @param {string} [endDate] 
         * @param {string} [startDate] 
         * @param {string} [status] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiPaymentReportSubscriptionsAllGet(endDate?: string, startDate?: string, status?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiPaymentReportSubscriptionsAllGet(endDate, startDate, status, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get subscriptions
         * @param {number} [limit] 
         * @param {string} [startingAfter] 
         * @param {string} [status] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiPaymentReportSubscriptionsGet(limit?: number, startingAfter?: string, status?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiPaymentReportSubscriptionsGet(limit, startingAfter, status, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ReportApi - factory interface
 * @export
 */
export const ReportApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ReportApiFp(configuration)
    return {
        /**
         * 
         * @summary Get cognito users by company
         * @param {CognitoUsersByCompanyRequestModel} [cognitoUsersByCompanyRequestModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAccountReportLoginsPost(cognitoUsersByCompanyRequestModel?: CognitoUsersByCompanyRequestModel, options?: any): AxiosPromise<CognitoUsersByCompanyResponseModelIEnumerableIDataResult> {
            return localVarFp.apiAccountReportLoginsPost(cognitoUsersByCompanyRequestModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get all subscriptions (implemented to bypass 100 limit of Stripe API)
         * @param {string} [endDate] 
         * @param {string} [startDate] 
         * @param {string} [status] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPaymentReportSubscriptionsAllGet(endDate?: string, startDate?: string, status?: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiPaymentReportSubscriptionsAllGet(endDate, startDate, status, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get subscriptions
         * @param {number} [limit] 
         * @param {string} [startingAfter] 
         * @param {string} [status] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPaymentReportSubscriptionsGet(limit?: number, startingAfter?: string, status?: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiPaymentReportSubscriptionsGet(limit, startingAfter, status, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ReportApi - object-oriented interface
 * @export
 * @class ReportApi
 * @extends {BaseAPI}
 */
export class ReportApi extends BaseAPI {
    /**
     * 
     * @summary Get cognito users by company
     * @param {CognitoUsersByCompanyRequestModel} [cognitoUsersByCompanyRequestModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportApi
     */
    public apiAccountReportLoginsPost(cognitoUsersByCompanyRequestModel?: CognitoUsersByCompanyRequestModel, options?: AxiosRequestConfig) {
        return ReportApiFp(this.configuration).apiAccountReportLoginsPost(cognitoUsersByCompanyRequestModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get all subscriptions (implemented to bypass 100 limit of Stripe API)
     * @param {string} [endDate] 
     * @param {string} [startDate] 
     * @param {string} [status] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportApi
     */
    public apiPaymentReportSubscriptionsAllGet(endDate?: string, startDate?: string, status?: string, options?: AxiosRequestConfig) {
        return ReportApiFp(this.configuration).apiPaymentReportSubscriptionsAllGet(endDate, startDate, status, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get subscriptions
     * @param {number} [limit] 
     * @param {string} [startingAfter] 
     * @param {string} [status] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportApi
     */
    public apiPaymentReportSubscriptionsGet(limit?: number, startingAfter?: string, status?: string, options?: AxiosRequestConfig) {
        return ReportApiFp(this.configuration).apiPaymentReportSubscriptionsGet(limit, startingAfter, status, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * SaleApi - axios parameter creator
 * @export
 */
export const SaleApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get sales
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {SaleRequestModel} [saleRequestModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPaymentSalesPost: async (pageNumber?: number, pageSize?: number, saleRequestModel?: SaleRequestModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/payment/sales`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (pageNumber !== undefined) {
                localVarQueryParameter['PageNumber'] = pageNumber;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(saleRequestModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get User Subscription Summary
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPaymentSalesUsersUserIdSalesGet: async (userId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('apiPaymentSalesUsersUserIdSalesGet', 'userId', userId)
            const localVarPath = `/api/payment/sales/users/{userId}/sales`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SaleApi - functional programming interface
 * @export
 */
export const SaleApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SaleApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get sales
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {SaleRequestModel} [saleRequestModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiPaymentSalesPost(pageNumber?: number, pageSize?: number, saleRequestModel?: SaleRequestModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SaleModelPagedResultsIDataResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiPaymentSalesPost(pageNumber, pageSize, saleRequestModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get User Subscription Summary
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiPaymentSalesUsersUserIdSalesGet(userId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserSaleHistoryModelIEnumerableIDataResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiPaymentSalesUsersUserIdSalesGet(userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SaleApi - factory interface
 * @export
 */
export const SaleApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SaleApiFp(configuration)
    return {
        /**
         * 
         * @summary Get sales
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {SaleRequestModel} [saleRequestModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPaymentSalesPost(pageNumber?: number, pageSize?: number, saleRequestModel?: SaleRequestModel, options?: any): AxiosPromise<SaleModelPagedResultsIDataResult> {
            return localVarFp.apiPaymentSalesPost(pageNumber, pageSize, saleRequestModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get User Subscription Summary
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPaymentSalesUsersUserIdSalesGet(userId: string, options?: any): AxiosPromise<UserSaleHistoryModelIEnumerableIDataResult> {
            return localVarFp.apiPaymentSalesUsersUserIdSalesGet(userId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SaleApi - object-oriented interface
 * @export
 * @class SaleApi
 * @extends {BaseAPI}
 */
export class SaleApi extends BaseAPI {
    /**
     * 
     * @summary Get sales
     * @param {number} [pageNumber] 
     * @param {number} [pageSize] 
     * @param {SaleRequestModel} [saleRequestModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SaleApi
     */
    public apiPaymentSalesPost(pageNumber?: number, pageSize?: number, saleRequestModel?: SaleRequestModel, options?: AxiosRequestConfig) {
        return SaleApiFp(this.configuration).apiPaymentSalesPost(pageNumber, pageSize, saleRequestModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get User Subscription Summary
     * @param {string} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SaleApi
     */
    public apiPaymentSalesUsersUserIdSalesGet(userId: string, options?: AxiosRequestConfig) {
        return SaleApiFp(this.configuration).apiPaymentSalesUsersUserIdSalesGet(userId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * SearchApi - axios parameter creator
 * @export
 */
export const SearchApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Search codes with code, description by given code set type(s)
         * @param {AdvancedSearchRequestModel} [advancedSearchRequestModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSearchAdvancedCodesPost: async (advancedSearchRequestModel?: AdvancedSearchRequestModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/search/advanced/codes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(advancedSearchRequestModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Search by code
         * @param {string} code 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSearchCodesCodeGet: async (code: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'code' is not null or undefined
            assertParamExists('apiSearchCodesCodeGet', 'code', code)
            const localVarPath = `/api/search/codes/{code}`
                .replace(`{${"code"}}`, encodeURIComponent(String(code)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Search code
         * @param {string} [searchText] 
         * @param {boolean} [isPrefix] 
         * @param {number} [skipItemCount] 
         * @param {number} [maxItemCount] 
         * @param {string} [highlightPreTag] 
         * @param {string} [highlightPostTag] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSearchCodesGet: async (searchText?: string, isPrefix?: boolean, skipItemCount?: number, maxItemCount?: number, highlightPreTag?: string, highlightPostTag?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/search/codes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (searchText !== undefined) {
                localVarQueryParameter['SearchText'] = searchText;
            }

            if (isPrefix !== undefined) {
                localVarQueryParameter['IsPrefix'] = isPrefix;
            }

            if (skipItemCount !== undefined) {
                localVarQueryParameter['SkipItemCount'] = skipItemCount;
            }

            if (maxItemCount !== undefined) {
                localVarQueryParameter['MaxItemCount'] = maxItemCount;
            }

            if (highlightPreTag !== undefined) {
                localVarQueryParameter['HighlightPreTag'] = highlightPreTag;
            }

            if (highlightPostTag !== undefined) {
                localVarQueryParameter['HighlightPostTag'] = highlightPostTag;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Search code by id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSearchCodesIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiSearchCodesIdGet', 'id', id)
            const localVarPath = `/api/search/codes/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Suggest code
         * @param {string} [keyword] 
         * @param {string} [userId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSearchCodesSuggestGet: async (keyword?: string, userId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/search/codes/suggest`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (keyword !== undefined) {
                localVarQueryParameter['keyword'] = keyword;
            }

            if (userId !== undefined) {
                localVarQueryParameter['userId'] = userId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary GetLastSearchHistories
         * @param {SearchHistoryRequestModel} [searchHistoryRequestModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSearchLastSearchHistoriesPost: async (searchHistoryRequestModel?: SearchHistoryRequestModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/search/last-search-histories`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(searchHistoryRequestModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Search NPI
         * @param {NpiSearchRequestModel} [npiSearchRequestModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSearchNpiPost: async (npiSearchRequestModel?: NpiSearchRequestModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/search/npi`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(npiSearchRequestModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary GetMyUserCodeNoteIndexes
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSearchUsersMeUserCodeNoteIndexesGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/search/users/me/user-code-note-indexes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary GetUserCodeNoteIndexes
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSearchUsersUserIdUserCodeNoteIndexesPost: async (userId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('apiSearchUsersUserIdUserCodeNoteIndexesPost', 'userId', userId)
            const localVarPath = `/api/search/users/{userId}/user-code-note-indexes`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SearchApi - functional programming interface
 * @export
 */
export const SearchApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SearchApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Search codes with code, description by given code set type(s)
         * @param {AdvancedSearchRequestModel} [advancedSearchRequestModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiSearchAdvancedCodesPost(advancedSearchRequestModel?: AdvancedSearchRequestModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SearchResponseIEnumerableIDataResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiSearchAdvancedCodesPost(advancedSearchRequestModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Search by code
         * @param {string} code 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiSearchCodesCodeGet(code: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SearchResponseIEnumerableIDataResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiSearchCodesCodeGet(code, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Search code
         * @param {string} [searchText] 
         * @param {boolean} [isPrefix] 
         * @param {number} [skipItemCount] 
         * @param {number} [maxItemCount] 
         * @param {string} [highlightPreTag] 
         * @param {string} [highlightPostTag] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiSearchCodesGet(searchText?: string, isPrefix?: boolean, skipItemCount?: number, maxItemCount?: number, highlightPreTag?: string, highlightPostTag?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SearchResponseIEnumerableIDataResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiSearchCodesGet(searchText, isPrefix, skipItemCount, maxItemCount, highlightPreTag, highlightPostTag, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Search code by id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiSearchCodesIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SearchResponseIDataResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiSearchCodesIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Suggest code
         * @param {string} [keyword] 
         * @param {string} [userId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiSearchCodesSuggestGet(keyword?: string, userId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SuggestResponseIDataResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiSearchCodesSuggestGet(keyword, userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary GetLastSearchHistories
         * @param {SearchHistoryRequestModel} [searchHistoryRequestModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiSearchLastSearchHistoriesPost(searchHistoryRequestModel?: SearchHistoryRequestModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LastSearchHistoryResponseModelIEnumerableIDataResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiSearchLastSearchHistoriesPost(searchHistoryRequestModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Search NPI
         * @param {NpiSearchRequestModel} [npiSearchRequestModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiSearchNpiPost(npiSearchRequestModel?: NpiSearchRequestModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NpiSearchResponseIDataResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiSearchNpiPost(npiSearchRequestModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary GetMyUserCodeNoteIndexes
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiSearchUsersMeUserCodeNoteIndexesGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserCodeNoteSearchResponseIEnumerableIDataResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiSearchUsersMeUserCodeNoteIndexesGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary GetUserCodeNoteIndexes
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiSearchUsersUserIdUserCodeNoteIndexesPost(userId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserCodeNoteSearchResponseIEnumerableIDataResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiSearchUsersUserIdUserCodeNoteIndexesPost(userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SearchApi - factory interface
 * @export
 */
export const SearchApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SearchApiFp(configuration)
    return {
        /**
         * 
         * @summary Search codes with code, description by given code set type(s)
         * @param {AdvancedSearchRequestModel} [advancedSearchRequestModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSearchAdvancedCodesPost(advancedSearchRequestModel?: AdvancedSearchRequestModel, options?: any): AxiosPromise<SearchResponseIEnumerableIDataResult> {
            return localVarFp.apiSearchAdvancedCodesPost(advancedSearchRequestModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Search by code
         * @param {string} code 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSearchCodesCodeGet(code: string, options?: any): AxiosPromise<SearchResponseIEnumerableIDataResult> {
            return localVarFp.apiSearchCodesCodeGet(code, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Search code
         * @param {string} [searchText] 
         * @param {boolean} [isPrefix] 
         * @param {number} [skipItemCount] 
         * @param {number} [maxItemCount] 
         * @param {string} [highlightPreTag] 
         * @param {string} [highlightPostTag] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSearchCodesGet(searchText?: string, isPrefix?: boolean, skipItemCount?: number, maxItemCount?: number, highlightPreTag?: string, highlightPostTag?: string, options?: any): AxiosPromise<SearchResponseIEnumerableIDataResult> {
            return localVarFp.apiSearchCodesGet(searchText, isPrefix, skipItemCount, maxItemCount, highlightPreTag, highlightPostTag, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Search code by id
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSearchCodesIdGet(id: string, options?: any): AxiosPromise<SearchResponseIDataResult> {
            return localVarFp.apiSearchCodesIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Suggest code
         * @param {string} [keyword] 
         * @param {string} [userId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSearchCodesSuggestGet(keyword?: string, userId?: string, options?: any): AxiosPromise<SuggestResponseIDataResult> {
            return localVarFp.apiSearchCodesSuggestGet(keyword, userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary GetLastSearchHistories
         * @param {SearchHistoryRequestModel} [searchHistoryRequestModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSearchLastSearchHistoriesPost(searchHistoryRequestModel?: SearchHistoryRequestModel, options?: any): AxiosPromise<LastSearchHistoryResponseModelIEnumerableIDataResult> {
            return localVarFp.apiSearchLastSearchHistoriesPost(searchHistoryRequestModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Search NPI
         * @param {NpiSearchRequestModel} [npiSearchRequestModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSearchNpiPost(npiSearchRequestModel?: NpiSearchRequestModel, options?: any): AxiosPromise<NpiSearchResponseIDataResult> {
            return localVarFp.apiSearchNpiPost(npiSearchRequestModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary GetMyUserCodeNoteIndexes
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSearchUsersMeUserCodeNoteIndexesGet(options?: any): AxiosPromise<UserCodeNoteSearchResponseIEnumerableIDataResult> {
            return localVarFp.apiSearchUsersMeUserCodeNoteIndexesGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary GetUserCodeNoteIndexes
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSearchUsersUserIdUserCodeNoteIndexesPost(userId: string, options?: any): AxiosPromise<UserCodeNoteSearchResponseIEnumerableIDataResult> {
            return localVarFp.apiSearchUsersUserIdUserCodeNoteIndexesPost(userId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SearchApi - object-oriented interface
 * @export
 * @class SearchApi
 * @extends {BaseAPI}
 */
export class SearchApi extends BaseAPI {
    /**
     * 
     * @summary Search codes with code, description by given code set type(s)
     * @param {AdvancedSearchRequestModel} [advancedSearchRequestModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SearchApi
     */
    public apiSearchAdvancedCodesPost(advancedSearchRequestModel?: AdvancedSearchRequestModel, options?: AxiosRequestConfig) {
        return SearchApiFp(this.configuration).apiSearchAdvancedCodesPost(advancedSearchRequestModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Search by code
     * @param {string} code 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SearchApi
     */
    public apiSearchCodesCodeGet(code: string, options?: AxiosRequestConfig) {
        return SearchApiFp(this.configuration).apiSearchCodesCodeGet(code, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Search code
     * @param {string} [searchText] 
     * @param {boolean} [isPrefix] 
     * @param {number} [skipItemCount] 
     * @param {number} [maxItemCount] 
     * @param {string} [highlightPreTag] 
     * @param {string} [highlightPostTag] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SearchApi
     */
    public apiSearchCodesGet(searchText?: string, isPrefix?: boolean, skipItemCount?: number, maxItemCount?: number, highlightPreTag?: string, highlightPostTag?: string, options?: AxiosRequestConfig) {
        return SearchApiFp(this.configuration).apiSearchCodesGet(searchText, isPrefix, skipItemCount, maxItemCount, highlightPreTag, highlightPostTag, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Search code by id
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SearchApi
     */
    public apiSearchCodesIdGet(id: string, options?: AxiosRequestConfig) {
        return SearchApiFp(this.configuration).apiSearchCodesIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Suggest code
     * @param {string} [keyword] 
     * @param {string} [userId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SearchApi
     */
    public apiSearchCodesSuggestGet(keyword?: string, userId?: string, options?: AxiosRequestConfig) {
        return SearchApiFp(this.configuration).apiSearchCodesSuggestGet(keyword, userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary GetLastSearchHistories
     * @param {SearchHistoryRequestModel} [searchHistoryRequestModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SearchApi
     */
    public apiSearchLastSearchHistoriesPost(searchHistoryRequestModel?: SearchHistoryRequestModel, options?: AxiosRequestConfig) {
        return SearchApiFp(this.configuration).apiSearchLastSearchHistoriesPost(searchHistoryRequestModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Search NPI
     * @param {NpiSearchRequestModel} [npiSearchRequestModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SearchApi
     */
    public apiSearchNpiPost(npiSearchRequestModel?: NpiSearchRequestModel, options?: AxiosRequestConfig) {
        return SearchApiFp(this.configuration).apiSearchNpiPost(npiSearchRequestModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary GetMyUserCodeNoteIndexes
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SearchApi
     */
    public apiSearchUsersMeUserCodeNoteIndexesGet(options?: AxiosRequestConfig) {
        return SearchApiFp(this.configuration).apiSearchUsersMeUserCodeNoteIndexesGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary GetUserCodeNoteIndexes
     * @param {string} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SearchApi
     */
    public apiSearchUsersUserIdUserCodeNoteIndexesPost(userId: string, options?: AxiosRequestConfig) {
        return SearchApiFp(this.configuration).apiSearchUsersUserIdUserCodeNoteIndexesPost(userId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * SubscriptionApi - axios parameter creator
 * @export
 */
export const SubscriptionApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get active user products
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPaymentSubscriptionsActiveProductsGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/payment/subscriptions/active-products`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Change User Subscription Status \"canceled\"
         * @param {string} subscriptionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPaymentSubscriptionsCancelSubscriptionIdDelete: async (subscriptionId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'subscriptionId' is not null or undefined
            assertParamExists('apiPaymentSubscriptionsCancelSubscriptionIdDelete', 'subscriptionId', subscriptionId)
            const localVarPath = `/api/payment/subscriptions/cancel/{subscriptionId}`
                .replace(`{${"subscriptionId"}}`, encodeURIComponent(String(subscriptionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create a subscription create intention
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPaymentSubscriptionsIntentGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/payment/subscriptions/intent`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get User And Company Subscription Summary
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPaymentSubscriptionsMyPlanGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/payment/subscriptions/my-plan`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create a subscription request. If there is an active subscription, products will be added to the subscription.  Important! For the active subscription MetaData fields user_id and user_emails fields must have value.
         * @param {CreateSubscriptionApiRequestModel} [createSubscriptionApiRequestModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPaymentSubscriptionsPost: async (createSubscriptionApiRequestModel?: CreateSubscriptionApiRequestModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/payment/subscriptions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createSubscriptionApiRequestModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Resume A Subscription Scheduled For Canceling
         * @param {string} subscriptionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPaymentSubscriptionsResumeSubscriptionIdPut: async (subscriptionId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'subscriptionId' is not null or undefined
            assertParamExists('apiPaymentSubscriptionsResumeSubscriptionIdPut', 'subscriptionId', subscriptionId)
            const localVarPath = `/api/payment/subscriptions/resume/{subscriptionId}`
                .replace(`{${"subscriptionId"}}`, encodeURIComponent(String(subscriptionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get user subscription schedule
         * @param {string} scheduleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPaymentSubscriptionsScheduleScheduleIdGet: async (scheduleId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'scheduleId' is not null or undefined
            assertParamExists('apiPaymentSubscriptionsScheduleScheduleIdGet', 'scheduleId', scheduleId)
            const localVarPath = `/api/payment/subscriptions/schedule/{scheduleId}`
                .replace(`{${"scheduleId"}}`, encodeURIComponent(String(scheduleId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete The Subscription Item
         * @param {string} subscriptionItemId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPaymentSubscriptionsSubscriptionItemSubscriptionItemIdDelete: async (subscriptionItemId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'subscriptionItemId' is not null or undefined
            assertParamExists('apiPaymentSubscriptionsSubscriptionItemSubscriptionItemIdDelete', 'subscriptionItemId', subscriptionItemId)
            const localVarPath = `/api/payment/subscriptions/subscription-item/{subscriptionItemId}`
                .replace(`{${"subscriptionItemId"}}`, encodeURIComponent(String(subscriptionItemId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Company Subscription Summary
         * @param {string} companyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPaymentSubscriptionsSummaryCompaniesCompanyIdGet: async (companyId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('apiPaymentSubscriptionsSummaryCompaniesCompanyIdGet', 'companyId', companyId)
            const localVarPath = `/api/payment/subscriptions/summary/companies/{companyId}`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get User Subscription Summary
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPaymentSubscriptionsSummaryUsersUserIdGet: async (userId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('apiPaymentSubscriptionsSummaryUsersUserIdGet', 'userId', userId)
            const localVarPath = `/api/payment/subscriptions/summary/users/{userId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get upcoming invoice summary by subscriptions
         * @param {UpcomingInvoiceBySubscriptionsRequestModel} [upcomingInvoiceBySubscriptionsRequestModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPaymentSubscriptionsUpcomingInvoicePost: async (upcomingInvoiceBySubscriptionsRequestModel?: UpcomingInvoiceBySubscriptionsRequestModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/payment/subscriptions/upcoming-invoice`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(upcomingInvoiceBySubscriptionsRequestModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Signed User\'s Active Product Features
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPaymentSubscriptionsUsersMeActiveProductFeaturesGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/payment/subscriptions/users/me/active-product-features`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get User Subscription Last Failed Payment Info
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPaymentSubscriptionsUsersUserIdLastFailedPaymentsGet: async (userId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('apiPaymentSubscriptionsUsersUserIdLastFailedPaymentsGet', 'userId', userId)
            const localVarPath = `/api/payment/subscriptions/users/{userId}/last-failed-payments`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get User Subscription Summary
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPaymentSubscriptionsUsersUserIdSubscriptionsGet: async (userId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('apiPaymentSubscriptionsUsersUserIdSubscriptionsGet', 'userId', userId)
            const localVarPath = `/api/payment/subscriptions/users/{userId}/subscriptions`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SubscriptionApi - functional programming interface
 * @export
 */
export const SubscriptionApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SubscriptionApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get active user products
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiPaymentSubscriptionsActiveProductsGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserProductsModelIEnumerableIDataResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiPaymentSubscriptionsActiveProductsGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Change User Subscription Status \"canceled\"
         * @param {string} subscriptionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiPaymentSubscriptionsCancelSubscriptionIdDelete(subscriptionId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StripeSubscriptionResponseModelIDataResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiPaymentSubscriptionsCancelSubscriptionIdDelete(subscriptionId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create a subscription create intention
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiPaymentSubscriptionsIntentGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StripeSetupIntentResponseModelIDataResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiPaymentSubscriptionsIntentGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get User And Company Subscription Summary
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiPaymentSubscriptionsMyPlanGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StripeSubscriptionSummaryModelIDataResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiPaymentSubscriptionsMyPlanGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create a subscription request. If there is an active subscription, products will be added to the subscription.  Important! For the active subscription MetaData fields user_id and user_emails fields must have value.
         * @param {CreateSubscriptionApiRequestModel} [createSubscriptionApiRequestModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiPaymentSubscriptionsPost(createSubscriptionApiRequestModel?: CreateSubscriptionApiRequestModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateUpdateSubscriptionResponseModelIDataResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiPaymentSubscriptionsPost(createSubscriptionApiRequestModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Resume A Subscription Scheduled For Canceling
         * @param {string} subscriptionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiPaymentSubscriptionsResumeSubscriptionIdPut(subscriptionId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StripeSubscriptionResponseModelIDataResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiPaymentSubscriptionsResumeSubscriptionIdPut(subscriptionId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get user subscription schedule
         * @param {string} scheduleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiPaymentSubscriptionsScheduleScheduleIdGet(scheduleId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StripeSubscriptionScheduleResponseModelIDataResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiPaymentSubscriptionsScheduleScheduleIdGet(scheduleId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete The Subscription Item
         * @param {string} subscriptionItemId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiPaymentSubscriptionsSubscriptionItemSubscriptionItemIdDelete(subscriptionItemId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StripeSubscriptionItemModelIDataResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiPaymentSubscriptionsSubscriptionItemSubscriptionItemIdDelete(subscriptionItemId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Company Subscription Summary
         * @param {string} companyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiPaymentSubscriptionsSummaryCompaniesCompanyIdGet(companyId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StripeSubscriptionSummaryModelIEnumerableIDataResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiPaymentSubscriptionsSummaryCompaniesCompanyIdGet(companyId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get User Subscription Summary
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiPaymentSubscriptionsSummaryUsersUserIdGet(userId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StripeSubscriptionSummaryModelIDataResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiPaymentSubscriptionsSummaryUsersUserIdGet(userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get upcoming invoice summary by subscriptions
         * @param {UpcomingInvoiceBySubscriptionsRequestModel} [upcomingInvoiceBySubscriptionsRequestModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiPaymentSubscriptionsUpcomingInvoicePost(upcomingInvoiceBySubscriptionsRequestModel?: UpcomingInvoiceBySubscriptionsRequestModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UpcomingInvoiceSummaryResponseModelIDataResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiPaymentSubscriptionsUpcomingInvoicePost(upcomingInvoiceBySubscriptionsRequestModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Signed User\'s Active Product Features
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiPaymentSubscriptionsUsersMeActiveProductFeaturesGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserActiveProductFeaturesModelIDataResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiPaymentSubscriptionsUsersMeActiveProductFeaturesGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get User Subscription Last Failed Payment Info
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiPaymentSubscriptionsUsersUserIdLastFailedPaymentsGet(userId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserSubscriptionLastPaymentInfoModelIEnumerableIDataResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiPaymentSubscriptionsUsersUserIdLastFailedPaymentsGet(userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get User Subscription Summary
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiPaymentSubscriptionsUsersUserIdSubscriptionsGet(userId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserSubscriptionDetailsModelIEnumerableIDataResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiPaymentSubscriptionsUsersUserIdSubscriptionsGet(userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SubscriptionApi - factory interface
 * @export
 */
export const SubscriptionApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SubscriptionApiFp(configuration)
    return {
        /**
         * 
         * @summary Get active user products
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPaymentSubscriptionsActiveProductsGet(options?: any): AxiosPromise<UserProductsModelIEnumerableIDataResult> {
            return localVarFp.apiPaymentSubscriptionsActiveProductsGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Change User Subscription Status \"canceled\"
         * @param {string} subscriptionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPaymentSubscriptionsCancelSubscriptionIdDelete(subscriptionId: string, options?: any): AxiosPromise<StripeSubscriptionResponseModelIDataResult> {
            return localVarFp.apiPaymentSubscriptionsCancelSubscriptionIdDelete(subscriptionId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create a subscription create intention
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPaymentSubscriptionsIntentGet(options?: any): AxiosPromise<StripeSetupIntentResponseModelIDataResult> {
            return localVarFp.apiPaymentSubscriptionsIntentGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get User And Company Subscription Summary
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPaymentSubscriptionsMyPlanGet(options?: any): AxiosPromise<StripeSubscriptionSummaryModelIDataResult> {
            return localVarFp.apiPaymentSubscriptionsMyPlanGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create a subscription request. If there is an active subscription, products will be added to the subscription.  Important! For the active subscription MetaData fields user_id and user_emails fields must have value.
         * @param {CreateSubscriptionApiRequestModel} [createSubscriptionApiRequestModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPaymentSubscriptionsPost(createSubscriptionApiRequestModel?: CreateSubscriptionApiRequestModel, options?: any): AxiosPromise<CreateUpdateSubscriptionResponseModelIDataResult> {
            return localVarFp.apiPaymentSubscriptionsPost(createSubscriptionApiRequestModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Resume A Subscription Scheduled For Canceling
         * @param {string} subscriptionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPaymentSubscriptionsResumeSubscriptionIdPut(subscriptionId: string, options?: any): AxiosPromise<StripeSubscriptionResponseModelIDataResult> {
            return localVarFp.apiPaymentSubscriptionsResumeSubscriptionIdPut(subscriptionId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get user subscription schedule
         * @param {string} scheduleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPaymentSubscriptionsScheduleScheduleIdGet(scheduleId: string, options?: any): AxiosPromise<StripeSubscriptionScheduleResponseModelIDataResult> {
            return localVarFp.apiPaymentSubscriptionsScheduleScheduleIdGet(scheduleId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete The Subscription Item
         * @param {string} subscriptionItemId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPaymentSubscriptionsSubscriptionItemSubscriptionItemIdDelete(subscriptionItemId: string, options?: any): AxiosPromise<StripeSubscriptionItemModelIDataResult> {
            return localVarFp.apiPaymentSubscriptionsSubscriptionItemSubscriptionItemIdDelete(subscriptionItemId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Company Subscription Summary
         * @param {string} companyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPaymentSubscriptionsSummaryCompaniesCompanyIdGet(companyId: string, options?: any): AxiosPromise<StripeSubscriptionSummaryModelIEnumerableIDataResult> {
            return localVarFp.apiPaymentSubscriptionsSummaryCompaniesCompanyIdGet(companyId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get User Subscription Summary
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPaymentSubscriptionsSummaryUsersUserIdGet(userId: string, options?: any): AxiosPromise<StripeSubscriptionSummaryModelIDataResult> {
            return localVarFp.apiPaymentSubscriptionsSummaryUsersUserIdGet(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get upcoming invoice summary by subscriptions
         * @param {UpcomingInvoiceBySubscriptionsRequestModel} [upcomingInvoiceBySubscriptionsRequestModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPaymentSubscriptionsUpcomingInvoicePost(upcomingInvoiceBySubscriptionsRequestModel?: UpcomingInvoiceBySubscriptionsRequestModel, options?: any): AxiosPromise<UpcomingInvoiceSummaryResponseModelIDataResult> {
            return localVarFp.apiPaymentSubscriptionsUpcomingInvoicePost(upcomingInvoiceBySubscriptionsRequestModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Signed User\'s Active Product Features
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPaymentSubscriptionsUsersMeActiveProductFeaturesGet(options?: any): AxiosPromise<UserActiveProductFeaturesModelIDataResult> {
            return localVarFp.apiPaymentSubscriptionsUsersMeActiveProductFeaturesGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get User Subscription Last Failed Payment Info
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPaymentSubscriptionsUsersUserIdLastFailedPaymentsGet(userId: string, options?: any): AxiosPromise<UserSubscriptionLastPaymentInfoModelIEnumerableIDataResult> {
            return localVarFp.apiPaymentSubscriptionsUsersUserIdLastFailedPaymentsGet(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get User Subscription Summary
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPaymentSubscriptionsUsersUserIdSubscriptionsGet(userId: string, options?: any): AxiosPromise<UserSubscriptionDetailsModelIEnumerableIDataResult> {
            return localVarFp.apiPaymentSubscriptionsUsersUserIdSubscriptionsGet(userId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SubscriptionApi - object-oriented interface
 * @export
 * @class SubscriptionApi
 * @extends {BaseAPI}
 */
export class SubscriptionApi extends BaseAPI {
    /**
     * 
     * @summary Get active user products
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubscriptionApi
     */
    public apiPaymentSubscriptionsActiveProductsGet(options?: AxiosRequestConfig) {
        return SubscriptionApiFp(this.configuration).apiPaymentSubscriptionsActiveProductsGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Change User Subscription Status \"canceled\"
     * @param {string} subscriptionId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubscriptionApi
     */
    public apiPaymentSubscriptionsCancelSubscriptionIdDelete(subscriptionId: string, options?: AxiosRequestConfig) {
        return SubscriptionApiFp(this.configuration).apiPaymentSubscriptionsCancelSubscriptionIdDelete(subscriptionId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create a subscription create intention
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubscriptionApi
     */
    public apiPaymentSubscriptionsIntentGet(options?: AxiosRequestConfig) {
        return SubscriptionApiFp(this.configuration).apiPaymentSubscriptionsIntentGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get User And Company Subscription Summary
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubscriptionApi
     */
    public apiPaymentSubscriptionsMyPlanGet(options?: AxiosRequestConfig) {
        return SubscriptionApiFp(this.configuration).apiPaymentSubscriptionsMyPlanGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create a subscription request. If there is an active subscription, products will be added to the subscription.  Important! For the active subscription MetaData fields user_id and user_emails fields must have value.
     * @param {CreateSubscriptionApiRequestModel} [createSubscriptionApiRequestModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubscriptionApi
     */
    public apiPaymentSubscriptionsPost(createSubscriptionApiRequestModel?: CreateSubscriptionApiRequestModel, options?: AxiosRequestConfig) {
        return SubscriptionApiFp(this.configuration).apiPaymentSubscriptionsPost(createSubscriptionApiRequestModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Resume A Subscription Scheduled For Canceling
     * @param {string} subscriptionId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubscriptionApi
     */
    public apiPaymentSubscriptionsResumeSubscriptionIdPut(subscriptionId: string, options?: AxiosRequestConfig) {
        return SubscriptionApiFp(this.configuration).apiPaymentSubscriptionsResumeSubscriptionIdPut(subscriptionId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get user subscription schedule
     * @param {string} scheduleId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubscriptionApi
     */
    public apiPaymentSubscriptionsScheduleScheduleIdGet(scheduleId: string, options?: AxiosRequestConfig) {
        return SubscriptionApiFp(this.configuration).apiPaymentSubscriptionsScheduleScheduleIdGet(scheduleId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete The Subscription Item
     * @param {string} subscriptionItemId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubscriptionApi
     */
    public apiPaymentSubscriptionsSubscriptionItemSubscriptionItemIdDelete(subscriptionItemId: string, options?: AxiosRequestConfig) {
        return SubscriptionApiFp(this.configuration).apiPaymentSubscriptionsSubscriptionItemSubscriptionItemIdDelete(subscriptionItemId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Company Subscription Summary
     * @param {string} companyId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubscriptionApi
     */
    public apiPaymentSubscriptionsSummaryCompaniesCompanyIdGet(companyId: string, options?: AxiosRequestConfig) {
        return SubscriptionApiFp(this.configuration).apiPaymentSubscriptionsSummaryCompaniesCompanyIdGet(companyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get User Subscription Summary
     * @param {string} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubscriptionApi
     */
    public apiPaymentSubscriptionsSummaryUsersUserIdGet(userId: string, options?: AxiosRequestConfig) {
        return SubscriptionApiFp(this.configuration).apiPaymentSubscriptionsSummaryUsersUserIdGet(userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get upcoming invoice summary by subscriptions
     * @param {UpcomingInvoiceBySubscriptionsRequestModel} [upcomingInvoiceBySubscriptionsRequestModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubscriptionApi
     */
    public apiPaymentSubscriptionsUpcomingInvoicePost(upcomingInvoiceBySubscriptionsRequestModel?: UpcomingInvoiceBySubscriptionsRequestModel, options?: AxiosRequestConfig) {
        return SubscriptionApiFp(this.configuration).apiPaymentSubscriptionsUpcomingInvoicePost(upcomingInvoiceBySubscriptionsRequestModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Signed User\'s Active Product Features
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubscriptionApi
     */
    public apiPaymentSubscriptionsUsersMeActiveProductFeaturesGet(options?: AxiosRequestConfig) {
        return SubscriptionApiFp(this.configuration).apiPaymentSubscriptionsUsersMeActiveProductFeaturesGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get User Subscription Last Failed Payment Info
     * @param {string} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubscriptionApi
     */
    public apiPaymentSubscriptionsUsersUserIdLastFailedPaymentsGet(userId: string, options?: AxiosRequestConfig) {
        return SubscriptionApiFp(this.configuration).apiPaymentSubscriptionsUsersUserIdLastFailedPaymentsGet(userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get User Subscription Summary
     * @param {string} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubscriptionApi
     */
    public apiPaymentSubscriptionsUsersUserIdSubscriptionsGet(userId: string, options?: AxiosRequestConfig) {
        return SubscriptionApiFp(this.configuration).apiPaymentSubscriptionsUsersUserIdSubscriptionsGet(userId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * UserCodeApi - axios parameter creator
 * @export
 */
export const UserCodeApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get all user codes
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAccountUserCodesGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/account/user-codes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Set user default location
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAccountUserCodesIdDefaultPut: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiAccountUserCodesIdDefaultPut', 'id', id)
            const localVarPath = `/api/account/user-codes/{id}/default`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete user code
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAccountUserCodesIdDelete: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiAccountUserCodesIdDelete', 'id', id)
            const localVarPath = `/api/account/user-codes/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary RemoveUserFavoriteCode
         * @param {string} code 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAccountUserCodesMyFavoriteCodesCodeDelete: async (code: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'code' is not null or undefined
            assertParamExists('apiAccountUserCodesMyFavoriteCodesCodeDelete', 'code', code)
            const localVarPath = `/api/account/user-codes/my-favorite-codes/{code}`
                .replace(`{${"code"}}`, encodeURIComponent(String(code)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary AddUserFavoriteCode
         * @param {string} code 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAccountUserCodesMyFavoriteCodesCodePost: async (code: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'code' is not null or undefined
            assertParamExists('apiAccountUserCodesMyFavoriteCodesCodePost', 'code', code)
            const localVarPath = `/api/account/user-codes/my-favorite-codes/{code}`
                .replace(`{${"code"}}`, encodeURIComponent(String(code)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary GetUserFavoriteCodes
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAccountUserCodesMyFavoriteCodesGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/account/user-codes/my-favorite-codes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Add user code
         * @param {UserCodeModel} [userCodeModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAccountUserCodesPost: async (userCodeModel?: UserCodeModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/account/user-codes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userCodeModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update user code
         * @param {UserCodeModel} [userCodeModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAccountUserCodesPut: async (userCodeModel?: UserCodeModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/account/user-codes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userCodeModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserCodeApi - functional programming interface
 * @export
 */
export const UserCodeApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UserCodeApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get all user codes
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAccountUserCodesGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserCodeModelIEnumerableIDataResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAccountUserCodesGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Set user default location
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAccountUserCodesIdDefaultPut(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BooleanIDataResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAccountUserCodesIdDefaultPut(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete user code
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAccountUserCodesIdDelete(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAccountUserCodesIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary RemoveUserFavoriteCode
         * @param {string} code 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAccountUserCodesMyFavoriteCodesCodeDelete(code: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAccountUserCodesMyFavoriteCodesCodeDelete(code, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary AddUserFavoriteCode
         * @param {string} code 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAccountUserCodesMyFavoriteCodesCodePost(code: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAccountUserCodesMyFavoriteCodesCodePost(code, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary GetUserFavoriteCodes
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAccountUserCodesMyFavoriteCodesGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserCodeModelIDataResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAccountUserCodesMyFavoriteCodesGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Add user code
         * @param {UserCodeModel} [userCodeModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAccountUserCodesPost(userCodeModel?: UserCodeModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserCodeModelIDataResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAccountUserCodesPost(userCodeModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update user code
         * @param {UserCodeModel} [userCodeModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAccountUserCodesPut(userCodeModel?: UserCodeModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserCodeModelIDataResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAccountUserCodesPut(userCodeModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UserCodeApi - factory interface
 * @export
 */
export const UserCodeApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UserCodeApiFp(configuration)
    return {
        /**
         * 
         * @summary Get all user codes
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAccountUserCodesGet(options?: any): AxiosPromise<UserCodeModelIEnumerableIDataResult> {
            return localVarFp.apiAccountUserCodesGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Set user default location
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAccountUserCodesIdDefaultPut(id: string, options?: any): AxiosPromise<BooleanIDataResult> {
            return localVarFp.apiAccountUserCodesIdDefaultPut(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete user code
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAccountUserCodesIdDelete(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiAccountUserCodesIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary RemoveUserFavoriteCode
         * @param {string} code 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAccountUserCodesMyFavoriteCodesCodeDelete(code: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiAccountUserCodesMyFavoriteCodesCodeDelete(code, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary AddUserFavoriteCode
         * @param {string} code 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAccountUserCodesMyFavoriteCodesCodePost(code: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiAccountUserCodesMyFavoriteCodesCodePost(code, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary GetUserFavoriteCodes
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAccountUserCodesMyFavoriteCodesGet(options?: any): AxiosPromise<UserCodeModelIDataResult> {
            return localVarFp.apiAccountUserCodesMyFavoriteCodesGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Add user code
         * @param {UserCodeModel} [userCodeModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAccountUserCodesPost(userCodeModel?: UserCodeModel, options?: any): AxiosPromise<UserCodeModelIDataResult> {
            return localVarFp.apiAccountUserCodesPost(userCodeModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update user code
         * @param {UserCodeModel} [userCodeModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAccountUserCodesPut(userCodeModel?: UserCodeModel, options?: any): AxiosPromise<UserCodeModelIDataResult> {
            return localVarFp.apiAccountUserCodesPut(userCodeModel, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UserCodeApi - object-oriented interface
 * @export
 * @class UserCodeApi
 * @extends {BaseAPI}
 */
export class UserCodeApi extends BaseAPI {
    /**
     * 
     * @summary Get all user codes
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserCodeApi
     */
    public apiAccountUserCodesGet(options?: AxiosRequestConfig) {
        return UserCodeApiFp(this.configuration).apiAccountUserCodesGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Set user default location
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserCodeApi
     */
    public apiAccountUserCodesIdDefaultPut(id: string, options?: AxiosRequestConfig) {
        return UserCodeApiFp(this.configuration).apiAccountUserCodesIdDefaultPut(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete user code
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserCodeApi
     */
    public apiAccountUserCodesIdDelete(id: string, options?: AxiosRequestConfig) {
        return UserCodeApiFp(this.configuration).apiAccountUserCodesIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary RemoveUserFavoriteCode
     * @param {string} code 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserCodeApi
     */
    public apiAccountUserCodesMyFavoriteCodesCodeDelete(code: string, options?: AxiosRequestConfig) {
        return UserCodeApiFp(this.configuration).apiAccountUserCodesMyFavoriteCodesCodeDelete(code, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary AddUserFavoriteCode
     * @param {string} code 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserCodeApi
     */
    public apiAccountUserCodesMyFavoriteCodesCodePost(code: string, options?: AxiosRequestConfig) {
        return UserCodeApiFp(this.configuration).apiAccountUserCodesMyFavoriteCodesCodePost(code, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary GetUserFavoriteCodes
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserCodeApi
     */
    public apiAccountUserCodesMyFavoriteCodesGet(options?: AxiosRequestConfig) {
        return UserCodeApiFp(this.configuration).apiAccountUserCodesMyFavoriteCodesGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Add user code
     * @param {UserCodeModel} [userCodeModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserCodeApi
     */
    public apiAccountUserCodesPost(userCodeModel?: UserCodeModel, options?: AxiosRequestConfig) {
        return UserCodeApiFp(this.configuration).apiAccountUserCodesPost(userCodeModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update user code
     * @param {UserCodeModel} [userCodeModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserCodeApi
     */
    public apiAccountUserCodesPut(userCodeModel?: UserCodeModel, options?: AxiosRequestConfig) {
        return UserCodeApiFp(this.configuration).apiAccountUserCodesPut(userCodeModel, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * UserCodeNoteApi - axios parameter creator
 * @export
 */
export const UserCodeNoteApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get Favorite user code note
         * @param {FavoriteUserCodeNoteModel} [favoriteUserCodeNoteModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAccountMeFavoriteUserCodeNotePost: async (favoriteUserCodeNoteModel?: FavoriteUserCodeNoteModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/account/me/favorite-user-code-note`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(favoriteUserCodeNoteModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Remove Favorite user code note
         * @param {FavoriteUserCodeNoteModel} [favoriteUserCodeNoteModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAccountMeFavoriteUserCodeNotesDelete: async (favoriteUserCodeNoteModel?: FavoriteUserCodeNoteModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/account/me/favorite-user-code-notes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(favoriteUserCodeNoteModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Favorite user code notes
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAccountMeFavoriteUserCodeNotesGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/account/me/favorite-user-code-notes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Add Favorite user code note
         * @param {FavoriteUserCodeNoteModel} [favoriteUserCodeNoteModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAccountMeFavoriteUserCodeNotesPost: async (favoriteUserCodeNoteModel?: FavoriteUserCodeNoteModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/account/me/favorite-user-code-notes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(favoriteUserCodeNoteModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete user code note
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAccountUserCodeNotesIdDelete: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiAccountUserCodeNotesIdDelete', 'id', id)
            const localVarPath = `/api/account/user-code-notes/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Add user code note
         * @param {UserCodeNoteBatchModel} [userCodeNoteBatchModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAccountUserCodeNotesPost: async (userCodeNoteBatchModel?: UserCodeNoteBatchModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/account/user-code-notes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userCodeNoteBatchModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update user code note
         * @param {UserCodeNoteModel} [userCodeNoteModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAccountUserCodeNotesPut: async (userCodeNoteModel?: UserCodeNoteModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/account/user-code-notes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userCodeNoteModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserCodeNoteApi - functional programming interface
 * @export
 */
export const UserCodeNoteApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UserCodeNoteApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get Favorite user code note
         * @param {FavoriteUserCodeNoteModel} [favoriteUserCodeNoteModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAccountMeFavoriteUserCodeNotePost(favoriteUserCodeNoteModel?: FavoriteUserCodeNoteModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserCodeNoteModelIDataResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAccountMeFavoriteUserCodeNotePost(favoriteUserCodeNoteModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Remove Favorite user code note
         * @param {FavoriteUserCodeNoteModel} [favoriteUserCodeNoteModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAccountMeFavoriteUserCodeNotesDelete(favoriteUserCodeNoteModel?: FavoriteUserCodeNoteModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAccountMeFavoriteUserCodeNotesDelete(favoriteUserCodeNoteModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Favorite user code notes
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAccountMeFavoriteUserCodeNotesGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FavoriteUserCodeNoteModelIEnumerableIDataResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAccountMeFavoriteUserCodeNotesGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Add Favorite user code note
         * @param {FavoriteUserCodeNoteModel} [favoriteUserCodeNoteModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAccountMeFavoriteUserCodeNotesPost(favoriteUserCodeNoteModel?: FavoriteUserCodeNoteModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserCodeNoteModelIDataResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAccountMeFavoriteUserCodeNotesPost(favoriteUserCodeNoteModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete user code note
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAccountUserCodeNotesIdDelete(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAccountUserCodeNotesIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Add user code note
         * @param {UserCodeNoteBatchModel} [userCodeNoteBatchModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAccountUserCodeNotesPost(userCodeNoteBatchModel?: UserCodeNoteBatchModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserCodeNoteBatchResponseModelIDataResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAccountUserCodeNotesPost(userCodeNoteBatchModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update user code note
         * @param {UserCodeNoteModel} [userCodeNoteModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAccountUserCodeNotesPut(userCodeNoteModel?: UserCodeNoteModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserCodeNoteModelIDataResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAccountUserCodeNotesPut(userCodeNoteModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UserCodeNoteApi - factory interface
 * @export
 */
export const UserCodeNoteApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UserCodeNoteApiFp(configuration)
    return {
        /**
         * 
         * @summary Get Favorite user code note
         * @param {FavoriteUserCodeNoteModel} [favoriteUserCodeNoteModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAccountMeFavoriteUserCodeNotePost(favoriteUserCodeNoteModel?: FavoriteUserCodeNoteModel, options?: any): AxiosPromise<UserCodeNoteModelIDataResult> {
            return localVarFp.apiAccountMeFavoriteUserCodeNotePost(favoriteUserCodeNoteModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Remove Favorite user code note
         * @param {FavoriteUserCodeNoteModel} [favoriteUserCodeNoteModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAccountMeFavoriteUserCodeNotesDelete(favoriteUserCodeNoteModel?: FavoriteUserCodeNoteModel, options?: any): AxiosPromise<void> {
            return localVarFp.apiAccountMeFavoriteUserCodeNotesDelete(favoriteUserCodeNoteModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Favorite user code notes
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAccountMeFavoriteUserCodeNotesGet(options?: any): AxiosPromise<FavoriteUserCodeNoteModelIEnumerableIDataResult> {
            return localVarFp.apiAccountMeFavoriteUserCodeNotesGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Add Favorite user code note
         * @param {FavoriteUserCodeNoteModel} [favoriteUserCodeNoteModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAccountMeFavoriteUserCodeNotesPost(favoriteUserCodeNoteModel?: FavoriteUserCodeNoteModel, options?: any): AxiosPromise<UserCodeNoteModelIDataResult> {
            return localVarFp.apiAccountMeFavoriteUserCodeNotesPost(favoriteUserCodeNoteModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete user code note
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAccountUserCodeNotesIdDelete(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiAccountUserCodeNotesIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Add user code note
         * @param {UserCodeNoteBatchModel} [userCodeNoteBatchModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAccountUserCodeNotesPost(userCodeNoteBatchModel?: UserCodeNoteBatchModel, options?: any): AxiosPromise<UserCodeNoteBatchResponseModelIDataResult> {
            return localVarFp.apiAccountUserCodeNotesPost(userCodeNoteBatchModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update user code note
         * @param {UserCodeNoteModel} [userCodeNoteModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAccountUserCodeNotesPut(userCodeNoteModel?: UserCodeNoteModel, options?: any): AxiosPromise<UserCodeNoteModelIDataResult> {
            return localVarFp.apiAccountUserCodeNotesPut(userCodeNoteModel, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UserCodeNoteApi - object-oriented interface
 * @export
 * @class UserCodeNoteApi
 * @extends {BaseAPI}
 */
export class UserCodeNoteApi extends BaseAPI {
    /**
     * 
     * @summary Get Favorite user code note
     * @param {FavoriteUserCodeNoteModel} [favoriteUserCodeNoteModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserCodeNoteApi
     */
    public apiAccountMeFavoriteUserCodeNotePost(favoriteUserCodeNoteModel?: FavoriteUserCodeNoteModel, options?: AxiosRequestConfig) {
        return UserCodeNoteApiFp(this.configuration).apiAccountMeFavoriteUserCodeNotePost(favoriteUserCodeNoteModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Remove Favorite user code note
     * @param {FavoriteUserCodeNoteModel} [favoriteUserCodeNoteModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserCodeNoteApi
     */
    public apiAccountMeFavoriteUserCodeNotesDelete(favoriteUserCodeNoteModel?: FavoriteUserCodeNoteModel, options?: AxiosRequestConfig) {
        return UserCodeNoteApiFp(this.configuration).apiAccountMeFavoriteUserCodeNotesDelete(favoriteUserCodeNoteModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Favorite user code notes
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserCodeNoteApi
     */
    public apiAccountMeFavoriteUserCodeNotesGet(options?: AxiosRequestConfig) {
        return UserCodeNoteApiFp(this.configuration).apiAccountMeFavoriteUserCodeNotesGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Add Favorite user code note
     * @param {FavoriteUserCodeNoteModel} [favoriteUserCodeNoteModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserCodeNoteApi
     */
    public apiAccountMeFavoriteUserCodeNotesPost(favoriteUserCodeNoteModel?: FavoriteUserCodeNoteModel, options?: AxiosRequestConfig) {
        return UserCodeNoteApiFp(this.configuration).apiAccountMeFavoriteUserCodeNotesPost(favoriteUserCodeNoteModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete user code note
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserCodeNoteApi
     */
    public apiAccountUserCodeNotesIdDelete(id: string, options?: AxiosRequestConfig) {
        return UserCodeNoteApiFp(this.configuration).apiAccountUserCodeNotesIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Add user code note
     * @param {UserCodeNoteBatchModel} [userCodeNoteBatchModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserCodeNoteApi
     */
    public apiAccountUserCodeNotesPost(userCodeNoteBatchModel?: UserCodeNoteBatchModel, options?: AxiosRequestConfig) {
        return UserCodeNoteApiFp(this.configuration).apiAccountUserCodeNotesPost(userCodeNoteBatchModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update user code note
     * @param {UserCodeNoteModel} [userCodeNoteModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserCodeNoteApi
     */
    public apiAccountUserCodeNotesPut(userCodeNoteModel?: UserCodeNoteModel, options?: AxiosRequestConfig) {
        return UserCodeNoteApiFp(this.configuration).apiAccountUserCodeNotesPut(userCodeNoteModel, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * UserCodeNotesApi - axios parameter creator
 * @export
 */
export const UserCodeNotesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCodesUserCodeNoteIndexesIdDelete: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiCodesUserCodeNoteIndexesIdDelete', 'id', id)
            const localVarPath = `/api/codes/user-code-note-indexes/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UserCodeNoteModel1} [userCodeNoteModel1] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCodesUserCodeNoteIndexesPost: async (userCodeNoteModel1?: UserCodeNoteModel1, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/codes/user-code-note-indexes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userCodeNoteModel1, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserCodeNotesApi - functional programming interface
 * @export
 */
export const UserCodeNotesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UserCodeNotesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCodesUserCodeNoteIndexesIdDelete(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCodesUserCodeNoteIndexesIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {UserCodeNoteModel1} [userCodeNoteModel1] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCodesUserCodeNoteIndexesPost(userCodeNoteModel1?: UserCodeNoteModel1, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCodesUserCodeNoteIndexesPost(userCodeNoteModel1, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UserCodeNotesApi - factory interface
 * @export
 */
export const UserCodeNotesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UserCodeNotesApiFp(configuration)
    return {
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCodesUserCodeNoteIndexesIdDelete(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiCodesUserCodeNoteIndexesIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UserCodeNoteModel1} [userCodeNoteModel1] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCodesUserCodeNoteIndexesPost(userCodeNoteModel1?: UserCodeNoteModel1, options?: any): AxiosPromise<void> {
            return localVarFp.apiCodesUserCodeNoteIndexesPost(userCodeNoteModel1, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UserCodeNotesApi - object-oriented interface
 * @export
 * @class UserCodeNotesApi
 * @extends {BaseAPI}
 */
export class UserCodeNotesApi extends BaseAPI {
    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserCodeNotesApi
     */
    public apiCodesUserCodeNoteIndexesIdDelete(id: string, options?: AxiosRequestConfig) {
        return UserCodeNotesApiFp(this.configuration).apiCodesUserCodeNoteIndexesIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UserCodeNoteModel1} [userCodeNoteModel1] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserCodeNotesApi
     */
    public apiCodesUserCodeNoteIndexesPost(userCodeNoteModel1?: UserCodeNoteModel1, options?: AxiosRequestConfig) {
        return UserCodeNotesApiFp(this.configuration).apiCodesUserCodeNoteIndexesPost(userCodeNoteModel1, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * UserLocationApi - axios parameter creator
 * @export
 */
export const UserLocationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Set user default location
         * @param {string} id 
         * @param {string} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAccountUserLocationsIdDefaultPut: async (id: string, body?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiAccountUserLocationsIdDefaultPut', 'id', id)
            const localVarPath = `/api/account/user-locations/{id}/default`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete user location
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAccountUserLocationsIdDelete: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiAccountUserLocationsIdDelete', 'id', id)
            const localVarPath = `/api/account/user-locations/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Add user location
         * @param {UserLocationModel} [userLocationModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAccountUserLocationsPost: async (userLocationModel?: UserLocationModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/account/user-locations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userLocationModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update user location
         * @param {UserLocationModel} [userLocationModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAccountUserLocationsPut: async (userLocationModel?: UserLocationModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/account/user-locations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userLocationModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserLocationApi - functional programming interface
 * @export
 */
export const UserLocationApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UserLocationApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Set user default location
         * @param {string} id 
         * @param {string} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAccountUserLocationsIdDefaultPut(id: string, body?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAccountUserLocationsIdDefaultPut(id, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete user location
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAccountUserLocationsIdDelete(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAccountUserLocationsIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Add user location
         * @param {UserLocationModel} [userLocationModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAccountUserLocationsPost(userLocationModel?: UserLocationModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserLocationModelIDataResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAccountUserLocationsPost(userLocationModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update user location
         * @param {UserLocationModel} [userLocationModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAccountUserLocationsPut(userLocationModel?: UserLocationModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserLocationModelIDataResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAccountUserLocationsPut(userLocationModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UserLocationApi - factory interface
 * @export
 */
export const UserLocationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UserLocationApiFp(configuration)
    return {
        /**
         * 
         * @summary Set user default location
         * @param {string} id 
         * @param {string} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAccountUserLocationsIdDefaultPut(id: string, body?: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiAccountUserLocationsIdDefaultPut(id, body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete user location
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAccountUserLocationsIdDelete(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiAccountUserLocationsIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Add user location
         * @param {UserLocationModel} [userLocationModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAccountUserLocationsPost(userLocationModel?: UserLocationModel, options?: any): AxiosPromise<UserLocationModelIDataResult> {
            return localVarFp.apiAccountUserLocationsPost(userLocationModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update user location
         * @param {UserLocationModel} [userLocationModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAccountUserLocationsPut(userLocationModel?: UserLocationModel, options?: any): AxiosPromise<UserLocationModelIDataResult> {
            return localVarFp.apiAccountUserLocationsPut(userLocationModel, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UserLocationApi - object-oriented interface
 * @export
 * @class UserLocationApi
 * @extends {BaseAPI}
 */
export class UserLocationApi extends BaseAPI {
    /**
     * 
     * @summary Set user default location
     * @param {string} id 
     * @param {string} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserLocationApi
     */
    public apiAccountUserLocationsIdDefaultPut(id: string, body?: string, options?: AxiosRequestConfig) {
        return UserLocationApiFp(this.configuration).apiAccountUserLocationsIdDefaultPut(id, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete user location
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserLocationApi
     */
    public apiAccountUserLocationsIdDelete(id: string, options?: AxiosRequestConfig) {
        return UserLocationApiFp(this.configuration).apiAccountUserLocationsIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Add user location
     * @param {UserLocationModel} [userLocationModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserLocationApi
     */
    public apiAccountUserLocationsPost(userLocationModel?: UserLocationModel, options?: AxiosRequestConfig) {
        return UserLocationApiFp(this.configuration).apiAccountUserLocationsPost(userLocationModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update user location
     * @param {UserLocationModel} [userLocationModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserLocationApi
     */
    public apiAccountUserLocationsPut(userLocationModel?: UserLocationModel, options?: AxiosRequestConfig) {
        return UserLocationApiFp(this.configuration).apiAccountUserLocationsPut(userLocationModel, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * UserLoginReportApi - axios parameter creator
 * @export
 */
export const UserLoginReportApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get user\'s logindetailreport
         * @param {UserLoginDetailRequestModel} [userLoginDetailRequestModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAccountUsersUserLoginDetailReportsPost: async (userLoginDetailRequestModel?: UserLoginDetailRequestModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/account/users/user-login-detail-reports`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userLoginDetailRequestModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get user\'s loginreport
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {PeriodRequestModel} [periodRequestModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAccountUsersUserLoginReportsPost: async (pageNumber?: number, pageSize?: number, periodRequestModel?: PeriodRequestModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/account/users/user-login-reports`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (pageNumber !== undefined) {
                localVarQueryParameter['PageNumber'] = pageNumber;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(periodRequestModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserLoginReportApi - functional programming interface
 * @export
 */
export const UserLoginReportApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UserLoginReportApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get user\'s logindetailreport
         * @param {UserLoginDetailRequestModel} [userLoginDetailRequestModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAccountUsersUserLoginDetailReportsPost(userLoginDetailRequestModel?: UserLoginDetailRequestModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserLoginHistoryModelIEnumerableIDataResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAccountUsersUserLoginDetailReportsPost(userLoginDetailRequestModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get user\'s loginreport
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {PeriodRequestModel} [periodRequestModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAccountUsersUserLoginReportsPost(pageNumber?: number, pageSize?: number, periodRequestModel?: PeriodRequestModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserLoginDayHistoryModelPagedResultsIDataResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAccountUsersUserLoginReportsPost(pageNumber, pageSize, periodRequestModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UserLoginReportApi - factory interface
 * @export
 */
export const UserLoginReportApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UserLoginReportApiFp(configuration)
    return {
        /**
         * 
         * @summary Get user\'s logindetailreport
         * @param {UserLoginDetailRequestModel} [userLoginDetailRequestModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAccountUsersUserLoginDetailReportsPost(userLoginDetailRequestModel?: UserLoginDetailRequestModel, options?: any): AxiosPromise<UserLoginHistoryModelIEnumerableIDataResult> {
            return localVarFp.apiAccountUsersUserLoginDetailReportsPost(userLoginDetailRequestModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get user\'s loginreport
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {PeriodRequestModel} [periodRequestModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAccountUsersUserLoginReportsPost(pageNumber?: number, pageSize?: number, periodRequestModel?: PeriodRequestModel, options?: any): AxiosPromise<UserLoginDayHistoryModelPagedResultsIDataResult> {
            return localVarFp.apiAccountUsersUserLoginReportsPost(pageNumber, pageSize, periodRequestModel, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UserLoginReportApi - object-oriented interface
 * @export
 * @class UserLoginReportApi
 * @extends {BaseAPI}
 */
export class UserLoginReportApi extends BaseAPI {
    /**
     * 
     * @summary Get user\'s logindetailreport
     * @param {UserLoginDetailRequestModel} [userLoginDetailRequestModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserLoginReportApi
     */
    public apiAccountUsersUserLoginDetailReportsPost(userLoginDetailRequestModel?: UserLoginDetailRequestModel, options?: AxiosRequestConfig) {
        return UserLoginReportApiFp(this.configuration).apiAccountUsersUserLoginDetailReportsPost(userLoginDetailRequestModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get user\'s loginreport
     * @param {number} [pageNumber] 
     * @param {number} [pageSize] 
     * @param {PeriodRequestModel} [periodRequestModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserLoginReportApi
     */
    public apiAccountUsersUserLoginReportsPost(pageNumber?: number, pageSize?: number, periodRequestModel?: PeriodRequestModel, options?: AxiosRequestConfig) {
        return UserLoginReportApiFp(this.configuration).apiAccountUsersUserLoginReportsPost(pageNumber, pageSize, periodRequestModel, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * UserProductSaleApi - axios parameter creator
 * @export
 */
export const UserProductSaleApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get user\'s product sale reports
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {PeriodRequestModel1} [periodRequestModel1] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPaymentUserUserProductSalesPost: async (pageNumber?: number, pageSize?: number, periodRequestModel1?: PeriodRequestModel1, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/payment/user/user-product-sales`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (pageNumber !== undefined) {
                localVarQueryParameter['PageNumber'] = pageNumber;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(periodRequestModel1, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserProductSaleApi - functional programming interface
 * @export
 */
export const UserProductSaleApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UserProductSaleApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get user\'s product sale reports
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {PeriodRequestModel1} [periodRequestModel1] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiPaymentUserUserProductSalesPost(pageNumber?: number, pageSize?: number, periodRequestModel1?: PeriodRequestModel1, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserProductSaleReportsModelPagedResultsIDataResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiPaymentUserUserProductSalesPost(pageNumber, pageSize, periodRequestModel1, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UserProductSaleApi - factory interface
 * @export
 */
export const UserProductSaleApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UserProductSaleApiFp(configuration)
    return {
        /**
         * 
         * @summary Get user\'s product sale reports
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {PeriodRequestModel1} [periodRequestModel1] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPaymentUserUserProductSalesPost(pageNumber?: number, pageSize?: number, periodRequestModel1?: PeriodRequestModel1, options?: any): AxiosPromise<UserProductSaleReportsModelPagedResultsIDataResult> {
            return localVarFp.apiPaymentUserUserProductSalesPost(pageNumber, pageSize, periodRequestModel1, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UserProductSaleApi - object-oriented interface
 * @export
 * @class UserProductSaleApi
 * @extends {BaseAPI}
 */
export class UserProductSaleApi extends BaseAPI {
    /**
     * 
     * @summary Get user\'s product sale reports
     * @param {number} [pageNumber] 
     * @param {number} [pageSize] 
     * @param {PeriodRequestModel1} [periodRequestModel1] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserProductSaleApi
     */
    public apiPaymentUserUserProductSalesPost(pageNumber?: number, pageSize?: number, periodRequestModel1?: PeriodRequestModel1, options?: AxiosRequestConfig) {
        return UserProductSaleApiFp(this.configuration).apiPaymentUserUserProductSalesPost(pageNumber, pageSize, periodRequestModel1, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ZipCodeApi - axios parameter creator
 * @export
 */
export const ZipCodeApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} zipCode 
         * @param {number} [day] 
         * @param {number} [month] 
         * @param {number} [year] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCodeZipCodesZipCodeMedicareGpciGet: async (zipCode: string, day?: number, month?: number, year?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'zipCode' is not null or undefined
            assertParamExists('apiCodeZipCodesZipCodeMedicareGpciGet', 'zipCode', zipCode)
            const localVarPath = `/api/code/zip-codes/{zipCode}/medicare-gpci`
                .replace(`{${"zipCode"}}`, encodeURIComponent(String(zipCode)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKey required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (day !== undefined) {
                localVarQueryParameter['Day'] = day;
            }

            if (month !== undefined) {
                localVarQueryParameter['Month'] = month;
            }

            if (year !== undefined) {
                localVarQueryParameter['Year'] = year;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ZipCodeApi - functional programming interface
 * @export
 */
export const ZipCodeApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ZipCodeApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} zipCode 
         * @param {number} [day] 
         * @param {number} [month] 
         * @param {number} [year] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCodeZipCodesZipCodeMedicareGpciGet(zipCode: string, day?: number, month?: number, year?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ZipCodeGpciModelIEnumerableIDataResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCodeZipCodesZipCodeMedicareGpciGet(zipCode, day, month, year, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ZipCodeApi - factory interface
 * @export
 */
export const ZipCodeApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ZipCodeApiFp(configuration)
    return {
        /**
         * 
         * @param {string} zipCode 
         * @param {number} [day] 
         * @param {number} [month] 
         * @param {number} [year] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCodeZipCodesZipCodeMedicareGpciGet(zipCode: string, day?: number, month?: number, year?: number, options?: any): AxiosPromise<ZipCodeGpciModelIEnumerableIDataResult> {
            return localVarFp.apiCodeZipCodesZipCodeMedicareGpciGet(zipCode, day, month, year, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ZipCodeApi - object-oriented interface
 * @export
 * @class ZipCodeApi
 * @extends {BaseAPI}
 */
export class ZipCodeApi extends BaseAPI {
    /**
     * 
     * @param {string} zipCode 
     * @param {number} [day] 
     * @param {number} [month] 
     * @param {number} [year] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ZipCodeApi
     */
    public apiCodeZipCodesZipCodeMedicareGpciGet(zipCode: string, day?: number, month?: number, year?: number, options?: AxiosRequestConfig) {
        return ZipCodeApiFp(this.configuration).apiCodeZipCodesZipCodeMedicareGpciGet(zipCode, day, month, year, options).then((request) => request(this.axios, this.basePath));
    }
}


