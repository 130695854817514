// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs';
import {SENTRY_DSN, SENTRY_RELEASE, SENTRY_SAMPLING_RATE} from "./environment";

Sentry.init({
  dsn: SENTRY_DSN ,
  release: SENTRY_RELEASE,
  tracesSampleRate: SENTRY_SAMPLING_RATE,
  ignoreErrors: [/The user is not authenticated/]
});
