const amplifyConfig = {
  'aws_project_region': process.env.NEXT_PUBLIC_COGNITO_REGION,
  'aws_cognito_region': process.env.NEXT_PUBLIC_COGNITO_REGION,
  'aws_user_pools_id': process.env.NEXT_PUBLIC_COGNITO_USER_POOL_ID,
  'aws_user_pools_web_client_id': process.env.NEXT_PUBLIC_COGNITO_USER_POOL_CLIENT_ID,
  'oauth': {
    'domain': process.env.NEXT_PUBLIC_COGNITO_DOMAIN,
    'scope': [
      'email',
      'openid',
      'profile',
      'aws.cognito.signin.user.admin'
    ],
    'redirectSignIn': process.env.NEXT_PUBLIC_COGNITO_DOMAIN,
    'redirectSignOut': process.env.NEXT_PUBLIC_COGNITO_DOMAIN,
    'responseType': 'code'
  },
  'federationTarget': 'COGNITO_USER_POOLS'
};

export default amplifyConfig;
